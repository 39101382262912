import { gql } from "@apollo/client"

const WHITELISTS_QUERY = gql`
query Whitelists(
  $page: Int
  $perPage: Int
  $search: String
  $orderDirection: OrderDirectionEnum
  $orderBy: WhitelistsOrderByEnum
  $spaceId: ID!
  $type: WhitelistTypeEnum
) {
  whitelists(
    page: $page
    perPage: $perPage
    search: $search
    orderDirection: $orderDirection
    orderBy: $orderBy
    spaceId: $spaceId
    type: $type
  ) {
		items {
      id
      firstName
      lastName
      type
      identification
      email
      address
      originalType
      cellPhoneNumber
    }
    pagination {
      page
      perPage
      totalItems
      totalPages
    }
  }
}
`
export default WHITELISTS_QUERY
