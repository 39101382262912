import { gql } from "@apollo/client"

const GET_BANK_ACCOUNTS = gql`
  query BankAccounts($spaceId: ID!) {
    bankAccounts(spaceId: $spaceId) {
      items {
        accountNumber
        id
        routeType
        bank {
          title
          icon
        }
      }
      pagination {
        totalItems
      }
    }
  }
`
export default GET_BANK_ACCOUNTS
