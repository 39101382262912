import React, { useEffect } from 'react'
import { GeneralButton } from 'components'
import styles from './styles.module.scss'
import { Icon } from '@iconify/react'
import clsx from 'clsx'
import { GeneralInputLabel } from 'web/components'
import { useMediaQuery, Theme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getBankAccountSelector, userSpaceSelector } from 'store/selectors'
import { bankFees, parseAmount, parseToSimpleNumber, toFloat, amountMask } from 'utils'
import { FormikConfig } from './formik'
import { setBankAccountData } from 'store/actions'
import { useDispatch } from 'react-redux'
import { UseIntermitence } from 'hooks'
import { CreateBankAccount } from 'web/components'
import { UseAlert, GetBalance } from 'hooks'

const BankAccountFirstStep = ({ handleStep }: any) => {

  const dispatch = useDispatch()
  const { title, amount, memo, type } = useSelector(getBankAccountSelector)
  const { currentBranchOffice } = useSelector(userSpaceSelector);

  const isSpecial = currentBranchOffice?.space?.isSpecial
  const tmpWallet = currentBranchOffice.wallets.find((w: any) => {
    return w.wallet.name == 'zelle'
  })

  const feeValue = tmpWallet ? tmpWallet.feeValue : 1
  
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))
  const { t } = useTranslation()
  const { switchStatus, status } = UseIntermitence()

  const handleSubmit = () => handleStep(2);
  const { showAlert } = UseAlert()

  const { balance } = GetBalance()

  const {
    values,
    handleSubmit: formikSubmit,
    handleChange,
    submitForm,
    errors,
    touched,
    setTouched,
    setFieldValue,
    isValid
  } = FormikConfig(handleSubmit, amount, memo)

  const changeInputStatus = (value: string, error: any) => {
    if (value !== "") {
      if (error) return "error";
      return "success";
    }
    return "inherit";
  };

  const handleSubmitData = () => {
    const { amount, memo } = values
    dispatch(setBankAccountData({ amount, memo }));
    if (!title) return showAlert('need_add_account', 'error')
    if (balance < amountWithFees(amount) || amountWithFees(amount) == 0) return showAlert('insufficient_balance', 'error')
    submitForm()
  }

  const amountWithFees = (currentAmount: string) => {
    const newAmount = isSpecial 
      ? parseFloat(parseToSimpleNumber(currentAmount || 0)) - bankFees[type] || 0 - (Number(parseToSimpleNumber(currentAmount)) * feeValue) 
      : parseFloat(parseToSimpleNumber(currentAmount || 0)) - bankFees[type] || 0
  
    return newAmount
  }

  useEffect(() => {
    handleOnTouched('amount')
  }, [])

  const handleOnTouched = (key: string) => setTouched({ ...touched, [key]: true });

  return (
    <>
      <div className={styles._main}>
        <div className={styles._btnsParent}>
          <GeneralButton
            text={!isMobile ? 'my_accounts' : null}
            className={styles._btnCustom}
            width={!isMobile ? '7rem' : '3.25rem'}
            icon={isMobile && <Icon icon='bx:list-ul' width={25} />}
            action={() => handleStep(1)}
          />
          <GeneralButton
            text={!isMobile ? 'affiliate' : null}
            className={styles._btnCustom}
            width={!isMobile ? '7rem' : '3.25rem'}
            action={() => switchStatus()}
            icon={isMobile && <Icon icon='bx:user-plus' width={25} />} />
        </div>

        <div className={clsx(styles._row, styles._topMargin, styles._borderBottom)}>
          <p className={clsx(styles._text,)}>{t('send_to')}:</p>

          <div className={clsx(styles._row, styles._center, styles._pointer)} onClick={() => handleStep(1)}>
            <div>
              <p className={clsx(styles._text, styles._rightText)}>{title || t('choose_destination_account')}</p>
              <p className={clsx(styles._text, styles._grayText)}>{title ? `${t('account_affiliated')} ${title}` : t('account_other_banks')}</p>
            </div>
            <Icon icon='bx:chevron-right' color='#919191' width={24} className={styles._iconStyles} />
          </div>
        </div>

        <div className={clsx(styles._row, styles._borderBottom, styles._paddingTop)}>
          <p className={styles._text}>{t('from')}:</p>

          <div className={clsx(styles._row, styles._center)}>
            <div>
              <p className={clsx(styles._text, styles._rightText)}>{t("balance")}: ${parseAmount(balance)}</p>
              <p className={clsx(styles._text, styles._grayText, styles._rightText)}>{t('puntu_account')}</p>
            </div>
            {/* <Icon icon='bx:chevron-right' color='#919191' width={24} className={styles._iconStyles} /> */}
          </div>
        </div>
        <form onSubmit={formikSubmit}>
          <div className={clsx(styles._row, styles._center, styles._inputRow, styles._paddingTop, isMobile && styles._borderBottom, isMobile && styles._extraPadding)}>
            <div>
              <p className={styles._text}>{t('amount')}:</p>
              {/* <p className={clsx(styles._text, styles._underLine, styles._grayText)}>{t('limits')}</p> */}
            </div>
            <GeneralInputLabel
              className={styles._customInputReverse}
              label='amount'
              name='amount'
              value={values.amount}
              onChange={(event: any) => amountMask(event, setFieldValue, 'amount')}
              dir="ltr"
              placeholder='$0.00'
              id='amount'
              type='text'
              status={changeInputStatus(
                values.amount,
                errors.amount && touched.amount
              )}
            />
          </div>

          <div className={clsx(styles._row, styles._center, styles._secondMarginTop, isMobile && styles._paddingTop)}>
            <p className={styles._text}>{t('fee')}</p>
            {type ? <p className={styles._text}>${parseAmount(type == 'WIRE' ? 30 : 3)}</p> : <p className={styles._text}>$0.00</p>}
            {/* Replace false by type */}
          </div>

          {isSpecial && (
            <div className={clsx(styles._row, styles._center, styles._secondMarginTop, isMobile && styles._paddingTop)}>
              <p className={styles._text}>{feeValue != 1 ? `${feeValue * 100}%` : '0%'}</p>
              <p className={styles._text}>${
                values.amount 
                  ? parseAmount(Number(parseToSimpleNumber(values.amount)) * feeValue) 
                  : '0.00'
              }</p>
            </div>
          )}

          <div className={clsx(styles._row, styles._center, !isMobile && styles._paddingTop, styles._borderBottom)}>
            <p className={clsx(styles._text, styles._boldText)}>{t('total')}</p>
            <p className={clsx(styles._text, styles._boldText)}> ${
              values.amount 
                ? isSpecial 
                  ? parseAmount(toFloat(values.amount || '0') - (bankFees[type] || 0) - Number(parseToSimpleNumber(values.amount)) * feeValue)
                  : parseAmount(toFloat(values.amount || '0') - (bankFees[type] || 0))
                : '0.00'
            }</p>
          </div>

          <div className={clsx(styles._row, styles._center, styles._paddingTop, styles._inputRow)}>
            <div>
              <p className={clsx(styles._text, styles._grayText, styles._smallText)}>{t('memo')}</p>
              <p className={clsx(styles._text, styles._grayText, styles._smallText)}>{t('optional')}</p>
            </div>
            <GeneralInputLabel
              className={styles._customInput}
              name='memo'
              value={values.memo}
              onChange={handleChange}
              id='memo'
              placeholder={t('enter_text')}
              label={`${values.memo.length}/50`}
              maxNumber={50}
              status={changeInputStatus(
                values.memo,
                errors.memo && touched.memo
              )}
            />
          </div>

          <div className={styles._submitBtnParent}>
            <GeneralButton className={styles._responsiveButton} text='continue' width={!isMobile ? '9.0625rem' : '80%'} type='submit' action={handleSubmitData} disabled={!isValid} />
          </div>
        </form>
      </div>
      <CreateBankAccount
        open={status}
        onClose={switchStatus}
      />
    </>
  )
}

export default BankAccountFirstStep
