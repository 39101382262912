import { gql } from '@apollo/client';

const ROLES_QUERY = gql`
  query UiRoles(
    $page: Int
    $perPage: Int
    $search: String
    $orderDirection: OrderDirectionEnum
    $orderBy: UiRolesOrderByEnum
    $spaceId: ID!
  ) {
    uiRoles(
      page: $page
      perPage: $perPage
      search: $search
      orderDirection: $orderDirection
      orderBy: $orderBy
      spaceId: $spaceId
    ) {
      items {
        id
        title
        permissions
        members
        color
      }
      pagination {
        page
        perPage
        totalItems
        totalPages
      }
    }
  }
`;

export default ROLES_QUERY;
