import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  emailRegex,
  onlyLettersRegex,
  translate,
  amountCharactersRegex,
  parseToSimpleNumber,
} from 'utils';

export const FormikConfig = (handleSubmit: any) =>
  useFormik({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      amount: '',
      description: '',
      walletId: '',
      ticketReason: '',
      claimDate: '',
      claimHour: '',
      voucherPhotoUrl: '',
    },

    validationSchema: Yup.object({
      email: Yup.string()
        .required('field_required')
        .matches(emailRegex, 'email_valid'),

      firstName: Yup.string()
        .required('field_required')
        .min(3, () => translate('min_invalid', { number: 3 }))
        .matches(onlyLettersRegex, 'only_letters'),

      lastName: Yup.string()
        .required('field_required')
        .min(3, () => translate('min_invalid', { number: 3 }))
        .matches(onlyLettersRegex, 'only_letters'),

      amount: Yup.string()
        .required('field_required')
        .matches(amountCharactersRegex, 'amount_invalid'),

      description: Yup.string()
        .required('field_required')
        .min(4, () => translate('min_invalid', { number: 4 }))
        .max(255, () => translate('max_invalid', { number: 255 })),

      walletId: Yup.string().required(),
      voucherPhotoUrl: Yup.string().required(),
      ticketReason: Yup.string().max(255, () =>
        translate('max_invalid', { number: 255 })
      ),
      claimDate: Yup.date().required('field_required'),
      claimHour: Yup.string().required('field_required'),
    }),

    onSubmit: (values) => {
      values['amount'] = parseToSimpleNumber(values['amount']);
      handleSubmit(values);
    },
  });
