import { gql } from '@apollo/client';

const WALLETS = gql`
  query {
    systemAccounts {
      wallet
      imageQR
      account
    }
  }
`;
export default WALLETS;
