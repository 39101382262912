import { gql } from '@apollo/client';

const BRANCH_OFFICES_QUERY = gql`
  query BranchOffices(
    $page: Int
    $perPage: Int
    $search: String
    $orderDirection: OrderDirectionEnum
    $orderBy: BranchOfficeOrderByEnum
  ) {
    branchOffices(
      page: $page
      perPage: $perPage
      search: $search
      orderDirection: $orderDirection
      orderBy: $orderBy
    ) {
      items {
        id
        name
        address
        code
        isPrincipal
        email
        dateOfCreation
        wallets {
          id
          total
          feeValue
          wallet {
            id
            name
            title
          }
        }
        space {
          name
          isSpecial
        }
      }
      pagination {
        page
        perPage
        totalItems
        totalPages
      }
    }
  }
`;

export default BRANCH_OFFICES_QUERY;
