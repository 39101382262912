import React from 'react'
import styles from './styles.module.scss'
import styled from 'styled-components'
import { Icon } from '@iconify/react'
import { IconButtonType } from './type'
import { useTranslation } from 'react-i18next';
import CircularProgress from "@mui/material/CircularProgress";
import clsx from 'clsx'

const Button: any = styled.button`
  background-color: ${({ background }: any) => (background ? background : '#F9F9F9')};
`
const Text: any = styled.p`
  color: ${({ textColor }: any) => (textColor ? textColor : '#000')};
`
const GeneralIconButton = ({
  text,
  background,
  iconColor,
  textColor,
  method,
  loader = false,
  icon = 'bx:chevron-down',
  secondaryColor = "#909090",
  leftIcon = false,
  leftIconType = 'bx:down-arrow-alt',
  rightIcon = false,
  className = null
}: IconButtonType) => {
  const { t } = useTranslation();

  return (
    <Button background={background} className={clsx(loader ? styles._loader : styles._baseBtn, className)} onClick={loader ? null : method}>
      {loader ?
        <CircularProgress color="primary" size={22} /> :
        <div className={styles._contentParent}>
          <div className={styles._textContent}>
              <div>{ leftIcon && <Icon icon={leftIconType} width={18} color={iconColor} /> }</div>
            <Text textColor={textColor}> {t(text)}</Text>
          </div>
          <div>
            { rightIcon && <Icon icon={icon} width={20} color={secondaryColor} /> }
          </div>
        </div>
      }
    </Button>
  )
};

export default GeneralIconButton
