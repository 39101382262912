import { Icon } from '@iconify/react'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next';
import i18next from 'i18next'

const LangButton = () => {

  const { t } = useTranslation();

  const pickLanguage = ()=> {
    if(i18next.language == 'es') return i18next.changeLanguage('en')
    if(i18next.language == 'en') return i18next.changeLanguage('es')
  }

  return (
    <div className={styles._main}>
      <button onClick={() => pickLanguage()} className={styles._button}>
        <div className={styles._buttonContent}>
          <Icon className={styles._icon} icon='bx:world' color="#111111" width={22} />
          <p className={styles._text}>{t(i18next?.language)}</p>
        </div>
      </button>
    </div>
  )
}

export default LangButton;
