import { gql } from "@apollo/client"

const ACTIVITY_AVAILABLE = gql`
query ActivityAvailable(
  $spaceId:ID!
) {activityAvailable(
    spaceId: $spaceId
  )
    {
      month,
      year,
      monthNumber
    }
  }
`
export default ACTIVITY_AVAILABLE
