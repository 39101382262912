import React, { useState, memo, useEffect, useRef } from 'react'
import styles from './styles.module.scss'
import { DetailRow, DetailModal } from './components'
import { UseIntermitence } from 'hooks'
import { transformTransactionsData } from 'utils'
import { useTranslation } from 'react-i18next'
import CircularProgress from '@mui/material/CircularProgress'
import { useDispatch, useSelector } from 'react-redux'
import { activitySelector, userSpaceSelector } from 'store/selectors'
import { setActivityData } from 'store/actions'
import { useLazyQuery } from '@apollo/client'
import { ACTIVITIES_QUERY } from 'graphql/queries'
import { Icon } from '@iconify/react'
import { allWallet } from 'utils'
import { GeneralInput } from 'components'

const MobileActivityTable = ({
  activities = null,
  loading,
  paginateBy,
  noPagination,
  parentLoading,
  pagination,
  nextPage,
  prevPage,
  currentPage,
  filterByWallet,
  walletName,
  handleCarrousel = () => {},
  isMobile
}: any) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [current, setCurrent] = useState(null)
  const { status, switchStatus } = UseIntermitence()
  const { status: filterStatus, switchStatus: switchFilterStatus } = UseIntermitence()
  const [getActivities, { loading: paginationLoader }] = useLazyQuery(ACTIVITIES_QUERY)
  const { perPage: perPageStore, searchValue, walletId, transactionType } = useSelector(activitySelector)
  const { currentSpace, currentBranchOffice } = useSelector(userSpaceSelector)
  const [currentTypeFilter, setCurrentTypeFilter] = useState('all')
  const [localTransactionType, setLocalTransactionType] = useState(null)

  const activeWallets = currentBranchOffice?.wallets ?? []

  const localTransactions = activities?.detail?.transactions
  const [state] = useState(paginateBy)
  const [currentWallet, setCurrentWallet] = useState(0)
  const [value, setValue] = useState('')
  const [timer, setTimer] = useState(null)

  const table = useRef<any>(null)

  const setDetail = (data: any): void => {
    setCurrent(data)
    switchStatus()
  }

  useEffect(() => {
    if (walletName) {
      const result = (activeWallets || []).find(((item: any) => item?.wallet?.name == walletName));
      const walletIndex = activeWallets.indexOf(result)
      handleFilter(result?.wallet?.id, walletIndex + 1);
    }

    if (!walletName && activities) handleFilter(null, 0)

  }, [walletName])

  useEffect(() => {
    if (activities && state > paginateBy) {
      getActivities({
        onCompleted: (response: any) => dispatch((setActivityData(response))),
        variables: {
          page: 1,
          perPage: state,
          search: searchValue,
          walletId: walletId || null,
          transactionType: transactionType,
          spaceId: currentSpace?.id,
          branchOfficeId: currentBranchOffice?.id
        },
        fetchPolicy: 'network-only'
      })
    }
  }, [state])

  useEffect(() => {
    getActivities({
      onCompleted: (response: any) => dispatch((setActivityData(response))),
      variables: {
        page: 1,
        perPage: state,
        search: value,
        walletId: walletId || null,
        transactionType: localTransactionType,
        spaceId: currentSpace?.id,
        branchOfficeId: currentBranchOffice?.id
      },
      fetchPolicy: 'network-only'
    })
  }, [value, localTransactionType])

  if (loading && !activities) return (
    <div className={styles._loaderParent}>
      <CircularProgress color="primary" size={24} />
    </div>
  )

  const scrollToTop = () => {
    const { current } = table
    current.scrollIntoView({ behavior: 'smooth' })
  }

  const localToNext = () => {
    nextPage()
    scrollToTop()
  }

  const localToPrevious = () => {
    prevPage()
    scrollToTop()
  }

  const handleFilter = (item: any, index: number) => {
    handleCarrousel(index)
    setCurrentWallet(index)
    filterByWallet(item, index)
  }

  const inputHandler = (event: any) => {
    const { target: { value } } = event

    setValue(value)
    if (timer) clearTimeout(timer);

    setTimer(
      setTimeout(() => {
      }, 500),
    );
  };

  const handleTypeFilter = (filter: any, type: string) => {
    setCurrentTypeFilter(type)
    setLocalTransactionType(filter)
  }

  return (
    <>
      <div className={styles._headerParent}>
        <GeneralInput
          onChange={inputHandler}
          value={value}
          className={styles._noMargin}
          placeholder={t("search")}
          withIcon
          icon="bx:search"
          iconColor="#909090"
          iconClass={styles._inputIcon}
        />

        <div className={styles._incomesParent} onClick={switchFilterStatus}>
          <p>{t(currentTypeFilter)}</p>
          <Icon icon='bx:chevron-down'></Icon>
          <div className={filterStatus ? styles._dropdown : styles._dropdownHidden}>
            <div className={filterStatus ? styles._option : styles._optionHidden} onClick={() => handleTypeFilter(null, 'all')}>
              <p >{t('all')}</p>
            </div>
            <div className={filterStatus ? styles._option : styles._optionHidden} onClick={() => handleTypeFilter('IN', 'see_incomes')}>
              <p >{t('see_incomes')}</p>
            </div>
            <div className={filterStatus ? styles._option : styles._optionHidden} onClick={() => handleTypeFilter('OUT', 'see_egress')}>
              <p >{t('see_egress')}</p>
            </div>
          </div>
        </div>
      </div>

      {
        <div className={styles._card} ref={table}>

          {
            !noPagination && (
              <div className={styles._walletsParent}>
                {
                  [...allWallet, ...activeWallets].map((item: any, index: number) => (
                    <div key={index} className={currentWallet == index ? styles._walletButtonSelected : styles._walletButton} onClick={() => handleFilter(item?.wallet, index)}>
                      <p>{t(item?.wallet?.title)}</p>
                    </div>
                  ))
                }
              </div>
            )
          }

          {
            (parentLoading || (loading && perPageStore == paginateBy)) && (
              <div className={styles._overLap}>
                <div className={styles._loaderParent}>
                  <CircularProgress color="primary" size={26} />
                </div>
              </div>
            )
          }

          {
            activities && localTransactions != '{}' ?
              (transformTransactionsData(activities) || []).map((transaction: any) => {
                return transaction[1].map((item: any, index: number) => (
                  <DetailRow onRow={setDetail} item={item} key={index} />
                ))
              }) : <div className={styles._noTransactionsParent}>
                <div className={styles._iconParent}>
                  <Icon icon='bx:x-circle' width={32}></Icon>
                </div>
                <div className={styles._textParent}>
                  <p>{t("no_recent_activity")}</p>
                  <p>{t("start_use_account")}</p>
                </div>
              </div>
          }

          {
            paginationLoader && (
              <div className={styles._loaderParent}>
                <CircularProgress color="primary" size={24} />
              </div>
            )
          }

          {
            (pagination?.totalPages > 0 && !noPagination) && (
              <div className={styles._paginationParent}>
                <div className={styles._paginationContainer}>
                  <div className={styles._leftArrow} onClick={localToPrevious}>
                    <Icon icon="bx:arrow-back" width={20} color='#909090' />
                  </div>
                  <p className={styles._paginationText}>{currentPage}/{pagination?.totalPages}</p>
                  <div className={styles._rightArrow} onClick={localToNext}>
                    <Icon icon="bx:right-arrow-alt" width={20} color='#909090' />
                  </div>
                </div>
              </div>
            )
          }
        </div>
      }

      <DetailModal open={status} onClose={switchStatus} data={current} />
    </>
  )
}

export default memo(MobileActivityTable)
