import React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { UseIntermitence } from "hooks";
import styles from "../styles.module.scss";
import { Icon } from "@iconify/react";
import { baseUrl } from "paths";
import { useTranslation } from "react-i18next";
import { parseAmount } from "utils";

export const Row = ({ row = {} }: any) => {
  const { status, switchStatus } = UseIntermitence();
  const { t } = useTranslation();

  const transactionType = row.transactionType;

  return (
    <>
      <TableRow
        sx={{ "&": { borderBottom: "solid #F9F9F9" } }}
        onClick={switchStatus}
        className={styles._row}
      >
        <TableCell width="5%">
          <IconButton aria-label="expand row" size="small">
            {transactionType == "in" ? (
              <img
                src={`${baseUrl}/resources/green-arrow.svg`}
                alt="green-arrow"
              />
            ) : (
              <img
                src={`${baseUrl}/resources//red-arrow.svg`}
                alt="red-arrow"
              />
            )}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" width="25%">
          <ul className={styles._list}>
            <li className={styles._bold}>{row?.externalUser?.name}</li>
            <li>{row?.externalUser?.email}</li>
          </ul>
        </TableCell>
        <TableCell width="25%">{row?.createdAt}</TableCell>
        <TableCell width="15%" className={styles._bold}>
          {t(row?.wallet)}
        </TableCell>
        <TableCell width="15%">
          <div className={styles._statusParent}>
            <div
              className={
                row?.status == "Recibido" ? styles._circle : styles._redCircle
              }
            />
            <p>{t(row?.status)}</p>
          </div>
        </TableCell>
        <TableCell align="right" width="15%">
          <ul className={styles._list}>
            <li className={styles._amount}>
              {transactionType == "out" ? "-" : ""}${parseAmount(row?.amount)}
            </li>
            <li className={styles._totalAmount}>${parseAmount(row?.total)}</li>
          </ul>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0, borderBottom: "none" }} colSpan={6}>
          <Collapse in={status} timeout="auto" unmountOnExit>
            <Box className={styles._boxParent}>
              <div className={styles._headerParent}>
                <h3 className={styles._boxTitle}>{t("details_transaction")}</h3>
                <div className={styles._closeParent}>
                  <Icon
                    icon="fe:close"
                    width="22px"
                    height="22px"
                    onClick={switchStatus}
                  ></Icon>
                </div>
              </div>

              <div className={styles._grayRow}>
                <p>{t("activity_description")}</p>
                <div className={styles._listArrow}>
                  <ul className={styles._list}>
                    <li>{row?.description}</li>
                    <li>{row?.externalUser?.name.toUpperCase()}</li>
                  </ul>
                </div>
              </div>
              <div className={styles._grayRow}>
                <p>{t("amount")}</p>
                <p>${parseAmount(row?.amount)}</p>
              </div>
              <div className={styles._grayRow}>
                <p>{t("date")}</p>
                <p>{row.createdAt}</p>
              </div>
              <div className={styles._grayRow}>
                <p>{t("type")}</p>
                <div className={styles._typeWithWallet}>
                  <p>{row?.wallet}</p>
                  <p>
                    {row.transactionType == "in" ? t("income") : t("egress")}
                  </p>
                </div>
              </div>
              <div className={styles._grayRow}>
                <p>{t("sent_to")}</p>
                <div className={styles._listArrow}>
                  <p className={styles._amount}>
                    {row?.externalUser?.name.toUpperCase()}
                  </p>
                  <div></div>
                </div>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
