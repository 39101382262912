import { gql } from '@apollo/client';

const COMMERCE_TYPE_QUERY = gql`
  query Commerce_type {
    typeCommerces {
      esName
      enName
      description
      id
      icon
    }
  }
`;
export default COMMERCE_TYPE_QUERY;
