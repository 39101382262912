import { combineReducers } from 'redux';
import onboarding from './onboarding/reducer';
import user from './user/reducer';
import intermitence from './intermitence/reducer';
import activity from './activity/reducer';
import resource from './resource/reducer';
import twoFA from './twoFA/reducer';
import userSpace from './space/reducer';
import bankAccount from './bankAccount/reducer';

const reducers = combineReducers({
  onboarding,
  user,
  intermitence,
  activity,
  resource,
  twoFA,
  userSpace,
  bankAccount
});

export default reducers;
