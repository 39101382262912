import { Theme } from '@mui/material';

const modalBox = (
  theme: Theme,
  width: string | number,
  height?: string | number
) => ({
  marginLeft: '14.5625rem',
  bgcolor: '#FFFFFF',
  p: 5,
  borderRadius: '0.5625rem',
  width: width ? width : 'auto',
  height: height ? height : 'auto',

  [theme.breakpoints.down('lg')]: {
    width: '90%',
    marginLeft: 0,
    p: 3,
  },
  [theme.breakpoints.down('md')]: {
    marginTop: '3rem',
  },
});

const modal = (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1300,
  '& .MuiBackdrop-root': {
    left: '14.5625rem',

    [theme.breakpoints.down('lg')]: {
      left: 0,
      marginTop: '3rem',
    },
  },
});

export { modalBox, modal };
