import { Theme } from '@mui/material';

const modalBox = (theme: Theme) => ({
  marginLeft: '14.5625rem',
  bgcolor: '#FFFFFF',
  p: 5,
  borderRadius: '0.5625rem',
  width: '36.9375rem',

  [theme.breakpoints.down('lg')]: {
    width: '90%',
    height: '80%',
    marginLeft: 0,
    p: "0.9375rem",
  },
});

const modal = (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 9999,

  '& .MuiBackdrop-root': {
    left: '14.5625rem',

    [theme.breakpoints.down('lg')]: {
      left: 0,
      marginTop: '3rem',
    },
  },
  [theme.breakpoints.down('lg')]: {
    top: 50,
  },
});

const radioButton = {
  padding: 0,
};

export { modalBox, modal, radioButton };
