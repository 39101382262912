import {
  SET_ERASED_DATA,
  SET_USER_DATA,
  CLEAR_ONBOARDING_STORE,
} from './action-types';

const initialState: any = {
  type: null,
  basicUserData: { completed: false },
  accountData: { completed: false },
  shareholdersData: { completed: false, shareholders: [] },
  signatureData: { completed: false },
  agreementsData: { completed: false },
};

const onboarding = (state = initialState, { type, payload }: any) => {
  switch (type) {
    case SET_USER_DATA:
    case SET_ERASED_DATA:
      return { ...state, ...payload };

    case CLEAR_ONBOARDING_STORE:
      return initialState;
    default:
      return state;
  }
};

export default onboarding;
