const faqs = [
  {
    label: 'account_features',
    icon: 'bxs-user',
    questions: [
      {
        question: 'dont_have_llc',
        answer: 'dont_have_llc_response',
      },
      {
        question: 'diferrentiating_factor',
        answer: 'diferrentiating_factor_response',
      },
      {
        question: 'how_we_operated',
        answer: 'how_we_operated_response',
      },
      {
        question: 'puntu_accounts_reflected',
        answer: 'puntu_accounts_reflected_response',
      },
    ],
  },
  {
    label: 'withdraw_send',
    icon: 'bx-transfer-alt',
    questions: [
      {
        question: 'withdrawal_frequency',
        answer: 'withdrawal_frequency_response',
      },
      {
        question: 'want_to_withdraw_money',
        answer: 'want_to_withdraw_money_response',
      },
      {
        question: 'how_will_we_send_money',
        answer: 'how_will_we_send_money_response',
      },
    ],
  },
  {
    label: 'payment_methods',
    icon: 'bxs-credit-card-front',
    questions: [
      {
        question: 'in_have_problem',
        answer: 'in_have_problem_response',
      },
      {
        question: 'will_we_include_payment_methods',
        answer: 'will_we_include_payment_methods_response',
      },
      {
        question: 'get_access_the_platform',
        answer: 'get_access_the_platform_response',
      },
    ],
  },
  {
    label: 'terms',
    icon: 'bxs:receipt',
    questions: [
      {
        question: 'want_read_terms',
        answer: 'https://www.google.com/webhp?authuser=1',
      },
    ],
  },
];

export default faqs;
