import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { onboardingSelector } from "store/selectors";

const naturalValidationObject: any = {
  "/onboarding/basic-user-data": [],
  "/onboarding/account-data": ["basicUserData"],
  "/onboarding/signature": ["basicUserData", "accountData"],
  "/onboarding/agreements": ["basicUserData", "accountData", "signatureData"],
};

const juridicValidationObject: any = {
  "/onboarding/basic-user-data": [],
  "/onboarding/account-data": ["basicUserData"],
  "/onboarding/shareholders": ["basicUserData", "accountData"],
  "/onboarding/signature": ["basicUserData", "accountData", "shareholdersData"],
  "/onboarding/agreements": [
    "basicUserData",
    "accountData",
    "shareholdersData",
    "signatureData",
  ],
};

const notAllObjectsCompleted = (
  onboarding: any,
  type: string,
  location: any
) => {
  const object =
    type === "natural" ? naturalValidationObject : juridicValidationObject;
  const array = object[location.pathname];
  if (array) {
    for (let i = 0; i < array.length; i++) {
      if (!onboarding[array[i]].completed) {
        const keys = Object.keys(object);
        return keys[keys.indexOf(location.pathname) - 1];
      }
    }
  } else {
    return "/onboarding/";
  }
  return;
};

const ProtectedOnboardingRoute = (a: any) => {
  const location = useLocation();
  const onboarding = useSelector(onboardingSelector);
  if (!onboarding.type) {
    return <Navigate to={"/onboarding"} replace />;
  }
  const goBack = notAllObjectsCompleted(onboarding, onboarding.type, location);
  if (goBack) {
    return <Navigate to={goBack} replace />;
  }
  return <Outlet />;
};

export default ProtectedOnboardingRoute;
