import React, { useState, useRef, useEffect } from "react";
import styles from "./styles.module.scss";
import {
  AccountCard,
  ActivityTable,
  BranchOfficeList,
} from "../../../components";
import { GeneralModal, NotificationPanel } from "../../../../components";
import {
  paginate,
  calculateTableHeight,
  walletEnabled,
  walletDisabled,
  allWallets,
  switchWalletsNumber,
  verifyWallets,
  EMPLOYEE,
} from "utils";
import { useTranslation } from "react-i18next";
import DashboardRightDrawer from "../../../components/Drawers/DashboardRightDrawer";
import { useSelector, useDispatch } from "react-redux";
import { userAuthSelector, userSpaceSelector } from "store/selectors";
import { baseUrl } from "paths";
import { Icon } from "@iconify/react";
import { UseIntermitence, UseAlert } from "hooks";
import { useMutation } from "@apollo/client";
import { ACTIVATE_WALLET } from "graphql/mutations";
import { useLazyQuery } from "@apollo/client";
import { ACTIVITIES_QUERY, BRANCH_OFFICE_WALLETS_QUERY } from "graphql/queries";
import { setActivityData, addActivityParams } from "store/actions";
import { useMediaQuery, Theme, Skeleton } from "@mui/material";
import MobileTopSection from "./components/MobileTopSection";
import { useNavigate, useLocation } from "react-router-dom";
import { UseWallets } from "utils/queries/walletsQuery";
import DirectoryList from "web/components/DirectoryList";
import { Contact, BranchOfficeICon } from "components/svg";
import { skeletonStyle } from "./styles";

const Dashboard = () => {
  const { status, switchStatus } = UseIntermitence();
  const container = useRef(null);
  const { t } = useTranslation();
  const [pagination, setPagination] = useState(1);
  const { currentSpace, currentBranchOffice } = useSelector(userSpaceSelector);

  const {
    userAuthData: { customer },
  } = useSelector(userAuthSelector);

  const userType = customer?.type;

  const navigate = useNavigate();
  const navigation = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(0);
  const dispatch = useDispatch();
  const { userAuthData } = useSelector(userAuthSelector);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const { current } = container;
  const [typeList, setTypeList] = useState<number>(0);

  const branchOfficeId: Array<any> = currentBranchOffice?.id;

  const { showAlert } = UseAlert();
  const [activateWallet] = useMutation(ACTIVATE_WALLET);
  const [getActivities, { loading }] = useLazyQuery(ACTIVITIES_QUERY);

  const roleName = userAuthData?.role?.name;

  const [getBranchOfficeWallets, { data, refetch, loading: walletsLoading }] =
    useLazyQuery(BRANCH_OFFICE_WALLETS_QUERY, {
      variables: {
        branchOfficeId: currentBranchOffice?.id,
      },
      fetchPolicy: "cache-and-network",
    });

  const userWallets = data?.branchOfficeWallets || [];

  const generalWallets = UseWallets("wallets");

  useEffect(() => {
    if (!loading) {
      getActivities({
        onCompleted: (response: any) => dispatch(setActivityData(response)),
        variables: {
          page: 1,
          perPage: 6,
          search: "",
          walletId: null,
          transactionType: null,
          spaceId: currentSpace?.id,
          branchOfficeId: currentBranchOffice?.id,
        },
        fetchPolicy: "network-only",
      });
    }

    getBranchOfficeWallets();

    navigation(location.pathname, { state: null });
    dispatch(addActivityParams({ walletId: null }));
    if (!userAuthData?.isVerified) return setShow(1);
  }, [currentBranchOffice]);

  const nextPage = () => {
    const result = Math.floor(userWallets.length / 4);
    if (pagination < result) setPagination(pagination + 1);
  };

  const previousPage = () => {
    if (pagination > 1) setPagination(pagination - 1);
  };

  const buildArray = (): Array<any> => {
    const localWallets = paginate(userWallets, 4, pagination);
    const baseArray: any = Array;
    let filledArray: Array<any> = [];

    if (localWallets.length < 4) {
      const enabledArray = baseArray(1).fill(walletEnabled);
      const disabledArray = baseArray(
        switchWalletsNumber(localWallets.length)
      ).fill(walletDisabled);
      filledArray = [...enabledArray, ...disabledArray];
    }

    return paginate([...userWallets, ...filledArray] || [], 4, pagination);
  };

  const handlerActivate = (id: string) => {
    activateWallet({
      variables: { walletId: id, branchOfficeId: branchOfficeId },
      onCompleted: (response) => {
        const { activateWallet } = response;
        if (activateWallet) {
          showAlert(t("wallet_activated"), "success");
          refetch();
          switchStatus();
        }
      },
      onError: (error) => {
        showAlert(t("something_went_wrong"), "error");
      },
    });
  };

  const compareWallets = (localWallet: any, generalWallet: any): Array<any> => {
    const results = generalWallet.filter(
      (itemA: any) =>
        !localWallet.some((itemB: any) => itemB?.wallet?.name === itemA?.name)
    );
    return results;
  };

  const navigateToActivity = () => navigate("/activity");

  return (
    <div className={styles._main}>
      <div ref={container}>
        {!isMobile && (
          <>
            <div className={styles._actionsRow}>
              <p className={styles._subtitle}>{t("affiliate_accounts")}</p>
              <div className={styles._cardsParent}>
                {userType == "natural" ? null : (
                  <p className={styles._branchName}>
                    {currentBranchOffice?.name}
                  </p>
                )}
                {/* <div className={styles._iconBackParent} onClick={previousPage}>
                    <Icon icon="bx:left-arrow-alt" width={22}></Icon>
                  </div>

                  <div className={styles._iconNextParent} onClick={nextPage}>
                    <Icon icon="bx:right-arrow-alt" width={22}></Icon>
                  </div> */}
              </div>
            </div>

            {!walletsLoading ? (
              <div className={styles._accountCardsParent}>
                {buildArray().map((item: any, index: number) => (
                  <div className={styles._cardParent} key={index}>
                    <AccountCard
                      account={item?.wallet?.name}
                      noAccount={verifyWallets(item?.wallet?.name, allWallets)}
                      balance={item?.total}
                      action={switchStatus}
                      id={item?.wallet?.id}
                      loading={loading}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div className={styles._skeleton}>
                {[...Array.from(Array(4).keys())].map((item: number) => (
                  <Skeleton
                    sx={skeletonStyle}
                    classes={{ root: styles._skRoot }}
                    key={item}
                  />
                ))}
              </div>
            )}
          </>
        )}

        <div>
          {isMobile && <MobileTopSection isAllowed={roleName != EMPLOYEE} />}

          {/* {
            !isMobile && (
              <div className={styles._stepperParent}>
                <Stepper maxSteps={2} step={pagination} width="1.4375rem"></Stepper>
              </div>
            )
          } */}

          {!isMobile && (
            <div className={styles._tableTop}>
              <p>{t("activity")}</p>
            </div>
          )}
        </div>
      </div>

      {isMobile && (
        <>
          <div className={styles._tableTop}>
            {userType == "juridic" && (
              <>
                {typeList == 0 ? (
                  <p>{t("Branch offices")}</p>
                ) : (
                  <p>{t("activity")}</p>
                )}
              </>
            )}

            {userType == "natural" && (
              <>
                {typeList === 0 ? (
                  <p>{t("activity")}</p>
                ) : (
                  <p>{t("Directory")}</p>
                )}
              </>
            )}
          </div>

          <div className={styles._miniCardsParent}>
            <div style={{ display: "flex" }}>
              <div className={styles._miniCard} onClick={() => setTypeList(0)}>
                {userType == "natural" ? (
                  <Icon
                    icon="bx:list-ul"
                    width={24}
                    color={typeList == 0 ? "#E6427A" : "#000"}
                  />
                ) : (
                  <BranchOfficeICon
                    backgroundColor={typeList == 0 ? "#E6427A" : "#000"}
                  />
                )}
              </div>

              <div className={styles._miniCard} onClick={() => setTypeList(1)}>
                {userType == "natural" ? (
                  <Contact color={typeList == 1 ? "#E6427A" : "#000"} />
                ) : (
                  <Icon
                    icon="bx:list-ul"
                    width={24}
                    color={typeList == 1 ? "#E6427A" : "#000"}
                  />
                )}
              </div>
            </div>

            <div className={styles._miniCard} onClick={navigateToActivity}>
              <Icon icon="bx:right-arrow-alt" width={24} />
            </div>
          </div>
        </>
      )}

      {userType == "natural" && (
        <>
          {typeList == 0 ? (
            <ActivityTable
              height={`calc(100vh - ${calculateTableHeight(
                current?.clientHeight + 55
              )}px)`}
              paginateBy={6}
              noPagination
              parentLoading={loading}
            />
          ) : (
            <DirectoryList />
          )}
        </>
      )}

      {(userType == "juridic" || roleName == "employee") && (
        <>
          {isMobile && typeList == 0 ? (
            <div className={styles._branchOfficesParent}>
              <BranchOfficeList isAllowed={roleName != EMPLOYEE} />
            </div>
          ) : (
            <ActivityTable
              height={`calc(100vh - ${calculateTableHeight(
                current?.clientHeight + 55
              )}px)`}
              paginateBy={6}
              noPagination
              parentLoading={loading}
            />
          )}
        </>
      )}

      {status && (
        <GeneralModal
          open={status}
          width="41.0625rem"
          onCancel={switchStatus}
          title={t("add_new_account")}
        >
          <div className={styles._modalMain}>
            {(compareWallets(userWallets, generalWallets) || []).map(
              (item: any, index: number) => (
                <div
                  className={styles._modalCard}
                  key={index}
                  onClick={() => handlerActivate(item?.id)}
                >
                  <div className={styles._leftSide}>
                    <img
                      src={`${baseUrl}/resources/${item?.name}-card-icon.svg`}
                      width={35}
                      alt="card-icon"
                    />
                    <p>{item?.title}</p>
                  </div>

                  <div className={styles._rightSide}>
                    <p>{t("affiliate")}</p>
                    <Icon icon="bx:right-arrow-circle" width={20} />
                  </div>
                </div>
              )
            )}
          </div>
        </GeneralModal>
      )}

      {location?.pathname !== "/profile" && <DashboardRightDrawer />}

      <NotificationPanel
        message={t("verify_account_to_continue")}
        linkMessage={t("go_to_email")}
        link={"https://mail.google.com/"}
        open={show}
        onCancel={() => setShow(2)}
      />
    </div>
  );
};

export default Dashboard;
