import styles from "./styles.module.scss";
import styled from "styled-components";
import { SelectType } from "./types";
import { styledComponentsBreakpoints } from "utils";
import { MenuItem, Select } from "@mui/material";
import { baseUrl } from "paths";
import { disabledColor, menuItemStyles, selectStyles } from "./materialStyles";
import { useTranslation } from "react-i18next";

const GeneralSelect = ({
  value,
  options,
  onChange,
  className,
  placeholder,
  reference,
  innerPaddingRight,
  multiple = false,
}: SelectType) => {
  const { t } = useTranslation();
  return (
    <Select
      className={className}
      displayEmpty
      multiple={multiple}
      value={value}
      onChange={onChange}
      MenuProps={{
        PopoverClasses: { paper: styles._popover },
        MenuListProps: { disablePadding: true },
      }}
      inputRef={reference}
      sx={[
        (t) => selectStyles(t, innerPaddingRight),
        value == "" ? disabledColor : null,
      ]}
      IconComponent={(iconClasses) => {
        return (
          <img
            src={`${baseUrl}/resources/select-down-arrow.svg`}
            alt="Arrow"
            style={{ top: "unset" }}
            {...iconClasses}
          />
        );
      }}
    >
      {placeholder && (
        <MenuItem
          sx={menuItemStyles}
          value=""
          disabled={multiple ? true : false}
        >
          {placeholder}
        </MenuItem>
      )}
      {options &&
        options.map((option) => (
          <MenuItem
            value={option.value ?? option.id}
            key={option.value ?? option.name}
            sx={menuItemStyles}
            classes={{ selected: styles._optionSelected }}
          >
            {t(option.label ?? option.name ?? option.title)}
          </MenuItem>
        ))}
    </Select>
  );
};

export default styled(GeneralSelect)`
  width: ${(props) => (props.width ? props.width : "")};
  @media ${styledComponentsBreakpoints.lg} {
  }
`;
