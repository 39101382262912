import React, { memo } from 'react'
import { Icon } from '@iconify/react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import styles from './styles.module.scss'
import DashboardHeader from '../../../../components/Headers/DashboardHeader'
import MobileDrawer from '../../../../components/Drawers/MobileDrawer'
import { useLocation } from 'react-router-dom'
import { UseIntermitence } from 'hooks'
import { useTranslation } from 'react-i18next'
import { parseAmount } from 'utils'

const DetailModal = ({ open, onClose, data }: any) => {

  const location = useLocation();
  const { status, switchStatus } = UseIntermitence()
  const { t } = useTranslation();

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
    >
      <DialogContent className={styles._main}>
        <DashboardHeader mobileMenuOpen={status} setOpen={switchStatus} />
        <MobileDrawer location={location} open={status} setOpen={switchStatus} />

        <div className={styles._comebackParent} onClick={onClose}>
          <Icon icon='bx:left-arrow-alt' width={22} height={22} color='#E6427A'></Icon>
          <p>{t('go_back')}</p>
        </div>

        <div className={styles._card}>
          <h3 className={styles._boxMobileTitle}>
            {t('details_transaction')}
          </h3>

          <div className={styles._grayRow}>
            <p>{t('description')}</p>
            <div className={styles._listArrow}>
              <ul className={styles._list}>
                <li>{data?.description}</li>
                <li>{data?.internalUser?.name.toUpperCase() || ''}</li>
              </ul>
            </div>
          </div>

          <div className={styles._grayRow}>
            <p>{t('amount')}</p>
            <p>${parseAmount(data?.amount)}</p>
          </div>

          <div className={styles._grayRow}>
            <p>{t('date')}</p>
            <p>{data?.createdAt}</p>
          </div>
          <div className={styles._grayRow}>
            <p>{t('type')}</p>
            <div className={styles._typeWithWallet}>
                  <p>{data?.wallet}</p>
                  <p>{data?.transactionType == 'in' ? t('income') : t('egress')}</p>
                </div>
            {/* <p>{data?.transactionType == 'in' ? t('income') : t('egress')}</p> */}
          </div>

          <div className={styles._grayRow}>
            <p>{t('sent_to')}</p>
            <div className={styles._listArrow}>
              <p className={styles._boldText}>{data?.externalUser?.name.toUpperCase()}</p>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default memo(DetailModal)
