import { gql } from '@apollo/client';

const CREATE_BRANCH_OFFICE = gql`
    mutation CreateBranchOffice($data: CreateBranchOfficeInput!) {
      createBranchOffice(data: $data) {
        id
        name
        code
    }
  }
`;
export default CREATE_BRANCH_OFFICE;

