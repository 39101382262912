import { gql } from '@apollo/client';

const UI_ROLE = gql`
  query UiRole($id: ID!) {
    uiRole(id: $id) {
      id
      title
      description
      color
      permissions {
        id
        permissionId
        permission {
          id
          name
        }
      }
    }
  }
`;

export default UI_ROLE;
