import { baseUrl } from "paths";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";

const BackArrow = ({
  onClick,
  action = false,
  route = "",
  icon = "",
}: {
  action?: boolean;
  onClick?: (e?: any) => void;
  route?: string;
  icon?: string;
}) => {
  const navigate = useNavigate();
  const handleClick = () => {
    onClick ? onClick() : action && !route ? navigate(-1) : navigate(route);
  };
  return icon ? (
    <Icon icon={icon} onClick={handleClick} className={styles._icon}/>
  ) : (
    <img
      src={`${baseUrl}/resources/back-arrow.svg`}
      alt="back-arrow"
      onClick={handleClick}
      className={styles._backArrow}
    />
  );
};

export default BackArrow;
