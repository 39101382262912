import { Routes, Route } from "react-router-dom";
import { SimpleLayout } from "../web/components";
import { HelmetHeader } from "../components";
import Set2FA  from "../web/screens/Onboarding/Set2fa";

const TwoFARouter = (props: any) => (
  <SimpleLayout>
    <HelmetHeader title="2fa" />
    <Routes>
      <Route path="" element={<Set2FA {...props}/>} />
    </Routes>
  </SimpleLayout>
);

export default TwoFARouter;
