import { gql } from '@apollo/client';

const EXPORT_ACTIVITIES = gql`
  mutation ExportActivities($folder: String!, $monthNumber: Int!, $format: ActivityFileFormatEnum!, $spaceId: ID!) {
    exportActivities(data:{folder: $folder, monthNumber: $monthNumber, format: $format, spaceId: $spaceId}) {
        result
    }
}
`;
export default EXPORT_ACTIVITIES;
