import { Modal, Box } from "@mui/material";
import { ModalType } from "./types";
import { modalBox, modal } from "./materialStyles";
import { Icon } from "@iconify/react";
import styles from "./styles.module.scss";

const GeneralModal = ({
  onCancel = null,
  title,
  open = false,
  onConfirm = null,
  children,
  width,
  height,
}: ModalType) => {
  return (
    <Modal open={open} onClose={onCancel} sx={modal}>
      <Box sx={(theme) => modalBox(theme, width, height)}>
        <div className={styles._contain}>
          <div className={styles._iconParent}>
            <div className={styles._icon}>
              <Icon
                onClick={onCancel}
                icon="zondicons:close"
                width={18}
                color="#000"
              />
            </div>
          </div>
          <div className={styles._titleContainer}>
            {title && <h3 className={styles._title}>{title}</h3>}
          </div>
        </div>
        {children}
      </Box>
    </Modal>
  );
};

export default GeneralModal;
