import { gql } from '@apollo/client';

const REQUEST_CHANGE = gql`
  mutation RequestChangeInCustomerInformation(
    $data: RequestChangeInCustomerInformationInput!
  ) {
    requestChangeInCustomerInformation(data: $data) {
      result
    }
  }
`;
export default REQUEST_CHANGE;
