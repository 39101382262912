import clsx from "clsx";
import { useTranslation } from "react-i18next";
import OtpInput from "react-otp-input";
import styles from "./styles.module.scss";
import { GeneralOTPType } from "./types";
const GeneralOTP = ({
  value,
  onChange,
  placeholder,
  error,
  className,
}: GeneralOTPType) => {
  const { t } = useTranslation();
  return (
    <div className={clsx(styles._otpContainer, className)}>
      <OtpInput
        containerStyle={styles._otp}
        numInputs={6}
        inputStyle={styles._otpInput}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        isInputNum
        hasErrored={!!error}
        errorStyle={styles._error}
      />
      {error && <p className={styles._errorMessage}>{t(error)}</p>}
    </div>
  );
};

export default GeneralOTP;
