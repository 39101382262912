import styles from "./styles.module.scss";
import { Modal, Box } from "@mui/material";
import { Theme, useMediaQuery } from "@mui/material";
import { modalBox, modal } from "./materialStyles";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { UseAlert, UseIntermitence } from "hooks";
import { GeneralButton, GeneralTextArea } from "components";
import { FormikConfig } from "./formik";
import { REQUEST_CHANGE } from "graphql/mutations";
import { use } from "i18next";
import { useState } from "react";

const RequestChangesModal = ({ open, onClose }: any) => {
  const modalWidth: any = "14.5625rem";
  const { showAlert } = UseAlert();
  const { t } = useTranslation();
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const handleOnTouched = (key: string) =>
    setTouched({ ...touched, [key]: true });
  UseIntermitence();

  const [requestChange, { loading: requestLoading }] =
    useMutation(REQUEST_CHANGE);

  const handleSubmit = (formValues: any) => {
    requestChange({
      variables: { data: { ...formValues } },
      onError: (error: any) => {
        showAlert(error?.message, "error");
        resetForm();
      },
      onCompleted: (response: any) => {
        const {
          requestChangeInCustomerInformation: { result },
        } = response;
        resetForm();
        if (result) {
          showAlert("notification_sent", "success");
          return setShowSuccessMessage(true);
        }

        showAlert("something_went_wrong", "error");
      },
    });
  };

  const {
    values,
    handleSubmit: formikSubmit,
    handleChange,
    submitForm,
    errors,
    touched,
    setTouched,
    resetForm,
  } = FormikConfig(handleSubmit);

  const changeInputStatus = (value: string, error: any) => {
    if (value !== "") {
      if (error) return "error";
      return "success";
    }
    return "inherit";
  };

  return (
    <>
      {open && (
        <Modal
          open={open}
          onClose={() => onClose()}
          sx={(theme) => modal(theme, modalWidth)}
        >
          <Box sx={(theme) => modalBox(theme, modalWidth, 6)}>
            <h3
              className={
                !showSuccessMessage ? styles._title : styles._successTitle
              }
            >
              {t(
                showSuccessMessage
                  ? "we_will_contact_you"
                  : "change_do_you_wish"
              )}
            </h3>
            <form onSubmit={formikSubmit} id="my-form">
              {!showSuccessMessage && (
                <div>
                  <GeneralTextArea
                    placeholder="add_comment"
                    className={styles._textArea}
                    onChange={handleChange}
                    value={values.message}
                    id="message"
                    name="message"
                    lines={isMobile ? 6 : 3}
                    onFocus={() => handleOnTouched("message")}
                    errorMessage={errors.message}
                    hasError={errors.message && touched.message ? true : false}
                    status={changeInputStatus(
                      values.message,
                      errors.message && touched.message
                    )}
                  />
                </div>
              )}
              <div
                className={
                  !showSuccessMessage ? styles._btnsParent : styles._singleBtn
                }
              >
                {!showSuccessMessage ? (
                  <>
                    <div className={styles._btnParentLeft}>
                      <GeneralButton
                        text={t("cancel")}
                        loading={requestLoading}
                        action={() => {
                          onClose();
                          resetForm();
                        }}
                      />
                    </div>
                    <div className={styles._btnParentRight}>
                      <GeneralButton
                        type="submit"
                        loading={requestLoading}
                        action={submitForm}
                        text={t("send")}
                      />
                    </div>
                  </>
                ) : (
                  <div className={styles._singleBtn}>
                    <GeneralButton
                      action={() => onClose()}
                      text={t("accept")}
                    />
                  </div>
                )}
              </div>
            </form>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default RequestChangesModal;
