import React, { useEffect, useRef } from "react";
import {
  GeneralModal,
  GeneralInput,
  GeneralButton,
  GeneralSelect,
} from "../../../components";
import styles from "./styles.module.scss";
import Grid from "@mui/material/Grid";
import { FormikConfig } from "./formik";
import { useMediaQuery, Theme } from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_BANK_ACCOUNT, UPDATE_BANK_ACCOUNT } from "graphql/mutations";
import { userSpaceSelector, userAuthSelector } from "store/selectors";
import { useSelector } from "react-redux";
import { UseAlert } from "hooks";
import { useTranslation } from "react-i18next";
import { STATES_QUERY, BANKS_QUERY } from "graphql/queries";
import { routingTypes } from "utils";

const CreateBankAccount = ({ open, onClose, refetch, accountData }: any) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const { t } = useTranslation();

  const ref: any = useRef(null);
  const stateRef: any = useRef(null);

  const { showAlert } = UseAlert();
  const { data: dataStates } = useQuery(STATES_QUERY);
  const { data: dataBanks } = useQuery(BANKS_QUERY);
  const { currentSpace } = useSelector(userSpaceSelector);
  const {
    userAuthData: { profile, customer },
  } = useSelector(userAuthSelector);

  const [createBankAccount, { loading }] = useMutation(CREATE_BANK_ACCOUNT);
  const [updateBankAccount, { loading: updateLoading }] =
    useMutation(UPDATE_BANK_ACCOUNT);

  const handleOnTouched = (key: string) =>
    setTouched({ ...touched, [key]: true });
  const onHandlerStateSelect = (value: any) => {
    setFieldValue("state", value);
  };
  const onHandlerRoutingSelect = (value: any) => {
    if (value === "ACH" && values.bankId) {
      const myBank = dataBanks.banks.find(
        (item: any) => values.bankId === item.id
      );
      setFieldValue("routeNumber", myBank.aba || "");
    } else if (value === "WIRE") setFieldValue("routeNumber", "");

    setFieldValue("routeType", value);
  };
  const onHandlerSelect = (bank: any) => {
    if (values.routeType == "ACH") {
      const myBank = dataBanks.banks.find((item: any) => bank === item.id);
      setFieldValue("routeNumber", myBank.aba || "");
    }
    setFieldValue("bankId", bank);
  };

  useEffect(() => {
    if (dataBanks) buildBanksArray(dataStates?.banks);
    if (dataStates) buildStatesArray(dataStates?.states);
  }, []);

  const buildBanksArray = (banks: Array<any>) => {
    const defaultBank: any = { title: t("bank"), value: "", icon: "", aba: "" };
    const localBanks: Array<any> = banks?.map((item: any) => {
      return {
        title: item?.title,
        value: item?.id,
        icon: item?.icon,
        aba: item?.aba,
      };
    });

    const orderedBanks = (localBanks || []).sort((itemA, itemB) => {
      if (itemA?.title < itemB.title) {
        return -1;
      }
      if (itemA?.title > itemB.title) {
        return 1;
      }
      return 0;
    });

    orderedBanks?.unshift(defaultBank);

    return orderedBanks || [];
  };

  const buildStatesArray = (states: Array<any>) => {
    const defaultStates: any = { label: t("state"), value: "" };
    const localStates: Array<any> = states?.map((item: any) => {
      return { label: item?.name, value: item?.code };
    });

    localStates?.unshift(defaultStates);
    return localStates || [];
  };

  useEffect(() => {
    const juridicData = customer?.legalRepresentative?.profile;

    if (open) {
      customer?.type == "natural"
        ? setFieldValue(
            "accountHolder",
            `${profile?.firstName} ${profile.lastName}`
          )
        : setFieldValue("accountHolder", `${currentSpace?.name}`);
    }

    return () => resetForm();
  }, [open]);

  const submitData = (formData: any) => {
    createBankAccount({
      variables: { data: { ...formData } },

      onCompleted: (response: any) => {
        if (response) {
          showAlert(t("bank_account_affiliated"), "success");
          onClose();
          refetch && refetch();
          return resetForm();
        }
      },
      onError: (error) => {
        return showAlert("something_went_wrong", "error");
      },
    });
  };

  const updateAccount = (formData: any) => {
    updateBankAccount({
      variables: { data: { ...formData } },

      onCompleted: (response: any) => {
        if (response) {
          showAlert(t("bank_account_edited"), "success");
          onClose();
          refetch && refetch();

          return resetForm();
        }
      },
      onError: (error) => {
        return showAlert("something_went_wrong", "error");
      },
    });
  };

  const {
    submitForm,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setTouched,
    setFieldValue,
    isValid,
    values,
    resetForm,
    dirty,
  } = FormikConfig(submitData, currentSpace?.id, accountData, updateAccount);

  const changeInputStatus = (value: string, error: any) => {
    if (error) return "error";
    if (value?.length) return "success";
    return "inherit";
  };

  return (
    <>
      <GeneralModal
        open={open}
        title={t(!accountData ? "add_bank_account" : "edit_bank_account")}
        onCancel={onClose}
      >
        <div className={styles._main}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={isMobile ? 2.8 : 3.5}>
              <Grid item xs={12} sm={12} md={6}>
                <GeneralSelect
                  value={values?.bankId ?? dataBanks?.banks?.[0]?.title}
                  onChange={(event: any) => {
                    onHandlerSelect(event?.target?.value);
                  }}
                  options={buildBanksArray(dataBanks?.banks ?? [])}
                  reference={ref}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <GeneralInput
                  placeholder="holder"
                  id="accountHolder"
                  name="accountHolder"
                  onChange={handleChange}
                  value={values?.accountHolder}
                  hasError={
                    errors.accountHolder && touched.accountHolder ? true : false
                  }
                  onFocus={() => handleOnTouched("accountHolder")}
                  errorMessage={errors.accountHolder}
                  disabled
                  status={changeInputStatus(
                    values?.accountHolder,
                    errors.accountHolder && touched.accountHolder
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <GeneralInput
                  placeholder="bank_fiscal_address"
                  id="address"
                  name="address"
                  onChange={handleChange}
                  value={values?.address}
                  hasError={errors.address && touched.address ? true : false}
                  onFocus={() => handleOnTouched("address")}
                  errorMessage={errors.address}
                  status={changeInputStatus(
                    values.address,
                    errors.address && touched.address
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <GeneralSelect
                  value={values?.state ?? dataStates?.states?.[0]?.label}
                  onChange={(event: any) => {
                    onHandlerStateSelect(event?.target?.value);
                  }}
                  options={buildStatesArray(dataStates?.states)}
                  reference={stateRef}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <GeneralInput
                  withIcon={false}
                  placeholder={"city"}
                  onChange={handleChange}
                  value={values.city}
                  id="city"
                  name="city"
                  hasError={errors.city && touched.city ? true : false}
                  onFocus={() => handleOnTouched("city")}
                  errorMessage={errors.city}
                  status={changeInputStatus(
                    values.city,
                    errors.city && touched.city
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <GeneralInput
                  withIcon={false}
                  placeholder={"zip_code"}
                  onChange={handleChange}
                  value={values.zipCode}
                  id="zipCode"
                  name="zipCode"
                  hasError={errors.zipCode && touched.zipCode ? true : false}
                  onFocus={() => handleOnTouched("zipCode")}
                  errorMessage={errors.zipCode}
                  status={changeInputStatus(
                    values.zipCode,
                    errors.zipCode && touched.zipCode
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <GeneralInput
                  withIcon={false}
                  placeholder={"account_number"}
                  onChange={handleChange}
                  value={values.accountNumber}
                  id="accountNumber"
                  name="accountNumber"
                  hasError={
                    errors.accountNumber && touched.accountNumber ? true : false
                  }
                  onFocus={() => handleOnTouched("accountNumber")}
                  errorMessage={errors.accountNumber}
                  status={changeInputStatus(
                    values.accountNumber,
                    errors.accountNumber && touched.accountNumber
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={2}>
                <GeneralSelect
                  value={values?.routeType}
                  onChange={(event: any) => {
                    onHandlerRoutingSelect(event?.target?.value);
                  }}
                  options={routingTypes ?? []}
                  reference={stateRef}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <GeneralInput
                  withIcon={false}
                  placeholder={"000000000"}
                  onChange={handleChange}
                  value={values.routeNumber}
                  id="routeNumber"
                  name="routeNumber"
                  hasError={
                    errors.routeNumber && touched.routeNumber ? true : false
                  }
                  onFocus={() => handleOnTouched("routeNumber")}
                  errorMessage={errors.routeNumber}
                  status={changeInputStatus(
                    values.routeNumber,
                    errors.routeNumber && touched.routeNumber
                  )}
                />
              </Grid>
            </Grid>
            <div className={styles._buttonParent}>
              <GeneralButton
                loading={loading || updateLoading}
                text={"save"}
                type="submit"
                action={submitForm}
                disabled={!isValid || !dirty}
              />
            </div>
          </form>
        </div>
      </GeneralModal>
    </>
  );
};

export default CreateBankAccount;
