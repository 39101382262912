import { gql } from '@apollo/client';

const DELETE_UI_ROLE_MEMBER = gql`
  mutation DeleteUiRoleMember($id: ID!) {
    deleteUiRoleMember(data: { id: $id }) {
      result
    }
  }
`;
export default DELETE_UI_ROLE_MEMBER;
