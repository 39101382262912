import React from 'react'
import styles from './styles.module.scss'
import { Icon } from '@iconify/react'
import { GeneralButton } from 'components'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { getBankAccountSelector } from 'store/selectors'
import { specialAmountFormat} from 'utils'
import { resetBankAccountData } from 'store/actions'
import { useNavigate } from 'react-router-dom'

const ConfirmationModal = ({ handleStep }: any) => {

  const { t } = useTranslation()

  const dispatch = useDispatch()
  const { amount, title, memo } = useSelector(getBankAccountSelector)

  const navigate = useNavigate()

  const acceptAndReset = () => {
    dispatch(resetBankAccountData())
    handleStep(0)
  }

  const toActivity = () => navigate('/activity')

  return (
    <div className={styles._main}>
      <div className={styles._modal}>
        <p className={styles._title}>{t('sended')} ${specialAmountFormat(amount)}</p>

        <div className={styles._checkParent}>
          <Icon icon='bx:check-circle' color='#E6427A' width={70}/>
        </div>


        <p className={styles._toText}>{t('a')} <span className={styles._titleInside}>{title}</span></p>
        <p className={styles._memo}>{memo}</p>

        <div className={styles._btnParent}>
          <GeneralButton text='accept' width='9.25rem' action={acceptAndReset}/>
        </div>

        <p className={styles._detailText} onClick={toActivity}>{t('payment_detail')}</p>
      </div>
    </div>
  )
}

export default ConfirmationModal
