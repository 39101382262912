import React, { useEffect } from 'react'
import { GeneralButton } from '../../../../../components'
import {OnboardingFooter} from '../../../../components'
import { useNavigate } from 'react-router-dom'
import styles from './styles.module.scss'
import { useDispatch } from 'react-redux'
import { clearOnboardingData, setUserData } from 'store/actions'

const ChooseTypeUser = ({t}:any) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const navigateTo = (type: string): void => {
    dispatch(setUserData({ type }))
    navigate("/onboarding/basic-user-data")
  }

  useEffect(() => {
    dispatch(clearOnboardingData())
  }, []);

  return (
    <div className={styles._main}>
      <div className={styles._container}>
        <div >
          <h3 className={styles._title}>{t('type_of_profile')}</h3>
          <p className={styles._subtitle}>{t('fill_form')}</p>
        </div>
        <div>
          <GeneralButton text={'natural_person'} action={() => navigateTo('natural')} />
          <div className={styles._btnParent}>
            <GeneralButton text={'legal_person'} action={() => navigateTo('juridic')} />
          </div>
          <OnboardingFooter showBtn={false} showStepper={false} />
        </div>
      </div>
    </div>
  );
};

export default ChooseTypeUser;
