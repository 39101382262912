import styles from "./styles.module.scss";
export const transformToRows = (items: Array<any>, isMobile: boolean) =>
  items
    ? items.map((item) => ({
        id: item.id,
        values: isMobile
          ? [
              {
                value: (
                  <div className={styles._mobileRow}>
                    <p>
                      <b>{item.name}</b>
                    </p>
                    <p>{item.email}</p>
                  </div>
                ),
              },
            ]
          : [
              {
                value: item.name,
              },
              {
                value: <p className={styles._ellipsis}>{item.email}</p>,
              },
              {
                value: item.role,
              },
              {
                value: item.createdAt ?? "",
              },
              {
                value: item.updatedAt ?? "",
              },
            ],
      }))
    : [];
