import { useFormik } from 'formik';
import { onlyLettersRegex, onlyNumbersRegex, translate, onlyLettersAndNumbers} from 'utils';
import * as Yup from 'yup';

const baseSchema = Yup.object({
  fiscalAddress: Yup.string()
    .required('field_required')
    .max(250, () => translate('max_invalid', { number: 250 })),

  typeCommerceId: Yup.string(),
  typeCommerceFallback: Yup.string()
    .min(3, () => translate('min_invalid', { number: 3 }))
    .matches(onlyLettersRegex, 'only_letters')
    .when('typeCommerceId', {
      is: undefined,
      then: Yup.string().required('field_required'),
    }),

  dniType:Yup.string().required(),

  dniNumber: Yup.string()
    .required('field_required')
    .when('dniType', {
      is: 'P',
      then: Yup.string().matches(onlyLettersAndNumbers,'only_alphanumeric'),
      otherwise: Yup.string().matches(onlyNumbersRegex, 'only_numbers')
    })
    .min(3, () => translate('min_invalid', { number: 3 }))
    .max(10, () => translate('max_invalid', { number: 10 })),

});

const naturalSchema = {
  firstName: Yup.string()
    .required('field_required')
    .min(3, () => translate('min_invalid', { number: 3 }))
    .matches(onlyLettersRegex, 'only_letters'),

  lastName: Yup.string()
    .required('field_required')
    .min(3, () => translate('min_invalid', { number: 3 }))
    .matches(onlyLettersRegex, 'only_letters'),
};

const legalSchema = {
  firstName: Yup.string(),
  lastName: Yup.string(),

  state: Yup.string().required('field_required'),

  city: Yup.string()
  .required('field_required')
  .min(3, () => translate('min_invalid', { number: 3 }))
  .max(255, () => translate('max_invalid', { number: 255 }))
  .matches(onlyLettersRegex, 'only_letters'),

  zipCode: Yup.string()
  .required('field_required')
  .min(4, () => translate('min_invalid', { number: 4 }))
  .max(12, () => translate('max_invalid', { number: 12 }))
  .matches(onlyNumbersRegex, 'only_numbers'),

  businessName: Yup.string()
    .required('field_required')
    .min(3, () => translate('min_invalid', { number: 3 }))

};

export const FormikConfig = (
  handleSubmit: any,
  typeUser: boolean,
  initialValues: any
) =>
  useFormik({
    initialValues: {
      firstName: initialValues.firstName || '',
      lastName: initialValues.lastName || '',
      fiscalAddress: initialValues.fiscalAddress || '',
      typeCommerceId: initialValues.typeCommerceId || undefined,
      typeCommerceFallback: initialValues.typeCommerceFallback || '',
      businessName: initialValues.businessName || '',
      dniNumber: initialValues.dni || '',
      dniType: initialValues.dniType || typeUser ? 'V' : 'EIN',
      state: initialValues.state || 'AL',
      city:initialValues.city || '',
      zipCode:initialValues.zipCode || ''
    },
    validationSchema: baseSchema.shape(typeUser ? naturalSchema : legalSchema),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
