import React, { memo, useEffect, useRef, useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import styles from './styles.module.scss'
import { Icon } from '@iconify/react'
import { Row } from './components/row'
import { useTranslation } from 'react-i18next'
import { UseIntermitence } from 'hooks'
import { ACTIVITIES_QUERY } from 'graphql/queries'
import { useDispatch, useSelector } from 'react-redux'
import { setActivityData } from 'store/actions'
import { transformTransactionsData } from 'utils'
import { CircularProgress } from '@mui/material'
import { activitySelector } from 'store/selectors'
import { useLazyQuery } from '@apollo/client';
import { MobileActivityTable } from '../../components'
import { useMediaQuery, Theme } from '@mui/material'
import { userSpaceSelector } from 'store/selectors'
import styled from 'styled-components'
import clsx from 'clsx'
import { allWallet } from 'utils'

const ActivityTable = ({ className, paginateBy, noPagination, parentLoading = false, fixedTable = false, setWalletIcon = () => {}, walletName, handleCarrousel }: any) => {

  const tableRef = useRef<any>()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    activities,
    perPage:
    perPageStore,
    searchValue,
    walletId: walletIdStore,
    transactionType: transactionTypeStore,
  } = useSelector(activitySelector)

  const { currentSpace, currentBranchOffice } = useSelector(userSpaceSelector)
  const { pagination } = activities || {}
  const { status, switchStatus } = UseIntermitence()
  const [getActivities, { loading }] = useLazyQuery(ACTIVITIES_QUERY)
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const localTransactions = activities?.detail?.transactions
  const [currentTypeFilter, setCurrentTypeFilter] = useState('all')
  const [typeLoading, setTypeLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1)
  const [currentWallet, setcurrentWallet] = useState(0)

  const activeWallets = currentBranchOffice?.wallets ?? []

  const nextPage = () => {
    if (currentPage < pagination?.totalPages) {
      setCurrentPage(currentPage + 1);
      handlePagination(currentPage + 1)
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      handlePagination(currentPage - 1)
    }
  };

  const handlePagination = (mypage: number) => {
    getActivities({
      onCompleted: (response: any) => {
        dispatch((setActivityData(response)))
      },
      variables: {
        page: mypage,
        perPage: paginateBy,
        search: searchValue,
        walletId: walletIdStore,
        transactionType: transactionTypeStore,
        spaceId: currentSpace?.id,
        branchOfficeId: currentBranchOffice?.id
      },
      fetchPolicy: 'network-only'
    })
  }

  const handleFilterBywallet = (wallet: any, index: number) => {
    setWalletIcon(wallet?.name)
    setcurrentWallet(index);
    getActivities({
      onCompleted: (response: any) => {
        dispatch((setActivityData(response)))
      },
      variables: {
        page: pagination?.page,
        perPage: paginateBy,
        search: searchValue,
        walletId: wallet?.id,
        transactionType: transactionTypeStore,
        spaceId: currentSpace?.id,
        branchOfficeId: currentBranchOffice?.id
      },
      fetchPolicy: 'network-only'
    })
  }

  useEffect(() => {
    setCurrentPage(pagination?.page || 1)
  }, [pagination])

  useEffect(() => {
    if (walletName) {
      const result = (currentBranchOffice?.wallets || []).find(((item: any) => item?.wallet?.name == walletName));
      const currentWallet = currentBranchOffice?.wallets.indexOf(result)
      handleFilterBywallet(result?.wallet, currentWallet + 1);
    }
  }, [walletName])

  const filterBytransactionType = (type: string, filterType: string): void => {
    setCurrentTypeFilter(filterType)
    setTypeLoading(true)
    getActivities({
      onCompleted: (response: any) => {
        dispatch(setActivityData(response))
        setTypeLoading(false)
      },
      variables: {
        page: 1,
        perPage: noPagination ? 6 : perPageStore,
        search: searchValue,
        walletId: walletIdStore,
        transactionType: type,
        spaceId: currentSpace?.id,
        branchOfficeId: currentBranchOffice?.id
      },
      onError: () => setTypeLoading(false),
      fetchPolicy: 'network-only'
    })
  }

  if (!isMobile && parentLoading && !activities) return (
    <div className={styles._loaderParent}>
      <CircularProgress color="primary" size={32} />
    </div>
  )

  return (
    <>
      <div className={styles._superParent}>
        {
          !isMobile && (
            ((loading && perPageStore == paginateBy) || (typeLoading || parentLoading)) && (
              <div className={styles._overLap}>
                <div className={styles._loaderParent}>
                  <CircularProgress color="primary" size={26} />
                </div>
              </div>
            ))
        }
        {
          !isMobile && (
            <div className={clsx(styles._tableParent, className)} ref={tableRef}>
              {
                !noPagination && (
                  <div className={styles._walletsParent}>
                  {
                    [...allWallet, ...activeWallets].map((item: any, index: number) => (
                      <div key={index} className={currentWallet == index ? styles._walletButtonSelected : styles._walletButton} onClick={() => handleFilterBywallet(item?.wallet, index)}>
                        <p>{t(item?.wallet?.title)}</p>
                      </div>
                    ))
                  }
                </div>
                )
              }

              <>
                {
                  activities && localTransactions != '{}' ?
                    (transformTransactionsData(activities) || []).map((item: any, index: number) => (
                      <div key={index}>
                        <div className={styles._headerParent}>
                          <div>
                            <p className={styles._date}>{item[0]}</p>
                          </div>
                          {
                            index == 0 && (
                              <div className={styles._incomesParent} onClick={switchStatus}>
                                <p>{t(currentTypeFilter)}</p>
                                <Icon icon='bx:chevron-down'></Icon>
                                <div className={status ? styles._dropdown : styles._dropdownHidden}>
                                  <div className={status ? styles._option : styles._optionHidden} onClick={() => filterBytransactionType(null, 'all')}>
                                    <p >{t('all')}</p>
                                  </div>
                                  <div className={status ? styles._option : styles._optionHidden} onClick={() => filterBytransactionType('IN', 'see_incomes')}>
                                    <p >{t('see_incomes')}</p>
                                  </div>
                                  <div className={status ? styles._option : styles._optionHidden} onClick={() => filterBytransactionType('OUT', 'see_egress')}>
                                    <p >{t('see_egress')}</p>
                                  </div>
                                </div>
                              </div>
                            )
                          }
                        </div>
                        <TableContainer>
                          <Table aria-label="collapsible table" className={fixedTable ? styles._tableArea : ''}>
                            <TableBody>
                              {item[1].map((row: any, index: number) => (
                                <Row key={index} row={row} />
                              ))}
                            </TableBody>
                          </Table>
                          {
                            (loading && perPageStore > paginateBy && !typeLoading) && (
                              <div className={styles._loaderParent}>
                                <CircularProgress color="primary" size={30} />
                              </div>
                            )
                          }
                        </TableContainer>
                      </div>
                    )) : !isMobile && <div className={styles._noTransactionsParent}>
                      <div className={styles._iconParent}>
                        <Icon icon='bx:x-circle' width={32}></Icon>
                      </div>
                      <div className={styles._textParent}>
                        <p>{t("no_recent_activity")}</p>
                        <p>{t("start_use_account")}</p>
                      </div>
                    </div>
                }
              </>

              {
                (pagination?.totalPages > 0 && !noPagination) && (
                  <div className={styles._paginationParent}>
                    <div className={styles._paginationContainer}>
                      <div className={styles._leftArrow} onClick={previousPage}>
                        <Icon icon="bx:arrow-back" width={20} color='#909090' />
                      </div>
                      <p className={styles._paginationText}>{currentPage}/{pagination?.totalPages}</p>
                      <div className={styles._rightArrow} onClick={nextPage}>
                        <Icon icon="bx:right-arrow-alt" width={20} color='#909090' />
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          )
        }

      </div>
      {isMobile && (
        <MobileActivityTable
          activities={activities}
          loading={loading}
          paginateBy={paginateBy}
          noPagination={noPagination}
          parentLoading={parentLoading}
          pagination={pagination}
          nextPage={nextPage}
          prevPage={previousPage}
          currentPage={currentPage}
          filterByWallet={handleFilterBywallet}
          currenWallet={currentWallet}
          walletName={walletName}
          handleCarrousel={handleCarrousel}
          isMobile={isMobile}
        />
      )}
    </>
  )
}

export default styled(memo(ActivityTable))`
  height: ${({ height }: any) => (height ? height : '100%')};
`;
