import React, { useEffect } from "react";
import { baseUrl } from "paths";
import { useRef, useState } from "react";
import SignaturePad from "react-signature-canvas";
import html2canvas from "html2canvas";
import styles from "./styles.module.scss";
import { loadFile } from "store/file/action";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import { useMediaQuery } from "@mui/material";
import { Theme } from "@mui/system";

const SignatureComponent = ({
  name,
  setFieldValue,
  error,
  validate,
  typeName,
  touched,
  setFieldTouched,
}: {
  error?: any;
  name?: string;
  touched?: any;
  setFieldValue: any;
  typeName: string;
  setFieldTouched: any;
  validate: any;
}) => {
  const canvasRef = useRef(null);
  const inputRef = useRef(null);
  const attachRef = useRef(null);
  const { t } = useTranslation();
  const [type, setType] = useState<"write" | "draw" | "attach">("draw");
  const [fileName, setFileName] = useState("");
  const dispatch = useDispatch();
  const [text, setText] = useState("");
  const isMobile = useMediaQuery((theme: Theme) =>theme.breakpoints.down("sm"))

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleChange();
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [text]);

  const handleAttachChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      dispatch(
        loadFile(file, "Customers", ({ ok, values }) => {
          if (ok) {
            setFieldValue(typeName, type);
            setFieldValue(name, values.link, true);
            setFileName(values.name);
          }
        })
      );
    }
  };
  const handleChange = async () => {
    if (type === "write" && inputRef.current.value !== "") {
      const data = await html2canvas(inputRef.current, {
        onclone(_, element) {
          element.style.width = inputRef.current.value?.length + "ch";
        },
      });
      const image = data.toDataURL("image/png", 1.0);
      setFieldValue(typeName, type);
      return setFieldValue(name, image);
    } else if (type === "draw" && !canvasRef.current.isEmpty()) {
      const image = canvasRef.current.getTrimmedCanvas().toDataURL();
      setFieldValue(typeName, type);
      return setFieldValue(name, image, "signature");
    }
    setFieldValue(name, "");
  };

  return (
    <div
      className={styles._signatureMainContainer}
      onClick={() => !touched && setFieldTouched(name, true, true)}
    >
      <div className={styles._signatureOptions}>
        <p
          className={type === "attach" ? styles._selected : null}
          style={{ position: "relative" }}
          onClick={() => {
            if (type != "attach") {
              setFieldValue(name, "");
              setFileName("");
            }
            setType("attach");
            attachRef.current.value = "";
          }}
        >
          {t("attach")}
          <input
            type="file"
            className={styles._attachInput}
            accept=".jpg, .png, .pdf"
            ref={attachRef}
            onChange={handleAttachChange}
          />
        </p>
        <p
          className={type === "draw" ? styles._selected : null}
          onClick={() => {
            setFieldValue(name, "");
            setType("draw");
          }}
        >
          {t("draw")}
        </p>
        <p
          className={type === "write" ? styles._selected : null}
          onClick={() => {
            setFieldValue(name, "");
            setType("write");
          }}
        >
          {t("write")}
        </p>
      </div>
      {type == "attach" ? (
        <div
          className={clsx(
            styles._file,
            fileName ? styles._fileSelected : styles._fileNotSelected
          )}
        >
          <p id="file-chosen" className={styles._fileName}>
            {fileName}
          </p>
          <div
            className={styles._iconContainer}
            onClick={(e) => {
              attachRef.current.value = "";
              setFieldValue(name, "");
              setFileName("");
            }}
          >
            <img
              src={`${baseUrl}../resources/close.svg`}
              alt="clip"
              width={12}
              height={12}
            />
          </div>
        </div>
      ) : (
        <div className={styles._signaturesContainer}>
          <div className={styles._relativeWrapper}>
            {type === "draw" ? (
              <SignaturePad
                ref={canvasRef}
                penColor="#111111"
                clearOnResize={!isMobile}
                dotSize={1.5}
                maxWidth={1.5}
                minDistance={0.5}
                velocityFilterWeight={0.1}
                canvasProps={{
                  className: styles._signaturePad,
                }}
                onEnd={handleChange}
              />
            ) : (
              <input
                className={styles._input}
                autoFocus
                ref={inputRef}
                maxLength={40}
                onChange={(e) => setText(e.target.value)}
              />
            )}
            <div className={styles._signatureLine}></div>
            <img
              src={`${baseUrl}/resources/clear-signature-icon.svg`}
              width={16}
              height={16}
              className={styles._clearSignatureIcon}
              alt="Clear signature"
              onClick={() => {
                setFieldValue(name, "");
                if (type === "draw") {
                  canvasRef.current?.clear();
                } else {
                  inputRef.current.value = "";
                }
              }}
            />
          </div>
        </div>
      )}
      {error && <p className={styles._errorMessage}>{error}</p>}
    </div>
  );
};

export default SignatureComponent;
