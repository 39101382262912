import styles from "./styles.module.scss";
import clsx from "clsx";

export const transformToRows = (items: Array<any>, isMobile = false) =>
  items
    ? items.map((item: any) => ({
        id: item.id,
        extraProps: {
          isPrincipal: false,
        },
        values: isMobile
          ? [
              {
                cellWidth: "65%",
                value: (
                  <div className={styles._mobileRow}>
                    <p className={clsx(styles._bold)}>{`${item?.firstName} ${item?.lastName}`} </p>
                    <p>{item?.email}</p>
                  </div>
                ),
              },
            ]
          : [
              {
                value: (
                  <p
                    className={clsx(styles._ellipsis, styles._bold, styles._fontSize)}
                  >{`${item?.firstName} ${item?.lastName}`}</p>
                ),
                cellWidth: "15%",
              },
              {
                value: <p className={styles._fontSize}>{item?.type}</p>,
                cellWidth: "10%",
              },
              {
                value: <p className={styles._fontSize}>{item?.identification}</p>,
                cellWidth: "10%",
              },
              {
                value: <p className={clsx(styles._ellipsis, styles._fontSize)}>{item?.email}</p>,
                cellWidth: "15%",
              },
              {
                value: (
                  <p className={clsx(styles._ellipsis, styles._fontSize)}>{item?.cellPhoneNumber}</p>
                ),
                cellWidth: "15%",
              },
              {
                value: <p className={clsx(styles._ellipsis, styles._fontSize)}>{item?.address}</p>,
                cellWidth: "15%",
              },
            ],
      }))
    : [];
