import React from 'react'
import styles from "./styles.module.scss"
import { baseUrl } from 'paths'
import { Icon } from '@iconify/react'
import { BalanceCardType } from './type'
import { useTranslation } from 'react-i18next';
import {parseAmount} from 'utils'

const BalanceCard = ({ balance = '0', icon = 'puntu' }: BalanceCardType) => {
  const { t } = useTranslation()

  return (
    <div className={styles._main}>
      <div className={styles._header}>
         <p className={styles._balanceText}>{t('balance')}</p>
        <img src={`${baseUrl}/resources/${icon}-card-icon.svg`}  alt='puntu-isotipo' width={35}></img>
      </div>

      <div className={styles._footer}>
        <div className={styles._amountParent}>
        <p className={styles._amountText}>${parseAmount(balance)}</p>
        <div className={styles._iconParent}>
          <Icon icon='bx:up-arrow-alt'></Icon>
        </div>
        </div>
        <div className={styles._percentParent}>
        </div>
      </div>
    </div>
  )
}

export default BalanceCard;
