import React, { memo } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import {
  tabsParent,
  tab,
  tabsBorder,
} from "../../web/components/Drawers/DashboardDrawer/materialStyles";
import { GeneralTabsType } from "./types";
import { useMediaQuery, Theme } from "@mui/material";
import styles from "./styles.module.scss";

const GeneralTabs = ({
  data = [],
  onChange,
  value = 0,
  labelKey = "",
  reverse = false,
  maxWidth = null,
  sx,
}: GeneralTabsType) => {
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  return (
    <Box sx={() => tabsParent(maxWidth)}>
      <Box sx={tabsBorder}>
        <Tabs
          value={value}
          variant="scrollable"
          onChange={onChange}
          aria-label="scrollable prevent tabs"
          classes={reverse ? { flexContainer: styles._reverse } : undefined}
        >
          {data.map((item: any, index: number) =>
            item ? (
              <Tab
                label={item[labelKey]}
                sx={sx ? sx : tab}
                value={index || 0}
                key={index}
                style={{
                  width: matches ? `${100 / data.length + 1}%` : "auto",
                  textTransform: "none",
                }}
              />
            ) : null
          )}
        </Tabs>
      </Box>
    </Box>
  );
};

export default memo(GeneralTabs);
