import React, { useState, useRef, useEffect, memo, useMemo } from 'react'
import styles from './styles.module.scss'
import { GeneralIconButton, GeneralInput, GeneralTabs } from '../../../../components'
import { ExportModal, BalanceCard, ActivityTable } from '../../../components'
import { Icon } from '@iconify/react'
import Slider from "react-slick";
import { useMediaQuery, Theme } from '@mui/material'
import { sliderSettings, calculateTableHeight, allWallet, CUSTOMER } from '../../../../utils'
import { useLazyQuery, useQuery } from '@apollo/client';
import { ACTIVITIES_QUERY } from '../../../../graphql/queries'
import { useDispatch, useSelector } from 'react-redux'
import { setActivityData, addActivityParams, setUserSpace } from '../../../../store/actions'
import { activitySelector, userAuthSelector, userSpaceSelector } from '../../../../store/selectors'
import { useLocation, useNavigate } from 'react-router-dom'
import { UseIntermitence, UseAlert } from 'hooks'
import BRANCH_OFFICES_QUERY from 'graphql/queries/branchOffices'

const Activity = ({ t }: any) => {

  const navigate = useNavigate()
  const location: any = useLocation()
  const dispatch = useDispatch()
  const activity = useSelector(activitySelector)
  const { userAuthData, permissions } = useSelector(userAuthSelector)
  const { activities } = activity || {}
  const { detail } = activities || {}
  const { showAlert } = UseAlert();

  const { data } = useQuery(BRANCH_OFFICES_QUERY, {
    fetchPolicy: "cache-and-network",
    variables: {
      perPage: 999,
      page: 1
    }
  });
  
  const name = userAuthData?.role?.name;
  const branchOffices = data?.branchOffices?.items || []

  const tabsData = useMemo(
    () =>
      branchOffices.map((item: any) => ({
        name: <p className={styles._tabItem}>{item.name}</p>,
      })),
    [branchOffices]
  );

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const { currentSpace, currentBranchOffice } = useSelector(userSpaceSelector)
  const userType = userAuthData?.customer?.type

  const [value, setValue] = useState('')
  const [actualWallet, setActualWallet] = useState(null)

  const [tabValue, setTabValue] = useState(0);
  const [timer, setTimer] = useState(null);
  const timeout = useRef(null)
  const parent = useRef(null)
  const mainRef = useRef(null)
  const carouselRef = useRef(null)
  const [walletName, setWalletName] = useState(null)

  const [getActivities, { loading }] = useLazyQuery(ACTIVITIES_QUERY)
  const { status, switchStatus } = UseIntermitence()

  const localdata = currentBranchOffice?.wallets || [];
  const userWallets = currentBranchOffice?.wallets || [];

  const handlerChange = async (event: any, value: any) => {
    if (!loading) {
      const localValue = branchOffices[value];
      setTabValue(value)
      await dispatch(setUserSpace({ currentBranchOffice: localValue }))
      await getActivitiesAction(localValue?.id, null)
    }
  }

  useEffect(() => {
    const { state } = location

    if (userWallets && userWallets.length) {
      const allWallets = [...allWallet, ...localdata] || [];
      if (state?.name && !walletName) {
        setWalletName(state?.name);
        const localIndex = allWallets.findIndex(((item: any) => item?.wallet?.name == state?.name))
        carouselRef?.current?.slickGoTo(!isMobile ? localIndex + 1 : localIndex, true)
      }
    }

    if (branchOffices && branchOffices?.length) {
      const branchOfficeIndex = branchOffices.findIndex((office: any) => office?.id === currentBranchOffice?.id);
      if (branchOfficeIndex > -1) {
        dispatch(setUserSpace({ currentBranchOffice: branchOffices[branchOfficeIndex] }))
        setTabValue(branchOfficeIndex)
      }
    }

  }, [userWallets, branchOffices])

  const handleCarrousel = (index: number) => {
    if (index > -1) carouselRef?.current?.slickGoTo(index, true)
  }

  useEffect(() => {
    const { state } = location
    if (!walletName && currentSpace && !state?.name) getActivitiesAction();

    return () => {
      clearTimeout(timeout.current)
      dispatch(addActivityParams({ walletId: null, searchValue: '', transactionType: null }))
      if (location?.state?.name) navigate(location?.pathname, { state: null });
    }
  }, [tabValue, currentSpace])

  const inputHandler = (event: any) => {
    if (!loading) {
      const { target: { value } } = event

      setValue(value)

      if (timer) clearTimeout(timer);

      setTimer(
        setTimeout(() => {
          dispatch(addActivityParams({ searchValue: value }))
          searchActivity(value)
        }, 500),
      );
    }
  };

  const getActivitiesAction = (localbranchOfice: string = null, wallet: string = null) => {
    getActivities({
      onCompleted: (response: any) => dispatch(setActivityData(response)),
      variables: {
        page: 1,
        perPage: activity?.perPage,
        search: activity?.searchValue,
        walletId: wallet,
        transactionType: activity?.transactionType,
        spaceId: currentSpace?.id,
        branchOfficeId: localbranchOfice || currentBranchOffice?.id
      },
      fetchPolicy: 'network-only'
    })
  }

  const searchActivity = (value: string) => {
    getActivities({
      onCompleted: (response: any) => dispatch(setActivityData(response)),
      variables: {
        page: 1,
        perPage: 10,
        search: value,
        walletId: activity?.walletId,
        transactionType: activity?.transactionType,
        spaceId: currentSpace?.id,
        branchOfficeId: currentBranchOffice?.id
      },
      fetchPolicy: 'network-only'
    })
  }

  const swipeHandler = (oldIndex: number, newIndex: number) => {
    const allWallets = [...allWallet, ...localdata]
    setWalletName(allWallets[newIndex]?.wallet?.name);
  }

  const setWalletIcon = (wallet: string) => setActualWallet(wallet);

  const validateActivity = () => {
    if (activities.detail.total == '0') return showAlert(t('no_recent_activity'), "error")
    switchStatus()
  }

  return (
    <div className={styles._mainContainer} ref={mainRef}>
      <div className={styles._responsiveBar}>
        <h3 className={styles._barTitle}>{t('activity')}</h3>
        {(name == CUSTOMER || permissions.includes('DOWNLOAD_ACTIVITY')) && (
          <div className={styles._responsiveBtnParent}>
            <GeneralIconButton iconColor='#E6427A' text='export' method={() => validateActivity()} leftIcon rightIcon />
          </div>
        )}

      </div>
      <div ref={parent} className={styles._sectionParent}>
        <div className={styles._actionsParent}>
          {
            !isMobile ? (
              <div className={styles._balanceCardParent}>
                <BalanceCard balance={detail?.total} icon={actualWallet || 'puntu'} />
              </div>
            ) : (<Slider {...sliderSettings} className={styles._slider} beforeChange={swipeHandler} ref={carouselRef}>
              {
                ([...allWallet, ...userWallets] || []).map((item: any) => (
                  <div className={styles._sliderBalanceCardParent} key={item}>
                    <BalanceCard balance={detail?.total} percent={detail?.rate} icon={item?.wallet?.name || 'puntu'} />
                  </div>
                ))
              }
            </Slider>)
          }

          {
            !isMobile && (
              <div className={styles._buttonsParent}>
                <div className={styles._inputParent}>
                  <GeneralInput
                    onChange={(event: any) => inputHandler(event)}
                    value={value}
                    placeholder={'search'}
                    className={styles._noMargin}
                  />
                  <Icon icon='bx:search' width={18} className={styles._icon} onClick={null} />
                </div>
                {(name == CUSTOMER || permissions.includes('DOWNLOAD_ACTIVITY')) && (
                  <div className={styles._buttonParent}>
                    <GeneralIconButton iconColor='#E6427A' text='export'
                      leftIcon
                      rightIcon
                      method={() => validateActivity()} />
                  </div>
                )}
              </div>
            )
          }

        </div>

        {
          userType == 'juridic' && (
            <GeneralTabs
              data={tabsData}
              onChange={handlerChange}
              value={tabValue}
              labelKey='name'
              maxWidth={isMobile ? '100%' : '80vw'}
            />
          )
        }
      </div>

      <ActivityTable
        height={`calc(100vh - ${calculateTableHeight(parent?.current?.clientHeight)}px)`}
        paginateBy={10}
        parentLoading={loading}
        fixedTable={true}
        setWalletIcon={setWalletIcon}
        walletName={walletName}
        handleCarrousel={handleCarrousel}
      />

      {
        status && (
          <ExportModal
            open={status}
            onCancel={switchStatus}
            btnText={'export'}
          />
        )
      }
    </div>
  );
};

export default memo(Activity);
