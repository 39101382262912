import { gql } from '@apollo/client';

const UPDATE_BANK_ACCOUNT = gql`
  mutation UpdateBankAccount($data: UpdateBankAccountInput!) {
    updateBankAccount(data: $data) {
      id
      bank {
        title
      }
      accountNumber
    }
  }
`;
export default UPDATE_BANK_ACCOUNT;
