import { AnyAction } from 'redux';
import { navbarHeight, activityTablePadding } from './constants';
import { onlyLettersRegex } from './regex';

export const actionObject = (
  type: string,
  payload?: any | null,
  callback?: ({
    ok,
    message,
    value,
    data,
  }: {
    ok: boolean;
    message?: string;
    value?: string;
    data?: any;
  }) => void
): AnyAction => ({
  type,
  payload,
  callback,
});

export const deleteEmptyKeysFromObject = (data: any = {}) => {
  for (const key in data) {
    if (!data[key]) delete data[key];
  }
  return data || {};
};

export const phoneMask = (value: string): string => {
  let formatedValue: string;
  const numberValue = value
    .replace(/\D/g, '')
    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  formatedValue = !numberValue[2]
    ? numberValue[1]
    : '(' +
      numberValue[1] +
      ') ' +
      numberValue[2] +
      (numberValue[3] ? '-' + numberValue[3] : '');

  return formatedValue;
};

export const transformTransactionsData = (localActivities: any): Array<any> => {
  const transactions = localActivities?.detail?.transactions ?? '{}';
  const parsed = JSON.parse(transactions);
  return Object.entries(parsed) ?? [];
};

export const paginate = (
  array: Array<any>,
  pageSize: number,
  pageNumber: number
) => {
  return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
};

export const calculateTableHeight = (height: number): number => {
  return height + (navbarHeight + activityTablePadding);
};

export const switchWalletsNumber = (walletsLength: number): number => {
  switch (walletsLength) {
    case 0:
      return 3;
    case 1:
      return 2;
    case 2:
      return 1;
    case 3:
      return 1;
    case 4:
      return 0;

    default:
      break;
  }
};

export const amountMask = (event: any, setFieldValue: any, key: string) => {
  const {
    target: { value },
  } = event;
  let localValue = value.replaceAll(',', '');
  let parsedValue = localValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  setFieldValue(key, parsedValue);
};

export const parseToSimpleNumber = (amount: any): string => {
  const parsed = amount.replaceAll(',', '');
  return parsed;
};

export const toFloat = (stringAmount: any = '0'): number => {
  if (onlyLettersRegex.test(stringAmount)) return 0;

  if (stringAmount.includes(',')) {
    const plainText = stringAmount.replaceAll(',', '');
    return parseFloat(plainText);
  }

  return parseFloat(stringAmount);
};

export const parseAmount = (amount: any = ''): string => {
  const parsedAmount = parseFloat(amount).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return parsedAmount;
};

export const specialAmountFormat = (amount: any = '') => {
  let localAmount = amount;
  if (amount.includes(',')) localAmount = amount.replaceAll(',', '');

  const parsedAmount = parseFloat(localAmount).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return parsedAmount;
};

export const verifyWallets = (
  text: string,
  allWallets: Array<any>
): boolean => {
  const regex = new RegExp(allWallets.join('|')).test(text);
  return !regex;
};

export const returnUserName = (
  profile: any = {},
  customer: any,
  spaces: any
): any => {
  if (customer?.type == 'juridic') return spaces?.[0]?.space?.name ?? '';
  return profile?.firstName ?? '';
};

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

export const shallowCompare = (obj1: any, obj2: any) =>
  Object.keys(obj1).length === Object.keys(obj2).length &&
  Object.keys(obj1).every(
    (key) => obj2.hasOwnProperty(key) && obj1[key] === obj2[key]
  );

export const normalizeResponseErrorMesages = (
  message: string,
  t: any
): string => {
  if (message.includes('is still referenced from table "transfer_requests'))
    return t('account_cant_deleted');
  return message;
};
