import React, { memo } from 'react'
import { Icon } from '@iconify/react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import styles from './styles.module.scss'
import { useSelector } from 'react-redux'
import { resourceSelector } from 'store/selectors'
import { useTranslation } from 'react-i18next'
import { baseUrl } from 'paths'
import { ACTIVATE_WALLET } from "graphql/mutations"
import { useMutation } from "@apollo/client"
import { UseAlert } from 'hooks'
import { userSpaceSelector } from 'store/selectors'

const SelectWallets = ({ open, onClose, refetch, userWallets = [] }: any) => {

  const { t } = useTranslation()
  const { wallets: generalWallets } = useSelector(resourceSelector)
  const { showAlert } = UseAlert()

  const [activateWallet] = useMutation(ACTIVATE_WALLET)
  const { currentBranchOffice } = useSelector(userSpaceSelector)

  const compareWallets = (localWallet: any, generalWallet: any): Array<any> => {
    const results = generalWallet.filter((itemA: any) => !localWallet.some((itemB: any) => itemB?.wallet?.name === itemA?.name));
    return results
  }

  const handlerActivate = (id: string) => {
    activateWallet({
      variables: { walletId: id, branchOfficeId: currentBranchOffice?.id },
      onCompleted: (response) => {
        const { activateWallet } = response
        if (activateWallet) {
          showAlert(t('wallet_activated'), 'success')
          if (refetch) refetch()
          onClose()
        }
      },
      onError: (error) => {
        showAlert(t('something_went_wrong'), 'error')
      }
    })
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
    >
      <DialogContent className={styles._main}>
          <div className={styles._header}>
          <img
              src={`${baseUrl}/resources/puntu-logo.png`}
              alt="onboarding-img"
              width="140px"
              className={styles._appbarImage}
            />
             <Icon
                icon="bx:x"
                className={styles._icon}
                color="#000000"
                width={32}
                onClick={onClose}
              />
          </div>
         <div>

         </div>
        <div className={styles._content}>
          <p className={styles._title}>{t('add_new_account')}</p>
        </div>

        {(compareWallets(userWallets, generalWallets) || []).map((item: any, index: number) => (
          <div className={styles._modalCard} key={index} onClick={() => handlerActivate(item?.id)}>
            <div className={styles._leftSide}>
              <div className={styles._iconParent}>
                <img src={`${baseUrl}/resources/${item?.name}-card-icon.svg`} width={35} alt='card-icon' />
              </div>
              <p className={styles._cardTitle}>{item?.title}</p>
            </div>

            <div className={styles._rightSide}>
              <p>{t('affiliate')}</p>
              <Icon icon='bx:right-arrow-circle' width={20}></Icon>
            </div>
          </div>
        ))}
      </DialogContent>
    </Dialog>
  )
}

export default memo(SelectWallets)
