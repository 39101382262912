export const passwordRegex =
  /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;
export const emailRegex =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
export const onlyLettersRegex = /^[a-zA-ZñÑ ]+$/;
export const onlyNumbersRegex = /^[0-9 ]+$/;
export const onlyNumbersWithoutSpace = /^[0-9]{0,}$/;
export const phoneRegex = /^(\+|00)[0-9]{1,4}[ ][0-9]{3}[0-9]{7,11}$/g;
export const amountRegex = /(^(\-|)[0-9]+\.[0-9]{1,2}$|^(\-|)[0-9]+$)/gm;
export const onlyLettersAndNumbers = /^[a-zA-ZñÑ0-9]*$/;
export const simplePhoneRegex = /^(\+|0)[0-9]{9,11}$/g
export const onlyLettersNumbersAndSpace = /^[a-zA-ZñÑ0-9 ]*$/;
export const onlyLettersNumbersWithoutSpace = /^[a-zA-ZñÑ0-9]*$/;
export const amountCharactersRegex = /^[0-9,.]+$/;
