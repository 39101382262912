import { SET_ALERT } from './action-types';

const initialState: any = {
  type: 'success',
  text: 'Éxito',
  status: 1
};

const intermitence = (state = initialState, { type, payload }: any) => {
  switch (type) {
    case SET_ALERT:
      return { ...state, ...payload };

    default:
      return state;
  }
};

export default intermitence;
