import React from "react";
import styles from "./styles.module.scss";
import SimpleHeader from "../../components/Headers/SimpleHeader";
import { Box, CssBaseline, Toolbar } from "@mui/material";
import { appBarStyles } from "./materialStyles";

const SimpleLayout = ({ children }: any) => {
  return (
    <Box sx={{ display: "flex" }} className={styles._main}>
      <CssBaseline />
      <SimpleHeader />
      <Box
        component="main"
        sx={(theme) => ({
          width: "100%",
        })}
      >
        <Toolbar disableGutters sx={appBarStyles} />
        {children}
      </Box>
    </Box>
  );
};

export default SimpleLayout;
