import { createTheme } from '@mui/material/styles';
import { materialUIBreakpoints } from 'utils';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xxl: true;
    xxxl: true;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    blackColorCustom?: Palette['primary'];
    whiteColorCustom?: Palette['primary'];
    grayColorCustom?: Palette['primary'];
  }
  interface PaletteOptions {
    blackColorCustom?: PaletteOptions['primary'];
    whiteColorCustom?: PaletteOptions['primary'];
    grayColorCustom?: PaletteOptions['primary'];
  }
}
const theme = createTheme({
  palette: {
    primary: { main: '#E6427A' },
    blackColorCustom: { main: '#111111', dark: '#000000' },
    background: { default: '#E5E5E5', paper: '#F9F9F9' },
    whiteColorCustom: { main: '#FFFFFF', light: '#F5F5F5' },
    grayColorCustom: { main: '#909090' },
  },
  breakpoints: {
    values: materialUIBreakpoints,
  },
  typography: {
    fontFamily: 'Space Grotesk, sans-serif',
  },
});

export default theme;
