import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import {
  BackArrow,
  GeneralTabs,
  SingleGeneralRadioGroup,
} from "../../../../components";
import { Icon } from "@iconify/react";
import { useMediaQuery, Theme } from "@mui/material";
import { paymentOptions, parseAmount } from "utils";
import { baseUrl } from "paths";
import { useSelector, useDispatch } from "react-redux";
import {
  BankAccountFirstStep,
  BankAccountList,
  BankAccountResume,
} from "./components";
import { ConfirmationModal } from "web/components";
import { resetBankAccountData } from "store/actions";
import ReceiveQR from "./components/ReceiveComponent";
import { useQuery } from "@apollo/client";
import WALLETS from "graphql/queries/systemAccounts";
import BRANCH_OFFICES_QUERY from "graphql/queries/branchOffices";
import { userAuthSelector } from "store/selectors";
import { GetBalance, UseQuery } from "hooks";
import { useNavigate } from "react-router-dom";
import { setUserSpace } from "store/actions";
import { userSpaceSelector } from "store/selectors";
import { resourceSelector } from "store/selectors";

const Payments = ({ t }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tab, setTab] = useState(0);
  const [paymentType, setPaymentType] = useState<any>("send_bank");
  const [currentStep, setCurrentStep] = useState(0);
  const [show, setShow] = useState(null);
  const { data: walletsData } = useQuery(WALLETS);
  const [currentPage, setCurrentPage] = useState(1);
  const { userAuthData } = useSelector(userAuthSelector);
  const { currentBranchOffice } = useSelector(userSpaceSelector);
  const { wallets: generalWallets } = useSelector(resourceSelector);
  const [walletId, setwalletId] = useState<any>(generalWallets[0]?.id);
  const [actualBranchOffice, setActualBranchOffice] = useState(
    currentBranchOffice?.code
  );
  const actualWallets = (generalWallets || []).filter(
    (item: any) => item?.name !== "puntu"
  );

  const specialCustomer = currentBranchOffice?.space?.isSpecial || false;

  const handleCheckUsers = (value: any) => setActualBranchOffice(value);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("lg")
  );
  const handleBranchOffice = (branchOffice: any) => {
    dispatch(setUserSpace({ currentBranchOffice: branchOffice }));
  };

  const { data, loading } = useQuery(BRANCH_OFFICES_QUERY, {
    fetchPolicy: "no-cache",
    variables: {
      page: currentPage,
      perPage: 3,
      orderBy: "CREATEDAT",
      orderDirection: "asc",
    },
  });

  const { balance } = GetBalance();

  const totalPages = data?.branchOffices?.pagination?.totalPages || 0;

  const nextPage = () => {
    if (currentPage <= totalPages - 1)
      setCurrentPage((currentPage: number) => currentPage + 1);
  };

  const previousPage = () => {
    if (currentPage >= 2)
      setCurrentPage((currentPage: number) => currentPage - 1);
  };

  const handleStep = (step: number) => setCurrentStep(step);

  const switchSteps = (currentStatus: number, handleStep: any) => {
    switch (currentStatus) {
      case 0:
        return <BankAccountFirstStep handleStep={handleStep} />;
      case 1:
        return <BankAccountList handleStep={handleStep} />;
      case 2:
        return <BankAccountResume handleStep={handleStep} />;
      default:
        break;
    }
  };
  const setShowedComponents = (type: string) => {
    setPaymentType(type);
    if (isMobile) return setShow(1);
  };
  const goBackState = () => {
    setPaymentType("");
    if (isMobile) return setShow(null);
  };
  useEffect(() => {
    return () => {
      dispatch(resetBankAccountData());
    };
  }, []);

  const query = UseQuery();

  useEffect(() => {
    const type = query.get("type");

    if (type) {
      if (type == "send") {
        setTab(0);
        setPaymentType("send_bank");
      } else {
        setTab(1);
        setPaymentType(generalWallets[0]?.name);
      }
    }
  }, [query.get("type")]);

  const goToActivities = () => navigate("/activity");

  return (
    <div className={styles._mainContainer}>
      {currentStep < 4 && (
        <>
          <div className={styles._topContainer}>
            <div className={styles._titleContainer}>
              {isMobile && show !== null && (
                <BackArrow onClick={() => goBackState()} />
              )}
              <p className={styles._title}> {t("transfers")}</p>
            </div>
            {isMobile && (
              <img
                src={`${baseUrl}/resources/go-activity-icon.svg`}
                alt="go to activy icon"
                onClick={goToActivities}
              />
            )}
          </div>
          <div className={styles._paymentContainer}>
            {show == null && (
              <div className={styles._leftContent}>
                <div className={styles._tabsContainer}>
                  <GeneralTabs
                    data={[{ name: t("send") }, { name: t("receive") }]}
                    onChange={() => {
                      setTab((prevValue) => (prevValue == 0 ? 1 : 0));
                      {
                        tab == 0
                          ? setPaymentType(generalWallets[0]?.name)
                          : setPaymentType("send_bank");
                      }
                    }}
                    value={tab}
                    labelKey="name"
                  />
                </div>
                <div className={styles._balanceContainer}>
                  <div className={styles._balanceParent}>
                    <div className={styles._iconContainer}>
                      <img
                        src={`${baseUrl}/resources/puntu-card-icon2.svg`}
                        alt="puntu-isotipo"
                        width={42}
                      ></img>
                    </div>
                    <div className={styles._textContent}>
                      <p className={styles._title}>Puntu</p>
                      <p className={styles._balanceTitle}>
                        {t("balance")}: ${parseAmount(balance)}
                      </p>
                    </div>
                  </div>

                  {!isMobile && (
                    <div className={styles._goToActivitiesParent}>
                      <p
                        className={styles._goToActivitiesText}
                        onClick={goToActivities}
                      >
                        {t("go_to_activity")}
                      </p>
                    </div>
                  )}
                </div>
                <div className={styles._bottomLeftContainer}>
                  {tab == 0 ? (
                    <>
                      {paymentOptions?.send?.map((item: any, index: number) => (
                        <div
                          className={
                            paymentType == item?.flag
                              ? styles._optionContainerSelected
                              : styles._optionContainer
                          }
                          key={index}
                          onClick={() => setShowedComponents(item?.flag)}
                        >
                          <div className={styles._optionsContent}>
                            <div className={styles._iconContainer}>
                              <Icon icon={item?.icon} width={25} />
                            </div>
                            <div className={styles._textContent}>
                              <p className={styles._title}>{t(item?.title)}</p>
                              <p className={styles._subtitle}>
                                {t(item?.description)}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      {actualWallets?.map((item: any, index: number) => (
                        <div
                          className={
                            paymentType == item?.name
                              ? styles._optionContainerSelected
                              : styles._optionContainer
                          }
                          key={index}
                          onClick={() => {
                            setShowedComponents(item?.name);
                            setwalletId(item?.id);
                          }}
                        >
                          <div className={styles._optionsContent}>
                            <div className={styles._iconContainer}>
                              <img
                                src={`${baseUrl}/resources/${item?.name}-card-icon.svg`}
                                width={35}
                                alt="card-icon"
                              />
                            </div>
                            <div className={styles._textContent}>
                              <p className={styles._title}>
                                {t("receive_by")} {item?.title}
                              </p>
                              <p className={styles._subtitle}>
                                {t("receive_funds")}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
                <div>
                  {tab !== 0 && userAuthData?.customer?.type == "juridic" && (
                    <div className={styles._groupContainer}>
                      <SingleGeneralRadioGroup
                        checkValue={
                          actualBranchOffice ??
                          data?.branchOffices?.items[0]?.code
                        }
                        handleCheckValue={(item: any) => {
                          handleCheckUsers(item?.code);
                          handleBranchOffice(item);
                        }}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        mapData={data?.branchOffices?.items}
                        leftMethod={previousPage}
                        rightMethod={nextPage}
                        loading={!loading}
                        title={"select_a_branch"}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            {((isMobile && show == 1) || (!isMobile && show == null)) && (
              <div className={styles._rightContent}>
                {((isMobile && show == 1) || (!isMobile && show == null)) && (
                  <div
                    className={
                      currentStep == 1
                        ? styles._bottomRightAccounts
                        : styles._bottomRightContainer
                    }
                  >
                    {tab == 0 ? (
                      <>
                        {paymentType == "send_bank" &&
                          switchSteps(currentStep, handleStep)}
                      </>
                    ) : (
                      <>
                        <ReceiveQR
                          branchCode={
                            actualBranchOffice ??
                            data?.branchOffices?.items[0]?.code
                          }
                          walletId={walletId}
                          type={paymentType}
                          userType={userAuthData.customer?.type}
                          walletsData={walletsData?.systemAccounts}
                          specialCustomer={specialCustomer}
                        />
                      </>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </>
      )}
      {currentStep == 4 && <ConfirmationModal handleStep={handleStep} />}
    </div>
  );
};
export default Payments;
