import { Theme } from '@mui/material';

const modalBox = (theme: Theme, withDrawer?: boolean, padding?: number) => ({
  marginLeft: withDrawer ? '14.5625rem' : '0',
  bgcolor: '#FFFFFF',
  p: padding ? padding : 5,
  borderRadius: '0.5625rem',
  width: '36.9375rem',

  [theme.breakpoints.down('lg')]: {
    width: '90%',
    marginLeft: 0,
    p: 3,
  },
});

const modal = (theme: Theme, withDrawer?: boolean) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 9999,
  '& .MuiBackdrop-root': {
    left: withDrawer ? '14.5625rem' : 0,

    [theme.breakpoints.down('lg')]: {
      left: 0,
      marginTop: '3rem',
    },
  },
});

export { modal, modalBox };
