import React, { useRef, useState } from "react";
import styles from "./styles.module.scss";
import { baseUrl } from "paths";
import { GeneralInput, GeneralSelect } from "../../components";
import { useDispatch } from "react-redux";
import { deleteFile, loadFile } from "store/file/action";
import { supportedDniFiletypes } from "utils";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";

const GeneralUploadFile = ({
  selectData,
  methodSelect,
  selectValue,
  inputValue,
  nameValue,
  methodInput,
  methodFile,
  methodIcon,
  linkValue,
  placeholder,
  hasError,
  onFocus,
  index,
  reference,
  validateMessage,
  inputStatus,
  ...inputProps
}: any) => {
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [error, setError] = useState("");

  const validateFile = (documentData: any) => {

    if (documentData?.size > 3000000) {
      setError(t("invalid_file_size"));
      return;
    }
    if (!supportedDniFiletypes.includes(documentData.type)) {
      setError(t("invalid_type"));
      return;
    }
    setError("");
    return true;
  };
  const handleUpload = (file: File) => {

    if (validateFile(file)) {
      setLoading(true);
      dispatch(
        loadFile(file, "Identifications", ({ ok, values }) => {
          if (ok) {
            methodFile(values);
            setLoading(false);
          }
        })
      );
    }
  };

  const handleDelete = () => {
    dispatch(deleteFile(linkValue));
    methodIcon();
  };

  return (
    <>
      <div className={styles._selectContainer}>
        <div className={styles._select}>
          <GeneralSelect
            value={selectValue}
            onChange={methodSelect}
            options={selectData}
            reference={reference}
          />
        </div>

        <div className={styles._selectValue}>
          <GeneralInput
            withIcon={false}
            value={inputValue}
            onChange={methodInput}
            placeholder={placeholder}
            hasError={hasError}
            onFocus={onFocus}
            errorMessage={validateMessage}
            {...inputProps}
            status={inputStatus}

          />
        </div>
        <div className={styles._fileContainer}>
          <input
            onChange={(event) => handleUpload(event.target.files[0])}
            type="file"
            accept=".jpg, .jpeg, .png"
            id={`actual-btn-${index}`}
            ref={inputRef}
            hidden
          />
          <label className={styles._btn} htmlFor={`actual-btn-${index}`}>
            {loading ? (
              <CircularProgress color="inherit" size={22} />
            ) : (
              <img
                src={`${baseUrl}../resources/clip.svg`}
                alt="clip"
                width={24}
                height={24}
              />
            )}
          </label>
        </div>
      </div>
      {nameValue && linkValue && (
        <div className={styles._fileSelected}>
          <p id="file-chosen" className={styles._fileName}>
            {nameValue}
          </p>
          <div
            className={styles._iconContainer}
            onClick={(e) => {
              inputRef.current.value = "";
              handleDelete();
            }}
          >
            <img
              src={`${baseUrl}../resources/close.svg`}
              alt="clip"
              width={12}
              height={12}
            />
          </div>
        </div>
      )}
      {error && <p className={styles._error}>{error}</p>}
    </>
  );
};

export default GeneralUploadFile;
