import { gql } from '@apollo/client'

const UPDATE_WHITELIST = gql`
  mutation UpdateWhitelist($data: UpdateWhitelistInput!) {
    updateWhitelist(data: $data) {
      id
      firstName
      lastName
      type
      identification
      email
      address
      cellPhoneNumber
    }
  }
`;
export default UPDATE_WHITELIST;
