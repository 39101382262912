import { gql } from '@apollo/client';

const VERYFY_CUSTOMER_EMAIL = gql`
  mutation VerifyCustomerEmail($email: String!) {
    verifyCustomerEmail(data: {email: $email}) {
        result
    }
}
`;
export default VERYFY_CUSTOMER_EMAIL;
