import React from 'react'
import styles from './styles.module.scss'
import { useSelector } from 'react-redux'
import { intermitenceSelector } from 'store/selectors'
import { Icon } from '@iconify/react'
import { useDispatch } from 'react-redux'
import { setAlert } from 'store/actions'
import clsx from 'clsx'
import { translate } from 'utils'

const Alert = () => {
  const dispatch = useDispatch()
  const { status, text, type } = useSelector(intermitenceSelector)

  const statusManagment = (): any => {
    if (status == 1) return styles._static
    if (status == 2) return styles._card
    if (status == 3) return styles._hiddenCard
  }

  const typeManagment = (icon: boolean = false): string => {
    if (type == 'success') return !icon ? '#65C65D' : 'bx:check-circle'
    if (type == 'error') return !icon ? '#D94848' : 'bx:x-circle'
    if (type == 'warning') return !icon ? '#EBC749' : 'bx:error-circle'
  }

  const closeAlert = () => dispatch(setAlert(3, type, text))

  return (
    <div className={statusManagment()} style={{ backgroundColor: typeManagment() }}>
      <div className={styles._leftSide}>
        <div className={styles._iconParent}>
          <Icon icon={typeManagment(true)} width={22} color='#FFF'></Icon>
        </div>
        <p>{translate(text, '')}</p>
      </div>

      <div className={clsx(styles._closeIconParent, styles._pointer)} onClick={closeAlert}>
        <Icon icon='zondicons:close' width={15} color='#FFF'></Icon>
      </div>
    </div>
  )
}

export default Alert
