import { Icon } from "@iconify/react";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import clsx from "clsx";
import EmptyState from "./EmptyState";
import { cellStyles, headerCellStyles } from "./materialStyles";
import styles from "./styles.module.scss";
import {
  ActionType,
  HeaderType,
  PaginationType,
  RowType,
  TableType,
} from "./types";

const Header = ({
  header,
  hasActions = false,
  orderDirection,
  orderBy,
}: {
  header: HeaderType;
  hasActions?: boolean;
  orderDirection: string;
  orderBy?: string;
}) => {
  return (
    <TableHead className={clsx(styles._header, header.headerClass)}>
      <TableRow sx={{ paddingBottom: "20px" }}>
        {header.values.map((elem, index) => (
          <TableCell padding="none" sx={headerCellStyles} key={index}>
            <div className={clsx(styles._headerCellContainer, elem.cellClass)}>
              <span
                className={orderBy == elem.order ? styles._selected : undefined}
              >
                {elem.value}
              </span>
              {!elem.disableSorting && (
                <Icon
                  icon="bx:sort-alt-2"
                  color={orderBy == elem.order && "#E6427A"}
                  className={styles._sortIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    header.sortClick({
                      orderBy: elem.order,
                      orderDirection:
                        elem.order == orderBy
                          ? orderDirection == "asc"
                            ? "desc"
                            : "asc"
                          : "desc",
                    });
                  }}
                />
              )}
            </div>
          </TableCell>
        ))}
        {hasActions && (
          <TableCell padding="none" sx={headerCellStyles}></TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

const ActionCell = ({
  actions,
  row,
}: {
  actions: Array<ActionType>;
  row: RowType;
}) => {
  return (
    <TableCell
      sx={cellStyles}
      align="right"
      className={styles._childrenMargin}
      width={row.actionCellWidth}
    >
      {actions.map((action, index) => {
        return (
          !action.hide && (
            <Icon
              icon={action.icon}
              key={index}
              className={styles._icon}
              onClick={(e) => {
                e.stopPropagation();
                action.actionClick && action.actionClick(row);
              }}
            />
          )
        );
      })}
    </TableCell>
  );
};

const Row = ({isAllowed = true, ...row}: RowType) => {
  return (
    <TableRow
      onClick={() => {
        row.rowClick && row.rowClick(row);
      }}
      className={styles._row}
    >
      {row.values.map((cell, index) => (
        <TableCell
          sx={cellStyles}
          key={index}
          width={cell.cellWidth}
          onClick={(e) => {
            cell.stopPropagation && e.stopPropagation();
            cell.cellClick && cell.cellClick({ id: row.id, ...cell });
          }}
          className={cell.cellClass}
        >
          {cell.value}
        </TableCell>
      ))}
      {isAllowed && (
        <ActionCell actions={row.actions} row={row} />
      )}
    </TableRow>
  );
};

const Pagination = ({ totalPages, page, moveClick }: PaginationType) => {
  return (
    <div className={styles._paginationContainer}>
      <span className={styles._pageText}>
        {page}/{totalPages}
      </span>
      <div style={{ flexGrow: 1 }}></div>
      <Icon
        icon="bx:left-arrow-alt"
        className={styles._arrowIcon}
        color="#909090"
        onClick={() => moveClick && page != 1 && moveClick(page - 1)}
      />
      <Icon
        icon="bx:left-arrow-alt"
        className={styles._rightArrow}
        color="#909090"
        onClick={() => moveClick && page != totalPages && moveClick(page + 1)}
      />
    </div>
  );
};

const GeneralTable = ({
  rows,
  header,
  actions,
  pagination,
  loading = false,
  title,
  orderBy,
  orderDirection,
  moveClick,
  searching,
  emptySearchRender,
  emptyRender,
  customTableStyle,
  sortIconClass,
  isAllowed
}: TableType) => {
  return (
    <>
      <div className={clsx(styles._tableMainContainer, customTableStyle)}>
        <div className={styles._titleContainer}>
          {title && <p className={styles._title}>{title}</p>}
          {!loading && rows.length !== 0 && (<Icon
            icon="bx:sort-alt-2"
            color={orderBy == header.values[0]?.order ? "#E6427A" : "#909090"}
            className={clsx(styles._sortIcon, sortIconClass)}
            onClick={(e) => {
              e.stopPropagation();
              header.sortClick({
                orderBy: header.values[0]?.order,
                orderDirection:
                  header.values[0]?.order == orderBy
                    ? orderDirection == "asc"
                      ? "desc"
                      : "asc"
                    : "desc",
              });
            }}
          />)}
        </div>
        <TableContainer>
          <Table>
            <Header
              header={header}
              hasActions
              orderDirection={orderDirection}
              orderBy={orderBy}
            />
            {!loading && rows && rows.length > 0 && (
              <TableBody>
                {rows?.map((row) => {
                  const newActions = actions(row);
                  return (
                    <Row
                      rowClick={row.rowClick}
                      extraProps={row.extraProps}
                      values={row.values}
                      id={row.id}
                      key={row.id}
                      actions={newActions}
                      actionCellWidth={row.actionCellWidth}
                      isAllowed={isAllowed}
                    />
                  );
                })}
              </TableBody>
            )}
          </Table>
          {loading && (
            <div className={styles._circularProgressContainer}>
              <CircularProgress size={30} />
            </div>
          )}
        </TableContainer>
        {!loading && rows && rows.length > 0 && (
          <Pagination
            totalPages={pagination.totalPages}
            moveClick={moveClick}
            page={pagination.page}
          />
        )}
      </div>
      {!loading && rows.length == 0 && (
        <EmptyState
          searching={searching}
          emptySearchRender={emptySearchRender}
          emptyRender={emptyRender}
        />
      )}
    </>
  );
};

export default GeneralTable;
