import React, { memo } from "react";
import styles from "./styles.module.scss";
import { GeneralTable } from "components";
import { useTranslation } from "react-i18next";

const DirectoryTable = ({
  orderBy,
  items,
  pagination,
  loading,
  handlePaginate,
  handleEdit,
  handleDelete,
  handleSort,
  orderDirection,
  handleSelected,
  isAllowed,
}: any) => {
  const { t } = useTranslation();

  return (
    <GeneralTable
      pagination={pagination}
      moveClick={(page: number) => handlePaginate(null, page)}
      orderBy={orderBy}
      orderDirection={orderDirection}
      searching={loading}
      customTableStyle={styles._table}
      sortIconClass={styles._sortIconClass}
      actions={(row) => {
        return isAllowed
          ? [
              {
                actionClick: (event) => handleSelected(event?.id),
                icon: "bx:show",
              },
              {
                actionClick: (event) => handleEdit(event?.id),
                icon: "bx:edit-alt",
              },
              {
                actionClick: (event) => handleDelete(event?.id),
                icon: "bx:trash",
              },
            ]
          : [];
      }}
      header={{
        values: [
          { value: t("name"), order: "NAME" },
          { value: t("type"), order: "TYPE" },
          { value: t("id_rif"), order: "IDENTIFICATION" },
          { value: t("email"), order: "EMAIL" },
          { value: t("phone"), order: "CELLPHONENUMBER" },
          { value: t("b_address"), order: "ADDRESS" },
        ],
        sortClick: (event: any) => handleSort(event),
      }}
      rows={items}
      loading={loading}
      emptyRender={
        <p>
          {t("no_contacts")}
          <br /> {t("add_new_members")}
        </p>
      }
      emptySearchRender={<p>{t("no_coincidence")}</p>}
    />
  );
};

export default memo(DirectoryTable);
