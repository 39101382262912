import { gql } from '@apollo/client';

const ALL_PERMISSIONS = gql`
  query Permissions(
    $page: Int
    $perPage: Int
    $search: String
    $orderDirection: OrderDirectionEnum
    $orderBy: PermissionsOrderByEnum
  ) {
    permissions(
      page: $page
      perPage: $perPage
      search: $search
      orderDirection: $orderDirection
      orderBy: $orderBy
    ) {
      items {
        id
        name
      }
      pagination {
        page
        perPage
        totalItems
        totalPages
      }
    }
  }
`;

export default ALL_PERMISSIONS;
