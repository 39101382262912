import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import {
  GeneralButton,
  GeneralInput,
  GeneralTable,
  GeneralConfirmModal,
  BackArrow,
} from "../../../../../../components";
import { useQuery } from "@apollo/client";
import BRANCH_OFFICES_QUERY from "../../../../../../graphql/queries/branchOffices";
import { transformToRows } from "./functions";
import { EMPLOYEE, shallowCompare } from "../../../../../../utils";
import { Theme, useMediaQuery } from "@mui/material";
import { CreateBrancheOffice } from "web/components";
import { DELETE_BRANCH_OFFICE } from "graphql/mutations";
import { useMutation } from "@apollo/client";
import { UseAlert, UseIntermitence } from "hooks";
import { useSelector } from "react-redux";
import { userAuthSelector, userSpaceSelector } from "store/selectors";
import { Icon } from "@iconify/react";

const BranchOfficeList = ({ t, getLazyBranchOffice }: any) => {
  const { loading, data, refetch } = useQuery(BRANCH_OFFICES_QUERY);
  const [deleteBranch] = useMutation(DELETE_BRANCH_OFFICE);
  const [branchId, setBranchId] = useState("");
  const { showAlert } = UseAlert();
  const { switchStatus, status } = UseIntermitence();
  const [typeOfModal, setTypeOfModal] = useState("");
  const { switchStatus: deleteModalSwitch, status: deleteStatus } =
    UseIntermitence();
  const { currentSpace } = useSelector(userSpaceSelector);
  const [ordering, setOrdering] = useState({
    orderBy: "",
    orderDirection: "desc",
    search: "",
  });
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("lg")
  );
  const items: any = !loading
    ? transformToRows(data?.branchOffices?.items, isMobile)
    : [];

  const [currentBranchOffice, setCurrentBranchOffice] = useState(null);

  const {
    userAuthData: { role: { name } },
  } = useSelector(userAuthSelector);

  const handleRefetchQuery = (newOrdering?: any, newPage?: number) => {
    const toUpdate = { ...ordering, ...newOrdering };
    if (!shallowCompare(ordering, toUpdate)) {
      setOrdering(toUpdate);
      return refetch({
        ...toUpdate,
      });
    }
    refetch({ page: newPage });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      refetch({ search: ordering.search });
    }, 300);

    return () => clearTimeout(timer);
  }, [ordering.search]);

  const showDeleteModal = (id: string) => {
    setBranchId(id);
    deleteModalSwitch();
  };

  const openCreate = () => {
    setCurrentBranchOffice(null);
    setTypeOfModal("create");
    switchStatus();
  };

  const editModal = (type: string, id: string) => {
    const branchOffices = data?.branchOffices?.items || [];
    const branchOfficeData = (branchOffices || []).find((item: any) => item?.id == id);
    setCurrentBranchOffice(branchOfficeData);
    setTypeOfModal(type);
    switchStatus();
  };

  const delBranch = () => {
    deleteBranch({
      variables: { id: branchId },
      onError: (error: any) => showAlert(error?.message, "error"),
      onCompleted: (response: any) => {
        const {
          deleteBranchOffice: { result },
        } = response;

        if (result) {
          showAlert("sucess_delete_branch", "success");
          deleteModalSwitch();
          setBranchId("");
          return refetch();
        }
        setBranchId("");
        deleteModalSwitch();
        showAlert("something_went_wrong", "error");
      },
    });
  };

  return (
    <>
      <div className={styles._arrowBackContainer}>
        <BackArrow icon="bx:left-arrow-alt" route="/dashboard" />
        <p>
          {t("back_to")} {t("dashboard_literal")}
        </p>
      </div>
      <div className={styles._topContainer}>
        <div className={styles._titleContainer}>
          <p>{t("branch_offices")}</p>
          {name != EMPLOYEE && (
            <GeneralButton
              icon={
                <div>
                  <Icon icon="bxs:buildings" />
                  <Icon icon="bx:x" />
                </div>
              }
              text={!isMobile ? t("add_new_branch_office") : ""}
              action={openCreate}
            />
          )}
        </div>
        <div className={styles._headerParent}>
          <div className={styles._inputParent}>
            <GeneralInput
              onChange={(event: any) =>
                setOrdering((prevValue) => ({
                  ...prevValue,
                  search: event.target.value,
                }))
              }
              value={ordering.search}
              className={styles._noMargin}
              placeholder={t("search")}
              withIcon
              icon="bx:search"
              iconColor="#909090"
              iconClass={styles._inputIcon}
            />
          </div>

          {name != EMPLOYEE && (
            <div className={styles._btnParent}>
              <GeneralButton text="add_new_branch_office" action={openCreate} />
            </div>
          )}
        </div>
      </div>
      <div className={styles._bottomContainer}>
        <GeneralTable
          isAllowed={name != EMPLOYEE}
          title={`${t("branch_of")} ${currentSpace.EIN ?? ""}`}
          pagination={data?.branchOffices.pagination}
          moveClick={(page) => handleRefetchQuery(undefined, page)}
          orderBy={ordering.orderBy}
          orderDirection={ordering.orderDirection}
          searching={!!ordering.search}
          actions={(row) => {
            return [
              {
                actionClick: (data) => {
                  getLazyBranchOffice({ variables: { id: data.id } });
                },
                icon: "bx:show",
              },
              {
                actionClick: (event) => {
                  editModal("edit", event?.id);
                },
                icon: "bx:edit-alt",
              },
              {
                actionClick: (e) => {
                  showDeleteModal(e?.id);
                },
                icon: "bx:trash",
                hide: row.extraProps.isPrincipal,
              },
            ];
          }}
          header={{
            values: [
              { value: t("name"), order: "NAME" },
              { value: t("code"), order: "CODE" },
              { value: t("b_address"), order: "ADDRESS" },
              { value: t("creation"), order: "CREATEDAT" },
            ],
            sortClick: (e) => handleRefetchQuery(e),
          }}
          rows={items}
          loading={loading}
          emptyRender={
            <p>
              {t("no_branch_office")}
              <br /> {t("operation_centralize")}
            </p>
          }
          emptySearchRender={<p>{t("no_coincidence")}</p>}
        />
      </div>

      <CreateBrancheOffice
        open={status}
        onClose={switchStatus}
        type={typeOfModal}
        refetch={refetch}
        branchOffice={currentBranchOffice}
      />

      <GeneralConfirmModal
        title="are_you_sure_delete_branch"
        open={deleteStatus}
        onConfirm={() => delBranch()}
        onCancel={deleteModalSwitch}
      />
    </>
  );
};

export default BranchOfficeList;
