import React, { useState, useEffect, forwardRef, useImperativeHandle, memo } from 'react'
import { DirectoryTable } from '../../components'
import styles from './styles.module.scss'
import { GeneralButton, GeneralInput, GeneralIconButton, GeneralConfirmModal } from 'components'
import { baseUrl } from 'paths'
import { CreateWhiteList } from 'web/components'
import { UseIntermitence } from 'hooks'
import { useLazyQuery, useMutation } from '@apollo/client'
import { WHITELISTS_QUERY } from 'graphql/queries'
import { useSelector } from 'react-redux'
import { userAuthSelector, userSpaceSelector } from 'store/selectors'
import { transformToRows } from '../../components/DirectoryTable/utils'
import { useMediaQuery, Theme } from '@mui/material'
import { DELETE_WHITELIST, EXPORT_WHITELIST } from 'graphql/mutations'
import { UseAlert } from 'hooks'
import { EMPLOYEE, shallowCompare } from 'utils'
import { useTranslation } from 'react-i18next'
import { whitelistType } from 'utils'
import { useLocation } from "react-router-dom";

const Directory = forwardRef(({ comeBack, handleSelected, updateDetail, }: any, ref: any) => {
  const [input, setInputValue] = useState('')
  const [timer, setTimer] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [currentWhiteList, setCurrentWhitelist] = useState(null)
  const [currentId, setCurrentId] = useState(null)
  const [type, setType] = useState(null)
  const { state }: any = useLocation();
  const [selectedCustomer, setSelectedCustome] = useState(state?.selectedCustomer?.id)

  const {
    userAuthData: { role: { name } },
  } = useSelector(userAuthSelector);

  const { t } = useTranslation()

  useImperativeHandle(ref, () => ({
    handleDelete(id: string) {
      setCurrentId(id)
      deleteSwitchStatus()
    },

    handleEdit(id: string) {
      const whitelist = data?.whitelists?.items || []
      const match = (whitelist || []).find((item: any) => item?.id == id);
      if (match) setCurrentWhitelist(match)
      switchStatus()
    }
  }));

  const { currentSpace } = useSelector(userSpaceSelector)
  const { status, switchStatus } = UseIntermitence()
  const { status: deleteStatus, switchStatus: deleteSwitchStatus } = UseIntermitence()

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const [ordering, setOrdering] = useState({
    orderBy: null,
    orderDirection: "asc",
  });

  const [getWhiteList, { data, loading, refetch }] = useLazyQuery(WHITELISTS_QUERY, {
    fetchPolicy: "no-cache",
    variables: {
      spaceId: currentSpace?.id,
      search: input,
      orderDirection: ordering?.orderDirection,
      page: currentPage,
      perPage: 10,
      type,
      orderBy: ordering?.orderBy
    },
  })

  const { showAlert } = UseAlert();
  const [exportWhitelist, { loading: exportLoading }] = useMutation(EXPORT_WHITELIST)
  const [deleteWhitelist] = useMutation(DELETE_WHITELIST)


  const handleSort = (newOrdering?: any, newPage?: number) => {
    const toUpdate = { ...ordering, ...newOrdering };

    if (!shallowCompare(ordering, toUpdate)) {
      setOrdering(toUpdate);
    }
    return { page: newPage };
  };

  useEffect(() => {
    getWhiteList()
  }, [])

  const details = data?.whitelists?.items

  useEffect(() => {
    if (selectedCustomer && details) findDetail(selectedCustomer)
  }, [details])

  const handleExport = () => {
    exportWhitelist({
      variables: {
        data: {
          spaceId: currentSpace?.id,
          folder: 'whitelist'
        }
      },
      onCompleted: (response) => {
        const { exportWhitelist: { result } } = response
        if (result) {
          showAlert('export_succesfull', 'success')
          window.location.href = result
        }
      },
      onError: () => {
        showAlert('error', 'error')
      }
    })
  }

  const pagination = data?.whitelists?.pagination
  const items: Array<any> = !loading

    ? transformToRows(data?.whitelists?.items, isMobile)
    : [];

  const handleSearch = (event: any) => {
    const { target: { value } } = event
    setInputValue(value)

    if (timer) clearTimeout(timer);

    setTimer(
      setTimeout(() => {
        setInputValue(value)
      }, 500)
    );
  }

  const handleEdit = (id: string) => {
    const whitelist = data?.whitelists?.items || []
    const match = (whitelist || []).find((item: any) => item?.id == id);
    if (match) setCurrentWhitelist(match)
    switchStatus()
  }

  const findDetail = (id: string) => {
    const whitelist = data?.whitelists?.items || []
    const match = (whitelist || []).find((item: any) => item?.id == id);
    if (match) {
      setSelectedCustome(null)
      handleSelected(match)
    }
  }

  const handleDelete = (id: string) => {
    setCurrentId(id)
    deleteSwitchStatus()
  }

  const deleteConfirm = () => {
    deleteWhitelist({
      variables: {
        id: currentId
      },
      onCompleted: (response) => {
        const { deleteWhitelist: { result } } = response
        if (result) {
          showAlert('delete_succesfull', 'success')
          setCurrentId(null)
          deleteSwitchStatus()
          comeBack && comeBack()
          refetch()
        }
      },
      onError: () => {
        showAlert('error', 'error')
      }
    })
  }

  const onCancel = () => {
    setCurrentId(null);
    deleteSwitchStatus()
  }

  const handleCreate = () => {
    setCurrentWhitelist(null);
    switchStatus()
  }

  const handleType = (item: string) => setType(item);

  const handlePaginate = (event: any, page: number) => setCurrentPage(page)

  return (
    <div className={styles._mainContainer}>
      <div className={styles._rowNoMargin}>
        <div className={styles._titleParent}>
          <p className={styles._title}>{t('Directory')}</p>
        </div>

        <div className={styles._responsiveRow}>
          {name != EMPLOYEE && (
            <>
              <div className={styles._addResponsiveBtn}>
                <GeneralButton action={handleCreate}>
                  <img src={`${baseUrl}/resources/add-whitelist.svg`} alt='add to directory' />
                </GeneralButton>
              </div>

              <div className={styles._exportResponsiveBtn}>
                <GeneralIconButton className={styles._responsiveBtn} text='export' method={handleExport} icon='bx:down-arrow-alt' loader={exportLoading} leftIcon iconColor='#E6427A' />
              </div>
            </>

          )}
        </div>
      </div>
      <div className={styles._row}>
        <div className={styles._inputParent}>
          <GeneralInput withIcon icon='bx:search' placeholder={t('search')} iconClass={styles._icon} onChange={handleSearch} value={input} />
        </div>

       {name != EMPLOYEE && (
          <div className={styles._exportBtnParent}>
            <GeneralIconButton className={styles._desktopBtn} secondaryColor='#E6427A' text='export' method={handleExport} loader={exportLoading} icon='bx:down-arrow-alt' rightIcon />
          </div>
       )}
      </div>

      <div className={styles._tableHeader}>
        <div className={styles._walletsParent}>
          {
            whitelistType.map((item: any, index: number) => (
              <div className={type == item?.value ? styles._walletButtonSelected : styles._walletButton} key={index} onClick={() => handleType(item.value)}>
                <p>{t(item?.name)}</p>
              </div>
            ))
          }
        </div>

        {name != EMPLOYEE && (
          <div className={styles._btnParent}>
            <GeneralButton text='add_new_contact' action={handleCreate} />
          </div>
        )}

      </div>
      <DirectoryTable
        search={input}
        items={items}
        pagination={pagination}
        loading={loading}
        handlePaginate={handlePaginate}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        handleSort={handleSort}
        orderDirection={ordering.orderDirection}
        orderBy={ordering.orderBy}
        handleSelected={findDetail}
        isAllowed={name != EMPLOYEE}
      />

      <CreateWhiteList
        open={status}
        onClose={switchStatus}
        refresh={refetch}
        currentWhiteList={currentWhiteList}
        updateDetail={updateDetail}
      />

      <GeneralConfirmModal
        open={deleteStatus}
        title='are_you_sure_delete_user'
        onConfirm={deleteConfirm}
        onCancel={onCancel}
      />

    </div>
  )
})

export default memo(Directory)
