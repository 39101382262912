import { gql } from '@apollo/client';
import { FRAGMENT_USER_RESPONSE } from '../fragments';

const LOGIN_MUTATION = gql`
  ${FRAGMENT_USER_RESPONSE}
  mutation Login($email: String!, $password: String!, $otp: String) {
    signIn(data: { email: $email, password: $password, otp: $otp }) {
      user {
        ...UserResponse
        twoFactors {
          isActive
        }
      }

      accessToken
    }
  }
`;
export default LOGIN_MUTATION;
