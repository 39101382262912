import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  divider,
  drawerStyles,
  listItemIcon,
  textItemButton,
  textStyle,
} from "./materialStyles";
import styles from "./styles.module.scss";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLayoutEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import { userAuthSelector } from "store/selectors";
import clsx from "clsx";
import { UseIntermitence } from "hooks";
import {
  ChangePasswordModal,
  ClearCacheModal,
  Disable2FAModal,
  SpacesCircles,
} from "web/components";
import { set2FAEmail } from "store/twoFA/action";
import { useDispatch } from "react-redux";
import { logout } from "store/actions";
import { GeneralSwitch } from "components";

const ProfileDrawer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [ready, setDomReady] = useState(null);
  const dispatch = useDispatch();
  const [notificationStatus, setNotificationStatus] = useState(false);

  const setNotifications = () =>
    setNotificationStatus((notificationStatus) => !notificationStatus);

  const { switchStatus: switchPasswordStatus, status: passwordStatus } =
    UseIntermitence();
  const { switchStatus: switchTwoFactStatus, status: twoFactStatus } =
    UseIntermitence();
  const { switchStatus: switchCacheStatus, status: cacheStatus } =
    UseIntermitence();
  useLayoutEffect(() => {
    setDomReady(document.getElementById("component-main"));
  }, []);

  const {
    userAuthData: {
      customer,
      email,
      twoFactors,
      profile,
      role: { name },
    },
  } = useSelector(userAuthSelector);

  const validateTwoFA = () => {
    if (twoFactors[0]?.isActive) return switchTwoFactStatus();
    dispatch(set2FAEmail(email));

    navigate("/set-2fa");
  };

  const profileOptions = [
    {
      label: "authenticator",
      icon: "bx-shield-quarter",
      method: () => validateTwoFA(),
      switch: false,
    },

    {
      label: "change_password",
      icon: "bxs:lock-alt",
      method: () => switchPasswordStatus(),
      switch: false,
    },
    {
      label: "deal_flow",
      icon: "bxs-offer",
      method: () => navigate("/deal-flow"),
      switch: false,
    },
    // {
    //   label: "notification",
    //   icon: "bxs-bell-ring",
    //   switch: true,
    // },
    {
      label: "faq",
      icon: "bxs-help-circle",
      method: () => navigate("/faq"),
      switch: false,
    },
    {
      label: "clear_cache",
      icon: "bxs-brush",
      method: () => switchCacheStatus(),
      switch: false,
    },
  ];

  return (
    ready &&
    ReactDOM.createPortal(
      <>
        <Drawer
          sx={drawerStyles}
          variant="permanent"
          anchor="right"
          className={styles._scrollBar}
        >
          <div className={styles._toolbarContainer}>
            {/* <Icon
              icon="bxs:bell"
              className={clsx(styles._icon, styles._iconMargin)}
              color="#000000"
            /> */}

            <div className={styles._iconPosition}>
              {/* <SpacesCircles onlyOne={true} /> */}
              <div className={styles._profileImage}>
                <Icon icon="bxs:user" width={28} color="#000000"></Icon>
              </div>
              <p className={styles._profileTitle}>Perfil</p>
            </div>
            {/* <div className={styles._profileContainer}>
              <div className={styles._profileImage}>
                <Icon icon="bxs:user" width={28} color="#000000"></Icon>
              </div>
              <p className={styles._profileText}>{t("profile")}</p>
            </div> */}
          </div>
          <div className={styles._imageContainer}>
            <div className={styles._imageCard}>
              <Icon icon="bxs:user" width={28} color="#000000"></Icon>
            </div>
            <div className={styles._cardTextContainer}>
              <p className={styles._cardTitle}>
                {customer?.type == "juridic"
                  ? `${customer?.legalRepresentative?.profile?.firstName} ${customer?.legalRepresentative?.profile?.lastName} `
                  : `${profile.firstName} ${profile?.lastName}`}
              </p>
              <p className={styles._cardSubtitle}>{email}</p>
            </div>
          </div>

          <p className={styles._titleUp}>{t("settings")}</p>
          <Divider sx={divider} />
          <List disablePadding className={styles._scrollBar}>
            {profileOptions.map((option, index) => {
              return (
                <ListItem key={index} disablePadding>
                  <ListItemButton
                    disableGutters
                    sx={textItemButton}
                    onClick={option?.method}
                  >
                    <ListItemIcon sx={listItemIcon}>
                      <Icon
                        icon={option.icon}
                        className={styles._icon}
                        color={
                          /*  location.pathname.startsWith(option.route)
              ? "#E6427A"
              : */ "000000"
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={t(option?.label)}
                      sx={(theme) => textStyle(theme, false)}
                    />
                    {option?.switch && (
                      <GeneralSwitch
                        checked={notificationStatus}
                        onChange={setNotifications}
                      />
                    )}
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Drawer>
        {passwordStatus && (
          <ChangePasswordModal
            open={passwordStatus}
            have2fa={twoFactors[0]?.isActive}
            onCancel={() => switchPasswordStatus()}
          ></ChangePasswordModal>
        )}
        {twoFactStatus && (
          <Disable2FAModal
            open={twoFactStatus}
            onCancel={() => switchTwoFactStatus()}
          ></Disable2FAModal>
        )}
        {cacheStatus && (
          <ClearCacheModal
            open={cacheStatus}
            onCancel={() => switchCacheStatus()}
          />
        )}
      </>,
      ready
    )
  );
};

export default ProfileDrawer;
