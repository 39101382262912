const Contact = ({ color = "#000000" }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.71509 12C10.8661 12 11.7151 11.151 11.7151 10C11.7151 8.849 10.8661 8 9.71509 8C8.56409 8 7.71509 8.849 7.71509 10C7.71509 11.151 8.56309 12 9.71509 12Z" fill={color} />
    <path d="M20 4H4C2.897 4 2 4.841 2 5.875V18.125C2 19.159 2.897 20 4 20H20C21.103 20 22 19.159 22 18.125V5.875C22 4.841 21.103 4 20 4ZM20 18L4 17.989V6L20 6.011V18Z" fill={color} />
    <path d="M14 9H18V11H14V9ZM15 13H18V15H15V13ZM13.43 15.536C13.43 14.162 11.754 12.75 9.715 12.75C7.676 12.75 6 14.162 6 15.536V16H13.43V15.536Z" fill={color} />
  </svg>
)

export default Contact

