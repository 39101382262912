import { useState } from "react";
import styles from "./styles.module.scss";
import {
  BackArrow,
  GeneralTabs,
  GeneralButton,
  GeneralInput,
} from "../../../../../../components";
import { Grid } from "@mui/material";
import { gridContainer } from "./materialStyles";
import clsx from "clsx";
import { Icon } from "@iconify/react";
import UsersBranchOffice from "../UsersBranchOffices";
import { parseAmount, shallowCompare } from "../../../../../../utils";
import i18next from "i18next";

const BranchOfficeDetail = ({ t, setView, branchOfficeData }: any) => {
  const [tab, setTab] = useState(0);
  const [localStatus, setLocalStatus] = useState(false);

  const {
    branchOffice: {
      space: {
        EIN,
        user: {
          profile: userProfile,
          customer: {
            fiscalAddress,
            legalRepresentative: { profile },
            typeCommerce,
            typeCommerceFallback,
            monthlyIncome,
          },
        },
      },
    },
    branchOffice,
  } = branchOfficeData;

  const [ordering, setOrdering] = useState({
    orderBy: "",
    orderDirection: "desc",
    search: "",
  });

  const handleRefetchQuery = (newOrdering?: any, newPage?: number) => {
    const toUpdate = { ...ordering, ...newOrdering };

    if (!shallowCompare(ordering, toUpdate)) {
      setOrdering(toUpdate);
      return { ...toUpdate };
    }
    return { page: newPage };
  };

  return (
    <>
      {tab == 0 && (
        <div className={styles._arrowBackContainer}>
          <BackArrow onClick={() => setView("list")} />
          <p>
            {t("back_to")} {t("branch_offices")}
          </p>
        </div>
      )}
      {tab == 1 && (
        <div className={styles._topContainer}>
          <div className={styles._btnResponsiveContainer}>
            <div className={styles._arrowBackContainer}>
              <BackArrow onClick={() => setView("list")} />
              <p>
                {t("back_to")} {t("branch_offices")}
              </p>
            </div>
            <div className={styles._btnResponsive}>
              <GeneralButton
                text={""}
                icon={
                  <div>
                    <Icon className={styles._icon} icon="bx-user-plus" />
                  </div>
                }
                action={() => setLocalStatus(true)}
              />
            </div>
          </div>

          <div className={styles._headerParent}>
            <div className={styles._inputParent}>
              <GeneralInput
                onChange={(event: any) =>
                  setOrdering((prevValue) => ({
                    ...prevValue,
                    search: event.target.value,
                  }))
                }
                value={ordering.search}
                className={styles._noMargin}
                placeholder={t("search")}
                withIcon
                icon="bx:search"
                iconColor="#909090"
                iconClass={styles._inputIcon}
              />
            </div>
          </div>
        </div>
      )}
      <div className={styles._mediumContent}>
        <div className={styles._tabsContainer}>
          <GeneralTabs
            data={[
              { name: t("branch_office_detail_tab_1") },
              { name: t("branch_office_detail_tab_2") },
            ]}
            onChange={() => setTab((prevValue) => (prevValue == 0 ? 1 : 0))}
            value={tab}
            labelKey="name"
          />
        </div>
        {tab == 1 && (
          <div className={styles._btnParent}>
            <GeneralButton
              text="add_new_user"
              action={() => setLocalStatus(true)}
            />
          </div>
        )}
      </div>

      <div className={styles._bottomContainer}>
        {tab == 0 ? (
          <div className={styles._bottomContainer_child}>
            <p className={styles._branchOfficeTitle}>
              {t("branch_office")} {branchOffice.name}
            </p>
            <Grid
              container
              sx={gridContainer}
              className={styles._gridContainerBorder}
              justifyContent="space-between"
            >
              <Grid item xs={12} className={styles._item}>
                <p className={styles._subtitle}>{t("creation")}</p>
                <p className={styles._content}>{branchOffice.dateOfCreation}</p>
              </Grid>
              <Grid item xs={5} lg={2} className={styles._item}>
                <p className={styles._subtitle}>{t("name")}</p>
                <p className={styles._content}>{branchOffice.name}</p>
              </Grid>
              <Grid item xs={5} lg={2} className={styles._item}>
                <p className={styles._subtitle}>LLC</p>
                <p className={styles._content}>{EIN}</p>
              </Grid>
              <Grid item xs={12} lg={3} className={styles._item}>
                <p className={styles._subtitle}>{t("fiscal_address")}</p>
                <p className={styles._content}>{branchOffice.address}</p>
              </Grid>
              <Grid item xs={12} lg={4} className={styles._item}>
                <p className={styles._subtitle}>{t("code")}</p>
                <p className={styles._content}>
                  {branchOffice.code.toUpperCase()}
                </p>
              </Grid>
            </Grid>
            <p className={clsx(styles._branchOfficeTitle, styles._titleMargin)}>
              {t("business")}
            </p>
            <Grid
              container
              sx={gridContainer}
              className={styles._gridContainerBorder}
              justifyContent="space-between"
            >
              <Grid item xs={5} lg={2} className={styles._item}>
                <p className={styles._subtitle}>{t("business_type")}</p>
                <div className={styles._commerceType}>
                  {typeCommerceFallback ? (
                    <p className={styles._content}>{typeCommerceFallback}</p>
                  ) : (
                    <>
                      <Icon icon={typeCommerce.icon} color="#E6427A" />
                      <p className={styles._content}>
                        {i18next.language == "es"
                          ? typeCommerce?.esName
                          : typeCommerce?.enName}
                      </p>
                    </>
                  )}
                </div>
              </Grid>
              <Grid item xs={5} lg={2} className={styles._item}>
                <p className={styles._subtitle}>{t("email_not_electronic")}</p>
                <p className={styles._content}>{branchOffice.email}</p>
              </Grid>
              <Grid item xs={12} lg={3} className={styles._item}>
                <Grid container justifyContent="space-between">
                  <Grid item xs={5}>
                    <p className={styles._subtitle}>{t("local_telephone")}</p>
                    <p className={styles._content}>{userProfile.phoneNumber}</p>
                  </Grid>
                  <Grid item xs={5}>
                    <p className={styles._subtitle}>{t("cell_phone")}</p>
                    <p className={styles._content}>
                      {userProfile.cellPhoneNumber}
                    </p>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} lg={4} className={styles._item}>
                <p className={styles._subtitle}>{t("monthly_income")}</p>
                <p className={styles._content}>${parseAmount(monthlyIncome)}</p>
              </Grid>
            </Grid>
            <p className={clsx(styles._branchOfficeTitle, styles._titleMargin)}>
              {t("legal_representative")}
            </p>
            <Grid
              container
              sx={gridContainer}
              style={{ paddingBottom: "2.9375rem" }}
              justifyContent="space-between"
            >
              <Grid item xs={5} lg={2} className={styles._item}>
                <p className={styles._subtitle}>{t("legal_representative")}</p>
                <p className={styles._content}>{`${profile.firstName ?? ""} ${
                  profile.lastName ?? ""
                }`}</p>
              </Grid>
              <Grid item xs={5} lg={2} className={styles._item}>
                <p className={styles._subtitle}>Rif</p>
                <p className={styles._content}>
                  {profile.dniType}-{profile.dniNumber}
                </p>
              </Grid>
              <Grid item xs={12} lg={3} className={styles._item}>
                <p className={styles._subtitle}>{t("b_address")}</p>
                <p className={styles._content}>{fiscalAddress}</p>
              </Grid>
              <Grid item xs={12} lg={4} className={styles._item}>
                <Grid container>
                  <Grid item xs={5}>
                    <p className={styles._subtitle}>{t("local_telephone")}</p>
                    <p className={styles._content}>{profile.phoneNumber}</p>
                  </Grid>
                  <Grid item xs={5}>
                    <p className={styles._subtitle}>{t("cell_phone")}</p>
                    <p className={styles._content}>{profile.cellPhoneNumber}</p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        ) : (
          <UsersBranchOffice
            branchName={branchOffice.name}
            branchId={branchOffice.id}
            openCreate={localStatus}
            closeCreate={() => setLocalStatus(false)}
            handleRefetchQuery={handleRefetchQuery()}
            ordering={ordering}
          />
        )}
      </div>
    </>
  );
};

export default BranchOfficeDetail;
