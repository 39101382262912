
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { emailRegex, translate, onlyNumbersRegex, onlyLettersAndNumbers, onlyLettersNumbersAndSpace } from 'utils';

const createSchema: any = Yup.object({
  name: Yup.string()
    .required('field_required')
    .min(3, () => translate('min_invalid', { number: 3 }))
    .max(200, () => translate('max_invalid', { number: 200 }))
    .matches(onlyLettersNumbersAndSpace, 'only_alphanumeric'),

  ein: Yup.string(),

  address: Yup.string()
    .required('field_required')
    .min(4, () => translate('min_invalid', { number: 4 }))
    .max(200, () => translate('max_invalid', { number: 200 })),

  email: Yup.string()
    .required('field_required')
    .max(200, () => translate('max_invalid', { number: 200 }))
    .matches(emailRegex, 'email_valid'),


  users: Yup.array(),

  localCode: Yup.string().default('+58'),

  phoneNumber: Yup.string()
    .required('field_required')
    .matches(onlyNumbersRegex, 'phone_invalid')
    .min(7, () => translate('min_invalid', { number: 7 }))
    .max(11, () => translate('max_invalid', { number: 11 })),

  mobileCode: Yup.string().default('+58')
    .required('field_required'),

  cellPhoneNumber: Yup.string()
    .required('field_required')
    .matches(onlyNumbersRegex, 'phone_invalid')
    .min(7, () => translate('min_invalid', { number: 7 }))
    .max(11, () => translate('max_invalid', { number: 11 })),
})

const editSchema: any = Yup.object({
  name: Yup.string()
    .required('field_required')
    .min(3, () => translate('min_invalid', { number: 3 }))
    .max(200, () => translate('max_invalid', { number: 200 }))
    .matches(onlyLettersAndNumbers, 'only_alphanumeric'),

  ein: Yup.string(),

  address: Yup.string()
    .required('field_required')
    .min(4, () => translate('min_invalid', { number: 4 }))
    .max(200, () => translate('max_invalid', { number: 200 })),

  email: Yup.string()
    .required('field_required')
    .matches(emailRegex, 'email_valid')
    .max(200, () => translate('max_invalid', { number: 200 })),

  users: Yup.array().notRequired().default([])
})

export const FormikConfig = (handleSubmit: any, spaceId: string, branchOffice: any, handleUpdate: any) =>
  useFormik({
    enableReinitialize: true,
    initialValues: {
      name: branchOffice?.name || '',
      ein: '',
      address: branchOffice?.address,
      email: branchOffice?.email || '',
      users: [],
      localCode: "",
      phoneNumber: "",
      mobileCode: "",
      cellPhoneNumber: "",
    },

    validationSchema: !branchOffice ? createSchema : editSchema,

    onSubmit: (values) => {
      const { localCode, phoneNumber, mobileCode, cellPhoneNumber, ein, ...formValues } = values
      const data = {
        ...formValues,
        phoneNumber: `${localCode} ${phoneNumber}`,
        cellPhoneNumber: `${mobileCode} ${cellPhoneNumber}`,
        spaceId
      }

      const updateData = {
        ...formValues,
        id: branchOffice?.id
      }

      !branchOffice ? handleSubmit(data) : handleUpdate(updateData);
    },
  });
