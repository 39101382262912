import { gql } from '@apollo/client';

const GET_PROFILE_ACCOUNTS = gql`
  query ProfileAccounts($spaceId: ID!, $perPage: Int) {
    bankAccounts(spaceId: $spaceId, perPage: $perPage) {
      items {
        id
        accountNumber
        routeType
        address
        accountHolder
        state
        city
        spaceId
        bankId
        routeNumber
        routeType
        zipCode
        bank {
          title
          icon
        }
      }
      pagination {
        totalItems
        perPage
      }
    }
  }
`;
export default GET_PROFILE_ACCOUNTS;
