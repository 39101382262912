import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { WALLETS_QUERY } from 'graphql/queries'
import { useDispatch } from 'react-redux'
import { setWallets } from 'store/actions'

export const UseWallets = (key: string) :any => {
  const dispatch = useDispatch()
  const [state, setState] = useState([])
  const  { data } = useQuery(WALLETS_QUERY)

  useEffect(() => {
    if (data) {
      dispatch(setWallets({ [key]: data?.wallets }))
      setState(data?.wallets)
    }
  }, [data])


  return state
}
