import { useState, useRef } from "react";
import { OnboardingFooter } from "../../../../components";
import {
  GeneralPhoneInput,
  GeneralSwitch,
  GeneralUploadFile,
  Tooltip,
  BackArrow,
  GeneralInput
} from "../../../../../components";
import { UseIntermitence } from "hooks";
import { FormikConfig } from "./formik";
import { amountMask, naturalIdentification, normalizeAccountData } from "utils";
import { useSelector, useDispatch } from "react-redux";
import { setUserData } from "store/actions";
import { useNavigate } from "react-router-dom";
import { onboardingSelector } from "store/selectors";
import { UseAlert } from "hooks";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { VERIFY_CUSTOMER_EMAIL } from "graphql/mutations";
import { useMutation } from "@apollo/client";

const AccountData = ({ t }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ref: any = useRef(null);
  const onboarding = useSelector(onboardingSelector);
  const { type, accountData } = onboarding;
  const { legalRepresentative } = accountData;
  const [verify_customer_email] = useMutation(VERIFY_CUSTOMER_EMAIL);

  const { status, switchStatus } = UseIntermitence();
  const { status: iconStatus, switchStatus: switchIconStatus } =
    UseIntermitence();
  const { status: iconConfirmStatus, switchStatus: switchConfirmIconStatus } =
    UseIntermitence();

  const [document, setDocument] = useState(
    legalRepresentative?.dniPhotoUrl ?? ""
  );

  const { showAlert } = UseAlert();

  const handleSubmit = (formValues: any) => {
    const additionalData: any = {
      document,
    };

    if (!document && type == "juridic")
      return showAlert("must_attach_document", "error");

    const allFormData =
      type == "juridic" ? { ...formValues, ...additionalData } : formValues;
    const fullData = normalizeAccountData(allFormData);
    dispatch(setUserData({ ...onboarding, accountData: fullData }));
    type == "juridic"
      ? navigate("/onboarding/shareholders")
      : navigate("/onboarding/signature");
  };

  const {
    values,
    handleSubmit: formikSubmit,
    handleChange,
    submitForm,
    errors,
    touched,
    setTouched,
    isValid = false,
    dirty,
    setFieldValue,
  } = FormikConfig(handleSubmit, type, accountData);

  const onHandlerDocument = (document: any) => setDocument(document);
  const onHandlerSelect = (value: any) =>
    setFieldValue("representativeDniType", value);
  const handleOnTouched = (key: string) =>
    setTouched({ ...touched, [key]: true });

  const handleRemoveDocument = () => {
    const localAccountData = { ...accountData };
    const { legalRepresentative: localRepresentative } = localAccountData;
    if (localRepresentative) localRepresentative.dniPhotoUrl = "";
    setDocument("");
    if (legalRepresentative?.document)
      dispatch(setUserData({ ...onboarding, accountData: localAccountData }));
  };

  const changeInputStatus = (value: string, error: any) => {
    if (value !== "") {
      if (error) return "error";
      return "success";
    }
    return "inherit";
  };

  const validateCustomerEmail = () => {
    verify_customer_email({
      variables: { email: values.email },
      onError: (error: any) => {
        showAlert(error?.message, 'error')
      },
      onCompleted: (response: any) => {
        const { verifyCustomerEmail: { result } } = response

        if (result) {
          return showAlert('email_already_exist', 'error')
        }
        return submitForm()
      }
    })
  }

  return (
    <>
      <div className={styles._main}>
        <div className={styles._arrowParent}>
          <BackArrow action route="/onboarding/basic-user-data" />
        </div>
        <div>
          <div className={styles._titlesParent}>
            <h3 className={styles._title}>{t("required_information")}</h3>
            <p className={styles._subtitle}>{t("fill_form")}</p>
          </div>

          <form onSubmit={formikSubmit} id="my-form">
            <div className={styles._inputParent}>
              <GeneralInput
                placeholder="email"
                id="email"
                name="email"
                onChange={handleChange}
                value={values.email}
                hasError={errors.email && touched.email ? true : false}
                onFocus={() => handleOnTouched("email")}
                errorMessage={errors.email}
                status={changeInputStatus(
                  values.email,
                  errors.email && touched.email
                )}
              />
            </div>

            <div className={styles._row}>
              <div className={styles._halfInput}>
                <div style={{ position: "relative" }}>
                  {status && (
                    <Tooltip text="password_pattern" top="-130%" left="65%" />
                  )}

                  <GeneralInput
                    placeholder="password"
                    onFocus={() => {
                      switchStatus();
                      handleOnTouched("password");
                    }}
                    onBlur={switchStatus}
                    withIcon
                    iconColor={"#919191"}
                    icon={`${!iconStatus ? "bx:show" : "bx:low-vision"}`}
                    id="password"
                    name="password"
                    onChange={handleChange}
                    value={values.password}
                    hasError={
                      errors.password && touched.password ? true : false
                    }
                    iconMethod={switchIconStatus}
                    type={`${!iconStatus ? "password" : "text"}`}
                    errorMessage={errors.password}
                    status={changeInputStatus(
                      values.password,
                      errors.password && touched.password
                    )}
                  />
                </div>
              </div>
              <div className={styles._halfInput}>
                <GeneralInput
                  placeholder="confirm_password"
                  withIcon
                  id="confirmPassword"
                  name="confirmPassword"
                  iconColor={"#919191"}
                  icon={`${!iconConfirmStatus ? "bx:show" : "bx:low-vision"}`}
                  type={`${!iconConfirmStatus ? "password" : "text"}`}
                  iconMethod={switchConfirmIconStatus}
                  onChange={handleChange}
                  hasError={
                    errors.confirmPassword && touched.confirmPassword
                      ? true
                      : false
                  }
                  value={values.confirmPassword}
                  onFocus={() => handleOnTouched("confirmPassword")}
                  errorMessage={errors.confirmPassword}
                  status={changeInputStatus(
                    values.confirmPassword,
                    errors.confirmPassword && touched.confirmPassword
                  )}
                />
              </div>
            </div>

            <div className={styles._row}>
              <div className={styles._halfInput}>
                <GeneralPhoneInput
                  value={values.phoneNumber}
                  name="phoneNumber"
                  status={changeInputStatus(
                    values.phoneNumber,
                    errors.phoneNumber && touched.phoneNumber
                  )}
                  errorMessage={errors.phoneNumber}
                  hasError={
                    errors.phoneNumber && touched.phoneNumber ? true : false
                  }
                  onFocus={() => handleOnTouched("phoneNumber")}
                  placeholder="2125866998"
                  setFieldValue={setFieldValue}
                />
              </div>
              <div className={styles._halfInput}>
                <GeneralPhoneInput
                  value={values.cellPhoneNumber}
                  name="cellPhoneNumber"
                  status={changeInputStatus(
                    values.cellPhoneNumber,
                    errors.cellPhoneNumber && touched.cellPhoneNumber
                  )}
                  hasError={
                    errors.cellPhoneNumber && touched.cellPhoneNumber
                      ? true
                      : false
                  }
                  errorMessage={errors.cellPhoneNumber}
                  onFocus={() => handleOnTouched("cellPhoneNumber")}
                  placeholder="4147880699"
                  setFieldValue={setFieldValue}
                />
              </div>
            </div>

            <div className={styles._inputParent}>
              <GeneralInput
                placeholder="monthly_income"
                id="monthlyIncome"
                name="monthlyIncome"
                onChange={(event: any) => amountMask(event, setFieldValue, 'monthlyIncome')}
                value={values.monthlyIncome}
                hasError={
                  errors.monthlyIncome && touched.monthlyIncome ? true : false
                }
                onFocus={() => handleOnTouched("monthlyIncome")}
                errorMessage={errors.monthlyIncome}
                status={changeInputStatus(
                  values.monthlyIncome,
                  errors.monthlyIncome && touched.monthlyIncome
                )}
              />
            </div>
            <div className={clsx(styles._inputParent, styles._switchContainer)}>
              <div>
                <p className={styles._2fa}>{t("2fa_verification")}</p>
                <p className={styles._recommended}>{t("recommended")}</p>
              </div>
              <GeneralSwitch
                checked={values.requestTwoFactor}
                onChange={(e) => {
                  setFieldValue("requestTwoFactor", e.target.checked);
                }}
              />
            </div>
            {type == "juridic" && (
              <>
                <p className={styles._formTitle}>{t("legal_representative")}</p>

                <div className={styles._row}>
                  <div className={styles._halfInput}>
                    <GeneralInput
                      placeholder="name_of_legal"
                      id="legalRepresentativeName"
                      name="legalRepresentativeName"
                      onChange={handleChange}
                      value={values.legalRepresentativeName}
                      hasError={
                        errors.legalRepresentativeName &&
                          touched.legalRepresentativeName
                          ? true
                          : false
                      }
                      onFocus={() => handleOnTouched("legalRepresentativeName")}
                      errorMessage={errors.legalRepresentativeName}
                      status={changeInputStatus(
                        values.legalRepresentativeName,
                        errors.legalRepresentativeName &&
                        touched.legalRepresentativeName
                      )}
                    />
                  </div>

                  <div className={styles._halfInput}>
                    <GeneralInput
                      placeholder="lastname_of_legal"
                      id="legalRepresentativeLastName"
                      name="legalRepresentativeLastName"
                      onChange={handleChange}
                      value={values.legalRepresentativeLastName}
                      hasError={
                        errors.legalRepresentativeLastName &&
                          touched.legalRepresentativeLastName
                          ? true
                          : false
                      }
                      onFocus={() =>
                        handleOnTouched("legalRepresentativeLastName")
                      }
                      errorMessage={errors.legalRepresentativeLastName}
                      status={changeInputStatus(
                        values.legalRepresentativeLastName,
                        errors.legalRepresentativeLastName &&
                        touched.legalRepresentativeLastName
                      )}
                    />
                  </div>
                </div>

                <div className={styles._inputParent}>
                  <GeneralUploadFile
                    selectData={naturalIdentification}
                    selectValue={values.representativeDniType || "V"}
                    nameValue={document?.name}
                    linkValue={document?.link}
                    methodSelect={(event: any) =>
                      onHandlerSelect(event.target.value)
                    }
                    inputValue={values.representativeDniNumber}
                    methodInput={handleChange}
                    methodFile={(value: any) => onHandlerDocument(value)}
                    placeholder={"id_or_passport"}
                    methodIcon={() => handleRemoveDocument()}
                    hasError={
                      errors.representativeDniNumber &&
                        touched.representativeDniNumber
                        ? true
                        : false
                    }
                    onFocus={() => handleOnTouched("representativeDniNumber")}
                    reference={ref}
                    validateMessage={errors.representativeDniNumber}
                    id="representativeDniNumber"
                    name="representativeDniNumber"
                    inputStatus={changeInputStatus(
                      values.representativeDniNumber,
                      errors.representativeDniNumber &&
                      touched.representativeDniNumber
                    )}
                  />
                </div>
                <div className={styles._inputParent}>
                  <GeneralInput
                    placeholder="address"
                    id="address"
                    name="address"
                    onChange={handleChange}
                    value={values.address}
                    hasError={errors.address && touched.address ? true : false}
                    onFocus={() => handleOnTouched("address")}
                    errorMessage={errors.address}
                    status={changeInputStatus(
                      values.address,
                      errors.address && touched.address
                    )}
                  />
                </div>

                <div className={styles._row}>
                  <div className={styles._halfInput}>
                    <GeneralPhoneInput
                      value={values.cellPhoneNumber}
                      name="representativePhone"
                      status={changeInputStatus(
                        values.representativePhone,
                        errors.representativePhone &&
                        touched.representativePhone
                      )}
                      hasError={
                        errors.representativePhone &&
                          touched.representativePhone
                          ? true
                          : false
                      }
                      placeholder="2125866998"
                      errorMessage={errors.representativePhone}
                      onFocus={() => handleOnTouched("representativePhone")}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                  <div className={styles._halfInput}>
                    <GeneralPhoneInput
                      value={values.cellPhoneNumber}
                      name="representativeCellPhone"
                      status={changeInputStatus(
                        values.representativeCellPhone,
                        errors.representativeCellPhone &&
                        touched.representativeCellPhone
                      )}
                      hasError={
                        errors.representativeCellPhone &&
                          touched.representativeCellPhone
                          ? true
                          : false
                      }
                      placeholder="4147880699"
                      errorMessage={errors.representativeCellPhone}
                      onFocus={() => handleOnTouched("representativeCellPhone")}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                </div>
              </>
            )}
          </form>
          <div className={styles._footerParent}>
            <OnboardingFooter
              buttonDisabled={
                accountData.completed ? !isValid : !isValid || !dirty
              }
              buttonText="continue"
              step={2}
              buttonAction={validateCustomerEmail}
              maxSteps={onboarding?.type == "natural" ? 4 : 5}
            ></OnboardingFooter>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountData;
