import { useCallback } from "react";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { onlyLettersRegex, translate } from "utils";
import { setUserData } from "store/actions";
import { useNavigate } from "react-router-dom";
import { onboardingSelector } from "store/selectors";
import {
  GeneralCheckbox,
  GeneralInput,
  GeneralInputDate,
  SignatureComponent,
  BackArrow
} from "../../../../../components";
import * as Yup from "yup";
import { OnboardingFooter } from "../../../../components";
import clsx from "clsx";
import styles from "./styles.module.scss";

const Signature = ({ t }: any) => {
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const onboarding = useSelector(onboardingSelector);
  const handleSubmit = useCallback((values: any) => {
    dispatch(
      setUserData({
        signatureData: {
          completed: true,
          acceptance: values.acceptance,
          signature: values.signature,
          position: values.position,
          type: values.type,
        },
      })
    );
    navigator("/onboarding/agreements");
  }, []);

  const changeInputStatus = (value: string, error: any) => {
    if (value !== "") {
      if (error) return "error";
      return "success";
    }
    return "inherit";
  };

  return (
    <div className={styles._mainContainer}>
      <div className={styles._arrowContainer}>
        <BackArrow
          action
          route={
            onboarding.type === "natural"
              ? "/onboarding/account-data"
              : "/onboarding/shareholders"
          }
        />
      </div>
      <div className={styles._content}>
        <h3 className={styles._title}>{t("required_information")}</h3>
        <p className={styles._subtitle}>{t("fill_form")}</p>
        <p className={styles._sectionTitle}>
          {t("funds_declaration")} <br />
          <br />
          {t("puntu_exempts")}
          <br />
          <br />
          <b>{t("do_you_agree")}</b>
        </p>
        <Formik
          initialValues={{
            acceptance: onboarding.signatureData.acceptance ?? undefined,
            name:
              onboarding.type == "natural"
                ? `${onboarding.basicUserData.firstName ?? ""} ${onboarding.basicUserData.lastName ?? ""
                }`
                : `${onboarding.accountData?.legalRepresentative?.firstName ?? ""
                } ${onboarding.accountData?.legalRepresentative?.lastName ?? ""
                }`,
            dni:
              `${onboarding.basicUserData.dniType} - ${onboarding.basicUserData.dni}` ??
              "",
            position: onboarding.signatureData.position ?? "",
            date: new Date().toISOString().split("T")[0],
            signature: null,
            type: "",
          }}
          onSubmit={handleSubmit}
          validationSchema={Yup.object({
            acceptance: Yup.boolean().required(),
            position: Yup.string()
              .required("field_required")
              .matches(onlyLettersRegex, "only_letters")
              .min(3, () => translate("min_invalid", { number: 3 })),
            signature: Yup.string().required(t("required_signature")),
          })}
        >
          {({
            values,
            setFieldValue,
            errors,
            isValid,
            touched,
            submitForm,
            handleChange,
            validateField,
            setFieldTouched,
          }) => {
            return (
              <Form className={styles._formStyles} action="#">
                <div className={styles._checkboxContainer}>
                  <GeneralCheckbox
                    checked={values.acceptance}
                    onChange={(e) =>
                      setFieldValue("acceptance", e.target.checked)
                    }
                    label="yes"
                  />
                  <GeneralCheckbox
                    checked={
                      values.acceptance !== undefined && !values.acceptance
                    }
                    onChange={(e) =>
                      setFieldValue("acceptance", !e.target.checked)
                    }
                    className={styles._checkboxMargin}
                    label="no"
                  />
                </div>
                <SignatureComponent
                  name="signature"
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  typeName="type"
                  touched={touched["signature"]}
                  validate={validateField}
                  error={touched.signature && errors.signature}
                />
                <div className={styles._inputsContainer}>
                  <GeneralInput
                    placeholder="name"
                    value={values.name}
                    disabled
                    className={styles._inputsMargin}
                  />
                  <GeneralInput
                    placeholder="id"
                    value={values.dni}
                    disabled
                    className={styles._inputsMargin}
                  />
                  <GeneralInput
                    placeholder="position"
                    value={values.position}
                    id="position"
                    onChange={handleChange}
                    hasError={!!touched.position && !!errors.position}
                    errorMessage={errors.position}
                    onFocus={() => {
                      setFieldTouched("position", true, true);
                    }}
                    name="position"
                    status={changeInputStatus(
                      values.position,
                      errors.position && touched.position
                    )}
                  />
                  <GeneralInputDate
                    placeholder="date"
                    value={values.date}
                    disabled
                    type="date"
                  />
                </div>
                <p className={clsx(styles._subtitle, styles._bottomMargin)}>
                  {t("representative_signature")}
                </p>
                <OnboardingFooter
                  buttonDisabled={!isValid}
                  buttonAction={() => submitForm()}
                  buttonText={"sign"}
                  step={onboarding?.type == "natural" ? 3 : 4}
                  maxSteps={onboarding?.type == "natural" ? 4 : 5}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default Signature;
