import React, { useEffect, useRef, useState } from "react";
import { GeneralButton, GeneralModal } from "../../../components";
import styles from "./styles.module.scss";
import { Icon } from "@iconify/react";
import { useMediaQuery, Theme, Checkbox, RadioGroup } from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import { DELETE_BANK_ACCOUNT } from "graphql/mutations";
import { userSpaceSelector } from "store/selectors";
import { UseAlert } from "hooks";
import { useTranslation } from "react-i18next";
import { baseUrl } from "paths";
import {
  bankFees,
  normalizeResponseErrorMesages,
  parseAmount,
  translate,
} from "utils";
import { GET_PROFILE_ACCOUNTS } from "graphql/queries";
import { useSelector } from "react-redux";

const ManageBankAccounts = ({ open, onClose, onchangeModal }: any) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const { t } = useTranslation();
  const { showAlert } = UseAlert();
  const [checkValue]: any = useState();
  const [listAccounts, setListAccounts]: any = useState([]);
  const { currentSpace } = useSelector(userSpaceSelector);
  const {
    data: banks,
    loading: banksLoading,
    refetch,
  } = useQuery(GET_PROFILE_ACCOUNTS, {
    fetchPolicy: "cache-and-network",
    variables: {
      spaceId: currentSpace?.id,
      perPage: 999,
    },
  });
  const [deleteBankAccount, { loading: deleteLoading }] =
    useMutation(DELETE_BANK_ACCOUNT);

  const handleCheckAccounts = (id: string) => {
    if (id == undefined) return;
    const accounts = [...listAccounts];
    const index = accounts.findIndex((accountId: string) => accountId == id);
    if (index > -1) accounts.splice(index, 1);
    if (index == -1) accounts.push(id);

    return setListAccounts(accounts);
  };

  const deleteAccounts = () => {
    deleteBankAccount({
      variables: { data: { ids: [...listAccounts] } },

      onCompleted: (response: any) => {
        if (response) {
          showAlert(t("account_deleted"), "success");
          onClose();
          refetch && refetch();
          return;
        }
      },
      onError: (error) => {
        return showAlert(
          normalizeResponseErrorMesages(error?.message, t),
          "error"
        );
      },
    });
  };

  const validateIcon = (bankName: string) => {
    if (bankName == "Bank of America") {
      return (
        <div className={styles._bankIcon}>
          <img
            src={`${baseUrl}/resources/bofa_witouth_background.svg`}
            alt="bank-icon"
          />
        </div>
      );
    }

    return (
      <div className={styles._defaultIcon}>
        <Icon width={25} icon="bxs-bank"></Icon>
      </div>
    );
  };

  return (
    <>
      <GeneralModal
        open={open}
        title={t("manage_my_accounts")}
        onCancel={onClose && onClose}
        height={isMobile ? "80%" : "85vh"}
        width={"35.5rem"}
      >
        <div className={styles._accountsMain}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
          >
            {banks?.bankAccounts?.items?.map((item: any, index: string) => {
              return (
                <label key={index}>
                  <div className={styles._accountsContainer}>
                    <div className={styles._bankTitleContainer}>
                      <div className={styles._row}>
                        <Checkbox
                          icon={<Icon icon="bx:radio-circle" fontSize={30} />}
                          checkedIcon={
                            <Icon icon="bx:radio-circle-marked" fontSize={30} />
                          }
                          checked={checkValue?.includes(item?.id)}
                          onChange={() => handleCheckAccounts(item?.id)}
                          value={item?.id}
                          name="select-all-checkbox"
                        />
                        {validateIcon(item?.bank?.title)}
                        <div>
                          <p className={styles._accountTitle}>
                            {t(item?.bank?.title)}
                          </p>
                          <p className={styles._accountSubtitle}>
                            {translate("account_affiliated_bank", {
                              bank: item?.bank?.title,
                            })}
                          </p>
                        </div>{" "}
                      </div>

                      {!isMobile && (
                        <div>
                          <p className={styles._miniTitle}>
                            {t("ach_transfer")}
                          </p>
                          <p className={styles._miniSubTitle}>
                            {t("commission")} $
                            {parseAmount(bankFees[item?.routeType])}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </label>
              );
            })}
          </RadioGroup>
        </div>
        <div className={styles._buttonsContainers}>
          <div className={styles._buttonParentLeft}>
            <GeneralButton
              text={"affiliate"}
              type="submit"
              loading={deleteLoading || banksLoading}
              className={styles._btnCutom}
              action={() => {
                onchangeModal();
                onClose();
              }}
              disabled={
                listAccounts.length >= 1 || deleteLoading || banksLoading
              }
            />
          </div>
          <div className={styles._buttonParentRight}>
            <GeneralButton
              loading={deleteLoading || banksLoading}
              text={"delete"}
              type="submit"
              action={() => deleteAccounts()}
              disabled={
                listAccounts.length == 0 || deleteLoading || banksLoading
              }
            />
          </div>
        </div>
      </GeneralModal>
    </>
  );
};

export default ManageBankAccounts;
