const onboardingSelector = (state: any) => state.onboarding;
const userAuthSelector = (state: any) => state.user;
const intermitenceSelector = (state: any) => state.intermitence;
const activitySelector = (state: any) => state.activity;
const resourceSelector = (state: any) => state.resource;
const userSpaceSelector = (state: any) => state.userSpace;
const getUserType = (state: any) => state.user.userAuthData?.customer?.type;
const getBankAccountSelector = (state: any) => state.bankAccount;

export {
  onboardingSelector,
  userAuthSelector,
  intermitenceSelector,
  activitySelector,
  resourceSelector,
  userSpaceSelector,
  getUserType,
  getBankAccountSelector,
};
