import { gql } from '@apollo/client';

const UPDATE_UI_ROLE = gql`
  mutation UpdateUiRole($data: UpdateUiRoleInput!) {
    updateUiRole(data: $data) {
      id
    }
  }
`;
export default UPDATE_UI_ROLE;
