import { useLazyQuery } from "@apollo/client";
import BRANCH_OFFICE_QUERY from "graphql/queries/branchOffice";
import { useState } from "react";
import BranchOfficeDetail from "./components/BranchOfficeDetail";
import BranchOfficeList from "./components/BranchOfficeList";
import styles from "./styles.module.scss";

const BranchOffice = ({ t }: any) => {
  const [view, setView] = useState("list");
  const [getLazyBranchOffice, { data }] = useLazyQuery(BRANCH_OFFICE_QUERY, {
    onCompleted: () => {
      setView("detail");
    },
  });

  return (
    <div className={styles._mainContainer}>
      {view == "list" ? (
        <BranchOfficeList
          t={t}
          setView={setView}
          getLazyBranchOffice={getLazyBranchOffice}
        />
      ) : (
        <BranchOfficeDetail t={t} setView={setView} branchOfficeData={data} />
      )}
    </div>
  );
};

export default BranchOffice;
