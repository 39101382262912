import React from 'react'
import { GeneralModal } from 'components'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'

const BranchOfficeConfirmation = ({ open = false, code = '', onClose }: any) => {
  const { t } = useTranslation()
  const stringToArray = (code: any) => {
    const upperCode = code.toUpperCase()
    const converted = [...upperCode]
    return converted || []
  };

  return (
    <GeneralModal open={open} title={null} onCancel={onClose && onClose}>
      <div className={styles._main}>
        <h1 className={styles._title}>{t('create_branch_success')}</h1>
        <p className={styles._text}>{t('branch_office_code_is')}</p>

        <div className={styles._codeParent}>
          {
            stringToArray(code).map((letter, index) => (
              <div className={styles._codeSquare} key={index}>
                <p>{letter}</p>
              </div>
            ))
          }
        </div>
        <p className={styles._text}> {t('can_find_operations')}</p>
      </div>
    </GeneralModal>
  )
}

export default BranchOfficeConfirmation;
