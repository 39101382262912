import styles from "./styles.module.scss";
import { baseUrl } from "paths";
import { CircularProgress } from "@mui/material";

import { Icon } from "@iconify/react";
import { GET_PROFILE_ACCOUNTS } from "graphql/queries";
import { useQuery } from "@apollo/client";
import { CreateBankAccount, ManageBankAccounts } from "web/components";
import { UseIntermitence } from "hooks";
import { useSelector } from "react-redux";
import { userSpaceSelector } from "store/selectors";
import { bankFees, parseAmount, translate } from "utils";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Accounts = () => {
  const { t } = useTranslation();
  const { currentSpace } = useSelector(userSpaceSelector);
  const [selectedAccount, setSelectedAccount]: any = useState();
  const { switchStatus, status } = UseIntermitence();

  const { switchStatus: manageSwitchStatus, status: manageStatus } =
    UseIntermitence();

  const {
    data: banks,
    loading: banksLoading,
    refetch,
  } = useQuery(GET_PROFILE_ACCOUNTS, {
    fetchPolicy: "cache-and-network",
    variables: {
      spaceId: currentSpace?.id,
      perPage: 999,
    },
  });

  const validateIcon = (bankName: string) => {
    if (bankName == "Bank of America") {
      return (
        <div className={styles._bankIcon}>
          <img
            src={`${baseUrl}/resources/bofa_witouth_background.svg`}
            alt="bank-icon"
          />
        </div>
      );
    }

    return (
      <div className={styles._defaultIcon}>
        <Icon width={25} icon="bxs-bank"></Icon>
      </div>
    );
  };

  return (
    <>
      {banksLoading ? (
        <div className={styles._loaderContainer}>
          <CircularProgress color="primary" size={35} />
        </div>
      ) : (
        <div className={styles._bottomContainer}>
          <div className={styles._titleContainer}>
            <p className={styles._title}>{t("my_affiliate_accounts")}</p>
            <p
              className={styles._manageTitle}
              onClick={() => manageSwitchStatus()}
            >
              {t("manage")}
            </p>
          </div>
          {banks?.bankAccounts?.items?.map((item: any, index: string) => {
            return (
              <div
                key={index}
                className={styles._accountContainer}
                onClick={() => {
                  switchStatus();
                  setSelectedAccount(item);
                }}
              >
                <div className={styles._row}>
                  {validateIcon(item?.bank?.title)}

                  <div>
                    <p className={styles._accountTitle}>
                      {t(item?.bank?.title)}
                    </p>
                    <p className={styles._accountSubtitle}>
                      {translate("account_affiliated_bank", {
                        bank: item?.bank?.title,
                      })}
                    </p>
                  </div>
                </div>
                <div>
                  <div className={styles._arrowIcon}>
                    <Icon width={25} icon="bx-right-arrow-circle"></Icon>
                  </div>
                </div>
              </div>
            );
          })}
          <div
            className={styles._accountContainer}
            onClick={() => switchStatus()}
          >
            <div className={styles._row}>
              <img
                className={styles._addIcon}
                src={`${baseUrl}/resources/plus-gray-back-icon.svg`}
                alt="no-wallet-icon"
              />

              <div>
                <p className={styles._accountTitle}>{t("add_and_account")}</p>
                <p className={styles._accountSubtitle}>
                  {t("account_affiliated")}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      <CreateBankAccount
        open={status}
        onClose={() => {
          switchStatus();
          setSelectedAccount(null);
        }}
        accountData={selectedAccount}
        refetch={refetch}
        resetEdit={() => setSelectedAccount(null)}
      />
      {manageStatus && (
        <ManageBankAccounts
          open={manageStatus}
          onClose={manageSwitchStatus}
          onchangeModal={switchStatus}
        />
      )}
    </>
  );
};

export default Accounts;
