import { Theme } from '@mui/material';

const connectorStyles = (theme: Theme) => ({
  left: 'calc(-50% + 18px)',
  right: 'calc(50% + 18px)',
  top: '18.5px',
  '& .MuiStepConnector-line': {
    borderColor: theme.palette.blackColorCustom?.dark,
  },
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
});

const labelStyles = (theme: Theme, active: boolean) => ({
  '& .MuiStepLabel-label': {
    color: theme.palette.blackColorCustom?.main,
    fontWeight: '400',
    '&.Mui-active': { color: theme.palette.primary.main, fontWeight: '400' },
  },
});

export { connectorStyles, labelStyles };
