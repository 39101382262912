import React from "react";
import styles from "./styles.module.scss";
import { baseUrl } from "paths";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { parseAmount } from "utils";

const AccountCard = ({
  account,
  noAccount,
  balance = 0,
  action = null,
  id,
  loading,
}: any) => {
  const { t } = useTranslation();
  const navigation = useNavigate();

  const filterAndNavigate = () =>
    navigation("/activity", { state: { wallet: id, name: account } });

  const handlerClick = () => {
    if (account == "disabled") return null;
    if (!noAccount && !loading) return filterAndNavigate();
    if (noAccount && account == "enabled") return action && action();
  };

  const titleCase = (str: string) =>
    `${str[0].toUpperCase()}${str.slice(1).toLowerCase()}`;

  return (
    <div
      className={
        account != "disabled" ? styles._cardParent : styles._cardParentDisabled
      }
      onClick={handlerClick}
    >
      <div className={styles._header}>
        {!noAccount ? (
          <img
            src={`${baseUrl}/resources/${account}-card-icon.svg`}
            width={35}
            alt="card-icon"
          />
        ) : (
          <img
            src={`${baseUrl}/resources/plus-gray-back-icon.svg`}
            alt="no-wallet-icon"
          />
        )}
        <div>
          <p className={styles._title}>
            {t(!noAccount ? `${titleCase(account)}` : "add_account")}
          </p>
          <p className={styles._subtitle}>{t("account_affiliated")}</p>
        </div>
      </div>
      {!noAccount && <p className={styles._amount}>${parseAmount(balance)}</p>}
    </div>
  );
};

export default AccountCard;
