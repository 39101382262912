import React, { useEffect, useState } from "react";
import { Modal, Box, Radio, CircularProgress } from "@mui/material";
import styles from "./styles.module.scss";
import { Icon } from "@iconify/react";

import { RolesModalType } from "./types";
import { modalBox, modal, radioButton } from "./materialStyles";
import { useTranslation } from "react-i18next";
import {
  GeneralButton,
  GeneralInput,
  GeneralSwitch,
  GeneralTabs,
} from "components";
import { SmallPagination } from "web/components";
import { Form, Formik } from "formik";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import ALL_PERMISSIONS from "graphql/queries/permissions";
import ROLE_MEMBERS from "graphql/queries/roleMembers";
import DELETE_UI_ROLE_MEMBER from "graphql/mutations/deleteUiRoleMember";
import CREATE_UI_ROLE from "graphql/mutations/createUiRole";
import UPDATE_UI_ROLE from "graphql/mutations/updateUiRole";
import { UseAlert } from "hooks";
import * as Yup from "yup";
import { onlyLettersRegex, translate } from "utils";
const colors = [
  "#722FC7",
  "#EC8F03",
  "#4AB3F6",
  "#F65757",
  "#FA3A7F",
  "#59C36A",
  "#6DF6EE",
  "#A0BCF5",
  "#EF89AC",
  "#FFD15C",
  "#FE6435",
];

const Show = ({
  form: {
    values,
    setFieldValue,
    handleSubmit,
    isValid,
    errors,
    touched,
    setFieldTouched,
  },
  t,
}: any) => {
  return (
    <>
      <GeneralInput
        placeholder={t("role_name")}
        value={values.title}
        onChange={(e: any) => setFieldValue("title", e.target.value)}
        errorMessage={touched["title"] && errors["title"]}
        hasError={touched["title"] && errors["title"]}
        onBlur={() => setFieldTouched("title", true)}
      />
      <p className={styles._rolColor}>{t("role_color")}</p>
      <div className={styles._colorsContainer}>
        {colors.map((elem) => (
          <div
            style={{
              display: "inline-block",
              width: "1.5362rem",
              height: "1.25rem",
              background: elem,
              cursor: "pointer",
              borderRadius: "0.25rem",
              border: values.color == elem ? "1px solid #000000" : null,
            }}
            key={elem}
            onClick={() => setFieldValue("color", elem)}
          />
        ))}
      </div>
      <GeneralButton
        text={t("save")}
        className={styles._showSaveButton}
        type="submit"
        disabled={!isValid}
        action={() => handleSubmit()}
      />
    </>
  );
};

const Permissions = ({
  form,
  allPermissionsData,
  fetchAllPermissions,
  t,
}: any) => {
  const { data: permissionsData, refetch } = useQuery(ALL_PERMISSIONS);
  const [search, setSearch] = useState("");
  const { showAlert } = UseAlert();
  useEffect(() => {
    const timeIntervalDebounce = setTimeout(() => {
      refetch({ search, page: 1 });
    }, 800);
    return () => {
      clearTimeout(timeIntervalDebounce);
    };
  }, [search]);

  const handleSelectAll = () => {
    if (form.values.selectAll) {
      form.setFieldValue("permissions", []);
      form.setFieldValue("selectAll", !form.values.selectAll, false);
      return;
    }

    if (!allPermissionsData) {
      fetchAllPermissions({
        variables: {
          perPage: 200,
        },
        onCompleted: (data: any) => {
          form.setFieldValue(
            "permissions",
            data.permissions.items.map((elem: any) => elem.id)
          );
          form.setFieldValue("selectAll", !form.values.selectAll, false);
        },
        onError: () => {
          showAlert(t("load_permissions_error"), "error");
        },
      });
      return;
    } else {
      form.setFieldValue(
        "permissions",
        allPermissionsData.permissions.items.map((elem: any) => elem.id)
      );
    }
    form.setFieldValue("selectAll", !form.values.selectAll, false);
  };

  return permissionsData ? (
    <>
      <GeneralInput
        placeholder={t("search_permissions")}
        value={search}
        onChange={(e: any) => setSearch(e.target.value)}
      />
      <div className={styles._radioContainer}>
        <Radio
          id="allowAll"
          name="allowAll"
          onClick={handleSelectAll}
          checked={form.values.selectAll}
          sx={radioButton}
          size="small"
        />
        <label htmlFor="allowAll">{t("permissions_allow_all")}</label>
      </div>
      <div className={styles._permissionsContainer}>
        {permissionsData?.permissions.items.map((elem: any) => (
          <div className={styles._permission} key={elem.id}>
            <p>{t(elem.name.toLowerCase())}</p>

            <GeneralSwitch
              onChange={(e) => {
                const checked = e.target.checked;
                checked
                  ? form.values.permissions.push(elem.id)
                  : form.values.permissions.splice(
                      form.values.permissions.indexOf(elem.id),
                      1
                    );
                form.setFieldValue("permissions", form.values.permissions);
                if (!checked) form.setFieldValue("selectAll", false);
              }}
              checked={form.values.permissions.includes(elem.id)}
            />
          </div>
        ))}
      </div>
      <div className={styles._paginationContainer}>
        <SmallPagination
          page={permissionsData.permissions.pagination.page}
          totalPages={
            permissionsData.permissions.pagination.totalPages != 0
              ? permissionsData.permissions.pagination.totalPages
              : 1
          }
          next={() => {
            refetch({
              page: permissionsData.permissions.pagination.page + 1,
            });
          }}
          previous={() => {
            refetch({
              page: permissionsData.permissions.pagination.page - 1,
            });
          }}
        />
        <GeneralButton
          className={styles._saveButton}
          disabled={!form.isValid}
          action={() => form.handleSubmit()}
          text={t("save")}
          type="submit"
        />
      </div>
    </>
  ) : (
    <div className={styles._circularProgressContainer}>
      <CircularProgress />
    </div>
  );
};

const Members = ({
  form,
  refetchRolesQuery,
  t,
  membersData,
  membersLoading,
  membersRefetch,
  setMembersNum,
}: any) => {
  const [deleteUiRoleMember] = useMutation(DELETE_UI_ROLE_MEMBER);

  const [search, setSearch] = useState("");

  useEffect(() => {
    const timeIntervalDebounce = setTimeout(() => {
      membersRefetch({ search, page: 1 });
    }, 800);
    return () => clearTimeout(timeIntervalDebounce);
  }, [search]);

  return !membersLoading ? (
    <>
      <GeneralInput
        placeholder={t("search_members")}
        value={search}
        onChange={(e: any) => setSearch(e.target.value)}
      />

      <div className={styles._membersContainer}>
        {membersData?.uiRoleMembers?.items?.map((elem: any) => (
          <div className={styles._member} key={elem.id}>
            <div>
              <p>{elem.name}</p>
              <p>{elem.email} </p>
            </div>
            <Icon
              icon="bx:x"
              onClick={() => {
                deleteUiRoleMember({
                  variables: {
                    id: elem.id,
                  },
                  onCompleted: () => {
                    membersRefetch();
                    refetchRolesQuery();
                    setMembersNum((prevValue: number) => prevValue - 1);
                  },
                });
              }}
            />
          </div>
        ))}
      </div>
      <div className={styles._paginationContainer}>
        <SmallPagination />
        <GeneralButton
          className={styles._saveButton}
          disabled={!form.isValid}
          action={() => form.handleSubmit()}
          text={t("save")}
          type="submit"
        />
      </div>
    </>
  ) : (
    <div className={styles._circularProgressContainer}>
      <CircularProgress />
    </div>
  );
};

const renderSection = (
  tab: number,
  form: any,
  fetchAllPermissions: any,
  allPermissionsData: any,
  uiRoleId: string,
  refetchRolesQuery: () => void,
  t: any,
  membersNum: number,
  membersData: any,
  membersLoading: boolean,
  membersRefetch: () => void,
  setMembersNum: any
) => {
  switch (tab) {
    case 0:
      return <Show form={form} t={t} />;
    case 1:
      return (
        <Permissions
          form={form}
          fetchAllPermissions={fetchAllPermissions}
          allPermissionsData={allPermissionsData}
          t={t}
        />
      );
    case 2:
      return (
        <Members
          form={form}
          uiRoleId={uiRoleId}
          refetchRolesQuery={refetchRolesQuery}
          t={t}
          membersNum={membersNum}
          membersLoading={membersLoading}
          membersRefetch={membersRefetch}
          membersData={membersData}
          setMembersNum={setMembersNum}
        />
      );
  }
};

const RolesModal = ({
  onCancel = null,
  open = false,
  currentSpaceId,
  data,
  refetchRolesQuery,
}: RolesModalType) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(0);
  const [membersNum, setMembersNum] = useState(null);
  const { showAlert } = UseAlert();
  const [fetchAllPermissions, { data: allPermissionsData }] =
    useLazyQuery(ALL_PERMISSIONS);
  const [createUiRole] = useMutation(CREATE_UI_ROLE);
  const [updateUiRole] = useMutation(UPDATE_UI_ROLE);
  const {
    data: membersData,
    loading: membersLoading,
    refetch: membersRefetch,
  } = useQuery(ROLE_MEMBERS, {
    variables: {
      spaceId: currentSpaceId,
      uiRoleId: data?.id,
      search: "",
      page: 1,
    },
    onCompleted: (data) => {
      if (membersNum == null) {
        setMembersNum(data.uiRoleMembers.pagination.totalItems);
      }
    },
  });
  useEffect(() => {
    if (!data) setTab(0);
  }, [data]);

  const handleSubmit = (values: any) => {
    if (data) {
      updateUiRole({
        variables: {
          data: {
            title: values.title,
            permissions: values.permissions,
            color: values.color,
            id: data.id,
            spaceId: currentSpaceId,
          },
        },
        onCompleted: (data) => {
          refetchRolesQuery();
          showAlert(t("update_rol_sucess"), "success");
          onCancel();
        },
        onError: () => {
          showAlert(t("something_went_wrong"), "error");
        },
      });
    } else {
      createUiRole({
        variables: {
          data: {
            title: values.title,
            permissions: values.permissions,
            color: values.color,
            spaceId: currentSpaceId,
          },
        },
        onCompleted: (data) => {
          refetchRolesQuery();
          showAlert(t("create_rol_sucess"), "success");
          onCancel();
        },
        onError: () => {
          showAlert(t("something_went_wrong"), "error");
        },
      });
    }
  };

  return (
    <Modal open={open} onClose={onCancel} sx={modal}>
      <Box sx={modalBox}>
        <Formik
          initialValues={{
            title: data?.title ?? "",
            color: data?.color ?? "",
            permissions: data?.permissions
              ? data.permissions.map((elem: any) => elem.permissionId)
              : [],
            selectAll: false,
          }}
          onSubmit={handleSubmit}
          validationSchema={Yup.object({
            title: Yup.string()
              .required("field_required")
              .matches(onlyLettersRegex, "only_alphanumeric")
              .max(255, () => translate("max_invalid", { number: 255 })),
            color: Yup.string().required("field_required"),
            permissions: Yup.array().min(1),
          })}
        >
          {(form) => (
            <Form
              style={{
                position: "relative",
                height: "100%",
              }}
            >
              <div className={styles._iconParent} onClick={onCancel}>
                <Icon icon="zondicons:close" width={18} color="#000" />
              </div>
              <p className={styles._edit}>
                {data ? t("edit") : t("create")} {t("role_minus")}
              </p>
              <p className={styles._newRoleText}>
                {t("roles_modal_edit_subtitle_1")}
                {data
                  ? t("roles_modal_edit_subtitle_2")
                  : t("roles_modal_create_subtitle")}
              </p>

              <GeneralTabs
                labelKey="name"
                data={[
                  { name: t("roles_modal_show_tab") },
                  { name: t("roles_modal_permissions_tab") },

                  data
                    ? {
                        name: `${t("roles_modal_members_tab")}${
                          membersNum != null ? `(${membersNum})` : ""
                        }`,
                      }
                    : null,
                ]}
                value={tab}
                onChange={(_, value) => setTab(value)}
              />

              <div className={styles._bottomContainer}>
                {renderSection(
                  tab,
                  form,
                  fetchAllPermissions,
                  allPermissionsData,
                  data?.id,
                  refetchRolesQuery,
                  t,
                  membersNum,
                  membersData,
                  membersLoading,
                  membersRefetch,
                  setMembersNum
                )}
              </div>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default RolesModal;
