import { gql } from "@apollo/client"

const STATES_QUERY = gql`
query States {
  states {
    id
    name
    code
  }
}
`
export default STATES_QUERY
