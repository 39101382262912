import { parseToSimpleNumber } from 'utils';

export function buildJson(
  onboardingData: any,
  affidavitAccepted: boolean,
  signatureFileUrl: string,
  language: string
): any {
  const { type, basicUserData, accountData, shareholdersData, signatureData } =
    onboardingData;
  //Should be a file when EP is ready
  // const signatureFile = yield uploadFileCall({ payload: 'jj' }); //signatureData.signature;
  // console.log(signatureFile)
  const baseObject = {
    type,
    dniType: basicUserData.dniType,
    dniNumber: basicUserData.dni,
    state: basicUserData.state,
    city: basicUserData.city,
    zipCode: basicUserData.zipCode,
    dniPhotoUrl: basicUserData.dniPhotoUrl.link,
    fiscalAddress: basicUserData.fiscalAddress,
    typeCommerceId: basicUserData.typeCommerceId
      ? basicUserData.typeCommerceId
      : undefined,
    typeCommerceFallback:
      basicUserData.typeCommerceFallback != ''
        ? basicUserData.typeCommerceFallback
        : undefined,
    email: accountData.email,
    password: accountData.password,
    phoneNumber:
      accountData.phoneNumber != '' ? accountData.phoneNumber : undefined,
    cellPhoneNumber: accountData.cellPhoneNumber,
    monthlyIncome: parseFloat(parseToSimpleNumber(accountData.monthlyIncome)),
    signature: signatureFileUrl,
    position: signatureData.position,
    affidavitAccepted,
    requestTwoFactor: accountData.requestTwoFactor,
    language: language,
  };

  if (type == 'natural') {
    return {
      ...baseObject,
      firstName: basicUserData.firstName,
      lastName: basicUserData.lastName,
    };
  }
  const firstName = accountData.legalRepresentative.firstName;
  const lastName = accountData.legalRepresentative.lastName;

  return {
    ...baseObject,
    businessName: basicUserData.businessName,
    legalRepresentative: {
      firstName,
      lastName,
      phoneNumber: accountData.legalRepresentative.phoneNumber,
      cellPhoneNumber: accountData.legalRepresentative.cellPhoneNumber,
      dniNumber: accountData.legalRepresentative.dni,
      dniType: accountData.legalRepresentative.dniType,
      dniPhotoUrl: accountData.legalRepresentative.dniPhotoUrl.link,
      address: accountData.legalRepresentative.address,
    },
    shareholders: shareholdersData.shareholders.map((elem: any) => {
      return { ...elem, dniPhotoName: undefined };
    }),
  };
}
