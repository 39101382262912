import { Theme } from '@mui/material';

const rightDrawerWidth = '23.0625rem';

const drawerStyles = (theme: Theme) => ({
  width: rightDrawerWidth,
  flexShrink: 0,
  zIndex: 1300,
  '& .MuiDrawer-paper': {
    width: rightDrawerWidth,
    padding: '0 1.1875rem 2rem 0.8125rem',
    boxSizing: 'border-box',
    background: theme.palette.background.paper,
  },
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
});

export { drawerStyles };
