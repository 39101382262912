import { gql } from "@apollo/client";

export const RESET_AUTH = gql`
  mutation requestResetAuthenticator($data: RequestResetAuthenticatorInput!) {
    requestResetAuthenticator(data: $data) {
      result
    }
  }
`;

export default RESET_AUTH;
