import React from "react";
import { Icon } from "@iconify/react";
import styles from "./styles.module.scss";

const SmallPagination = ({
  page = 1,
  totalPages = 1,
  next = null,
  previous = null,
}: any) => {
  return (
    <div className={styles._paginationParent}>
      <p className={styles._pagesNumber}>
        {page}/{totalPages}
      </p>
      <div className={styles._iconsParent}>
        <div>
          <Icon
            icon="bx-left-arrow-alt"
            width={24}
            color="#909090"
            onClick={page != 1 ? previous : null}
          />
        </div>
        <div>
          <Icon
            icon="bx:right-arrow-alt"
            width={24}
            color="#909090"
            onClick={page != totalPages ? next : null}
          />
        </div>
      </div>
    </div>
  );
};

export default SmallPagination;
