import React from "react";
import { OnboardingLayout, OnboardingFooter } from "web/components";
import { GeneralButton, GeneralInput } from "components";
import styles from "./styles.module.scss";
import { FormikConfig } from "./formik";
import i18next from "i18next";
import { useMutation } from "@apollo/client";
import { VERIFY_ACCOUNT } from "graphql/mutations";
import { UseAlert } from "hooks";
import { useNavigate } from "react-router-dom";

const VerifyAccount = ({ t }: any) => {
  const navigate = useNavigate();
  const { showAlert } = UseAlert();
  const [verifyAccount, { loading }] = useMutation(VERIFY_ACCOUNT);

  const handleSubmit = (formValues: any) => {
    const { language } = i18next;
    const body = { ...formValues, language };

    verifyAccount({
      variables: { data: body },
      onError: (error: any) => showAlert("email_dont_registered", "error"),
      onCompleted: (response: any) => {
        const {
          resendVerificationEmail: { result },
        } = response;

        if (result) return showAlert("email_sent", "success");
        showAlert("email_dont_registered", "error");
      },
    });
  };

  const {
    values,
    handleSubmit: formikSubmit,
    handleChange,
    submitForm,
    errors,
    touched,
    setTouched,
    isValid,
    dirty,
  } = FormikConfig(handleSubmit);

  const handleOnTouched = (key: string) =>
    setTouched({ ...touched, [key]: true });

  const changeInputStatus = (value: string, error: any) => {
    if (value !== "") {
      if (error) return "error";
      return "success";
    }
    return "inherit";
  };

  return (
    <OnboardingLayout>
      <div className={styles._mainContainer}>
        <form className={styles._form} onSubmit={formikSubmit} id="verify-form">
          <div className={styles._input}>
            <p className={styles._title}>{t("verify_your_puntu_account")}</p>
            <p className={styles._tinyTitle}>{t("enter_email")}</p>
            <div className={styles._inputDualContainer}>
              <div className={styles._inputContainer}>
                <GeneralInput
                  withIcon={false}
                  placeholder={t("email")}
                  id="email"
                  name="email"
                  onChange={handleChange}
                  value={values.email}
                  hasError={errors.email && touched.email ? true : false}
                  onFocus={() => handleOnTouched("email")}
                  errorMessage={errors.email}
                  status={changeInputStatus(
                    values.email,
                    errors.email && touched.email
                  )}
                />
              </div>
            </div>
          </div>

          <div className={styles._bottomContainer}>
            <div className={styles._buttonsContainer}>
              <div className={styles._buttonLeft}>
                <GeneralButton
                  action={() => navigate("/login")}
                  text="return"
                  type="submit"
                  isGoBack={true}
                />
              </div>
              <div className={styles._buttonRight}>
                <GeneralButton
                  action={submitForm}
                  text="continue"
                  type="submit"
                  disabled={!isValid || !dirty}
                  loading={loading}
                />
              </div>
            </div>
            <div className={styles._footer}>
              <OnboardingFooter
                buttonText="continue"
                buttonAction={submitForm}
                showStepper={false}
                showBtn={false}
                message={t("no_account")}
                link={t("create_one")}
                route="/onboarding"
              />
            </div>
          </div>
        </form>
      </div>
    </OnboardingLayout>
  );
};

export default VerifyAccount;
