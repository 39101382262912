import { GeneralButton } from 'components'
import styles from './styles.module.scss'
import { Icon } from '@iconify/react'
import { baseUrl } from 'paths'
import clsx from 'clsx'
import { useMediaQuery, Theme, CircularProgress } from '@mui/material'
import { CreateBankAccount } from 'web/components'
import { UseIntermitence } from "hooks";
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import GET_BANK_ACCOUNTS from 'graphql/queries/bankAccounts'
import { useSelector } from 'react-redux'
import { userSpaceSelector } from 'store/selectors'
import { useDispatch } from 'react-redux'
import { setBankAccountData } from 'store/actions'
import { parseAmount, bankFees } from 'utils'

const BankAccountList = ({ handleStep }: any) => {

  const dispatch = useDispatch()
  const { currentSpace } = useSelector(userSpaceSelector)
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))
  const { switchStatus, status } = UseIntermitence();
  const { t } = useTranslation()

  const { data, loading, refetch } = useQuery(GET_BANK_ACCOUNTS, {
    variables: {
      spaceId: currentSpace?.id
    },
    fetchPolicy: 'cache-and-network'
  });

  const list = data?.bankAccounts?.items || []

  const handleSelectAccount = (item: any) => {
    const data = {
      accountNumber: item?.accountNumber,
      bankAccountId: item?.id,
      title: item?.bank?.title,
      type: item?.routeType,
    }

    dispatch(setBankAccountData(data))
    handleStep(0)
  }

  if (loading) return (
    <div className={styles._loaderParent}>
      <CircularProgress size={24} />
    </div>
  )

  return (
    <div className={styles._main}>
      <div className={styles._header}>
        <p className={styles._bold}>{t('my_accounts')}</p>
        <GeneralButton text={!isMobile ? 'affiliate' : null}
          className={styles._btnCustom}
          width={!isMobile ? '6.6875rem' : '3.25rem'}
          icon={isMobile && <Icon icon='bx:user-plus'
            width={22} color='#E6427A' />}
          action={() => switchStatus()} />
      </div>

      {
        !isMobile && (
            <div className={styles._iconParent} onClick={() => handleStep(0)}>
              <Icon icon='bx:left-arrow-alt' width={22} color='#E6427A' />
            </div>
        )
      }

      <div className={styles._listParent}>
        {
          list && list.length ?
            list.map((item: any, index: number) => (
              <div className={styles._card} key={index} onClick={() => handleSelectAccount(item)} >
                <div className={styles._rightSide}>
                  {item?.bank?.icon
                    ? <img src={`${baseUrl}/resources/bofa.svg`} alt='bofa' className={styles._logo} />
                    : <div className={styles._defaultIcon}>
                      <Icon icon='bxs-bank'></Icon>
                    </div>
                  }
                  <div>
                    <p className={styles._title}>{item?.bank?.title}</p>
                    <p className={styles._subTitle}>{t('account_affiliated')} {item?.bank?.title}</p>
                  </div>
                </div>
                <div className={styles._leftSide}>
                  <p className={clsx(styles._leftText, styles._bold)}>{t('transfer')} {item?.routeType}</p>
                  <p className={styles._leftText}>{t('fee')} ${parseAmount(bankFees[item?.routeType])}</p>
                </div>
              </div>
            )) : <div className={styles._emptyStateParent} >
              <div className={styles._iconParentEmpty}>
                <Icon icon='bx:x-circle' width={32}></Icon>
              </div>
              <div className={styles._textParent}>
                <p>{t('empty_bank_accounts')}</p>
              </div>
            </div>
        }
      </div>

      <CreateBankAccount
        open={status}
        onClose={switchStatus}
        refetch={refetch}
      />

    </div>
  )
}

export default BankAccountList
