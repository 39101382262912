import clsx from "clsx";
import styled from "styled-components";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";

const GeneralButton = ({
  text,
  action = null,
  type,
  className,
  disabled,
  formId = "",
  loading = false,
  icon,
  children,
  isGoBack = false,
}: any) => {
  const { t } = useTranslation();
  return (
    <button
      className={clsx(isGoBack ? styles._btnGoBack : styles._btn, className)}
      type={type ?? "button"}
      form={formId}
      onClick={action && !loading ? action : null}
      disabled={disabled}
    >
      {icon && icon}
      {!loading
        ? t(text)
        : !children && <CircularProgress color="inherit" size={22} />}
      {children}
    </button>
  );
};

export default styled(GeneralButton)`
  width: ${({ width }) => (width ? width : "")};
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "")};
`;
