import AccordionSummary from '@mui/material/AccordionSummary';
import styled from '@emotion/styled';
import { Theme } from '@mui/material';

const divider = (theme: Theme) => ({
  width: '100%;',
  border: 'none',
  height: '0.125rem',
  margin: '1.5625rem 0',
  backgroundColor: theme.palette.primary.main,
  [theme.breakpoints.down('md')]: {
    margin: '0.781rem 0',
  },
});

const accordion = (theme: Theme) => ({
  marginBottom: '0 !important',
  backgroundColor: '#E5E5E5',
  '&.MuiAccordion-root, & .Mui-expanded': {
    marginTop: '0!important',
  },
  '&.MuiAccordion-root:before': {},
  [theme.breakpoints.down('md')]: {
    left: 0,

    marginTop: '0 !important',
    '& .Mui-expanded': {
      marginTop: '20px !important',
    },
  },
});

export { divider, accordion };
export const AccordionItemStyled = styled(AccordionSummary)``;
