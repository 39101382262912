import { gql } from "@apollo/client";

export const DISABLED_2FA = gql`
  mutation DisableAuthenticator($data: DisableAuthenticatorInput!) {
    disableAuthenticator(data: $data) {
      result
    }
  }
`;

export default DISABLED_2FA;
