import styles from './styles.module.scss'
import { GeneralDropdownType } from './type'
import { useTranslation } from 'react-i18next';

const GeneralDropdown = ({
  options,
  show,
  method
}: GeneralDropdownType) => {

  const { t } = useTranslation();

  return (
    <div className={show ? styles._dropStatic : styles._dropHidden}>
      {options.map((item: any, index: string) => (
        <div key={index}
        className={show ? styles._dropTitleStatic : styles._dropTitleHidden}
        onClick={() => {method(item.value)}}>
          <p> {t(item.label)} </p>
        </div>
      ))}
    </div>
  )
};

export default GeneralDropdown
