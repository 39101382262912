import { gql } from '@apollo/client';

const PROFILE_QUERY = gql`
  query Profile {
    customer {
      id
      fiscalAddress
      signature
      signatureUrl
      dateOfCreation
      type
      monthlyIncome
      typeCommerce {
        esName
        enName
        icon
      }
      typeCommerceFallback
      user {
        email
        profile {
          firstName # Only natural users
          lastName # Only natutal users
          dniType
          dniNumber
          cellPhoneNumber
          phoneNumber
        }
      }
      legalRepresentative {
        businessName
        dni
        profile {
          firstName
          lastName
          phoneNumber
          cellPhoneNumber
        }
      }
      shareholders {
        email
        dni
        profile {
          firstName
          lastName
          bornDate
          cellPhoneNumber
        }
      }
    }
  }
`;

export default PROFILE_QUERY;
