import { Icon } from "@iconify/react";
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { baseUrl } from "paths";
import { Location } from "react-router-dom";
import {
  divider,
  drawerStyles,
  listItemIcon,
  textItemButton,
  textStyle,
} from "./materialStyles";
import styles from "./styles.module.scss";
import { logout } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GeneralConfirmModal, LangButton } from "../../../../components";
import { ClaimPayment } from "../../../components";
import { UseIntermitence } from "hooks";
import { menuOptions, routesTypeAndPermissions } from "utils";
import { userAuthSelector } from "store/selectors";

import { CLAIM_PAYMENT } from "utils/permissions";

const DashboardDrawer = ({ location }: { location: Location }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const userData = useSelector(userAuthSelector);
  const userType = userData.userAuthData?.customer?.type;
  const userPermissions = userData.permissions;
  
  const logoutUser = () => {
    dispatch(logout());
    navigate("/login");
  };

  const navigateTo = (route: string) => navigate(route);

  const { status, switchStatus } = UseIntermitence();
  const { status: claimModalStatus, switchStatus: switchClaimModalStatus } =
    UseIntermitence();

  return (
    <>
      <Drawer
        sx={drawerStyles}
        variant="permanent"
        anchor="left"
        className={styles._scrollBar}
      >
        <div className={styles._imageContainer}>
          <img
            src={`${baseUrl}/resources/puntu-logo.png`}
            alt="onboarding-img"
            width="140px"
            className={styles._logo}
            onClick={() => navigate("/dashboard")}
          />
        </div>
        <List
          disablePadding
          sx={{
            mt: "2.5rem",
          }}
          className={styles._scrollBar}
        >
          {menuOptions.map((option, index) =>
            (userType &&
              routesTypeAndPermissions[option.route].type.includes(userType)) ||
            userPermissions?.find(
              (elem: string) =>
                elem === routesTypeAndPermissions[option.route].permission
            ) ? (
              <ListItem
                key={t(option.label)}
                disablePadding
                onClick={() => navigateTo(option?.route)}
              >
                <ListItemButton disableGutters sx={textItemButton}>
                  <ListItemIcon sx={listItemIcon}>
                    <Icon
                      icon={option.icon}
                      className={styles._icon}
                      color={
                        location.pathname.startsWith(option.route)
                          ? "#E6427A"
                          : "000000"
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={t(option.label)}
                    sx={(theme) =>
                      textStyle(
                        theme,
                        location.pathname.startsWith(option.route)
                      )
                    }
                  />
                </ListItemButton>
              </ListItem>
            ) : null
          )}
        </List>
        <Divider sx={divider} />
        {(!!userType ||
          userPermissions?.find((elem: string) => elem === CLAIM_PAYMENT)) && (
            <ListItem disablePadding>
              <ListItemButton
                disableGutters
                sx={textItemButton}
                onClick={switchClaimModalStatus}
              >
                <ListItemIcon sx={listItemIcon}>
                  <Icon
                    icon="bxs:alarm-exclamation"
                    className={styles._icon}
                    color={
                      /*  location.pathname.startsWith(option.route)
              ? "#E6427A"
              : */ "000000"
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  primary={t("claim_your_payment")}
                  sx={(theme) => textStyle(theme, false)}
                />
              </ListItemButton>
            </ListItem>
          )}
        <div style={{ flexGrow: 1 }} />

        <ListItem>
          <LangButton />
        </ListItem>
        <ListItem disablePadding sx={{ marginTop: "0rem" }}>
          <ListItemButton
            disableGutters
            sx={textItemButton}
            onClick={switchStatus}
          >
            <ListItemIcon sx={listItemIcon}>
              <Icon
                icon="bx:log-out-circle"
                className={styles._icon}
                color={
                  /*  location.pathname.startsWith(option.route)
              ? "#E6427A"
              : */ "000000"
                }
              />
            </ListItemIcon>

            <ListItemText
              primary={t("log_out")}
              sx={(theme) => textStyle(theme, false)}
            />
          </ListItemButton>
        </ListItem>
        <p className={styles._copyright}>{t("copyright")}</p>
      </Drawer>
      <GeneralConfirmModal
        title="are_you_sure_logout"
        open={status}
        onConfirm={logoutUser}
        onCancel={switchStatus}
        btnText={"logout_button"}
      />

      <ClaimPayment
        open={claimModalStatus}
        onConfirm={logoutUser}
        onCancel={switchClaimModalStatus}
        btnText={"claim"}
      />
    </>
  );
};

export default DashboardDrawer;
