import { gql } from '@apollo/client';

const CREATE_BANK_ACCOUNT = gql`
    mutation createBankAccount($data: CreateBankAccountInput!) {
      createBankAccount(data: $data) {
        id
        bankId
        bank{
          title
          id
          icon
        }
        accountNumber
        accountHolder
        address
        state
        city
        zipCode
        routeType
        routeNumber
        spaceId
    }
  }
`;
export default CREATE_BANK_ACCOUNT;

