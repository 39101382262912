import { baseUrl } from "paths";
import styles from "./styles.module.scss";

const goTo = (url: string) => window.open(url);
const ChooseApp = () => {
  return (
    <div className={styles._container}>
      <img
        src={`${baseUrl}/resources/2FAScreens/ChooseApp/Apple.svg`}
        alt="Apple logo"
        onClick={() => goTo("https://apps.apple.com/es/app/authy/id494168017")}
      />
      <img
        src={`${baseUrl}/resources/2FAScreens/ChooseApp/Authy.svg`}
        alt="Authy logo"
        onClick={() => goTo("https://authy.com/")}
      />
      <img
        src={`${baseUrl}/resources/2FAScreens/ChooseApp/Android.svg`}
        alt="Android logo"
        onClick={() =>
          goTo(
            "https://play.google.com/store/apps/details?id=com.authy.authy"
          )
        }
      />
    </div>
  );
};

export default ChooseApp;
