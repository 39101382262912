import { gql } from '@apollo/client';

const  USERS_FOR_BRANCH_OFFICE = gql`
query usersForBranchOffice(
  $page: Int
  $perPage: Int
  $search: String
  $orderDirection: OrderDirectionEnum
  $orderBy: UsersForBranchOfficeOrderByEnum
  $spaceId:ID!
  $branchOfficeId: ID!
) {usersForBranchOffice(
    page: $page
    perPage: $perPage
    search: $search
    orderDirection: $orderDirection
    orderBy: $orderBy
    spaceId: $spaceId
    branchOfficeId: $branchOfficeId
)
  {
    items {
      id
      name
      email
      role
      createdAt
      updatedAt
    }
    pagination {
      page
      perPage
      totalItems
      totalPages
    }
  }
}
`;

export default USERS_FOR_BRANCH_OFFICE;
