import { Theme } from '@mui/material';
import { drawerWidth } from 'utils';

const appBarStyles = (theme: Theme) => ({
  backgroundColor: theme.palette.background.default,
  width: `calc(100% - ${drawerWidth})`,
  ml: drawerWidth,
  zIndex: 1300,
  [theme.breakpoints.down('lg')]: {
    backgroundColor: theme.palette.whiteColorCustom?.light,
    width: '100%',
    ml: 0,
    minHeight: '3rem',
    '& > .MuiToolbar-root': {
      minHeight: '3rem',
    },
  },
});

export { appBarStyles };
