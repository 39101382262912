import { Theme } from '@mui/material';
import { drawerWidth } from 'utils';

const drawerStyles = (theme: Theme) => ({
  width: drawerWidth,
  flexShrink: 0,
  zIndex: 1308,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
    background: theme.palette.background.paper,
  },
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
});

const appBarStyles = (theme: Theme) => ({
  backgroundColor: theme.palette.background.default,
  width: `calc(100% - ${drawerWidth})`,
  ml: drawerWidth,
});

const textStyle = (theme: Theme, active: boolean) => ({
  margin: 0,
  '& .MuiTypography-root': {
    fontSize: '14px',
    // lineHeight: '1.27625rem',

    color: active
      ? theme.palette.primary.main
      : theme.palette.blackColorCustom?.main,
  },
});

const divider = (theme: Theme) => ({
  width: '10.71875rem',
  border: 'none',
  height: '0.5px',
  margin: '1.5625rem auto',
  backgroundColor: theme.palette.primary.main,
});

const textItemButton = { padding: '0.8125rem 1.6875rem 0.8125rem 2.1875rem' };
const listItemIcon = { minWidth: '2.375rem' };

const tabsParent = (maxWidth: any) => ({
  width: '100%',
  maxWidth: maxWidth || '100%',
  marginBottom: '1.6875rem',
});

const tabsBorder = {
  borderBottom: 1,
  borderColor: '#C4C4C4',
};

const tab = (theme: Theme) => ({
  textTransform: 'capitalize',
  fontFamily: theme.typography,
  fontSize: '0.875rem',
  fontWeight: 400,
  color: theme.palette.blackColorCustom?.main,
  maxWidth: '10ch',
});

export {
  drawerStyles,
  appBarStyles,
  textStyle,
  textItemButton,
  listItemIcon,
  divider,
  tabsParent,
  tab,
  tabsBorder,
};
