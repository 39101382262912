import { gql } from "@apollo/client"

const BANKS_QUERY = gql`
query Banks {
  banks {
    id
    title
    icon
    aba
  }
}
`
export default BANKS_QUERY
