import styles from "./styles.module.scss";
import clsx from "clsx";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const GeneralInputDate = ({
  className,
  placeholder = "",
  onChange,
  value,
  disabled,
  id,
  name,
  inputClasses,
  hasError,
  errorMessage,
  status,
  onFocus,
  type = "date",
  ...rest
}: any) => {
  const { t } = useTranslation();
  const statusManagment = (type: any = "inherit"): string => {
    if (type == "inherit") return styles._input;
    if (type == "success") return styles._input;
    if (type == "error") return styles._inputError;
  };

  return (
    <div className={clsx(styles._inputContainer, className)}>
      <input
        className={clsx(statusManagment(status), inputClasses)}
        type="text"
        value={value}
        onChange={onChange}
        placeholder={t(placeholder)}
        id={id}
        name={name}
        disabled={disabled}
        onFocus={(e) => {
          e.target.type = type;
          onFocus(e);
        }}
        {...rest}
      />
      {hasError && errorMessage && (
        <p className={styles._textError}>{t(errorMessage)}</p>
      )}
    </div>
  );
};

export default styled(GeneralInputDate)``;
