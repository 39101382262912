import { Theme } from '@mui/material';

const selectStyles = (theme: Theme, innerPaddingRight?: string) => ({
  borderRadius: '1.25rem',
  width: '100%',
  height: '2.5rem',
  padding: '0.1px 0rem 0 0rem',
  fontSize: '0.875rem',
  '& > div:first-of-type': {
    paddingRight: innerPaddingRight ? innerPaddingRight : '',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: `solid 0.031rem ${theme.palette.blackColorCustom.main}`,
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: '0.031rem',
    border: `solid 0.031rem ${theme.palette.blackColorCustom.main}`,
  },
  '&:hover': {
    '& .MuiOutlinedInput-notchedOutline': {
      border: `solid 0.031rem ${theme.palette.blackColorCustom.main}`,
    },
  },
  '@media (max-width: 768px)': {
    '& .MuiOutlinedInput-notchedOutline': {
      border: `solid 0.063rem ${theme.palette.blackColorCustom.main}`,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `solid 0.063rem ${theme.palette.blackColorCustom.main}`,
    },
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: `solid 0.063rem ${theme.palette.blackColorCustom.main}`,
      },
    },
  },
});

const disabledColor = (theme: Theme) => ({
  color: theme.palette.grayColorCustom?.main,
});

const menuItemStyles = (theme: Theme) => ({
  fontSize: '0.875rem',
  borderRadius: '1.09375rem',
});

export { selectStyles, disabledColor, menuItemStyles };
