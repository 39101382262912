import { memo } from "react";
import styles from "./styles.module.scss";
import { InputType } from "./types";
import styled from "styled-components";
import clsx from "clsx";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";

const GeneralInput = ({
  placeholder,
  type,
  icon,
  withIcon,
  iconMethod,
  iconColor,
  className,
  inputClasses,
  value = "",
  onChange,
  onFocus,
  onBlur,
  id,
  name,
  disabled,
  hasError = false,
  errorMessage,
  status = "inherit",
  iconClass,
}: InputType) => {
  const { t } = useTranslation();

  const statusManagment = (type: any = "inherit"): string => {
    if (type == "inherit") return styles._input;
    if (type == "success") return styles._input;
    if (type == "error") return styles._inputError;
  };

  return (
    <div className={clsx(styles._inputContainer, className)}>
      <div style={{ width: "100%", position: "relative" }}>
        <input
          className={clsx(
            statusManagment(status),
            withIcon ? styles._rightIconPadding : ""
          )}
          type={type}
          placeholder={t(placeholder)}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          id={id}
          name={name}
          disabled={disabled}
          autoComplete={type == "password" ? "on" : "off"}
        />
        {withIcon ? (
          <div
            className={clsx(
              iconMethod ? styles._iconPointer : styles._iconNoPointer,
              iconClass
            )}
            onClick={iconMethod && iconMethod}
          >
            <Icon
              color={iconColor}
              icon={icon}
              className={clsx(styles._icon)}
            />
          </div>
        ) : null}
      </div>
      {hasError && errorMessage && (
        <p className={styles._textError}>{t(errorMessage)}</p>
      )}
    </div>
  );
};

export default styled(memo(GeneralInput))`
  width: ${({ inputWidth }) => (inputWidth ? inputWidth : "")};
`;
