import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { CURRENCIES_QUERY } from 'graphql/queries'
import { useDispatch } from 'react-redux'
import { setCurrencies } from 'store/actions'

export const UseCurrencies = (key: string) => {
  const dispatch = useDispatch()
  const [state, setState] = useState([])
  const  { data } = useQuery(CURRENCIES_QUERY)

  useEffect(() => {
    if (data) {
      dispatch(setCurrencies({ [key]: data?.currencies }))
      setState(data?.currencies)

    }
  }, [data])

  return state
}
