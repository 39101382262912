import { useState, useRef, useEffect } from "react";
import { naturalIdentification, legalIdentification } from "utils";
import { FormikConfig } from "./formik";
import { normalizeBasicUserData } from "utils";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "store/actions";
import { useNavigate } from "react-router-dom";
import { onboardingSelector } from "store/selectors";
import { UseAlert } from "hooks";
import {
  GeneralInput,
  GeneralUploadFile,
  BackArrow,
  GeneralSelect
} from "../../../../../components";
import { OnboardingFooter } from "../../../../components";
import clsx from "clsx";
import styles from "./styles.module.scss";
import { useQuery } from "@apollo/client";
import { COMMERCER_TYPE_QUERY, STATES_QUERY } from "graphql/queries";
import CircularProgress from "@mui/material/CircularProgress";
import { VERYFY_DNI } from "graphql/mutations";
import { useMutation } from "@apollo/client";
import i18next from 'i18next'

const BasicUserData = ({ t }: any) => {

  const onboarding = useSelector(onboardingSelector);
  const { type, basicUserData } = onboarding;
  const [document, setDocument] = useState(basicUserData?.dniPhotoUrl ?? "");
  const [toggleId, setToggleId] = useState(undefined || basicUserData.typeCommerceId);
  const ref: any = useRef(null);
  const stateRef: any = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showAlert } = UseAlert();
  const { loading, data } = useQuery(COMMERCER_TYPE_QUERY);
  const { data: dataStates } = useQuery(STATES_QUERY);
  const [verify_dni] = useMutation(VERYFY_DNI);

  const setFormValues = () => {
    for (let key in basicUserData) setFieldValue(key, basicUserData[key]);
  };

  const handleOnTouched = (key: string) =>
    setTouched({ ...touched, [key]: true });

  useEffect(() => {
    setFormValues();
  }, []);

  const onHandlerDocument = (document: any) => setDocument(document);

  const handleRemoveDocument = () => {
    const localUserData = { ...basicUserData };

    if (localUserData) localUserData.dniPhotoUrl = "";
    setDocument("");
    if (basicUserData?.dniPhotoUrl)
      dispatch(setUserData({ ...onboarding, basicUserData: localUserData }));
  };

  const handleSubmit = (formValues: any) => {
    const additionalData: any = {
      document,
    };

    const allFormData = { ...formValues, ...additionalData };
    const basicUserData = normalizeBasicUserData(allFormData);

    if (!document) return showAlert("must_attach_document", "error");
    dispatch(
      setUserData({ basicUserData: { completed: true, ...basicUserData } })
    );
    navigate("/onboarding/account-data");
  };

  const validateDni = () => {
    verify_dni({
      variables: { dniNumber: values.dniNumber },
      onError: (error: any) => {
        showAlert(error?.message, 'error')
      },
      onCompleted: (response: any) => {
        const { verifyDNI: { result } } = response

        if (result) {
          return showAlert('dni_already_exist', 'error')
        }
        return submitForm()
      }
    })
  }

  const {
    values,
    handleSubmit: formikSubmit,
    handleChange,
    submitForm,
    errors,
    touched,
    setTouched,
    setFieldValue,
    dirty,
    isValid,
  } = FormikConfig(
    handleSubmit,
    type == "natural" ? true : false,
    basicUserData
  );


  const onHandlerSelect = (value: any) => { setFieldValue("dniType", value) }
  const onHandlerStateSelect = (value: any) => { setFieldValue("state", value) }

  const setType = (id: any) => {
    if (id !== values.typeCommerceId) {
      values.typeCommerceFallback = "";
      setToggleId(id);
      return setFieldValue("typeCommerceId", id);
    }
    setToggleId(undefined);
    setFieldValue("typeCommerceId", undefined);
  };

  const changeInputStatus = (value: string, error: any) => {
    if (value !== "") {
      if (error) return "error";
      return "success";
    }
    return "inherit";
  };

  const buildStatesArray = (states: Array<any>) => {
    const defaultStates: any = { label: t("state"), value: '' }
    const localStates: Array<any> = states?.map((item: any) => {
      return { label: item?.name, value: item?.code }
    })

    localStates?.unshift(defaultStates)
    return localStates || []
  }

  return (
    <div className={styles._mainContainer}>
      <div className={styles._arrowContainer}>
        <BackArrow action route="/onboarding" />
      </div>
      <div className={styles._content}>
        <form className={styles._form} onSubmit={formikSubmit} id="my-form">
          <div>
            <h3 className={styles._title}>{t("required_information")}</h3>
            <p className={styles._subtitle}>{t("fill_form")}</p>

            <p className={styles._tinyTitle}>
              {type == "natural" ? t("name_lastname") : t("business_name")}
            </p>

            {type == "natural" ? (
              <div className={styles._inputDualContainer}>
                <div className={styles._contentInput}>
                  <GeneralInput
                    withIcon={false}
                    placeholder={"name"}
                    id="firstName"
                    name="firstName"
                    onChange={handleChange}
                    value={values.firstName}
                    hasError={
                      errors.firstName && touched.firstName ? true : false
                    }
                    onFocus={() => handleOnTouched("firstName")}
                    errorMessage={errors.firstName}
                    status={changeInputStatus(
                      values.firstName,
                      errors.firstName && touched.firstName
                    )}
                  />
                </div>

                <div className={styles._contentInput}>
                  <GeneralInput
                    withIcon={false}
                    placeholder={"last_name"}
                    id="lastName"
                    name="lastName"
                    onChange={handleChange}
                    value={values.lastName}
                    hasError={
                      errors.lastName && touched.lastName ? true : false
                    }
                    onFocus={() => handleOnTouched("lastName")}
                    errorMessage={errors.lastName}
                    status={changeInputStatus(
                      values.lastName,
                      errors.lastName && touched.lastName
                    )}
                  />
                </div>
              </div>
            ) : (
              <div className={styles._inputContainer}>
                <GeneralInput
                  withIcon={false}
                  placeholder={"business_name"}
                  onChange={handleChange}
                  value={values.businessName}
                  id="businessName"
                  name="businessName"
                  hasError={
                    errors.businessName && touched.businessName ? true : false
                  }
                  onFocus={() => handleOnTouched("businessName")}
                  errorMessage={errors.businessName}
                  status={changeInputStatus(
                    values.businessName,
                    errors.businessName && touched.businessName
                  )}
                />
              </div>
            )}

            <div className={styles._inputContainer}>
              <GeneralUploadFile
                selectData={type == 'natural' ? naturalIdentification : legalIdentification}
                selectValue={values.dniType}
                nameValue={document?.name}
                linkValue={document?.link}
                placeholder={type == 'natural' ? t("id_or_passport") : 'EIN'}
                methodSelect={(event: any) => {
                  onHandlerSelect(event.target.value);
                }}
                inputValue={values.dniNumber}
                methodInput={handleChange}
                methodFile={(value: any) => onHandlerDocument(value)}
                methodIcon={() => handleRemoveDocument()}
                hasError={errors.dniNumber && touched.dniNumber ? true : false}
                onFocus={() => handleOnTouched("dniNumber")}
                reference={ref}
                validateMessage={errors.dniNumber}
                id="dniNumber"
                name="dniNumber"
                inputStatus={changeInputStatus(
                  values.dniNumber,
                  errors.dniNumber && touched.dniNumber
                )}
              />
            </div>

            <div className={styles._mt}>
              <GeneralInput
                withIcon={false}
                placeholder={type == "juridic" ? 'business_address' : 'natural_address'}
                onChange={handleChange}
                value={values.fiscalAddress}
                id="fiscalAddress"
                name="fiscalAddress"
                hasError={
                  errors.fiscalAddress && touched.fiscalAddress ? true : false
                }
                onFocus={() => handleOnTouched("fiscalAddress")}
                errorMessage={errors.fiscalAddress}
                status={changeInputStatus(
                  values.fiscalAddress,
                  errors.fiscalAddress && touched.fiscalAddress
                )}
              />
            </div>
            <div className={styles._mt}>
              {type == "juridic" ? (
                <div className={styles._tripleContainer}>
                  <div className={styles._contentTripleInput}>
                    <GeneralSelect
                      value={values?.state ?? dataStates?.states?.[0]?.label}
                      onChange={(event: any) => {
                        onHandlerStateSelect(event?.target?.value);
                      }}
                      options={buildStatesArray(dataStates?.states)}
                      reference={stateRef} />
                  </div>
                  <div className={styles._contentTripleInput}>
                    <GeneralInput
                      withIcon={false}
                      placeholder={"city"}
                      onChange={handleChange}
                      value={values.city}
                      id="city"
                      name="city"
                      hasError={
                        errors.city && touched.city ? true : false
                      }
                      onFocus={() => handleOnTouched("city")}
                      errorMessage={errors.city}
                      status={changeInputStatus(
                        values.city,
                        errors.city && touched.city
                      )}
                    />
                  </div>
                  <div className={styles._contentTripleInput}>
                    <GeneralInput
                      withIcon={false}
                      placeholder={"zip_code"}
                      onChange={handleChange}
                      value={values.zipCode}
                      id="zipCode"
                      name="zipCode"
                      hasError={
                        errors.zipCode && touched.zipCode ? true : false
                      }
                      onFocus={() => handleOnTouched("zipCode")}
                      errorMessage={errors.zipCode}
                      status={changeInputStatus(
                        values.zipCode,
                        errors.zipCode && touched.zipCode
                      )}
                    />
                  </div>
                </div>
              ) : null
              }
            </div>
          </div>
          <div className={clsx(styles._content, styles._mb)}>
            <p className={styles._tinyTitle}>{t("type_businnes")}</p>
            {loading ? (
              <div className={styles._loaderBox}>
                <CircularProgress size={60} />
              </div>
            ) : (
              <div id="typeCommerceId" className={styles._toggleContainer}>
                {(data?.typeCommerces || []).map((item: any, index: any) => {
                  return (
                    <div
                      className={
                        item.id == toggleId && toggleId !== ""
                          ? styles._selectedToggle
                          : styles._toggle
                      }
                      key={item.id}
                      onClick={() => {
                        setType(item.id);
                      }}
                    >
                      <Icon
                        color={
                          item.id == toggleId && toggleId !== ""
                            ? "#E6427A"
                            : "#555555"
                        }
                        className={styles._icon}
                        icon={`${item?.icon}`}
                      />
                      <p
                        className={
                          item.id == toggleId && toggleId !== ""
                            ? styles._selectedCategorie
                            : styles._categorie
                        }
                      >
                        {i18next.language == 'es' ? item?.esName : item?.enName}
                      </p>
                    </div>
                  );
                })}
              </div>
            )}
            <p className={styles._advice}>{t("economic_activity")}</p>
            <div className={styles._mb}>
              <GeneralInput
                disabled={toggleId !== undefined || '' ? true : false}
                withIcon={false}
                placeholder={"write_type_businnes"}
                onChange={handleChange}
                value={values.typeCommerceFallback}
                id="typeCommerceFallback"
                name="typeCommerceFallback"
                hasError={
                  errors.typeCommerceFallback && touched.typeCommerceFallback
                    ? true
                    : false
                }
                onFocus={() => handleOnTouched("typeCommerceFallback")}
                errorMessage={errors.typeCommerceFallback}
                status={changeInputStatus(
                  values.typeCommerceFallback,
                  errors.typeCommerceFallback && touched.typeCommerceFallback
                )}
              />
            </div>
            { }
          </div>
          <OnboardingFooter
            buttonDisabled={
              basicUserData.completed ? !isValid : !isValid || !dirty
            }
            buttonText="continue"
            buttonAction={validateDni}
            step={1}
            maxSteps={onboarding?.type == "natural" ? 4 : 5}
          />
        </form>
      </div>
    </div>
  );
};

export default BasicUserData;
