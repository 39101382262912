import { Icon } from "@iconify/react";
import styles from "./styles.module.scss";

const EmptyState = ({
  searching,
  emptyRender,
  emptySearchRender,
}: {
  searching?: boolean;
  emptyRender?: React.ReactElement;
  emptySearchRender?: React.ReactElement;
}) => {
  return (
    <div className={styles._emptyStateContainer}>
      <div>
        <Icon icon="bx:x-circle" />
      </div>
      {searching ? emptySearchRender : emptyRender}
    </div>
  );
};
export default EmptyState;
