import { SET_BANK_ACCOUNT_DATA, RESET_BANK_ACCOUNT_DATA } from './action-types';

const initialState: any = {
  title: null,
  description: null,
  memo: null,
  bankAccountId: null,
  amount: null,
  type: null
};

const bankAccount = (state = initialState, { type, payload }: any) => {
  switch (type) {
    case SET_BANK_ACCOUNT_DATA:
      return { ...state, ...payload };

    case RESET_BANK_ACCOUNT_DATA:
      return { ...initialState }

    default:
      return state;
  }
};

export default bankAccount;
