import React, { useCallback, useState } from "react";
import { BackArrow, GeneralCheckbox } from "../../../../../components";
import styles from "./styles.module.scss";
import { OnboardingFooter } from "../../../../components";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "store/actions";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { onboardingSelector } from "store/selectors";
import { loadFile } from "store/file/action";
import { dataURLtoFile } from "utils";
import { buildJson } from "store/onboarding/funtions";
import { REGISTER_ONBOARDING } from "graphql/mutations";
import { set2FAEmail } from "store/twoFA/action";
import i18next from 'i18next';

const Agreements = ({ t }: any) => {
  const [affidavitAccepted, setAffidavitAccepted] = useState(false);
  const dispatch = useDispatch();
  const onboarding = useSelector(onboardingSelector);
  const navigator = useNavigate();
  const [sendMutation, { loading }] = useMutation(REGISTER_ONBOARDING, {
    onCompleted: (data) => {
      if (onboarding.accountData.requestTwoFactor) {
        dispatch(set2FAEmail(data.customerOnboarding.user.email));
      }
      navigator("/all-set");
    },
    onError: (error: any) => {
      dispatch(setAlert(2, "error", "general_error"));
    },
  });

  const handleSubmit = useCallback((affidavitAccepted: boolean) => {
    if (!onboarding.type) {
      dispatch(setAlert(2, "error", "type_not_selected"));
      return;
    }
    const keys = Object.keys(onboarding);
    keys.splice(keys.indexOf("type"));
    for (const key of keys) {
      if (!onboarding[key].completed) {
        dispatch(setAlert(2, "error", "steps_incomplete"));
        return;
      }
    }

    if (onboarding.signatureData.type != "attach") {
      return dispatch(
        loadFile(
          dataURLtoFile(onboarding.signatureData.signature, "signature"),
          "Customers",
          ({ ok, values }) => {
            if (ok) {
              sendMutation({
                variables: {
                  data: buildJson(onboarding, affidavitAccepted, values.link, i18next.language),
                },
              });
            }
          }
        )
      );
    }
    sendMutation({
      variables: {
        data: buildJson(
          onboarding,
          affidavitAccepted,
          onboarding.signatureData.signature,
          i18next.language
        ),
      },
    });
  }, []);
  return (
    <div className={styles._mainContainer}>
      <div className={styles._arrowContainer}>
        <BackArrow route="/onboarding/signature" />
      </div>
      <div className={styles._content}>
        <h3 className={styles._title}>{t("required_information")}</h3>

        <p className={styles._sectionTitle}>
          {t("terms_and_conditions")} <br /> <br />
          <b>{t("first_clause")}</b> <br />
          <br />
          {t("purpose_of_contract")}
        </p>
        <a href="http" className={styles._link}>
          {t("service_contract")}
        </a>
        <a href="http" className={styles._link}>
          {t("privacy_politics")}
        </a>
        {/* <a href="http" className={styles._link}>
          {t("legal_representative")}
        </a> */}
        <GeneralCheckbox
          label="i_agree"
          checked={affidavitAccepted}
          onChange={() => setAffidavitAccepted((prevValue) => !prevValue)}
          className={styles._checkboxMargin}
        />
        <p className={clsx(styles._subtitle, styles._bottomMargin)}>
          {t("agree_contract")}
        </p>

        <OnboardingFooter
          buttonDisabled={!affidavitAccepted}
          buttonAction={() => handleSubmit(affidavitAccepted)}
          buttonText="finalize"
          step={onboarding?.type == "natural" ? 4 : 5}
          maxSteps={onboarding?.type == "natural" ? 4 : 5}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default Agreements;
