import { useFormik } from 'formik';
import * as Yup from 'yup';
import { passwordRegex, translate } from 'utils';

export const FormikConfig = (handleSubmit: any) =>
  useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
      showOtp: false,
      otp: '',
    },

    validationSchema: Yup.object({
      password: Yup.string()
        .required('field_required')
        .matches(passwordRegex, 'field_invalid'),

      confirmPassword: Yup.string()
        .required('field_required')
        .matches(passwordRegex, 'field_invalid')
        .oneOf([Yup.ref('password'), null], 'password_must_match'),
      otp: Yup.string().when('showOtp', {
        is: true,
        then: Yup.string()
          .required('field_required')
          .min(6, () => translate('min_invalid', { number: 6 })),
      }),
    }),

    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
