import React, { memo } from 'react'
import styled from 'styled-components'
import styles from './styles.module.scss'
import { TooltipType } from 'types'
import { styledComponentsBreakpoints } from 'utils'
import { useTranslation } from 'react-i18next';

const Parent: any = styled.div`
  top: ${({ top }: any) => (top ? top : '')};
  left: ${({ left }: any) => (left ? left : '')};
  bottom: ${({ bottom }: any) => (bottom ? bottom : '')};
  right: ${({ right }: any) => (right ? right : '')};

  @media ${styledComponentsBreakpoints.lg} {
    left: 15%;
  }
`
const StyledTooltip: any = styled.div`
  width: ${({ width }: any) => (width ? width : '16.75rem')};
`
const Tooltip = ({ text = '', width, top, left, right, bottom }: TooltipType) =>{
  const { t } = useTranslation()
  return(
  <Parent className={styles._parent} top={top} left={left} right={right} bottom={bottom}>
    <StyledTooltip className={styles._main} width={width} >
      <p>{t(text)}</p>
      <div className={styles._square}></div>
    </StyledTooltip>
  </Parent>
)
}


export default memo(Tooltip)
