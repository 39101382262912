import { gql } from "@apollo/client"

const CURRENCIES_QUERY = gql`
query Curriencies {
  currencies {
    id
    name
    symbol
    price
    isoCode
  }
}
`
export default CURRENCIES_QUERY
