import React from "react";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { GeneralButton } from "components";
import { useMediaQuery, Theme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getBankAccountSelector, userAuthSelector, userSpaceSelector } from "store/selectors";
import {
  bankFees,
  parseAmount,
  parseToSimpleNumber,
  specialAmountFormat,
  toFloat,
} from "utils";
import { useMutation } from "@apollo/client";
import { BANK_TRANSFER_REQUEST } from "graphql/mutations";
import { UseAlert, GetBalance } from "hooks";

const BankAccounteResume = ({ handleStep }: any) => {
  const { title, memo, amount, bankAccountId, type } = useSelector(
    getBankAccountSelector
  );
  const { currentBranchOffice } = useSelector(userSpaceSelector);
  
  const isSpecial = currentBranchOffice?.space?.isSpecial
  const tmpWallet = currentBranchOffice.wallets.find((w: any) => {
    return w.wallet.name == 'zelle'
  })

  const feeValue = tmpWallet ? tmpWallet.feeValue : 1

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const { t } = useTranslation();

  const { balance } = GetBalance();
  const { showAlert } = UseAlert();
  const [requestTransfer, { loading }] = useMutation(BANK_TRANSFER_REQUEST);

  const handleSubmit = () => {
    const transferData = {
      bankAccountId,
      amount: parseToSimpleNumber(amount),
      branchOfficeId: currentBranchOffice?.id,
      memo,
    };

    requestTransfer({
      variables: { data: { ...transferData } },
      onError: (error) => {
        showAlert("something_went_wrong", "error");
      },
      onCompleted: (response: any) => {
        if (response) {
          // showAlert(t('success'), 'success')
          handleStep(4);
        }
      },
    });
  };

  return (
    <div className={styles._main}>
      <p className={styles._bold}>{t("resumen")}</p>
      <div
        className={clsx(
          styles._row,
          styles._borderBottom,
          styles._separationTop
        )}
      >
        <p className={styles._text}>{t("destiny_account")}:</p>

        <div className={clsx(styles._row)}>
          <div>
            <p className={styles._textLeft}>
              {title || t("choose_destination_account")}
            </p>
            <p className={clsx(styles._text, styles._grayText)}>
              {" "}
              {title ? title : t("account_affiliated")} - {type} transfer
            </p>
          </div>
        </div>
      </div>

      <div
        className={clsx(styles._row, styles._borderBottom, styles._paddingTop)}
      >
        <p className={styles._text}>{t("from")}:</p>

        <div className={clsx(styles._row)}>
          <div>
            <p className={styles._textLeft}>
              {t("balance")}: ${parseAmount(balance)}
            </p>
            <p
              className={clsx(styles._text, styles._grayText, styles._textLeft)}
            >
              {t("puntu_account")}
            </p>
          </div>
        </div>
      </div>

      <div className={clsx(styles._row, styles._center, styles._paddingTop)}>
        <div>
          <p className={styles._text}>{t("amount")}:</p>
          {/* <p className={clsx(styles._text, styles._underLine, styles._grayText)}>{t('limits')}</p> */}
        </div>
        <p className={styles._text}>${specialAmountFormat(amount)}</p>
      </div>

      <div className={styles._separation}>
        <div
          className={clsx(styles._row, styles._center, styles._secondMarginTop)}
        >
          <p className={styles._text}>{t("fee")}</p>
          {/* <p className={styles._text}>$0.00</p> */}
          <p className={styles._text}>{type == "WIRE" ? "$30.00" : "$3.00"}</p>
          {/* Replace false by type == 'WIRE' */}
        </div>

        {isSpecial && (
          <div
            className={clsx(styles._row, styles._center, styles._secondMarginTop)}
          >
            <p className={styles._text}>{feeValue != 1 ? `${feeValue * 100}%` : '0%'}</p>
            <p className={styles._text}>${parseAmount(Number(parseToSimpleNumber(amount)) * feeValue)}</p>
          </div>
        )}

        <div
          className={clsx(styles._row, styles._center, styles._borderBottom)}
        >
          <p className={clsx(styles._text, styles._boldText)}>{t("total")}</p>
          <p className={clsx(styles._text, styles._boldText)}>
            ${
              isSpecial 
                ? parseAmount(toFloat(amount || "0") - (bankFees[type] || 0 ) - Number(parseToSimpleNumber(amount)) * feeValue)
                : parseAmount(toFloat(amount || "0") - (bankFees[type] || 0 ))
            }
          </p>
        </div>
      </div>

      <div className={clsx(styles._row, styles._paddingTop)}>
        <div>
          <p className={clsx(styles._text, styles._grayText)}>{t("memo")}</p>
          <p className={clsx(styles._text, styles._grayText)}>
            {t("optional")}
          </p>
        </div>
        <p
          className={clsx(
            styles._text,
            styles._grayText,
            styles._smallText,
            styles._toStart
          )}
        >
          {memo}
        </p>
      </div>

      <div className={styles._btnsContainer}>
        <GeneralButton
          text="go_back"
          width="145px"
          className={styles._comeBack}
          action={() => handleStep(0)}
        />
        <GeneralButton
          text="send"
          width={!isMobile ? "145px" : "80%"}
          action={handleSubmit}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default BankAccounteResume;
