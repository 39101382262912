import { GeneralInput, GeneralOTP } from "../../../../../../components";
import { Form, Formik } from "formik";
import { UseIntermitence } from "hooks";
import { passwordRegex, translate } from "utils";
import { useMutation } from "@apollo/client";
import { ACTIVATE_2FA } from "graphql/mutations/twoFA";
import { CircularProgress } from "@mui/material";
import styles from "./styles.module.scss";
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';
import { UseAlert } from "hooks";

const changeInputStatus = (value: string, error: any) => {
  if (value !== "") {
    if (error) return "error";
    return "success";
  }
  return "inherit";
};

const VerifyScreen = ({ formRef, email, onSuccess, }: any) => {

  const { status: iconStatus, switchStatus: switchIconStatus } =
    UseIntermitence();

  const { t } = useTranslation()
  const { showAlert } = UseAlert();

  const [sendMutation, { loading }] = useMutation(ACTIVATE_2FA, {
    onCompleted: () => {
      onSuccess && onSuccess();
    },
    onError: (error) => {
      showAlert(error.message, "error",)
    },
  });
  return (
    <Formik
      initialValues={{ password: "", otp: "" }}
      innerRef={formRef}
      validationSchema={Yup.object({
        password: Yup.string()
          .required("field_required")
          .matches(passwordRegex, "field_invalid"),
        otp: Yup.string()
          .required("field_required")
          .min(6, () => translate("min_invalid", { number: 6 })),
      })}
      onSubmit={(values) => {
        sendMutation({
          variables: {
            data: {
              email,
              password: values.password,
              otp: values.otp,
            },
          },
        });
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldTouched,
        setFieldValue,
        handleChange,
        handleSubmit,
      }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <div className={styles._container}>
              <GeneralInput
                placeholder="password"
                inputWidth="100%"
                onFocus={() => {
                  switchIconStatus();
                  setFieldTouched("password", true, true);
                }}
                className={styles._passwordInput}
                onBlur={switchIconStatus}
                withIcon
                iconColor={"#919191"}
                icon={`${!iconStatus ? "bx:show" : "bx:low-vision"}`}
                id="password"
                name="password"
                onChange={handleChange}
                value={values.password}
                hasError={errors.password && touched.password}
                iconMethod={switchIconStatus}
                type={`${!iconStatus ? "password" : "text"}`}
                errorMessage={errors.password}
                status={changeInputStatus(
                  values.password,
                  errors.password && touched.password
                )}
              />
              <p className={styles._2FA}>2FA</p>
              <p className={styles._suggestion}>
                {t('enter_2fa_code_thirdparty')}
              </p>
              <GeneralOTP
                value={values.otp}
                onChange={(value) => {
                  !touched.otp && setFieldTouched("otp", true, false);
                  setFieldValue("otp", value);
                }}
                placeholder="000000"
                error={touched.otp && errors.otp}
              />
            </div>
            {loading && (
              <CircularProgress
                sx={{ margin: "1rem auto", display: "block" }}
              />
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default VerifyScreen;
