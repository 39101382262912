import { gql } from "@apollo/client"

const ACTIVITIES_QUERY = gql`
query Activities($page: Int!, $perPage: Int!, $search: String!, $walletId: ID, $transactionType: TransactionTypeEnum, $spaceId: ID!, $branchOfficeId: ID) {
  activities(page: $page, perPage: $perPage, search: $search, walletId: $walletId, transactionType: $transactionType, spaceId: $spaceId, branchOfficeId: $branchOfficeId) {
    detail {
      total
      transactions
    }
    pagination {
      page
      perPage
      totalItems
      totalPages
    }
  }
}
`

export default ACTIVITIES_QUERY
