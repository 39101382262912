import { useFormik } from 'formik';
import * as Yup from 'yup';
import { emailRegex, passwordRegex, translate } from 'utils';

export const FormikConfig = (handleSubmit: any) =>
  useFormik({
    initialValues: {
      email: '',
      password: '',
      otp: '',
      otpView: false,
    },

    validationSchema: Yup.object({
      email: Yup.string().when('otpView', {
        is: false,
        then: Yup.string()
          .required('field_required')
          .matches(emailRegex, 'email_valid'),
      }),

      password: Yup.string().when('otpView', {
        is: false,
        then: Yup.string()
          .required('field_required')
          .matches(passwordRegex, 'password_pattern'),
      }),
      otp: Yup.string().when('otpView', {
        is: true,
        then: Yup.string()
          .required('field_required')
          .min(6, () => translate('min_invalid', { number: 6 })),
      }),
    }),

    onSubmit: (values, form) => {
      handleSubmit(values, form);
    },
  });
