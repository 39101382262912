import { Theme } from '@mui/material';

const appBarStyles = (theme: Theme) => ({
  backgroundColor: theme.palette.whiteColorCustom?.main,
  [theme.breakpoints.down('lg')]: {
    backgroundColor: theme.palette.whiteColorCustom?.main,
    width: '100%',
    ml: 0,
    minHeight: '3rem',
    '& > .MuiToolbar-root': {
      minHeight: '3rem',
    },
  },
});

export { appBarStyles };
