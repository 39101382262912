import { useState, useRef, useEffect } from "react";
import { FormikConfig } from "./formik";
import { UseAlert } from "hooks";
import {
  GeneralInput,
  GeneralButton,
  GeneralSelect,
  GeneralModal,
  SimpleUploadFile,
  GeneralTextArea,
  GeneralInputDate,
} from "../../../components";

import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { UseCurrencies } from "utils/queries/currenciesQuery";
import { CLAIM_PAYMENT } from "graphql/mutations";
import { useMutation } from "@apollo/client";

import { useSelector } from "react-redux";
import { userAuthSelector, userSpaceSelector } from "store/selectors";
import { useLazyQuery } from "@apollo/client";
import { BRANCH_OFFICE_WALLETS_QUERY } from "graphql/queries";
import { amountMask } from "utils";
import GeneralInputLabel from "../GeneralInputLabel";

const ClaimPayment = ({ open, onCancel }: any) => {
  const { t } = useTranslation();
  const [voucher, setVoucher]: any = useState();

  const currencies = UseCurrencies("currencies");
  const ref: any = useRef(null);
  const { showAlert } = UseAlert();
  const [claimPayment, { loading }] = useMutation(CLAIM_PAYMENT);
  const [currencyId, setcurrencyId]: any = useState();
  const { userAuthData } = useSelector(userAuthSelector);
  const { currentBranchOffice } = useSelector(userSpaceSelector);

  const branchOfficeId =
    userAuthData?.spaces?.[0]?.space?.branchOffices?.[0]?.id;

  const [getBranchOfficeWallets, { data }] = useLazyQuery(
    BRANCH_OFFICE_WALLETS_QUERY,
    {
      variables: {
        branchOfficeId: currentBranchOffice?.id,
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const wallets = data?.branchOfficeWallets || [];

  useEffect(() => {
    getBranchOfficeWallets();
  }, []);

  const onHandlerDocument = (voucher: any) => {
    setVoucher(voucher);
    setFieldValue("voucherPhotoUrl", voucher.link);
    setcurrencyId(currencies?.[0]?.id);
  };

  const handleOnTouched = (key: string) =>
    setTouched({ ...touched, [key]: true });

  const handleRemoveDocument = () => {
    setVoucher("");
  };

  const handleSubmit = (formValues: any) => {
    const additionalData: any = {
      currencyId: currencyId,
      branchOfficeId: branchOfficeId,
    };

    const allFormData = { ...formValues, ...additionalData };
    if (!voucher) return showAlert("must_attach_document", "error");
    claimPayment({
      variables: { data: allFormData },
      onError: (error: any) => {
        showAlert(error?.message, "error");
        resetForm();
      },
      onCompleted: (response: any) => {
        const {
          claimPayment: { result },
        } = response;
        resetForm();
        if (result) {
          showAlert("claim_payment_success", "success");
          setVoucher("");

          return onCancel();
        }

        showAlert("something_went_wrong", "error");
      },
    });
  };

  const {
    values,
    handleSubmit: formikSubmit,
    handleChange,
    submitForm,
    errors,
    touched,
    setTouched,
    setFieldValue,
    dirty,
    isValid,
    resetForm,
  } = FormikConfig(handleSubmit);

  const onHandlerSelect = (value: any) => {
    setFieldValue("walletId", value);
  };

  const changeInputStatus = (value: string, error: any) => {
    if (value !== "") {
      if (error) return "error";
      return "success";
    }
    return "inherit";
  };

  const buildWalletsArray = (activeWallets: Array<any>) => {
    const defaultWallet: any = { label: t("account_type"), value: "" };
    const localWallets: Array<any> = activeWallets.map((item: any) => {
      return { label: item?.wallet?.title, value: item?.wallet?.id };
    });

    localWallets.unshift(defaultWallet);
    return localWallets || [];
  };

  useEffect(() => {
    return () => resetForm();
  }, [open]);

  return (
    <GeneralModal
      open={open}
      onCancel={onCancel}
      title={t("claim_your_payment")}
    >
      <div className={styles._mainContainer}>
        <div className={styles._content}>
          <form className={styles._form} onSubmit={formikSubmit} id="my-form">
            <div className={styles._firstDualContainer}>
              <div className={styles._firstDualInput}>
                <GeneralInput
                  withIcon={false}
                  placeholder={"name"}
                  id="firstName"
                  name="firstName"
                  onChange={handleChange}
                  value={values.firstName}
                  hasError={
                    errors.firstName && touched.firstName ? true : false
                  }
                  onFocus={() => handleOnTouched("firstName")}
                  errorMessage={errors.firstName}
                  status={changeInputStatus(
                    values.firstName,
                    errors.firstName && touched.firstName
                  )}
                />
              </div>

              <div className={styles._firstDualInput}>
                <GeneralInput
                  withIcon={false}
                  placeholder={"last_name"}
                  id="lastName"
                  name="lastName"
                  onChange={handleChange}
                  value={values.lastName}
                  hasError={errors.lastName && touched.lastName ? true : false}
                  onFocus={() => handleOnTouched("lastName")}
                  errorMessage={errors.lastName}
                  status={changeInputStatus(
                    values.lastName,
                    errors.lastName && touched.lastName
                  )}
                />
              </div>
            </div>
            <div className={styles._inputDualContainer}>
              <div className={styles._contentInput}>
                <GeneralInput
                  withIcon={false}
                  placeholder={"email"}
                  onChange={handleChange}
                  value={values.email}
                  id="email"
                  name="email"
                  hasError={errors.email && touched.email ? true : false}
                  onFocus={() => handleOnTouched("email")}
                  errorMessage={errors.email}
                  status={changeInputStatus(
                    values.email,
                    errors.email && touched.email
                  )}
                />
              </div>
              <div className={styles._contentInput}>
                <GeneralInputLabel
                  placeholder={"amount"}
                  className={styles._amountClass}
                  onChange={(event: any) =>
                    amountMask(event, setFieldValue, "amount")
                  }
                  value={values.amount}
                  id="amount"
                  name="amount"
                  label="$"
                  hasError={errors.amount && touched.amount ? true : false}
                  onFocus={() => handleOnTouched("amount")}
                  errorMessage={errors.amount}
                  status={changeInputStatus(
                    values.amount,
                    errors.amount && touched.amount
                  )}
                />
              </div>
            </div>
            <div className={styles._inputDualContainer}>
              <div className={styles._contentInput}>
                <GeneralInput
                  withIcon={false}
                  placeholder={"concept"}
                  onChange={handleChange}
                  value={values.description}
                  id="description"
                  name="description"
                  hasError={
                    errors.description && touched.description ? true : false
                  }
                  onFocus={() => handleOnTouched("description")}
                  errorMessage={errors.description}
                  status={changeInputStatus(
                    values.description,
                    errors.description && touched.description
                  )}
                />
              </div>
              <div className={styles._contentInput}>
                <GeneralSelect
                  value={values?.walletId ?? wallets?.[0]?.wallet?.id}
                  onChange={(event: any) => {
                    onHandlerSelect(event?.target?.value);
                  }}
                  options={buildWalletsArray(wallets)}
                  reference={ref}
                />
              </div>
            </div>
            <div className={styles._inputDualContainer}>
              <div className={styles._contentInput}>
                <GeneralInputDate
                  placeholder="Fecha de pago"
                  value={values.claimDate}
                  id="claimDate"
                  name="claimDate"
                  onChange={handleChange}
                  onFocus={() => handleOnTouched("claimDate")}
                  hasError={
                    errors.claimDate && touched.claimDate ? true : false
                  }
                  status={changeInputStatus(
                    values.claimDate,
                    errors.claimDate && touched.claimDate
                  )}
                  errorMessage={errors.claimDate}
                />
              </div>
              <div className={styles._contentInput}>
                <GeneralInputDate
                  placeholder="Hora de pago"
                  value={values.claimHour}
                  id="claimHour"
                  name="claimHour"
                  onChange={handleChange}
                  onFocus={() => handleOnTouched("claimHour")}
                  hasError={
                    errors.claimHour && touched.claimHour ? true : false
                  }
                  status={changeInputStatus(
                    values.claimHour,
                    errors.claimHour && touched.claimHour
                  )}
                  errorMessage={errors.claimHour}
                  type="time"
                />
              </div>
            </div>
            <div className={styles._inputDualContainer}>
              <div className={styles._contentFile}>
                <SimpleUploadFile
                  nameValue={voucher?.name}
                  linkValue={voucher?.link}
                  methodFile={(value: any) => onHandlerDocument(value)}
                  methodIcon={() => handleRemoveDocument()}
                  serverFolder={"PaymentClaims"}
                />
              </div>
            </div>
            <div className={styles._inputContainer}>
              <GeneralTextArea
                placeholder="leave_comment"
                onChange={handleChange}
                value={values.ticketReason}
                id="ticketReason"
                name="ticketReason"
                onFocus={() => handleOnTouched("ticketReason")}
                errorMessage={errors.ticketReason}
                hasError={
                  errors.ticketReason && touched.ticketReason ? true : false
                }
                status={changeInputStatus(
                  values.ticketReason,
                  errors.ticketReason && touched.ticketReason
                )}
              />
            </div>

            <div className={styles._button}>
              <GeneralButton
                action={submitForm}
                text={"send"}
                loading={loading}
                type="submit"
                disabled={!isValid || !dirty}
              />
            </div>
          </form>
        </div>
      </div>
    </GeneralModal>
  );
};

export default ClaimPayment;
