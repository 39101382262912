import styles from "./styles.module.scss";
export const transformToRows = (
  items: Array<any>,
  isMobile: boolean,
  branchOffice: string,
  t: any
) =>
  items
    ? items.map((item) => {
        const permissions = item.permissions.split(",");

        return {
          id: item.id,
          values: isMobile
            ? [
                {
                  value: (
                    <div className={styles._mobileRow}>
                      <div className={styles._rowContainer}>
                        <p
                          style={{ backgroundColor: item?.color }}
                          className={styles._circle}
                        ></p>
                        <div className={styles._titleContent}>
                          <b>{item.title}</b>
                          {branchOffice}
                        </div>
                      </div>
                    </div>
                  ),
                },
                {
                  cellClass: styles._noShow,
                  value: item.members
                },
              ]
            : [
                {
                  value: (
                    <div className={styles._colorContent}>
                      <p
                        style={{ backgroundColor: item?.color }}
                        className={styles._circle}
                      ></p>
                      {item.title}
                    </div>
                  ),
                },
                {
                  cellWidth: "40%",
                  value: (
                    <p style={{ paddingRight: 8 }}>
                      {permissions.map((permission: any, index: number) => {
                        return (
                          t(permission.trim().toLowerCase()) +
                          (index !== permissions.length - 1 ? ", " : "")
                        );
                      })}
                    </p>
                  ),
                },
                {
                  value: item.members, //<p className={styles._ellipsis}>{item.address}</p>,
                },
              ],
        };
      })
    : [];
