import { baseUrl } from "paths";
import styles from "./styles.module.scss";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { twoFASelector } from "store/twoFA/selectors";

const StoreKey = () => {
  const qrData = useSelector(twoFASelector)
  const { t } = useTranslation()
  return (
    <>
      <div className={styles._container}>
        <img
          src={`${baseUrl}/resources/2FAScreens/StoreKey/Key.svg`}
          alt="Apple logo"
          className={styles._key}
        />
      </div>
      <p className={styles._secret}>{qrData?.code}</p>
      <p className={styles._suggestion}>
       {t('remember_write_password')} <br />
       {t('allow_restore_2fa')}
      </p>
    </>
  );
};

export default StoreKey;
