import { RESET_2FA, SET_2FA_DATA, SET_2FA_EMAIL } from './action-types';

const initialState: any = {
  email: '',
  code: '',
  QR: '',
};

const onboarding = (state = initialState, { type, payload }: any) => {
  switch (type) {
    case SET_2FA_EMAIL:
    case SET_2FA_DATA:
      return { ...state, ...payload };
    case RESET_2FA:
      return initialState;
    default:
      return state;
  }
};

export default onboarding;
