import styles from "./styles.module.scss";
import { baseUrl } from "paths";
import clsx from "clsx";
import { useTranslation } from 'react-i18next'
import OnboardingLangButton from '../OnboardingLangButton'
import { useLocation } from "react-router-dom";
import styled from "styled-components";

const OnboardingLayout = ({ children, className, backgroundImg, position }: any) => {

  const { t } = useTranslation()
  const location = useLocation();

  const Banner: any = styled.div`
    background-image: url(${({ backgroundImg }: any) => (backgroundImg ? `${baseUrl}/resources/${backgroundImg}` : `${baseUrl}/resources/login-banner.svg`)});
    background-position: ${({ position }: any) => (position ? position : 'top center')};
  `
  const validatePath = () => location.pathname.includes('/onboarding');

  return (
    <>
      <div className={clsx(styles._main, className)}>
        <div className={styles._leftSide}>
          <div className={styles._header}>
            <div>
              <img
                src={`${baseUrl}/resources/puntu-logo.png`}
                alt="onboarding-img"
                width="150px"
              />
            </div>
            <div>
              <OnboardingLangButton />
            </div>
          </div>
          {children}

        </div>
        <div className={styles._rightSide}>
          <Banner className={styles._banner} backgroundImg={backgroundImg} position={position}>
            { validatePath() && <p className={styles._text}>{t('all_accounts_in_puntu')}</p> }
          </Banner>
        </div>
      </div>

    </>
  );
};

export default OnboardingLayout;
