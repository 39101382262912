import { Icon } from "@iconify/react";
import { useEffect } from "react";
import { baseUrl } from "paths";
import { useTranslation } from "react-i18next";
import { useMediaQuery, Theme } from "@mui/material";
import { useMutation } from "@apollo/client";
import { ACTIVATE_WALLET } from "graphql/mutations";
import { useSelector } from "react-redux";
import { userSpaceSelector } from "store/selectors";
import { UseAlert } from "hooks";
import { useLazyQuery } from "@apollo/client";
import { BRANCH_OFFICE_WALLETS_QUERY } from "graphql/queries";
import styles from "./styles.module.scss";

const walletImage = {
  paypal: "/resources/Paypal.svg",
  zelle: "/resources/Zelle.svg",
  venmo: "/resources/Venmo.svg",
};

const ReceiveQR = ({
  type,
  walletsData,
  branchCode,
  userType,
  walletId,
  specialCustomer,
}: {
  type: string & keyof typeof walletImage;
  walletsData: Array<any>;
  branchCode: string;
  userType: string;
  walletId: string;
  specialCustomer: boolean;
}) => {
  const { currentBranchOffice } = useSelector(userSpaceSelector);
  const wallet = (walletsData || []).find((elem: any) => elem.wallet === type);
  const [activateWallet] = useMutation(ACTIVATE_WALLET);
  const branchOfficeId: Array<any> = currentBranchOffice?.id;
  const { showAlert } = UseAlert();
  const { t } = useTranslation();
  
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("lg")
  );

  const stringToArray = (code: string) => {
    if (!code) return [];

    return Array.from(code?.toUpperCase());
  };

  const [getBranchOfficeWallets, { data: updatedWallets, refetch }] =
    useLazyQuery(BRANCH_OFFICE_WALLETS_QUERY, {
      variables: {
        branchOfficeId: currentBranchOffice?.id,
      },
      fetchPolicy: "no-cache",
    });

  const branchOfficeWallets = (updatedWallets?.branchOfficeWallets || []).find(
    (elem: any) => elem?.wallet?.name === type
  );

  useEffect(() => {
    getBranchOfficeWallets();
  }, []);

  const handlerActivate = () => {
    activateWallet({
      variables: { walletId: walletId, branchOfficeId: branchOfficeId },
      onCompleted: (response) => {
        const { activateWallet } = response;
        if (activateWallet) {
          showAlert(t("wallet_activated"), "success");
          return refetch();
        }
      },
      onError: (error) => {
        showAlert(t("something_went_wrong"), "error");
      },
    });
  };

  return (
    <>
      {" "}
      {branchOfficeWallets && wallet && (
        <div className={styles._mainContainer}>
          <div className={styles._row}>
            <p className={styles._receive}>{t("receive_in")}:</p>
            <img alt="Logo" src={`${baseUrl}${walletImage[type]}`} />

            <div style={{ flexGrow: 1 }} />
            <p className={styles._account}>
              {t("account")}{" "}
              <span style={{ textTransform: "capitalize" }}>{type}</span>
            </p>
          </div>
          <div className={styles._row}>
            {/* <p className={styles._print}>{t('click_to_print')}</p> */}
            <div style={{ flexGrow: 1 }} />
            <div
              className={styles._accountNumberContainer}
              onClick={() => {
                navigator.clipboard.writeText(wallet?.account);
              }}
            >
              <Icon icon="fa-solid:copy" color="#6B818C" />
              <p className={styles._accountNumber}>{specialCustomer ? branchOfficeWallets.account : wallet?.account}</p>
            </div>
          </div>

          <div className={styles._bottomContainer}>
            <a
              href={specialCustomer ? branchOfficeWallets.accountQR : wallet?.imageQR}
              target="_blank"
              download=""
              rel="noreferrer"
            >
              {!isMobile
                ? `${t("click_to_export")} ${"QR"}`
                : `${t("tap_to_export")} ${"QR"}`}
            </a>

            <div>
              <img alt="qr_code" src={specialCustomer ? branchOfficeWallets.accountQR : `${baseUrl}${wallet?.imageQR}`} />
              {/* <img alt="qr_code" src={wallet?.imageQR} />  TO DO GET REAL QR ROUTE*/}
            </div>
          </div>

          <div className={styles._codeContainer}>
            <p className={styles._codeTitle}>
              {" "}
              {t(
                userType === "juridic"
                  ? "code_of_branch_is"
                  : "code_assigned_is"
              )}
            </p>
            <div className={styles._codeParent}>
              {branchCode &&
                stringToArray(branchCode).map((letter, index) => (
                  <div className={styles._codeSquare} key={index}>
                    <p>{letter}</p>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
      {!branchOfficeWallets && wallet && (
        <div className={styles._emptyState}>
          <div className={styles._emptyContainer}>
            <div className={styles._iconParent}>
              <Icon icon="bx:x-circle" width={32}></Icon>
            </div>

            <p>{t("account_isnt_activated")}</p>
            <div className={styles._header} onClick={() => handlerActivate()}>
              <img
                src={`${baseUrl}/resources/plus-gray-back-icon.svg`}
                alt="no-wallet-icon"
              />

              <div>
                <p className={styles._title}>{t("add_account")}</p>
              </div>
            </div>
          </div>
        </div>
      )}
      {!wallet && (
        <div className={styles._emptyState}>
          <div className={styles._emptyContainer}>
            <div className={styles._iconParent}>
              <Icon icon="bx:x-circle" width={32}></Icon>
            </div>

            <p className={styles._emptyTitle}>{t("system_account_inactive")}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default ReceiveQR;
