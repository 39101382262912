import { gql } from '@apollo/client';

const BRANCH_OFFICE_WALLETS_QUERY = gql`
  query BranchOfficeWallets($branchOfficeId: ID!) {
    branchOfficeWallets(branchOfficeId: $branchOfficeId) {
      total
      account
      accountQR
      feeValue
      wallet {
        id
        name
        title
      }
    }
  }
`;
export default BRANCH_OFFICE_WALLETS_QUERY;
