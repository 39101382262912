import { gql } from "@apollo/client"

const BALANCE_QUERY = gql`
query Activities($page: Int!, $perPage: Int!, $spaceId: ID!, $branchOfficeId: ID) {
  activities(page: $page, perPage: $perPage, spaceId: $spaceId, branchOfficeId: $branchOfficeId) {
    detail {
      total
    }
  }
}
`
export default BALANCE_QUERY
