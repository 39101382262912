import styles from "./styles.module.scss";
import { RadioGroupType } from "./types";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";
import { Checkbox, Radio, RadioGroup } from '@mui/material'
import CircularProgress from "@mui/material/CircularProgress";

const GeneralRadioGroup = ({
  mapData = [],
  totalPages,
  handleCheckValue,
  handleSelectAll,
  checkValue,
  currentPage,
  leftMethod,
  rightMethod,
  loading,
  multiple = false,
  checkedAll
}: RadioGroupType) => {

  const { t } = useTranslation();

  return (
    <RadioGroup
      aria-labelledby="demo-radio-buttons-group-label"
      name="radio-buttons-group"

    >
      <div className={styles._radioMain}>
        {loading ?
          <>
            {mapData.length != 0 && multiple &&
              <label className={styles._radioContainer}>
                <Checkbox
                  checked={checkValue?.length === checkedAll}
                  onChange={handleSelectAll}
                  value={checkValue}
                  name="select-all-checkbox"
                  icon={<Icon icon='bx:radio-circle' fontSize={30} />}
                  checkedIcon={<Icon icon='bx:radio-circle-marked' fontSize={30} />}
                />
                <p className={styles._chooseAll}>{t('select_all')}</p>
              </label>
            }

            {mapData?.map((item: any, index: number) => (

              <div className={styles._radioContainer} key={index}>

                {!multiple &&
                  <label className={styles._radioContent} onClick={handleCheckValue}>
                    <Radio
                      value={item?.id}
                      name={item?.title}
                      onChange={handleCheckValue}
                      checked={item?.id == checkValue ? true : false} />
                    <div className={styles._textRadioContent}>
                      <p className={styles._userRadio}>{item?.title ?? item?.name}</p>
                    </div>
                  </label>
                }

                {multiple &&
                  <label className={styles._radioContent} onClick={handleCheckValue}>

                    <Checkbox
                      icon={<Icon icon='bx:radio-circle' fontSize={30} />}
                      checkedIcon={<Icon icon='bx:radio-circle-marked' fontSize={30} />}
                      key={index}
                      checked={checkValue.includes(item?.id)}
                      onChange={handleCheckValue}
                      value={item?.id}
                      name="select-all-checkbox"
                    />
                    <div className={styles._textRadioContent}>
                      <p className={styles._userRadio}>{item?.name}</p>
                      <p className={styles._emailRadio}>{item?.email}</p>
                    </div>

                  </label>
                }
              </div>

            ))}
          </> :
          <div className={styles._loaderContainer}>
            <CircularProgress color="primary" size={35} />
          </div>
        }

        {mapData.length == 0 && loading && <p className={styles._empty}>{t('no_coincidence')}  </p>}

        {mapData.length != 0 &&
          <div className={styles._paginationContainer} >
            <div>
              <p className={styles._paginate}>{currentPage}/{totalPages}</p>
            </div>

            <div className={styles._paginate}>
              <Icon icon='bx:left-arrow-alt'
                onClick={leftMethod}
                className={currentPage >= 2 ? styles._icon : styles._iconNone}
                width={24} color={'#909090'}></Icon>

              <Icon icon='bx:right-arrow-alt' width={24} onClick={rightMethod}
                className={currentPage <= totalPages - 1 ? styles._icon : styles._iconNone}
                color='#909090'></Icon>
            </div>
          </div>
        }
      </div>

    </RadioGroup>
  );
};

export default GeneralRadioGroup
