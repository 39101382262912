import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  passwordRegex,
  emailRegex,
  onlyLettersRegex,
  phoneRegex,
  translate,
  onlyNumbersRegex,
  onlyLettersAndNumbers,
  parseToSimpleNumber,
  amountCharactersRegex
} from 'utils';

const baseSchema: any = Yup.object({
  email: Yup.string()
    .required('field_required')
    .matches(emailRegex, 'email_valid'),

  password: Yup.string()
    .required('field_required')
    .matches(passwordRegex, 'field_invalid'),

  confirmPassword: Yup.string()
    .required('field_required')
    .matches(passwordRegex, 'field_invalid')
    .oneOf([Yup.ref('password'), null], 'password_must_match'),

  phoneNumber: Yup.string().matches(phoneRegex, 'phone_invalid'),

  cellPhoneNumber: Yup.string()
    .required('field_required')
    .matches(phoneRegex, 'phone_invalid'),

  monthlyIncome: Yup.string()
    .required('field_required')
    .matches(amountCharactersRegex, 'amount_invalid'),
});

const legalSchema: any = Yup.object({
  legalRepresentativeName: Yup.string()
    .required('field_required')
    .min(3, () => translate('min_invalid', { number: 3 }))
    .matches(onlyLettersRegex, 'only_letters'),

  legalRepresentativeLastName: Yup.string()
    .required('field_required')
    .min(3, () => translate('min_invalid', { number: 3 }))
    .matches(onlyLettersRegex, 'only_letters'),

  address: Yup.string()
    .required('field_required')
    .max(250, () => translate('max_invalid', { number: 250 })),

  representativePhone: Yup.string().matches(phoneRegex, 'phone_invalid'),

  representativeCellPhone: Yup.string()
    .required('field_required')
    .matches(phoneRegex, 'phone_invalid'),

  representativeDniType: Yup.string().required(),

  representativeDniNumber: Yup.string()
    .required('field_required')
    .when('representativeDniType', {
      is: 'P',
      then: Yup.string().matches(onlyLettersAndNumbers, 'only_alphanumeric'),
      otherwise: Yup.string().matches(onlyNumbersRegex, 'only_numbers'),
    })

    .min(3, () => translate('min_invalid', { number: 3 }))
    .max(10, () => translate('max_invalid', { number: 10 })),
});

export const FormikConfig = (
  handleSubmit: any,
  type: string,
  accountData: any
) =>
  useFormik({
    initialValues: {
      email: accountData?.email ?? '',
      password: accountData?.password ?? '',
      confirmPassword: accountData?.password ?? '',
      phoneNumber: accountData?.phoneNumber ?? '',
      cellPhoneNumber: accountData?.cellPhoneNumber ?? '',
      monthlyIncome: accountData?.monthlyIncome ?? '',
      legalRepresentativeName:
        accountData?.legalRepresentative?.firstName ?? '',
      legalRepresentativeLastName:
        accountData?.legalRepresentative?.lastName ?? '',
      address: accountData?.legalRepresentative?.address ?? '',
      representativePhone: accountData?.legalRepresentative?.phoneNumber ?? '',
      representativeCellPhone:
        accountData?.legalRepresentative?.cellPhoneNumber ?? '',
      representativeDniNumber: accountData?.legalRepresentative?.dni ?? '',
      representativeDniType: accountData?.legalRepresentative?.dniType ?? 'V',
      requestTwoFactor: accountData?.requestTwoFactor ?? false,
    },

    validationSchema:
      type == 'juridic' ? baseSchema.concat(legalSchema) : baseSchema,

    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
