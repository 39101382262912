import styles from "./styles.module.scss";
import { Modal, Box } from "@mui/material";
import { Theme, useMediaQuery } from "@mui/material";
import { modalBox, modal } from "./materialStyles";
import { useTranslation } from "react-i18next";

import { GeneralButton } from "components";

const ClearCacheModal = ({ open, onCancel }: any) => {
  const modalWidth: any = "14.5625rem";
  const { t } = useTranslation();

  const clearCache = () => {
    window.location.href =
      window.location.pathname + "?t=" + new Date().getTime();
  };

  return (
    <>
      {open && (
        <Modal
          open={open}
          onClose={() => onCancel()}
          sx={(theme) => modal(theme, modalWidth)}
        >
          <Box sx={(theme) => modalBox(theme, modalWidth, 6)}>
            <p className={styles._title}>{t("you_want_clear_cache")}</p>
            <div className={styles._btnsParent}>
              <div className={styles._btnParentLeft}>
                <GeneralButton
                  text={t("cancel")}
                  action={() => {
                    onCancel();
                  }}
                />
              </div>
              <div className={styles._btnParentRight}>
                <GeneralButton
                  type="submit"
                  action={() => {
                    clearCache();
                  }}
                  text={t("confirm")}
                />
              </div>
            </div>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default ClearCacheModal;
