import React from 'react'
import { useTranslation } from 'react-i18next'
import { HelmetType } from './types'
import { Helmet, HelmetProvider } from 'react-helmet-async';

const HelmetHeader = ({ title }: HelmetType) => {
  const { t } = useTranslation()

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Centraliza tu negocio en un mismo Puntu" />
        <meta property="og:title" content="Puntu" />
        <meta property="og:url" content="https://admin.puntu.io/" />
        <meta property="og:description" content="Centraliza tu negocio en un mismo Puntu" />
        <meta name="og:image" content="/favicon.ico" />
        <link rel="apple-touch-icon" href="/favicon.ico" />
        <title>{`Puntu | ${t(title)}`}</title>
      </Helmet>
    </HelmetProvider>
  )
}

export default HelmetHeader
