import { useFormik } from 'formik'
import * as Yup from 'yup'
import { emailRegex } from 'utils'

export const FormikConfig = (handleSubmit: any) => (useFormik({
  initialValues: {
    email: '',
  },

  validationSchema: Yup.object({
    email: Yup.string()
    .required('field_required')
    .matches(emailRegex, 'email_valid'),
  }),

  onSubmit: values => {
    handleSubmit(values);
  }

}))
