import styles from "./styles.module.scss";
import { Grid, Theme, useMediaQuery } from "@mui/material";

import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { UseAlert, UseIntermitence } from "hooks";
import {
  GeneralButton,
  GeneralModal,
  GeneralInput,
  GeneralOTP,
  Tooltip,
} from "components";
import { FormikConfig } from "./formik";
import { CHANGE_PASSWORD } from "graphql/mutations";
import { useEffect } from "react";

const ChangePasswordModal = ({ open, onCancel, have2fa }: any) => {
  const { showAlert } = UseAlert();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const { status, switchStatus } = UseIntermitence();
  const { status: iconConfirmStatus, switchStatus: switchConfirmIconStatus } =
    UseIntermitence();
  const { status: iconNewStatus, switchStatus: switchIconNewStatus } =
    UseIntermitence();
  const { status: iconStatus, switchStatus: switchIconStatus } =
    UseIntermitence();

  const [changePassword, { loading }] = useMutation(CHANGE_PASSWORD);

  const handleSubmit = (formValues: any) => {
    const payloadWitouthOtp = {
      oldPassword: formValues?.oldPassword,
      newPassword: formValues?.newPassword,
    };

    const payloadWithOtp = {
      oldPassword: formValues?.oldPassword,
      newPassword: formValues?.newPassword,
      otp: formValues?.otp,
    };

    changePassword({
      variables: values?.showOtp
        ? { data: { ...payloadWithOtp } }
        : { data: { ...payloadWitouthOtp } },
      onError: (error: any) => {
        showAlert(error?.message, "error");
        resetForm();
      },
      onCompleted: (response: any) => {
        const {
          changePassword: { result },
        } = response;
        resetForm();
        if (result) {
          showAlert("change_password_success", "success");

          return onCancel();
        }

        showAlert("something_went_wrong", "error");
      },
    });
  };

  useEffect(() => {
    if (have2fa) setFieldValue("showOtp", true);
  }, []);

  const {
    values,
    handleSubmit: formikSubmit,
    handleChange,
    submitForm,
    errors,
    touched,
    setTouched,
    resetForm,
    isValid,
    setFieldValue,
    dirty,
  } = FormikConfig(handleSubmit);

  const handleOnTouched = (key: string) =>
    setTouched({ ...touched, [key]: true });
  UseIntermitence();

  const changeInputStatus = (value: string, error: any) => {
    if (value !== "") {
      if (error) return "error";
      return "success";
    }
    return "inherit";
  };

  return (
    <GeneralModal
      open={open}
      title={t("change_password")}
      width={"41.063rem"}
      onCancel={onCancel}
    >
      <form onSubmit={formikSubmit} id="my-form">
        <Grid container spacing={isMobile ? 2.8 : 3.5}>
          <Grid
            item
            xs={12}
            marginTop={isMobile ? "2rem" : "1rem"}
            sm={12}
            md={6}
          >
            <GeneralInput
              placeholder="old_password"
              onFocus={() => {
                switchStatus();
                handleOnTouched("oldPassword");
              }}
              onBlur={switchStatus}
              withIcon
              iconColor={"#919191"}
              icon={`${!iconNewStatus ? "bx:show" : "bx:low-vision"}`}
              id="oldPassword"
              name="oldPassword"
              onChange={handleChange}
              value={values.oldPassword}
              hasError={
                errors.oldPassword && touched.oldPassword ? true : false
              }
              iconMethod={switchIconNewStatus}
              type={`${!iconNewStatus ? "password" : "text"}`}
              errorMessage={errors.oldPassword}
              status={changeInputStatus(
                values.oldPassword,
                errors.oldPassword && touched.oldPassword
              )}
            />
          </Grid>
          {!isMobile && <Grid item xs={12} sm={12} md={6}></Grid>}
          <Grid item xs={12} sm={12} md={6}>
            <div style={{ position: "relative" }}>
              {status && (
                <Tooltip text="password_pattern" top="-130%" left="65%" />
              )}
              <GeneralInput
                placeholder="new_password"
                onFocus={() => {
                  switchStatus();
                  handleOnTouched("newPassword");
                }}
                onBlur={switchStatus}
                withIcon
                iconColor={"#919191"}
                icon={`${!iconStatus ? "bx:show" : "bx:low-vision"}`}
                id="newPassword"
                name="newPassword"
                onChange={handleChange}
                value={values.newPassword}
                hasError={
                  errors.newPassword && touched.newPassword ? true : false
                }
                iconMethod={switchIconStatus}
                type={`${!iconStatus ? "password" : "text"}`}
                errorMessage={errors.newPassword}
                status={changeInputStatus(
                  values.newPassword,
                  errors.newPassword && touched.newPassword
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <GeneralInput
              placeholder="confirm_password"
              withIcon
              id="confirmNewPassword"
              name="confirmNewPassword"
              iconColor={"#919191"}
              icon={`${!iconConfirmStatus ? "bx:show" : "bx:low-vision"}`}
              type={`${!iconConfirmStatus ? "password" : "text"}`}
              iconMethod={switchConfirmIconStatus}
              onChange={handleChange}
              hasError={
                errors.confirmNewPassword && touched.confirmNewPassword
                  ? true
                  : false
              }
              value={values.confirmNewPassword}
              onFocus={() => handleOnTouched("confirmNewPassword")}
              errorMessage={errors.confirmNewPassword}
              status={changeInputStatus(
                values.confirmNewPassword,
                errors.confirmNewPassword && touched.confirmNewPassword
              )}
            />
          </Grid>
        </Grid>
        {values.showOtp && (
          <Grid item xs={11} sm={11} md={11} margin={"2rem auto 0 auto"}>
            <h3 className={styles._2faTitle}>{t("2fa")}</h3>
            <GeneralOTP
              value={values.otp}
              error={touched.otp && errors.otp}
              placeholder="000000"
              onChange={(value) => {
                handleOnTouched("otp");
                setFieldValue("otp", value);
              }}
              className={styles._otp}
            />
          </Grid>
        )}
        <div className={styles._button}>
          <GeneralButton
            action={submitForm}
            text={"save"}
            loading={loading}
            type="submit"
            disabled={!isValid || !dirty}
          />
        </div>
      </form>
    </GeneralModal>
  );
};

export default ChangePasswordModal;
