import { gql } from '@apollo/client';

const DELETE_BANK_ACCOUNT = gql`
  mutation DeleteBankAccount($data: DeleteBankAccountInput!) {
    deleteBankAccount(data: $data) {
      result
    }
  }
`;
export default DELETE_BANK_ACCOUNT;
