import React from "react";
import { useTranslation } from "react-i18next";
import { baseUrl } from "paths";
import styles from "./styles.module.scss";
import styled from "styled-components";
import clsx from "clsx";
import { parseAmount } from "utils";
import { GetBalance } from "hooks";
import { useSelector } from "react-redux";
import { userAuthSelector, userSpaceSelector } from "store/selectors";

const DashboardDrawerCard = ({ withBackground = true, className }: any) => {
  const { t } = useTranslation();
  const { balance } = GetBalance();
  const { currentBranchOffice } = useSelector(userSpaceSelector);
  const { userAuthData } = useSelector(userAuthSelector);

  return (
    <div
      className={
        withBackground ? styles._outerBackground : styles._noBackground
      }
    >
      <div className={styles._card}>
        <div className={clsx(styles._innerBackground, className)}></div>
        <p className={styles._balance}>
          {userAuthData?.customer?.type == "natural"
            ? t("Puntu")
            : currentBranchOffice.name}
        </p>
        <img
          src={`${baseUrl}/resources/isotipo-blanco.svg`}
          alt="Puntu logo"
          className={styles._logo}
        />
        <div className={styles._amountContainer}>
          <p className={styles._amount}>${parseAmount(balance)}</p>
        </div>
        <img
          src={`${baseUrl}/resources/card-hand.svg`}
          alt="Card hand"
          className={styles._hand}
        />
      </div>
    </div>
  );
};

export default styled(DashboardDrawerCard)`
  width: ${({ width }: any) => (width ? width : "17.4375rem")};
`;
