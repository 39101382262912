import React, { memo, useEffect, useState } from 'react'
import styles from './styles.module.scss'
import DashboardDrawerCard from '../../../../../components/DashboardDrawerCard'
import { GeneralButton } from '../../../../../../components'
import { Icon } from '@iconify/react'
import { baseUrl } from 'paths'
import { paginate, switchWalletsNumber, walletEnabled, walletDisabled, allWallets } from 'utils'
import { useSelector } from 'react-redux'
import { userSpaceSelector} from 'store/selectors'
import SelectWallets from '../MobileSelectWallets'
import { UseIntermitence } from 'hooks'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { BRANCH_OFFICE_WALLETS_QUERY } from 'graphql/queries'

const MobileTopSection = ({ isAllowed }: any) => {

  const navigation = useNavigate()
  const [pagination] = useState(1)
  const { currentBranchOffice } = useSelector(userSpaceSelector)
  const { t } = useTranslation()

  const [getBranchOfficeWallets, { data, refetch }] = useLazyQuery(BRANCH_OFFICE_WALLETS_QUERY, {
    variables: {
      branchOfficeId: currentBranchOffice?.id
    },
    fetchPolicy: 'cache-and-network'
  });

  const wallets = data?.branchOfficeWallets || []

  useEffect(() => {
    getBranchOfficeWallets()
  }, [])

  const { status, switchStatus } = UseIntermitence()

  const buildArray = (wallets: any) => {
    const localWallets = paginate(wallets, 4, pagination)
    const baseArray: any = Array
    let filledArray: Array<any> = [...localWallets]

    if (localWallets.length < 4) {
      const enabledArray = baseArray(1).fill(walletEnabled)
      const disabledArray = baseArray(switchWalletsNumber(localWallets.length)).fill(walletDisabled)
      filledArray = [...enabledArray, ...disabledArray];
    }
    return paginate(([...wallets, ...filledArray] || []), 4, pagination)
  }

  const verifyWallets = (text: string) => {
    const regex = new RegExp(allWallets.join('|')).test(text);
    return !regex
  }

  const filterAndNavigate = (id: string, account: string) => navigation('/activity', { state: { wallet: id, name: account } })

  const handleCardsAction = (item: any) => {
    const name = item?.wallet?.name
    if (name == 'enabled') return switchStatus()
    if (!verifyWallets(name) && name == 'disabled') return null
    if (!verifyWallets(name)) filterAndNavigate(item?.wallet?.id, name)
  }

  return (
    <>
      <div className={styles._cardContainer}>
        <DashboardDrawerCard width='90%' withBackground={false} />
      </div>

      <div className={styles._buttonsParent}>
        {isAllowed && (
          <>
            <GeneralButton
              action={() => navigation("/payments?type=send")}
              className={styles._button}
              text="send"
              icon={
                <Icon
                  icon="bx:left-arrow-alt"
                  className={styles._arrowIcon}
                />}
            />
            <GeneralButton
              action={() => navigation("/payments?type=receive")}
              className={styles._button}
              text="receive"
              icon={
                <Icon
                  icon="bx:left-arrow-alt"
                  className={styles._arrowIcon}
                  style={{ transform: "rotate(270deg)" }}
                />}
            />
          </>
        )}

      </div>

      <div className={styles._myAccounts}>
        <p>{t('my_accounts')}</p>
        {/* <div className={styles._smallCard}>
          <Settings />
        </div> */}
      </div>

      <div className={styles._smallCardsParent}>
        {
          buildArray(wallets).map((item: any, index: number) => {
            const name = item?.wallet?.name
            return (
              <div
                className={verifyWallets(name) && name == 'disabled'
                  ? styles._disabled
                  : styles._smallSquare}
                key={index}
                onClick={() => handleCardsAction(item)}
              >
                {!verifyWallets(name) && <img src={`${baseUrl}/resources/${name}-card-icon.svg`} width={35} alt='wallet-small-card' />}
                {verifyWallets(name) && <Icon icon='bx:plus-circle' width={30}></Icon>}
              </div>
            )
          })
        }
      </div>
      <SelectWallets open={status} onClose={switchStatus} refetch={refetch} userWallets={wallets}/>
    </>
  )
}

export default memo(MobileTopSection)
