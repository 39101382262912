import { gql } from '@apollo/client';

const USER_PERMISSIONS_QUERY = gql`
  query UserPermissions($branchOfficeId: ID!) {
    userPermissions(branchOfficeId: $branchOfficeId) {
      name
    }
  }
`;
export default USER_PERMISSIONS_QUERY;
