import { memo } from 'react'
import { NotificacionPanelType } from './types'
import { Icon } from '@iconify/react'
import styles from './styles.module.scss'
import clsx from 'clsx'
import styled from 'styled-components'

const NotificationPanel = ({
  open = 0,
  onCancel= null,
  message,
  linkMessage,
  link,
  className
}: NotificacionPanelType) => {

  const assignClass = () => {
    if (open === 0) return styles._mainStatic
    if (open === 1) return styles._mainIn
    if (open === 2) return styles._mainOut
  }
  return (
        <div className={clsx(assignClass(), styles._notification ,className)} >
          <div className={styles._content}>
            <p className={styles._text}>{message}
             <a target={"_blank"} rel="noreferrer" href={link}>{linkMessage}</a></p>

            <div className={clsx(styles._closeIconParent, styles._pointer)} onClick={onCancel}>
              <Icon icon='zondicons:close' width={15} color='#FFF'></Icon>
            </div>
          </div>

        </div>
  )
}

export default styled(memo(NotificationPanel))`
  height: ${({ height }) => (height ? height : "2.875rem")};
  background: ${({ background }) => (background ? background : "#E6427A")};
`;

