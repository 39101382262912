import React, { memo } from 'react'
import Grid from '@mui/material/Grid'
import { Icon } from '@iconify/react'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'
import { parseAmount } from 'utils'

const DetailRow = ({ onRow, item }: any) => {
  const { t } = useTranslation()
  const { externalUser, status, transactionType, amount, total, createdAt } = item || {}

  return (
    <div className={styles._row} onClick={() => onRow(item)}>
      <p className={styles._date}>{createdAt}</p>
      <Grid container className={styles._rowChild}>
        <Grid item xs={6}>
          <div className={styles._iconText}>
            <div className={styles._iconParent}>
              {
                transactionType == 'in'
                ? <Icon icon='bx:up-arrow-alt' width={22} height={22} color='#65C65D'></Icon>
                : <Icon icon='bx:down-arrow-alt' width={22} height={22} color='#D94848'></Icon>
              }
            </div>
            <div>
              <ul className={styles._list}>
                <li>{externalUser?.name}</li>
                <li>{externalUser?.email}</li>
              </ul>
            </div>
          </div>
        </Grid>
        <Grid item xs={3} >
          <div className={styles._textParent}>
            <p>{t(status)}</p>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={styles._listParent}>
            <ul className={styles._amountsList}>
              <li>${parseAmount(amount)}</li>
              <li>${parseAmount(total)}</li>
            </ul>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default memo(DetailRow)
