import styles from "./styles.module.scss";
import {
  GeneralInput,
  GeneralButton,
  Tooltip,
  GeneralOTP,
} from "../../../../components";
import { OnboardingFooter, OnboardingLayout } from "../../../components";
import { FormikConfig } from "./formik";
import { useNavigate, useLocation } from "react-router-dom";
import { UseIntermitence } from "hooks";
import { UseAlert } from "hooks";
import { RESET_PASSWORD, VERIFY_OTP_RESET } from "graphql/mutations";
import { useMutation } from "@apollo/client";
import { useEffect } from "react";
import i18next from "i18next";

const ChangePassword = ({ t }: any) => {
  const navigate = useNavigate();
  const { status, switchStatus } = UseIntermitence();
  const { status: iconStatus, switchStatus: switchIconStatus } =
    UseIntermitence();
  const { status: iconConfirmStatus, switchStatus: switchConfirmIconStatus } =
    UseIntermitence();
  const { showAlert } = UseAlert();
  const [reset_password, { loading }] = useMutation(RESET_PASSWORD);
  const [verifyOtp, { loading: otpLoading }] = useMutation(VERIFY_OTP_RESET);
  const { search } = useLocation();
  const initPathToken = search.split("=");
  const pathToken = initPathToken[1].split("&lang");

  useEffect(() => {
    i18next.changeLanguage(initPathToken[2]);
    verifyOtp({
      variables: {
        data: {
          verifierToken: pathToken[0],
        },
      },
      onCompleted: (data) => {
        if (data.verifyTwoFactor?.result?.length > 0) {
          setFieldValue("showOtp", true);
        }
      },
      onError: (error: any) => {
        showAlert(error?.message, "error");
      },
    });
  }, []);
  const handleSubmit = (formValues: any) => {
    const data = {
      resetToken: pathToken[0],
      newPassword: formValues.password,
      otp: formValues.otp,
    };

    reset_password({
      variables: { ...data },
      onError: (error: any) => showAlert(error?.message, "error"),
      onCompleted: (response: any) => {
        const {
          resetPassword: { result },
        } = response;

        if (result) {
          showAlert("change_password_success", "success");
          return navigateTo("/login");
        }
        showAlert("reset_password_fail", "error");
      },
    });
  };
  const handleOnTouched = (key: string) =>
    setTouched({ ...touched, [key]: true });
  const navigateTo = (route: string) => {
    navigate(route);
  };

  const {
    values,
    handleSubmit: formikSubmit,
    handleChange,
    submitForm,
    errors,
    touched,
    setTouched,
    dirty,
    isValid,
    setFieldValue,
  } = FormikConfig(handleSubmit);

  const changeInputStatus = (value: string, error: any) => {
    if (value !== "") {
      if (error) return "error";
      return "success";
    }
    return "inherit";
  };

  return (
    <OnboardingLayout>
      <div className={styles._mainContainer}>
        <form className={styles._form} onSubmit={formikSubmit} id="my-form">
          <div className={styles._input}>
            <p className={styles._title}>{t("change_password")}</p>
            <p className={styles._tinyTitle}>{t("change_your_password")}</p>
            <div className={styles._inputDualContainer}>
              <div className={styles._inputContainer}>
                <div style={{ position: "relative" }}>
                  {status && (
                    <Tooltip text="password_pattern" top="-130%" left="65%" />
                  )}

                  <GeneralInput
                    placeholder="password"
                    onFocus={() => {
                      switchStatus();
                      handleOnTouched("password");
                    }}
                    onBlur={switchStatus}
                    withIcon
                    iconColor={"#919191"}
                    icon={`${!iconStatus ? "bx:show" : "bx:low-vision"}`}
                    id="password"
                    name="password"
                    onChange={handleChange}
                    value={values.password}
                    hasError={
                      errors.password && touched.password ? true : false
                    }
                    iconMethod={switchIconStatus}
                    type={`${!iconStatus ? "password" : "text"}`}
                    errorMessage={errors.password}
                    status={changeInputStatus(
                      values.password,
                      errors.password && touched.password
                    )}
                  />
                </div>
              </div>
              <div className={styles._inputContainer}>
                <GeneralInput
                  placeholder="confirm_password"
                  withIcon
                  id="confirmPassword"
                  name="confirmPassword"
                  iconColor={"#919191"}
                  icon={`${!iconConfirmStatus ? "bx:show" : "bx:low-vision"}`}
                  type={`${!iconConfirmStatus ? "password" : "text"}`}
                  iconMethod={switchConfirmIconStatus}
                  onChange={handleChange}
                  hasError={
                    errors.confirmPassword && touched.confirmPassword
                      ? true
                      : false
                  }
                  value={values.confirmPassword}
                  onFocus={() => handleOnTouched("confirmPassword")}
                  errorMessage={errors.confirmPassword}
                  status={changeInputStatus(
                    values.confirmPassword,
                    errors.confirmPassword && touched.confirmPassword
                  )}
                />
              </div>
            </div>
          </div>

          <div className={styles._bottomContainer}>
            {values.showOtp && (
              <div className={styles._otpContainer}>
                <p className={styles._title}>{t("2fa_code")}</p>
                <p className={styles._subtitle}>
                  {t("enter_2fa_code_change_password")}
                </p>
                <GeneralOTP
                  value={values.otp}
                  error={touched.otp && errors.otp}
                  placeholder="000000"
                  onChange={(value) => {
                    handleOnTouched("otp");
                    setFieldValue("otp", value);
                  }}
                  className={styles._otp}
                />
              </div>
            )}
            <div className={styles._buttonsContainer}>
              <div className={styles._buttonLeft}>
                <GeneralButton
                  action={() => navigate("/login")}
                  text={"return"}
                  type="submit"
                  isGoBack={true}
                />
              </div>

              <div className={styles._buttonRight}>
                <GeneralButton
                  action={submitForm}
                  text={"continue"}
                  type="submit"
                  disabled={!isValid || !dirty}
                  loading={loading || otpLoading}
                />
              </div>
            </div>

            <div className={styles._footer}>
              <OnboardingFooter
                buttonText="log_in"
                buttonAction={submitForm}
                showStepper={false}
                showBtn={false}
                message={t("back_to")}
                link={t("log_in_two")}
                route={"/login"}
              />
            </div>
          </div>
        </form>
      </div>
    </OnboardingLayout>
  );
};

export default ChangePassword;
