import { gql } from '@apollo/client';

const DELETE_BRANCH_OFFICE = gql`
    mutation DeleteBranchOffice($id: ID!) {
      deleteBranchOffice(data: { id: $id }) {
        result
    }
  }
`;
export default DELETE_BRANCH_OFFICE;

