import { gql } from '@apollo/client';

const VERIFY_OTP_RESET = gql`
  mutation VerifyTwoFactor($data: VerifyTokenInput!) {
    verifyTwoFactor(data: $data) {
      result
    }
  }
`;
export default VERIFY_OTP_RESET;
