import { useLazyQuery } from "@apollo/client";
import BRANCH_OFFICE_QUERY from "graphql/queries/branchOffice";
import { useState } from "react";
import RolesList from "./components/RolesList";
import styles from "./styles.module.scss";

const Roles = ({ t }: any) => {
  const [view, setView] = useState("list");

  return (
    <div className={styles._mainContainer}>
      <RolesList t={t} setView={setView} />
    </div>
  );
};

export default Roles;
