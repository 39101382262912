import i18next from 'i18next';
import en from './en.json'
import es from './es.json'

i18next
  .init({
    lng: 'es',
    resources: {
      en: {
        translations: en
      },
      es: {
        translations: es
      }
    },
    ns: ['translations'],
    defaultNS: 'translations',
    returnObjects: true,
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false
    }
  });

i18next.languages = ['es', 'en'];

export default i18next;
