import { useFormik } from 'formik';
import * as Yup from 'yup';
import { translate, onlyLettersNumbersAndSpace } from 'utils';

export const FormikConfig = (submitData: any) =>
  useFormik({
    initialValues: {
      message: '',
    },

    validationSchema: Yup.object({
      message: Yup.string()
        .required('field_required')
        .min(3, () => translate('min_invalid', { number: 3 }))
        .max(255, () => translate('max_invalid', { number: 255 }))
        .matches(onlyLettersNumbersAndSpace, 'only_letters'),
    }),

    onSubmit: (values) => {
      submitData(values);
    },
  });
