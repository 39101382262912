import { gql } from '@apollo/client';

const CREATE_UI_ROLE = gql`
  mutation CreateUiRole($data: CreateUiRoleInput!) {
    createUiRole(data: $data) {
      id
    }
  }
`;
export default CREATE_UI_ROLE;
