import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { VERYFY_EMAIL } from "graphql/mutations";
import { useMutation } from "@apollo/client";
import { UseAlert } from "hooks";

const VerifyData = (t: any) => {
  const navigate = useNavigate();
  const [verify_email] = useMutation(VERYFY_EMAIL);
  const { showAlert } = UseAlert();
  const { search } = useLocation();
  const pathToken = search.split("?");
  const verifyType = pathToken?.[1]?.split("=");
  const token = verifyType?.[1]?.split("&lang");

  useEffect(() => {
    validateEmail(token[0]);
  }, []);

  const validateEmail = (token: string) => {
    verify_email({
      variables: { verifierToken: token },
      onError: (error: any) => {
        showAlert(error?.message, "error");
        return navigate("/login");
      },
      onCompleted: (response: any) => {
        const {
          verifyEmail: { result },
        } = response;

        if (result) {
          showAlert("account_verified_success", "success");
          return navigate("/login");
        }
        return navigate("/login");
      },
    });
  };

  return <div></div>;
};

export default VerifyData;
