import { gql } from '@apollo/client';

const ROLE_MEMBERS = gql`
  query UiRolesMembers(
    $page: Int
    $perPage: Int
    $search: String
    $orderDirection: OrderDirectionEnum
    $spaceId: ID!
    $uiRoleId: ID!
  ) {
    uiRoleMembers(
      page: $page
      perPage: $perPage
      search: $search
      orderDirection: $orderDirection
      spaceId: $spaceId
      uiRoleId: $uiRoleId
    ) {
      items {
        id
        name
        email
      }
      pagination {
        page
        perPage
        totalItems
        totalPages
      }
    }
  }
`;

export default ROLE_MEMBERS;
