import { gql } from '@apollo/client';

const DELETE_WHITELIST = gql`
    mutation DeleteWhitelist($id: ID!) {
      deleteWhitelist(data: { id: $id } ) {
        result
    }
  }
`;
export default DELETE_WHITELIST;

