import { actionObject } from 'utils';
import { DELETE_FILE, LOAD_FILE } from './action-types';

export const loadFile = (
  file: File,
  folder: string,
  callback?: ({ ok }: { ok: boolean; values: any }) => void
) => actionObject(LOAD_FILE, { file, folder }, callback);

export const deleteFile = (
  name: string,
  callback?: ({ ok }: { ok: boolean }) => void
) => actionObject(DELETE_FILE, { name }, callback);
