import { useState } from "react";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";
import faqs from "../data";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Divider,
} from "@mui/material";
import { accordion, divider } from "../materialStyles";

const MobileAccordion = () => {
  const { t } = useTranslation();
  const [category, setCategory] = useState("");

  const showCategory = (name: string) => {
    if (name == category) return setCategory("");
    setCategory(name);
  };

  return (
    <div className={styles._content}>
      <div className={styles._questions}>
        <Divider sx={divider} />
        {faqs?.map((item: any, index: number) => {
          return (
            <Accordion key={index} elevation={0} sx={accordion}>
              <AccordionSummary
                expandIcon={false}
                className={
                  category == item?.label
                    ? styles._selectedAcordion
                    : styles._backgroundAccordion
                }
                sx={{
                  "& .Mui-expanded": {
                    marginTop: "1rem",
                  },
                }}
                onClick={() => showCategory(item?.label)}
              >
                <Icon className={styles._icon} icon={item?.icon} width={22} />
                <p className={styles._categoryText}>{t(item?.label)}</p>
              </AccordionSummary>
              {item?.questions?.map((subItems: any, subIndex: number) => {
                return (
                  <Accordion key={subIndex} elevation={0}>
                    <AccordionSummary
                      expandIcon={<Icon icon={"bx-chevron-down"} width={22} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      key={subIndex}
                      sx={{
                        paddingLeft: 0,
                        paddingRight: 0,
                        "& .Mui-expanded": {
                          margin: 0,
                        },
                      }}
                      className={styles._backgroundAccordion}
                    >
                      <Typography className={styles._barTitle}>
                        {t(subItems?.question)}
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails
                      className={styles._backgroundAccordionDetail}
                      sx={{ paddingLeft: 0, paddingTop: "30px" }}
                    >
                      <Typography className={styles._text}>
                        {subItems?.question == "want_read_terms" ? (
                          <a
                            target={"_blank"}
                            rel="noreferrer"
                            className={styles._link}
                            href={`${subItems?.answer}`}
                          >
                            {subItems?.answer}
                          </a>
                        ) : (
                          t(subItems?.answer)
                        )}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Accordion>
          );
        })}
      </div>
    </div>
  );
};

export default MobileAccordion;
