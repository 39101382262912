import { gql } from '@apollo/client';

const FRAGMENT_USER_RESPONSE = gql`
  fragment UserResponse on UserType {
    email
    isVerified
    customer {
      type
      fiscalAddress
      monthlyIncome
      position
      signature
      affidavitAccepted
      legalRepresentative {
        profile {
          firstName
          lastName
        }
      }
    }
    role {
      name
    }
    profile {
      firstName
      lastName
      dniType
      dniNumber
      phoneNumber
      cellPhoneNumber
      dateOfBirthday
    }
    spaces {
      id
      space {
        id
        name
        brandPhoto
        code
        EIN
        isSpecial
        branchOffices {
          id
          name
          address
          code
          isPrincipal
          space {
            user {
              email
            }
            isSpecial
          }
          wallets {
            id
            feeValue
            wallet {
              id
              name
              title
            }
            total
          }
        }
      }
    }
  }
`;

export default FRAGMENT_USER_RESPONSE;
