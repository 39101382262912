import { gql } from '@apollo/client';

const DELETE_UI_ROLE = gql`
  mutation DeleteUiRole($id: ID!) {
    deleteUiRole(data: { id: $id }) {
      result
    }
  }
`;
export default DELETE_UI_ROLE;
