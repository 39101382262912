import { gql } from '@apollo/client';

const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($email: String!, $language: LanguageEnum) {
    forgotPassword(data: { email: $email, language: $language }) {
      result
    }
  }
`;
export default FORGOT_PASSWORD;
