import React, { useCallback } from "react";
import styles from "./styles.module.scss";
import {
  naturalIdentification,
  phoneRegex,
  translate,
  onlyNumbersRegex,
  onlyLettersRegex,
  onlyLettersAndNumbers,
} from "utils";
import { Formik, Form, FieldArray } from "formik";
import {
  GeneralInput,
  GeneralInputDate,
  GeneralPhoneInput,
  GeneralUploadFile,
  BackArrow,
} from "../../../../../components";
import { baseUrl } from "paths";
import { OnboardingFooter } from "../../../../components";
import * as Yup from "yup";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { setEraseData, setUserData } from "store/actions";
import { shareholdersDataSelector } from "store/onboarding/selectors";
import { useNavigate } from "react-router-dom";
import { onboardingSelector } from "store/selectors";

const initialBlankValue: any = {
  firstName: "",
  lastName: "",
  dniType: "V",
  dniNumber: "",
  dniPhotoName: "",
  dniPhotoUrl: "",
  dateOfBirthday: "",
  email: "",
  cellPhoneNumber: "",
};

const Shareholders = ({ t }: any) => {
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const handleSubmit = useCallback((values: any) => {
    dispatch(setUserData({ shareholdersData: { completed: true, ...values } }));
    navigator("/onboarding/signature");
  }, []);
  const { shareholders } = useSelector(shareholdersDataSelector);
  const onboarding = useSelector(onboardingSelector);

  const handleEraseFile = (setFieldValue: any, index: number) => {
    shareholders[index].dniPhotoName = "";
    shareholders[index].dniPhotoUrl = "";
    dispatch(
      setEraseData({ shareholdersData: { completed: false, shareholders } })
    );
    setFieldValue(`shareholders.${index}.dniPhotoUrl`, "");
    setFieldValue(`shareholders.${index}.dniPhotoName`, "");
  };

  const changeInputStatus = (
    touched: any,
    errors: any,
    index: number,
    field: string
  ) => {
    return touched.shareholders && touched.shareholders[index]?.[field]
      ? errors.shareholders && errors.shareholders[index]?.[field]
        ? "error"
        : "success"
      : "inherit";
  };

  return (
    <div className={styles._mainContainer}>
      <div className={styles._arrowContainer}>
        <BackArrow action route={"/onboarding/account-data"} />
      </div>
      <div className={styles._content}>
        <h3 className={styles._title}>{t("required_information")}</h3>
        <p className={styles._subtitle}>{t("fill_form")}</p>
        <p className={styles._sectionTitle}>{t("shareholders")}</p>
        <Formik
          initialValues={{
            shareholders:
              shareholders && shareholders.length
                ? shareholders
                : [initialBlankValue],
          }}
          onSubmit={handleSubmit}
          validationSchema={Yup.object().shape({
            shareholders: Yup.array()
              .of(
                Yup.object().shape({
                  firstName: Yup.string()
                    .required("field_required")
                    .min(3, () => translate("min_invalid", { number: 3 }))
                    .matches(onlyLettersRegex, "only_letters"),
                  lastName: Yup.string()
                    .required("field_required")
                    .min(3, () => translate("min_invalid", { number: 3 }))
                    .matches(onlyLettersRegex, "only_letters"),
                  email: Yup.string()
                    .email("email_valid")
                    .required("field_required"),
                  cellPhoneNumber: Yup.string()
                    .matches(phoneRegex, "phone_invalid")
                    .required("field_required"),
                  dniType: Yup.string().oneOf(["V", "E", "J", "P"]),
                  dniNumber: Yup.string()
                    .required("field_required")
                    .when("dniType", {
                      is: "P",
                      then: Yup.string().matches(
                        onlyLettersAndNumbers,
                        "only_alphanumeric"
                      ),
                      otherwise: Yup.string().matches(
                        onlyNumbersRegex,
                        "only_numbers"
                      ),
                    })
                    .test(
                      "numMaxLength",
                      translate("min_invalid", { number: 3 }),
                      (value) => String(value).length >= 3
                    )
                    .test(
                      "numMaxLength",
                      translate("max_invalid", { number: 10 }),
                      (value) => String(value).length <= 10
                    )
                    .test("sameDni", "same_dni", (_, context: any) => {
                      const array = context.from[1].value.shareholders;
                      const currentIndex = context.options.index;
                      return !(array || []).find(
                        (elem: any, index: number) =>
                          currentIndex != index &&
                          elem.dniNumber == context.originalValue &&
                          elem.dniType == array[currentIndex].dniType
                      );
                    }),
                  dniPhotoName: Yup.string().required(),
                  dniPhotoUrl: Yup.string().required(t("invalid_file")),
                  dateOfBirthday: Yup.date()
                    .required("field_required")
                    .max(new Date(), t("invalid_date_max")),
                })
              )
              .min(1),
          })}
        >
          {({
            values,
            setFieldValue,
            setFieldTouched,
            errors,
            touched,
            isValid,
            submitForm,
            handleChange,
          }: any) => (
            <Form className={styles._formStyles}>
              <FieldArray name="shareholders">
                {({ push, remove }) => (
                  <div className={styles._scrollableContainer}>
                    {values.shareholders.length > 0 &&
                      values.shareholders.map((form: any, index: number) => {
                        return (
                          <div
                            key={index}
                            className={clsx(
                              index > 0 && styles._formContainer,
                              styles._formSeparator
                            )}
                          >
                            <div className={styles._headerRowContainer}>
                              <p className={styles._shareholderText}>
                                {t("shareholder")} {index + 1}
                              </p>
                              <div
                                className={styles._removeShareholderContainer}
                                onClick={() => remove(index)}
                              >
                                <img
                                  src={`${baseUrl}/resources/add-button.svg`}
                                  alt="Add button"
                                  style={{ transform: "rotate(45deg)" }}
                                />
                                <p className={styles._addButtonText}>
                                  {t("delete")}
                                </p>
                              </div>
                            </div>
                            <div className={styles._firstRow}>
                              <GeneralInput
                                id={`shareholders.${index}.firstName`}
                                onChange={handleChange}
                                placeholder="name"
                                value={form.firstName}
                                className={styles._nameInput}
                                onFocus={() =>
                                  setFieldTouched(
                                    `shareholders.${index}.firstName`,
                                    true,
                                    true
                                  )
                                }
                                hasError={
                                  touched.shareholders &&
                                  errors.shareholders &&
                                  touched.shareholders[index]?.firstName &&
                                  errors.shareholders[index]?.firstName
                                }
                                errorMessage={
                                  errors.shareholders &&
                                  errors.shareholders[index]?.firstName
                                }
                                status={changeInputStatus(
                                  touched,
                                  errors,
                                  index,
                                  "firstName"
                                )}
                              />
                              <GeneralInput
                                id={`shareholders.${index}.lastName`}
                                value={form.lastName}
                                onChange={handleChange}
                                placeholder="last_name"
                                className={styles._nameInput}
                                onFocus={() =>
                                  setFieldTouched(
                                    `shareholders.${index}.lastName`,
                                    true,
                                    true
                                  )
                                }
                                hasError={
                                  touched.shareholders &&
                                  errors.shareholders &&
                                  touched.shareholders[index]?.lastName &&
                                  errors.shareholders[index]?.lastName
                                }
                                errorMessage={
                                  errors.shareholders &&
                                  errors.shareholders[index]?.lastName
                                }
                                status={changeInputStatus(
                                  touched,
                                  errors,
                                  index,
                                  "lastName"
                                )}
                              />
                            </div>
                            <div className={styles._secondRow}>
                              <GeneralUploadFile
                                placeholder={"id_or_passport"}
                                selectData={naturalIdentification}
                                inputValue={form.dniNumber}
                                selectValue={form.dniType}
                                nameValue={form.dniPhotoName}
                                linkValue={form.dniPhotoUrl}
                                index={index}
                                methodSelect={(e: any) =>
                                  setFieldValue(
                                    `shareholders.${index}.dniType`,
                                    e.target.value
                                  )
                                }
                                methodInput={(e: any) =>
                                  setFieldValue(
                                    `shareholders.${index}.dniNumber`,
                                    e.target.value
                                  )
                                }
                                methodFile={(values: any) => {
                                  setFieldTouched(
                                    `shareholders.${index}.dniPhotoUrl`,
                                    true,
                                    false
                                  );
                                  setFieldValue(
                                    `shareholders.${index}.dniPhotoUrl`,
                                    values.link
                                  );
                                  setFieldValue(
                                    `shareholders.${index}.dniPhotoName`,
                                    values.name
                                  );
                                }}
                                methodIcon={() =>
                                  handleEraseFile(setFieldValue, index)
                                }
                                onFocus={() =>
                                  setFieldTouched(
                                    `shareholders.${index}.dniNumber`,
                                    true,
                                    true
                                  )
                                }
                                hasError={
                                  touched.shareholders &&
                                  errors.shareholders &&
                                  touched.shareholders[index]?.dniNumber &&
                                  errors.shareholders[index]?.dniNumber
                                }
                                validateMessage={
                                  errors.shareholders &&
                                  errors.shareholders[index]?.dniNumber
                                }
                                inputStatus={changeInputStatus(
                                  touched,
                                  errors,
                                  index,
                                  "dniNumber"
                                )}
                              />
                            </div>
                            <div className={styles._thirdRow}>
                              <GeneralInputDate
                                placeholder="date_of_birth"
                                value={form.dateOfBirthday}
                                id={`shareholders.${index}.dateOfBirthday`}
                                name={`shareholders.${index}.dateOfBirthday`}
                                onChange={handleChange}
                                onFocus={() =>
                                  setFieldTouched(
                                    `shareholders.${index}.dateOfBirthday`,
                                    true,
                                    true
                                  )
                                }
                                hasError={
                                  touched.shareholders &&
                                  errors.shareholders &&
                                  touched.shareholders[index]?.dateOfBirthday &&
                                  errors.shareholders[index]?.dateOfBirthday
                                }
                                status={changeInputStatus(
                                  touched,
                                  errors,
                                  index,
                                  "dateOfBirthday"
                                )}
                                errorMessage={
                                  errors.shareholders &&
                                  errors.shareholders[index]?.dateOfBirthday
                                }
                              />
                            </div>
                            <div className={styles._fourthRow}>
                              <GeneralInput
                                placeholder="email"
                                id={`shareholders.${index}.email`}
                                type="email"
                                value={form.email}
                                onChange={handleChange}
                                status={changeInputStatus(
                                  touched,
                                  errors,
                                  index,
                                  "email"
                                )}
                                onFocus={() =>
                                  setFieldTouched(
                                    `shareholders.${index}.email`,
                                    true,
                                    true
                                  )
                                }
                                hasError={
                                  touched.shareholders &&
                                  errors.shareholders &&
                                  touched.shareholders[index]?.email &&
                                  errors.shareholders[index]?.email
                                }
                                errorMessage={
                                  errors.shareholders &&
                                  errors.shareholders[index]?.email
                                }
                              />
                              <GeneralPhoneInput
                                value={
                                  values.shareholders[index]?.cellPhoneNumber
                                }
                                name={`shareholders.${index}.cellPhoneNumber`}
                                status={changeInputStatus(
                                  touched,
                                  errors,
                                  index,
                                  "cellPhoneNumber"
                                )}
                                errorMessage={
                                  errors.shareholders &&
                                  errors.shareholders[index]?.cellPhoneNumber
                                }
                                hasError={
                                  touched.shareholders &&
                                  errors.shareholders &&
                                  touched.shareholders[index]
                                    ?.cellPhoneNumber &&
                                  errors.shareholders[index]?.cellPhoneNumber
                                }
                                onFocus={() =>
                                  setFieldTouched(
                                    `shareholders.${index}.cellPhoneNumber`,
                                    true,
                                    true
                                  )
                                }
                                placeholder="2125866998"
                                setFieldValue={setFieldValue}
                              />
                            </div>
                          </div>
                        );
                      })}
                    <div
                      className={styles._addButton}
                      onClick={() => push(initialBlankValue)}
                    >
                      <img
                        src={`${baseUrl}/resources/add-button.svg`}
                        alt="Add button"
                      />
                      <p className={styles._addButtonText}>{t("add")}</p>
                    </div>
                  </div>
                )}
              </FieldArray>

              <OnboardingFooter
                buttonDisabled={!isValid}
                buttonAction={() => submitForm()}
                buttonText="continue"
                paddingRight="2.125rem"
                step={3}
                maxSteps={onboarding?.type == "natural" ? 4 : 5}
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Shareholders;
