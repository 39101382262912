import { Icon } from "@iconify/react";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Theme,
  useMediaQuery,
} from "@mui/material";
import { Location } from "react-router-dom";
import {
  listItemIcon,
  swipeableDrawerStyles,
  textItemButton,
  textStyle,
} from "./materialStyles";
import styles from "./styles.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "store/actions";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GeneralConfirmModal, LangButton } from "../../../../components";
import { UseIntermitence } from "hooks";
import { ClaimPayment } from "../../../components";
import { menuOptions, routesTypeAndPermissions } from "utils";
import { userAuthSelector } from "store/selectors";

import { CLAIM_PAYMENT } from "utils/permissions";

const MobileDrawer = ({
  open,
  setOpen,
  location,
}: {
  open: boolean;
  setOpen: any;
  location: Location;
}) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("lg")
  );

  const { status, switchStatus } = UseIntermitence();
  const { status: claimModalStatus, switchStatus: switchClaimModalStatus } =
    UseIntermitence();
  const userData = useSelector(userAuthSelector);
  const userType = userData.userAuthData?.customer?.type;
  const userPermissions = userData.permissions;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const logoutUser = () => {
    dispatch(logout());
    navigate("/login");
  };

  const openModal = () => {
    setOpen(false);
    switchStatus();
  };

  const navigateTo = (path: string) => {
    setOpen(false);
    navigate(path);
  };

  return isMobile ? (
    <>
      <SwipeableDrawer
        anchor="right"
        disableBackdropTransition
        hideBackdrop
        sx={swipeableDrawerStyles}
        className={styles._scrollBar}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        <List
          disablePadding
          sx={{
            mt: "2rem",
          }}
        >
          {menuOptions.map((option, index) =>
            (userType &&
              routesTypeAndPermissions[option.route].type.includes(userType)) ||
            userPermissions?.find(
              (elem: string) =>
                elem === routesTypeAndPermissions[option.route].permission
            ) ? (
              <ListItem
                key={t(option.label)}
                disablePadding
                onClick={() => navigateTo(option?.route)}
              >
                <ListItemButton disableGutters sx={textItemButton}>
                  <ListItemIcon sx={listItemIcon}>
                    <Icon
                      icon={option.icon}
                      className={styles._icon}
                      color={
                        location.pathname.startsWith(option.route)
                          ? "#E6427A"
                          : "000000"
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={t(option.label)}
                    sx={(theme) =>
                      textStyle(
                        theme,
                        location.pathname.startsWith(option.route)
                      )
                    }
                  />
                </ListItemButton>
              </ListItem>
            ) : null
          )}
        </List>
        {(!!userType ||
          userPermissions?.find((elem: string) => elem === CLAIM_PAYMENT)) && (
          <ListItem disablePadding>
            <ListItemButton
              disableGutters
              sx={textItemButton}
              onClick={switchClaimModalStatus}
            >
              <ListItemIcon sx={listItemIcon}>
                <Icon
                  icon="bxs:alarm-exclamation"
                  className={styles._icon}
                  color={
                    /*  location.pathname.startsWith(option.route)
              ? "#E6427A"
              : */ "000000"
                  }
                />
              </ListItemIcon>
              <ListItemText
                primary={t("claim_your_payment")}
                sx={(theme) => textStyle(theme, false)}
              />
            </ListItemButton>
          </ListItem>
        )}
        <div style={{ flexGrow: 1 }} />
        <ListItem sx={{ marginTop: "3rem" }}>
          <LangButton />
        </ListItem>
        <ListItem disablePadding >
          <ListItemButton
            disableGutters
            sx={textItemButton}
            onClick={openModal}
          >
            <ListItemIcon sx={listItemIcon}>
              <Icon
                icon="bx:log-out-circle"
                className={styles._icon}
                color="000000"
              />
            </ListItemIcon>
            <ListItemText
              primary={t("log_out")}
              sx={(theme) => textStyle(theme, false)}
            />
          </ListItemButton>
        </ListItem>
        <p className={styles._copyright}>{t("copyright")}</p>
      </SwipeableDrawer>
      <GeneralConfirmModal
        title="are_you_sure_logout"
        open={status}
        onConfirm={logoutUser}
        onCancel={switchStatus}
        btnText={"confirm"}
      />
      <ClaimPayment
        open={claimModalStatus}
        onConfirm={logoutUser}
        onCancel={switchClaimModalStatus}
        btnText={"claim"}
      />
    </>
  ) : null;
};

export default MobileDrawer;
