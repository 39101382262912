import { gql } from '@apollo/client'

const CREATE_WHITELIST = gql`
  mutation CreateWhitelist($data: CreateWhitelistInput!) {
    createWhitelist(data: $data) {
      id
      type
      firstName
      lastName
      identification
      email
      address
      cellPhoneNumber
    }
  }
`;
export default CREATE_WHITELIST;
