import { SET_USER_SPACE } from './action-types';

const initialState: any = {
  currentSpace: null,
  currentBranchOffice: null
};

const userSpace = (state = initialState, { type, payload }: any) => {
  switch (type) {
    case SET_USER_SPACE:
      return { ...state, ...payload };

    default:
      return state;
  }
};

export default userSpace
