import { gql } from '@apollo/client';
import { FRAGMENT_USER_RESPONSE } from 'graphql/fragments';

const SIGN_IN_GOOGLE = gql`
  ${FRAGMENT_USER_RESPONSE}
  mutation SignInWithGoogle($verifierToken: String!, $otp: String) {
    signInWithGoogle(data: { verifierToken: $verifierToken, otp: $otp }) {
      user {
        ...UserResponse
      }
      accessToken
    }
  }
`;
export default SIGN_IN_GOOGLE;
