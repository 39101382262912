import {
  LOGOUT,
  SET_PERMISSIONS,
  SET_USER_AUTH,
  UPDATE_WALLETS,
  SET_2FA_DISABLE,
  SET_USER_EMAIL,
} from './action-types';

const initialState: any = {
  userAuthData: null,
  permissions: null,
  userEmail: '',
};

const user = (state = initialState, { type, payload }: any) => {
  switch (type) {
    case SET_USER_AUTH:
    case SET_PERMISSIONS:
      return { ...state, ...payload };

    case LOGOUT:
      return {
        ...state,
        userAuthData: null,
        permissions: null,
      };

    case UPDATE_WALLETS:
      return {
        ...state,
        userAuthData: {
          ...state.userAuthData,
          customer: {
            ...state.userAuthData.customer,
            walletAccounts: payload,
          },
        },
      };
    case SET_2FA_DISABLE:
      return {
        ...state,
        userAuthData: {
          ...state.userAuthData,
          twoFactors: [{ isActive: false }],
        },
      };

    case SET_USER_EMAIL:
      return { ...state, userEmail: payload };
    default:
      return state;
  }
};

export default user;
