import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useLazyQuery } from "@apollo/client";
import BRANCH_OFFICES_QUERY from "graphql/queries/branchOffices";
import BranchOfficeCards from "../BranchOfficeCards";
import SmallPagination from "../SmallPagination";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";

const BranchOfficeList = ({ searchValue, order, orderDesc, isAllowed = true }: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);

  const [getLazyBranchOffice, { data, loading, refetch }] = useLazyQuery(
    BRANCH_OFFICES_QUERY,
    {
      fetchPolicy: "no-cache",
      variables: {
        page: currentPage,
        perPage: 5,
        search: searchValue,
        orderBy: order,
        orderDirection: orderDesc ? "desc" : "asc",
      },
    }
  );

  const branchOffices = data?.branchOffices?.items || [];
  const totalPages = data?.branchOffices?.pagination?.totalPages;

  useEffect(() => {
    getLazyBranchOffice();
  }, []);

  const nextPage = () => {
    if (currentPage <= totalPages - 1)
      setCurrentPage((currentPage: number) => currentPage + 1);
  };

  const previousPage = () => {
    if (currentPage >= 2)
      setCurrentPage((currentPage: number) => currentPage - 1);
  };

  if (loading)
    return (
      <div className={styles._loaderParent}>
        <CircularProgress size={24} />
      </div>
    );

  return (
    <>
      <div className={styles._counterPartsContainer}>
        {(branchOffices || []).map((elem: any, index: number) => (
          <BranchOfficeCards
            key={index}
            item={elem}
            className={styles._client}
            onRow={() => navigate("/counterparties")}
            index={index}
            dataLength={branchOffices.length}
            refetch={refetch}
            isAllowed={isAllowed}
          />
        ))}
      </div>

      {branchOffices.length <= 1 && !searchValue && currentPage == 1 && (
        <div className={styles._emptyStateParent}>
          <div className={styles._iconParent}>
            <Icon icon="bx:x-circle" width={32}></Icon>
          </div>
          <div className={styles._textParent}>
            <p>{t("create_branch_failed")}</p>
          </div>
        </div>
      )}

      {!branchOffices.length && searchValue && (
        <div className={styles._emptyStateParent}>
          <div className={styles._iconParent}>
            <Icon icon="bx:x-circle" width={32}></Icon>
          </div>
          <div className={styles._textParent}>
            <p>{t("not_result")}</p>
          </div>
        </div>
      )}

      {branchOffices.length >= 1 && currentPage >= 1 && (
        <div className={styles._paginationParent}>
          <SmallPagination
            page={currentPage}
            next={nextPage}
            previous={previousPage}
            totalPages={totalPages}
          />
        </div>
      )}
    </>
  );
};

export default BranchOfficeList;
