import { gql } from '@apollo/client';

const GET_QR = gql`
  mutation generateAuthenticatorQR($data: GenerateAuthenticatorQRInput!) {
    generateAuthenticatorQR(data: $data) {
      qr
      secretKey
    }
  }
`;

export const ACTIVATE_2FA = gql`
  mutation ActivateAuth($data: ActivateAuthenticatorInput!) {
    activateAuthenticator(data: $data) {
      result
    }
  }
`;

export default GET_QR;
