import { Theme } from '@mui/material';

const cellStyles = (theme: Theme) => ({
  padding: '1.125rem 0 1.125rem 0',
  border: 'none',
  [theme.breakpoints.down('lg')]: {
    padding: '1rem 0 1rem 0',
  },
});

const headerCellStyles = (theme: Theme) => ({
  color: theme.palette.grayColorCustom?.main,
  fontSize: '0.75rem',
  fontWeight: 400,
  border: 'none',
  width: '40px',
  paddingBottom: '1.25rem',
  WebkitTapHighlightColor: 'transparent',
});

export { cellStyles, headerCellStyles };
