
import { useState, useEffect } from "react";
import { Icon } from '@iconify/react'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next';
import { languages } from "utils";
import i18next from 'i18next'

const LangButton = () => {

  const [showAdvice, setShowAdvice] = useState(false)
  const { t } = useTranslation();

  const pickLanguage = (lang:string): void => {
    i18next.changeLanguage(lang);
    setStatusAdvice()
  }
  const setStatusAdvice = () => setShowAdvice(showAdvice => !showAdvice)


  return (
    <div style={{width:'100%', position:'relative'}}>
      <button onClick={()=> setStatusAdvice()} className={styles._button}>
        <div className={styles._buttonContent}>
          <Icon icon='bx:world' color="#FFF" width={22} />
          <p className={styles._text}>{t(i18next?.language)}</p>
        </div>

        <Icon icon='bx:chevron-down' color="#FFF" width={22} />

      </button>
      <div className={showAdvice ? styles._dropStatic : styles._dropHidden}>
        {languages?.map((item: any, index) => (
          <div key={index}
            className={showAdvice ? styles._dropTitleStatic : styles._dropTitleHidden}
            onClick={() => {pickLanguage(item.value) }}>
            <p> {t(item.name)} </p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default LangButton;
