import { baseUrl } from "paths";
import styles from "./styles.module.scss";

const SuccessScreen = () => {
  return (
    <div className={styles._container}>
      <img
        src={`${baseUrl}/resources/2FAScreens/SuccessScreen/Check.svg`}
        alt="Apple logo"
        className={styles._key}
      />
    </div>
  );
};

export default SuccessScreen;
