import { Theme } from '@mui/material';
import { mobileAppbarHeight } from 'utils';

const swipeableDrawerStyles = (theme: Theme) => ({
  width: '100%',
  height: `calc(100% - ${mobileAppbarHeight})`,
  top: 'unset',
  '& .MuiPaper-root': {
    width: '100%',
    height: `calc(100% - ${mobileAppbarHeight})`,
    top: 'unset',
    bottom: 0,
    backgroundColor: theme.palette.background.paper,
  },
  zIndex: 1299,
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
});

const listItemIcon = { minWidth: '2.375rem' };
const textItemButton = { padding: '0.8125rem 0.875rem 0.8125rem 0.875rem' };
const textStyle = (theme: Theme, active: boolean) => ({
  margin: 0,
  '& .MuiTypography-root': {
    fontSize: '14px',
    // lineHeight: '1.27625rem',

    color: active
      ? theme.palette.primary.main
      : theme.palette.blackColorCustom?.main,
  },
});

export { swipeableDrawerStyles, listItemIcon, textItemButton, textStyle };
