import { useEffect, useState } from "react";
import styles from "./styles.module.scss";

import { divider, accordion } from "./materialStyles";
import { Icon } from "@iconify/react";
import faqs from "./data";
import {
  Divider,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@mui/material";
import FaqMobileAccordion from "./components/MobileAccordion";
import { useMediaQuery, Theme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Faq = () => {
  const [category, setCategory]: any = useState();
  const [questions, setQuestion]: any = useState();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const navigate = useNavigate();
  const { t } = useTranslation();

  const showCategory = (category: any) => {
    setCategory(category?.label);
    setQuestion(category?.questions);
  };

  useEffect(() => {
    setCategory(faqs?.[0]?.label);
    setQuestion(faqs?.[0]?.questions);
  }, []);

  return (
    <div className={styles._mainContainer}>
      <div>
        <div className={styles._headerContainer}>
          <div className={styles._barTitleContainer}>
            <Icon
              onClick={() => navigate("/profile")}
              icon={"bx-left-arrow-alt"}
              className={styles._iconPointer}
              width={22}
            />
            <h3 className={styles._barTitle}>{t("support_help")}</h3>
          </div>
        </div>

        <div className={styles._content}>
          {!isMobile && (
            <>
              <div className={styles._leftContainer}>
                <Divider sx={divider} />
                {faqs.map((item: any, index: number) => (
                  <div key={index} className={styles._faqContainer}>
                    <div
                      className={
                        category == item?.label
                          ? styles._selectedCategory
                          : styles._category
                      }
                    >
                      <div
                        className={styles._categoryContainer}
                        onClick={() => showCategory(item)}
                      >
                        <Icon
                          className={styles._icon}
                          icon={item?.icon}
                          width={22}
                        />
                        <p className={styles._categoryText}>{t(item.label)}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className={styles._rightContainer}>
                <div>
                  {questions?.map((question: any, index: number | string) => {
                    return (
                      <Accordion sx={accordion} key={index} elevation={0}>
                        <AccordionSummary
                          sx={{ padding: 0 }}
                          expandIcon={
                            <Icon icon={"bx-chevron-down"} width={22} />
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className={styles._backgroundAccordion}
                        >
                          <Typography className={styles._barTitle}>
                            {t(question?.question)}
                          </Typography>
                        </AccordionSummary>

                        <AccordionDetails
                          className={styles._backgroundAccordionDetail}
                          sx={{ paddingLeft: 0, paddingBottom: 0 }}
                        >
                          <Typography className={styles._text}>
                            {question?.question == "want_read_terms" ? (
                              <a
                                target={"_blank"}
                                rel="noreferrer"
                                className={styles._link}
                                href={`${question?.answer}`}
                              >
                                {question?.answer}
                              </a>
                            ) : (
                              t(question?.answer)
                            )}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </div>
              </div>
            </>
          )}

          {isMobile && <FaqMobileAccordion />}
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Faq;
