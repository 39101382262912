import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { listItemIcon, textItemButton, textStyle } from "../../materialStyles";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import { userAuthSelector } from "store/selectors";

import { UseIntermitence } from "hooks";
import {
  ChangePasswordModal,
  ClearCacheModal,
  Disable2FAModal,
} from "web/components";
import { set2FAEmail } from "store/twoFA/action";
import { useDispatch } from "react-redux";
import { logout } from "store/actions";
import { GeneralSwitch } from "components";
import { useState } from "react";

const Settings = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [notificationStatus, setNotificationStatus] = useState(false);

  const setNotifications = () =>
    setNotificationStatus((notificationStatus) => !notificationStatus);

  const { switchStatus: switchPasswordStatus, status: passwordStatus } =
    UseIntermitence();
  const { switchStatus: switchTwoFactStatus, status: twoFactStatus } =
    UseIntermitence();

  const {
    userAuthData: { email, twoFactors },
  } = useSelector(userAuthSelector);

  const validateTwoFA = () => {
    if (twoFactors[0]?.isActive) return switchTwoFactStatus();
    dispatch(set2FAEmail(email));
    navigate("/set-2fa");
  };
  const { switchStatus: switchCacheStatus, status: cacheStatus } =
    UseIntermitence();

  const profileOptions = [
    {
      label: "authenticator",
      icon: "bx-shield-quarter",
      method: () => validateTwoFA(),
      switch: false,
    },

    {
      label: "change_password",
      icon: "bxs:lock-alt",
      method: () => switchPasswordStatus(),
      switch: false,
    },
    {
      label: "deal_flow",
      icon: "bxs-offer",
      method: () => navigate("/deal-flow"),
      switch: false,
    },
    // {
    //   label: "notification",
    //   icon: "bxs-bell-ring",
    //
    // },
    {
      label: "faq",
      icon: "bxs-help-circle",
      method: () => navigate("/faq"),
      switch: false,
    },
    {
      label: "clear_cache",
      icon: "bxs-brush",
      method: () => switchCacheStatus(),
      switch: false,
    },
  ];

  return (
    <>
      <List disablePadding className={styles._listContainer}>
        {profileOptions.map((option, index) => {
          return (
            <ListItem key={index} disablePadding>
              <ListItemButton
                disableGutters
                sx={textItemButton}
                onClick={option?.method}
              >
                <ListItemIcon sx={listItemIcon}>
                  <Icon
                    icon={option.icon}
                    className={styles._icon}
                    color={
                      /*  location.pathname.startsWith(option.route)
              ? "#E6427A"
              : */ "000000"
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  primary={t(option?.label)}
                  sx={(theme) => textStyle(theme, false)}
                />
                {option?.switch && (
                  <GeneralSwitch
                    checked={notificationStatus}
                    onChange={setNotifications}
                  />
                )}
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
      {passwordStatus && (
        <ChangePasswordModal
          open={passwordStatus}
          have2fa={twoFactors[0]?.isActive}
          onCancel={() => switchPasswordStatus()}
        ></ChangePasswordModal>
      )}
      {twoFactStatus && (
        <Disable2FAModal
          open={twoFactStatus}
          onCancel={() => switchTwoFactStatus()}
        ></Disable2FAModal>
      )}
      {cacheStatus && (
        <ClearCacheModal
          open={cacheStatus}
          onCancel={() => switchCacheStatus()}
        />
      )}
    </>
  );
};

export default Settings;
