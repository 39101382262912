import React, { useEffect } from 'react';
import { GeneralModal, GeneralInput, GeneralSelect, GeneralButton } from '../../../components'
import styles from './styles.module.scss'
import Grid from '@mui/material/Grid'
import { FormikConfig } from './formik'
import { useSelector } from 'react-redux'
import { userSpaceSelector } from 'store/selectors';
import { countries } from 'utils/countries';
import { useMediaQuery, Theme } from '@mui/material'
import { useMutation } from '@apollo/client';
import { CREATE_WHITELIST, UPDATE_WHITELIST } from 'graphql/mutations';
import { UseAlert } from 'hooks';
import { useTranslation } from 'react-i18next'
import GeneralInputLabel from '../GeneralInputLabel';

const types = [{ label: 'employee', value: 'EMPLOYEE' }, { label: 'customer', value: 'CUSTOMER' }, { label: 'provider', value: 'SUPPLIER' }]

const CreateWhiteList = ({ open, onClose, refresh, currentWhiteList, updateDetail }: any) => {

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const { currentSpace } = useSelector(userSpaceSelector)
  const { t } = useTranslation()

  const handleOnTouched = (key: string) => setTouched({ ...touched, [key]: true });

  const { showAlert } = UseAlert()
  const [createWhiteList, { loading }] = useMutation(CREATE_WHITELIST);
  const [updateWhiteList, { loading: updateLoading }] = useMutation(UPDATE_WHITELIST);

  const submitData = (formData: any) => {
    createWhiteList({
      variables: { data: { ...formData } },
      onError: (error) => {
        showAlert('whitelist_error', 'error')
      },
      onCompleted: (response: any) => {
        if (response) {
          showAlert(('white_list_created'), 'success')
          refresh && refresh()
          onClose && onClose()
          resetForm()
        }
      }
    })
  };

  const updateData = (formData: any) => {
    updateWhiteList({
      variables: { data: { ...formData } },
      onError: (error) => {
        showAlert('whitelist_error_updated', 'error')
      },
      onCompleted: (response: any) => {
        if (response) {
          showAlert(('white_list_updated'), 'success')
          refresh && refresh()
          onClose && onClose()
          updateDetail && updateDetail(response?.updateWhitelist)
          resetForm()
        }
      }
    })
  };

  const {
    submitForm,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setTouched,
    setFieldValue,
    isValid,
    values,
    resetForm
  } = FormikConfig(submitData, currentSpace?.id, currentWhiteList, updateData);

  const changeInputStatus = (value: string, error: any) => {
    if (error) return "error"
    if (value?.length) return "success"
    return "inherit";
  };

  useEffect(() => {
    if (!currentWhiteList) {
      setFieldValue('mobileCode', '+58')
    }

    return () => resetForm()
  }, [open])

  const handleLocalCode = (event: any, key: string) => {
    const { target: { value } } = event;
    setFieldValue(key, value);
  }

  return (
    <>
      <GeneralModal open={open} title={t(!currentWhiteList ? 'add_user' : 'edit_contact')} onCancel={onClose && onClose}>
        <div className={styles._main}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={isMobile ? 2.8 : 3.5} >

              <Grid item xs={12} sm={12} md={6}>
                <GeneralInput
                  placeholder='name'
                  id='firstName'
                  name='firstName'
                  onChange={handleChange}
                  value={values?.firstName}
                  hasError={errors.firstName && touched.firstName ? true : false}
                  onFocus={() => handleOnTouched("firstName")}
                  errorMessage={errors.firstName}
                  status={changeInputStatus(
                    values?.firstName,
                    errors.firstName && touched.firstName
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <GeneralInput
                  placeholder='last_name'
                  id='lastName'
                  name='lastName'
                  onChange={handleChange}
                  value={values?.lastName}
                  hasError={errors.lastName && touched.lastName ? true : false}
                  onFocus={() => handleOnTouched("lastName")}
                  errorMessage={errors.lastName}
                  status={changeInputStatus(
                    values?.lastName,
                    errors.lastName && touched.lastName
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <GeneralInput
                  placeholder='id'
                  id='identification'
                  name='identification'
                  onChange={handleChange}
                  value={values?.identification}
                  hasError={errors.identification && touched.identification ? true : false}
                  onFocus={() => handleOnTouched("identification")}
                  errorMessage={errors.identification}
                  status={changeInputStatus(
                    values?.identification,
                    errors.identification && touched.identification
                  )}
                />
              </Grid>


              <Grid item xs={12} sm={12} md={6}>
                <GeneralSelect
                  options={types}
                  id="type"
                  name="type"
                  placeholder={t('type')}
                  onChange={(event: any) => handleLocalCode(event, 'type')}
                  value={values?.type}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <GeneralInputLabel
                  placeholder='address'
                  id='address'
                  name='address'
                  className={styles._addressInput}
                  onChange={handleChange}
                  fullWidth
                  label={`(${t('optional')})`}
                  value={values?.address}
                  hasError={errors.address && touched.address ? true : false}
                  onFocus={() => handleOnTouched("address")}
                  errorMessage={errors.address}
                  status={changeInputStatus(
                    values.address,
                    errors.address && touched.address
                  )} />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <p className={styles._label}>{t('email')}</p>

                <GeneralInput
                  placeholder='email_placeholder'
                  id='email'
                  name='email'
                  onChange={handleChange}
                  value={values?.email}
                  hasError={errors.email && touched.email ? true : false}
                  onFocus={() => handleOnTouched("email")}
                  errorMessage={errors.email}
                  status={changeInputStatus(
                    values.email,
                    errors.email && touched.email
                  )} />

              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <p className={styles._label}>{t('cell_phone')}</p>
                <div className={styles._row}>
                  <div className={styles._leftSide}>
                    <GeneralSelect options={countries.countries} id="mobileCode" name="mobileCode" onChange={(event: any) => handleLocalCode(event, 'mobileCode')} value={values?.mobileCode} />
                  </div>

                  <div className={styles._rightSide}>
                    <GeneralInput
                      placeholder='2123443334'
                      id='cellPhoneNumber'
                      name='cellPhoneNumber'
                      onChange={handleChange}
                      value={values?.cellPhoneNumber}
                      hasError={errors.cellPhoneNumber && touched.cellPhoneNumber ? true : false}
                      onFocus={() => handleOnTouched("cellPhoneNumber")}
                      errorMessage={errors.cellPhoneNumber}
                      status={changeInputStatus(
                        values.cellPhoneNumber,
                        errors.cellPhoneNumber && touched.cellPhoneNumber
                      )}
                    ></GeneralInput>
                  </div>
                </div>
              </Grid>
            </Grid>

            <div className={styles._buttonParent}>
              <GeneralButton text={'save'} type='submit' action={submitForm} loading={loading || updateLoading} disabled={!isValid} />
            </div>
          </form>
        </div>
      </GeneralModal>
    </>
  )
};

export default CreateWhiteList
