import { gql } from '@apollo/client';

const RESET_PASSWORD = gql`
  mutation ResetPassword(
    $resetToken: String!
    $newPassword: String!
    $otp: String
  ) {
    resetPassword(
      data: { resetToken: $resetToken, newPassword: $newPassword, otp: $otp }
    ) {
      result
    }
  }
`;
export default RESET_PASSWORD;
