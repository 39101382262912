import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import { accordion } from "../../materialStyles";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import i18next from "i18next";
import { PROFILE_QUERY } from "graphql/queries";
import { userSpaceSelector } from "store/selectors";
import { useQuery } from "@apollo/client";
import { parseAmount } from "utils";
import { useState } from "react";

const Detail = () => {
  const { t } = useTranslation();
  const { currentSpace } = useSelector(userSpaceSelector);
  const [openBussines, setOpenBussines]: any = useState(true);
  const [openLegal, setOpenLegal]: any = useState(true);
  const [openShareHolders, setOpenShareHolders]: any = useState(true);
  const { data: profile, loading: profileLoading } = useQuery(PROFILE_QUERY, {
    variables: {
      spaceId: currentSpace?.id,
    },
    fetchPolicy: "cache-and-network",
  });

  return (
    <div className={styles._bottomContainer_child}>
      <Grid
        container
        sx={{
          paddingTop: 0,
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <Grid item xs={12}>
          <p className={styles._subtitle}>{t("creation")}</p>
          <p className={styles._content}>{profile?.customer?.dateOfCreation}</p>
        </Grid>
        <Grid item xs={6} lg={6} className={styles._item}>
          <p className={styles._subtitle}>{t("name")}</p>
          <p className={styles._content}>
            {profile?.customer?.type == "juridic"
              ? profile?.customer?.legalRepresentative?.businessName
              : `${profile?.customer?.user?.profile?.firstName} ${profile?.customer?.user?.profile?.lastName}`}
          </p>
        </Grid>
        <Grid item xs={6} lg={6} className={styles._item}>
          <p className={styles._subtitle}>{t("Rif")}</p>
          <p className={styles._content}>
            {profile?.customer?.user?.profile?.dniType}-
            {profile?.customer?.user?.profile?.dniNumber}
          </p>
        </Grid>

        <Grid item xs={12} lg={12} className={styles._item}>
          <p className={styles._subtitle}>{t("natural_address")}</p>
          <p className={styles._content}>{profile?.customer?.fiscalAddress}</p>
        </Grid>
      </Grid>
      <p className={clsx(styles._titleBold)}>{t("legal_signature")}</p>
      <div className={styles._signatureContainer}>
        <img
          src={`${profile?.customer?.signatureUrl}`}
          alt="signature"
          className={styles._signature}
        />
      </div>

      <Accordion
        sx={accordion}
        expanded={openBussines}
        onChange={() => setOpenBussines((prevValue: any) => !prevValue)}
        elevation={0}
      >
        <AccordionSummary
          expandIcon={<Icon icon={"bx-chevron-down"} width={22} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={styles._backgroundAccordion}
        >
          <Typography className={styles._accordionTitle}>
            {t("bussines")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingLeft: 0, paddingBottom: 0 }}>
          <Grid
            container
            sx={{
              paddingTop: 0,
              paddingLeft: "5px",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Grid item xs={6} lg={6} className={styles._bussinesItem}>
              <p className={styles._subtitle}>{t("business_type")}</p>
              <div className={styles._commerceType}>
                {profile?.customer?.typeCommerceFallback ? (
                  <p className={styles._content}>
                    {profile?.customer?.typeCommerceFallback}
                  </p>
                ) : (
                  <>
                    <Icon
                      icon={profile?.customer?.typeCommerce?.icon}
                      color="#E6427A"
                    />
                    <p className={styles._content}>
                      {i18next.language == "es"
                        ? profile?.customer?.typeCommerce?.esName
                        : profile?.customer?.typeCommerce?.enName}
                    </p>
                  </>
                )}
              </div>
            </Grid>
            <Grid item xs={6} lg={6} className={styles._bussinesItem}>
              <p className={styles._subtitle}>{t("local_telephone")}</p>
              <p className={styles._content}>
                {profile?.customer.user.profile.cellPhoneNumber}
              </p>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              paddingTop: 0,
              paddingLeft: "5px",
              display: "flex",
              justifyContent: "flex-start",
              marginBottom: "0.5rem",
            }}
          >
            <Grid item xs={6} lg={6} className={styles._bussinesItem}>
              <p className={styles._subtitle}>{t("monthly_income")}</p>
              <p className={styles._content}>
                ${parseAmount(profile?.customer?.monthlyIncome)}
              </p>
            </Grid>
            <Grid item xs={6} lg={6} className={styles._bussinesItem}>
              <p className={styles._subtitle}>{t("email_not_electronic")}</p>
              <p className={styles._content}>{profile?.customer.user.email}</p>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      {profile?.customer?.type == "juridic" && (
        <Accordion
          sx={accordion}
          expanded={openLegal}
          onChange={() => setOpenLegal((prevValue: any) => !prevValue)}
          elevation={0}
        >
          <AccordionSummary
            expandIcon={<Icon icon={"bx-chevron-down"} width={22} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={styles._backgroundAccordion}
          >
            <Typography className={styles._accordionTitle}>
              {t("legal_representative")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ paddingLeft: 0, paddingBottom: 0 }}>
            <Grid
              container
              sx={{
                paddingTop: 0,
                paddingLeft: "5px",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <Grid item xs={6} lg={6} className={styles._bussinesItem}>
                <p className={styles._subtitle}>{t("legal_representative")}</p>
                <p className={styles._content}>
                  {`${profile?.customer?.legalRepresentative?.profile?.firstName} ${profile?.customer?.legalRepresentative?.profile?.lastName}`}
                </p>
              </Grid>
              <Grid item xs={6} lg={6} className={styles._bussinesItem}>
                <p className={styles._subtitle}>{t("Rif")}</p>
                <p className={styles._content}>
                  {profile?.customer?.legalRepresentative?.dni}
                </p>
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                paddingTop: 0,
                paddingLeft: "5px",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <Grid item xs={12} lg={12} className={styles._bussinesItem}>
                <p className={styles._subtitle}>{t("natural_address")}</p>
                <p className={styles._content}>
                  {profile?.customer?.fiscalAddress}
                </p>
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                paddingTop: 0,
                paddingLeft: "5px",
                display: "flex",
                justifyContent: "flex-start",
                marginBottom: "0.5rem",
              }}
            >
              <Grid item xs={4} lg={4} className={styles._bussinesItem}>
                <p className={styles._subtitle}>{t("phone")}</p>
                <p className={styles._content}>
                  {profile?.customer?.legalRepresentative?.profile?.phoneNumber}
                </p>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}

      {profile?.customer?.type == "juridic" && (
        <Accordion
          sx={accordion}
          expanded={openShareHolders}
          onChange={() => setOpenShareHolders((prevValue: any) => !prevValue)}
          elevation={0}
        >
          <AccordionSummary
            expandIcon={<Icon icon={"bx-chevron-down"} width={22} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={styles._backgroundAccordion}
          >
            <Typography className={styles._accordionTitle}>
              {t("shareholders")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              padding: "8px 0 0 0",
              borderBottom: "1px solid #6B818C",
            }}
          >
            <div className={styles?._shareholdersBox}>
              {profile?.customer.shareholders.map(
                (item: any, index: string) => {
                  return (
                    <div key={index} className={styles._shareholdersContainer}>
                      <div className={styles._shareHolderItems}>
                        <p className={styles._contentShareHolders}>
                          {`${item?.profile?.firstName} ${item?.profile?.lastName}`}
                        </p>
                      </div>
                      <div className={styles._shareHolderItems}>
                        <p className={styles._contentSubtitle}>{item?.dni}</p>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
};

export default Detail;
