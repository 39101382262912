import { useFormik } from 'formik';
import * as Yup from 'yup';
import { passwordRegex, translate } from 'utils';

export const FormikConfig = (submitData: any) =>
  useFormik({
    initialValues: {
      password: '',
      otp: '',
    },

    validationSchema: Yup.object({
      password: Yup.string()
        .required('field_required')
        .matches(passwordRegex, 'field_invalid'),
      otp: Yup.string()
        .required('field_required')
        .min(6, () => translate('min_invalid', { number: 6 })),
    }),

    onSubmit: (values) => {
      submitData(values);
    },
  });
