import { memo } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import {
  Login,
  ForgotPassword,
  ChangePassword,
  VerifyData,
  Dashboard,
  Activity,
  Faq,
  BranchOffice,
  Directory,
  Payments,
  VerifyAccount,
  Profile,
  DealFlow,
} from "../web/screens";
import { withTrans } from "../i18n/withTrans";
import Onboarding from "./onboarding";
import AllSet from "../web/screens/Onboarding/AllSet";
import { DashboardLayout } from "../web/components";
import { ProtectedRoute } from "../components";
import TwoFARouter from "./2FA";
import Roles from "web/screens/Dashboard/Roles";

const Main = (props: any) => (
  <>
    <Routes>
      <Route path="/" element={<Navigate to="/login" replace />} />
      <Route path="/onboarding/*" element={<Onboarding {...props} />} />
      <Route path="/login" element={<Login {...props} />} />
      <Route path="/forgot-password" element={<ForgotPassword {...props} />} />
      <Route path="/change-password" element={<ChangePassword {...props} />} />
      <Route path="/all-set" element={<AllSet {...props} />} />
      <Route path="/verify" element={<VerifyData {...props} />} />
      <Route path="/set-2fa/*" element={<TwoFARouter {...props} />} />
      <Route path="verify-account" element={<VerifyAccount {...props} />} />

      <Route path="/" element={<ProtectedRoute />}>
        <Route element={<DashboardLayout />}>
          <Route element={<Dashboard {...props} />} path="/dashboard" />
          <Route element={<Activity {...props} />} path="/activity" />
          <Route element={<BranchOffice {...props} />} path="/branch-office" />
          <Route element={<Faq {...props} />} path="/faq" />
          <Route element={<Directory {...props} />} path="/directory" />
          <Route element={<Roles {...props} />} path="/roles" />
          <Route element={<Payments {...props} />} path="/payments" />
          <Route element={<Profile {...props} />} path="/profile" />
          <Route element={<DealFlow {...props} />} path="/deal-flow" />
        </Route>
      </Route>
    </Routes>
  </>
);

export default memo(withTrans(Main));
