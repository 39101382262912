import React, { useEffect } from "react";

import styles from "./styles.module.scss";
import { useMutation } from "@apollo/client";
import { GENERATE_QR } from "graphql/mutations";
import { setAlert } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { set2FAData } from "store/twoFA/action";
import { twoFASelector } from "store/twoFA/selectors";

const QRScreen = ({ email }: { email: string }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const qrData = useSelector(twoFASelector);
  const [sendMutation, { loading, error }] = useMutation(GENERATE_QR, {
    onError: () => {
      dispatch(setAlert(2, "error", "general_error"));
    },
    onCompleted: (data) => {
      dispatch(
        set2FAData(
          data.generateAuthenticatorQR.secretKey,
          data.generateAuthenticatorQR.qr
        )
      );
    },
  });
  useEffect(() => {
    !qrData?.code &&
      sendMutation({
        variables: { data: { email } },
      });
  }, []);

  return loading || error ? (
    <CircularProgress sx={{ display: "block", margin: "2rem auto" }} />
  ) : (
    <>
      <div className={styles._container}>
        <img
          src={qrData?.QR}
          alt="Apple logo"
          width="154"
          className={styles._qr}
        />
      </div>
      <p className={styles._secret}>{qrData?.code}</p>
      <p className={styles._suggestion}>
        {t("if_have_problems_qr")}
        <br /> {t("enter_digits_manually")}
      </p>
    </>
  );
};

export default QRScreen;
