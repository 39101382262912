import { actionObject } from 'utils';
import { RESET_2FA, SET_2FA_DATA, SET_2FA_EMAIL } from './action-types';

export const set2FAEmail = (email: string) =>
  actionObject(SET_2FA_EMAIL, { email });

export const set2FAData = (code: string, QR: string) =>
  actionObject(SET_2FA_DATA, { code, QR });

export const reset2FA = () => actionObject(RESET_2FA);
