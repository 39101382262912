export const VIEW_BRANCH_OFFICE = 'VIEW_BRANCH_OFFICE',
  VIEW_DASHBOARD = 'VIEW_DASHBOARD',
  VIEW_DIRECTORY = 'VIEW_DIRECTORY',
  VIEW_ACTIVITY = 'VIEW_ACTIVITY',
  CLAIM_PAYMENT = 'CLAIM_PAYMENT',
  VIEW_TRANSFER = 'VIEW_TRANSFER',
  DOWNLOAD_ACTIVITY = 'DOWNLOAD_ACTIVITY';

export const permissionsToRoutes: {
  [key: string]: string;
} = {
  [VIEW_DASHBOARD]: '/dashboard',
  [VIEW_DIRECTORY]: '/directory',
  [VIEW_ACTIVITY]: '/activity',
  [VIEW_BRANCH_OFFICE]: '/branch-office',
  [DOWNLOAD_ACTIVITY]: '/activity',
  [CLAIM_PAYMENT]: '/dashboard'
};
