import { gql } from '@apollo/client';

const UPDATE_BRANCH_OFFICE = gql`
  mutation UpdateBranchOffice($data: UpdateBranchOfficeInput!) {
      updateBranchOffice(data: $data) {
        id
    }
  }
`;
export default UPDATE_BRANCH_OFFICE;

