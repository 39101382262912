import { Theme } from '@mui/material';

const divider = (theme: Theme, blacked?: boolean) => ({
  width: '100%;',
  border: 'none',
  height: '0.125rem',
  margin: '1.5625rem 0',
  backgroundColor: blacked ? '#CDCDCD' : theme.palette.primary.main,
  [theme.breakpoints.down('md')]: {
    margin: '0.781rem 0',
  },
});

export { divider };
