import i18next from 'i18next';
import {
  VIEW_ACTIVITY,
  VIEW_BRANCH_OFFICE,
  VIEW_DASHBOARD,
  VIEW_DIRECTORY,
  VIEW_TRANSFER,
} from './permissions';

const naturalIdentification = [
  { label: 'V', value: 'V' },
  { label: 'E', value: 'E' },
  { label: 'P', value: 'P' },
];

const legalIdentification = [{ label: 'EIN', value: 'EIN' }];

const wallets: Array<any> = [
  { label: 'Zelle', value: '1' },
  { label: 'Venmo', value: '2' },
  { label: 'Puntu', value: '3' },
  { label: 'Paypal', value: '4' },
];

const styledComponentsBreakpoints = {
  xs: `(max-width: 370px)`,
  sm: `(max-width: 576px)`,
  md: `(max-width: 768px)`,
  lg: `(max-width: 992px)`,
  xl: `(max-width: 1200px)`,
  xxl: `(max-width: 1400px)`,
  xxxl: `(max-width: 1600px)`,
};

const whitelistType: Array<any> = [
  { name: 'all', value: null },
  { name: 'employees', value: 'EMPLOYEE' },
  { name: 'providers', value: 'SUPPLIER' },
  { name: 'customers', value: 'CUSTOMER' },
];

const materialUIBreakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
  xxxl: 1600,
};

const supportedDniFiletypes = ['image/png', 'image/jpg', 'image/jpeg'];
const drawerWidth = '14.5625rem';
const mobileAppbarHeight = '3rem';
const navbarHeight = 64;
const activityTablePadding = 29;

const translate = (key: any, value: any = {}) => {
  let translated: any = i18next.t(key, { ...value });
  return translated;
};

const allWallets = ['zelle', 'venmo', 'puntu', 'paypal'];

const bankFees: any = {
  WIRE: 30,
  ACH: 3,
};

const walletEnabled = { wallet: { name: 'enabled' } };
const walletDisabled = { wallet: { name: 'disabled' } };
const allWallet: any = [{ wallet: { title: 'wallet_all', id: null } }];

const sliderSettings: any = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  arrows: false,
};

const languages = [
  { name: 'spanish', value: 'es' },
  { name: 'english', value: 'en' },
];

const menuOptions = [
  {
    label: 'dashboard_option',
    icon: 'bxs:dashboard',
    route: '/dashboard',
  },
  // {
  //   label: 'analysis_data',
  //   icon: 'bxs:analyse',
  //   route: '/s',
  // },
  {
    label: 'transfers',
    icon: 'bxs:credit-card',
    route: '/payments',
  },
  {
    label: 'Directory',
    icon: 'bxs:book-reader',
    route: '/directory',
  },
  {
    label: 'activity',
    icon: 'bxs:spreadsheet',
    route: '/activity',
  },
  {
    label: 'branch_offices',
    icon: 'bxs:buildings',
    route: '/branch-office',
  },
  // {
  //   label: 'commerce',
  //   icon: 'bxs:cart-alt',
  //   route: '/s',
  // },
  {
    label: 'roles',
    icon: 'bxs:user-circle',
    route: '/roles',
  },
];

export const routesTypeAndPermissions: {
  [key: string]: { type: Array<string>; permission?: string };
} = {
  '/branch-office': {
    type: ['juridic'],
    permission: VIEW_BRANCH_OFFICE,
  },
  '/dashboard': {
    type: ['natural', 'juridic'],
    permission: VIEW_DASHBOARD,
  },
  '/faq': {
    type: ['natural', 'juridic'],
  },
  '/activity': {
    type: ['natural', 'juridic'],
    permission: VIEW_ACTIVITY,
  },
  '/directory': {
    type: ['natural', 'juridic'],
    permission: VIEW_DIRECTORY,
  },
  '/roles': {
    type: ['juridic'],
  },
  '/payments': {
    type: ['natural', 'juridic'],
    permission: VIEW_TRANSFER,
  },
  '/profile': {
    type: ['natural', 'juridic'],
  },
  '/deal-flow': {
    type: ['natural', 'juridic'],
  },
};

const paymentOptions = {
  send: [
    // {
    //   title: "send_money_qr_or_email",
    //   description: "make_tranfers_puntu_users",
    //   icon: "bx-qr-scan",
    //   flag: 'send_email'
    // },
    {
      title: 'send_money_bank_account',
      description: 'make_tranfers_bank_accounts',
      icon: 'bxs-bank',
      flag: 'send_bank',
    },
  ],
  receive: [
    {
      title: 'receive_by',
      description: 'receive_funds',
      icon: 'zelle',
      flag: 'Zelle',
      wallet: 'zelle',
    },
    {
      title: 'receive_by',
      description: 'receive_funds',
      icon: 'venmo',
      flag: 'Venmo',
      wallet: 'venmo',
    },
    {
      title: 'receive_by',
      description: 'receive_funds',
      icon: 'paypal',
      flag: 'Paypal',
      wallet: 'paypal',
    },
    // {
    //   title: "card_by",
    //   description: "receive_funds",
    //   icon: "credit",
    //   flag:'credito'
    // },
  ],
};
const routingTypes = [
  { label: 'Tipo', value: '' },
  { label: 'ACH', value: 'ACH' },
  { label: 'WIRE', value: 'WIRE' },
];

export {
  naturalIdentification,
  legalIdentification,
  styledComponentsBreakpoints,
  supportedDniFiletypes,
  materialUIBreakpoints,
  drawerWidth,
  mobileAppbarHeight,
  translate,
  sliderSettings,
  wallets,
  navbarHeight,
  activityTablePadding,
  allWallets,
  walletEnabled,
  walletDisabled,
  languages,
  allWallet,
  menuOptions,
  paymentOptions,
  whitelistType,
  routingTypes,
  bankFees,
};
