import {
  Step,
  StepConnector,
  StepLabel,
  Stepper as StepperMUI,
  Theme,
  useMediaQuery,
} from "@mui/material";
import clsx from "clsx";
import {
  BackArrow,
  GeneralButton,
  GeneralConfirmModal,
  Stepper,
} from "../../../../components";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { reset2FA } from "store/twoFA/action";
import { twoFAEmailSelector } from "store/twoFA/selectors";
import { connectorStyles, labelStyles } from "./materialStyles";
import * as Screens from "./Screens";
import styles from "./styles.module.scss";

const steps: Array<{
  component: keyof typeof Screens;
  step: number;
  label: string;
  stepLabel: string;
}> = [
  {
    component: "ChooseApp",
    step: 1,
    label: "download_2fa_app",
    stepLabel: "download_app",
  },
  {
    component: "QRScreen",
    step: 2,
    label: "scan_qr_from_app",
    stepLabel: "scan_qr",
  },
  {
    component: "StoreKey",
    step: 3,
    label: "save_password_secure_location",
    stepLabel: "save_security_password",
  },
  {
    component: "VerifyScreen",
    step: 4,
    label: "validate_2fa",
    stepLabel: "active_code",
  },
  {
    component: "SuccessScreen",
    step: 5,
    label: "active_2fa_success",
    stepLabel: "sucess_auht",
  },
];

const Set2FA = ({ t }: any) => {
  const [currentStep, setCurrentStep] = useState(steps[0]);
  const email = useSelector(twoFAEmailSelector);
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const Component: any = Screens[currentStep.component];
  const [openModal, setOpenModal] = useState(false);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const onSuccess = () => {
    setCurrentStep((prevValue) => steps[prevValue.step]);
  };
  useEffect(() => {
    if (!email) {
      //dispatch(setAlert(2, "error", "noTwoFAEmail"));
      navigator(-1);
    }
  }, [email]);
  return (
    <>
      <div className={styles._mainContainer}>
        {currentStep.step != 5 && currentStep.step != 1 && (
          <div className={styles._arrowBackContainer}>
            <BackArrow
              onClick={() =>
                setCurrentStep((prevValue) => steps[prevValue.step - 2])
              }
            />
            <p>{t("return")}</p>
          </div>
        )}
        <StepperMUI
          nonLinear
          activeStep={currentStep.step - 1}
          alternativeLabel
          connector={<StepConnector sx={connectorStyles} />}
        >
          {steps.map((elem, index) => (
            <Step
              key={elem.component}
              className={
                currentStep.step != elem.step && isMobile
                  ? styles._mobileHide
                  : ""
              }
            >
              <StepLabel
                sx={(theme) => labelStyles(theme, true)}
                StepIconComponent={(props) => {
                  return (
                    <div
                      className={clsx(
                        styles._circle,
                        props.active ? styles._active : styles._inactive
                      )}
                    >
                      <p>{elem.step}</p>
                    </div>
                  );
                }}
                color="inherit"
              >
                {t(elem.stepLabel)}
              </StepLabel>
            </Step>
          ))}
        </StepperMUI>
        <div className={styles._contentContainer}>
          <p className={styles._screenTitle}>{t(currentStep.label)}</p>
          <Component formRef={formRef} email={email} onSuccess={onSuccess} />
        </div>
        <div className={styles._footerContainer}>
          <div
            className={clsx(
              styles._buttonsContainer,
              currentStep.step == 5 ? styles._buttonsCenter : ""
            )}
          >
            {currentStep.step < 5 ? (
              <>
                <GeneralButton
                  width="48%"
                  text={currentStep.step == 1 ? t("cancel") : t("go_back")}
                  className={styles._cancelButton}
                  action={() => {
                    const currentIndex = steps.indexOf(currentStep);
                    const newIndex = currentIndex - 1;

                    currentStep.step == 1
                      ? setOpenModal(true)
                      : setCurrentStep(steps[newIndex]);
                  }}
                />
                <GeneralButton
                  width="48%"
                  text={t("continue")}
                  action={() => {
                    if (!formRef.current) {
                      return setCurrentStep(
                        (prevValue) => steps[prevValue.step]
                      );
                    }
                    formRef.current.handleSubmit();
                  }}
                />
              </>
            ) : (
              <GeneralButton
                className={styles._finalizeButton}
                text={t("finalize")}
                action={() => {
                  navigator("/login");
                  dispatch(reset2FA());
                }}
              />
            )}
          </div>
          <div className={styles._mobileStepper}>
            <Stepper maxSteps={5} step={currentStep.step} />
          </div>
        </div>
      </div>
      <GeneralConfirmModal
        open={openModal}
        btnText={t("confirm")}
        withDrawer={false}
        title={t("2fa_cancel_confirm_title")}
        onCancel={() => setOpenModal(false)}
        onConfirm={() => dispatch(reset2FA())}
      />
    </>
  );
};

export default Set2FA;
