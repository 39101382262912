import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  translate,
  onlyLettersRegex,
  onlyNumbersRegex,
  onlyLettersAndNumbers,
  onlyLettersNumbersWithoutSpace,
} from 'utils';

const validationSchema: any = Yup.object({
  bankId: Yup.string().required('field_required'),
  state: Yup.string().required('field_required'),
  routeType: Yup.string().required('field_required'),

  accountHolder: Yup.string()
    .required('field_required')
    .max(255, () => translate('max_invalid', { number: 255 }))
    .min(3, () => translate('min_invalid', { number: 3 })),
  // .matches(onlyLettersRegex, 'only_letters'),

  address: Yup.string()
    .required('field_required')
    .min(4, () => translate('min_invalid', { number: 4 }))
    .max(200, () => translate('max_invalid', { number: 200 })),

  city: Yup.string()
    .required('field_required')
    .min(3, () => translate('min_invalid', { number: 3 }))
    .max(255, () => translate('max_invalid', { number: 255 }))
    .matches(onlyLettersRegex, 'only_letters'),

  zipCode: Yup.string()
    .required('field_required')
    .min(4, () => translate('min_invalid', { number: 4 }))
    .max(12, () => translate('max_invalid', { number: 12 }))
    .matches(onlyNumbersRegex, 'only_numbers'),

  accountNumber: Yup.string()
    .required('field_required')
    .min(6, () => translate('min_invalid', { number: 6 }))
    .max(24, () => translate('max_invalid', { number: 24 }))
    .matches(onlyLettersNumbersWithoutSpace, 'only_alphanumeric'),

  routeNumber: Yup.string()
    .required('field_required')
    .min(4, () => translate('min_invalid', { number: 4 }))
    .max(50, () => translate('max_invalid', { number: 50 }))
    .matches(onlyLettersAndNumbers, 'only_alphanumeric'),
});

export const FormikConfig = (
  handleSubmit: any,
  spaceId: string,
  accountData: any,
  handleUpdate: Function
) =>
  useFormik({
    enableReinitialize: true,
    initialValues: {
      bankId: accountData?.bankId ?? '',
      state: accountData?.state ?? '',
      routeType: accountData?.routeType ?? '',
      accountHolder: accountData?.accountHolder ?? '',
      address: accountData?.address ?? '',
      city: accountData?.city ?? '',
      zipCode: accountData?.zipCode ?? '',
      accountNumber: accountData?.accountNumber ?? '',
      routeNumber: accountData?.routeNumber ?? '',
    },

    validationSchema: validationSchema,

    onSubmit: (values) => {
      const data = {
        ...values,
        spaceId,
      };

      const updateValues = {
        ...data,
        id: accountData?.id,
      };

      if (!accountData) return handleSubmit(data);
      handleUpdate(updateValues);
    },
  });
