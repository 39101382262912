import React, { useRef } from "react";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

const GeneralInputLabel = ({
  onChange = null,
  label = "0.00",
  placeholder = "Amount",
  className = null,
  value,
  name,
  type = "text",
  id,
  maxNumber,
  errorMessage,
  disabled = false,
  status,
}: any) => {
  const { t } = useTranslation();
  const inputRef: any = useRef();
  const focusOnInput = () => inputRef?.current?.focus();

  const statusManagment = (type: any = "inherit"): string => {
    if (type == "inherit") return styles._inputParent;
    if (type == "success") return styles._inputParent;
    if (type == "error") return styles._inputError;
  };

  return (
    <div>
      <div
        className={clsx(statusManagment(status), className)}
        onClick={focusOnInput}
      >
        <input
          placeholder={t(placeholder)}
          onChange={onChange}
          value={value}
          name={name}
          type={type}
          id={id}
          disabled={disabled}
          maxLength={maxNumber || null}
          ref={inputRef}
          autoComplete="off"
        />
        <p className={styles._label}>{t(label)}</p>
      </div>
      {errorMessage && <p className={styles._textError}>{t(errorMessage)}</p>}
    </div>
  );
};

export default GeneralInputLabel;
