import { memo } from "react";
import styles from "./styles.module.scss";
import { InputAreaType } from "./types";
import styled from "styled-components";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { TextareaAutosize } from "@mui/material";

const GeneralTextArea = ({
  placeholder,
  className,
  hasError = false,
  errorMessage,
  status = "inherit",
  value = "",
  onChange,
  onFocus,
  onBlur,
  id,
  name,
}: InputAreaType) => {
  const { t } = useTranslation();

  const statusManagment = (type: any = "inherit"): string => {
    if (type == "inherit") return styles._input;
    if (type == "success") return styles._input;
    if (type == "error") return styles._inputError;
  };

  return (
    <div className={clsx(styles._inputContainer, className)}>
      <div style={{ width: "100%", position: "relative" }}>
        <TextareaAutosize
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder={t(placeholder)}
          aria-multiline
          minRows={3}
          id={id}
          name={name}
          className={statusManagment()}
          style={{ width: "100%" }}
        />
        {hasError && errorMessage && (
          <p className={styles._textError}>{t(errorMessage)}</p>
        )}
      </div>
    </div>
  );
};

export default styled(memo(GeneralTextArea))`
  width: ${({ inputWidth }) => (inputWidth ? inputWidth : "")};
`;
