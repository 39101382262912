import styles from "./styles.module.scss";
import { SingleRadioGroupType } from "./types";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";
import { Radio, RadioGroup } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useMediaQuery, Theme } from "@mui/material";

const SingleGeneralRadioGroup = ({
  mapData = [],
  totalPages,
  handleCheckValue,
  checkValue,
  currentPage,
  leftMethod,
  rightMethod,
  loading,
  title,
}: SingleRadioGroupType) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("lg")
  );
  const { t } = useTranslation();

  return (
    <RadioGroup
      aria-labelledby="demo-radio-buttons-group-label"
      name="radio-buttons-group"
    >
      <div className={styles._radioMain}>
        <div className={styles._titleContainer}>
          <p className={styles._title}>{t(title)}</p>
        </div>

        {loading ? (
          <>
            {mapData?.map((item: any, index: number) => (
              <div className={styles._radioContainer} key={index}>
                <label
                  className={styles._radioContent}
                  onClick={() => handleCheckValue(item)}
                >
                  <Radio
                    value={item?.code}
                    name={item?.title}
                    checked={item?.code == checkValue ? true : false}
                  />
                  {!isMobile && (
                    <div className={styles._textRadioContent}>
                      <p className={styles._title}>{item?.name}</p>
                      <p className={styles._subtitle}>{item?.address}</p>
                      <p className={styles._subtitle}>
                        {item?.code.toUpperCase()}
                      </p>
                    </div>
                  )}
                  {isMobile && (
                    <>
                      <div className={styles._textRadioContent}>
                        <p className={styles._title}>{item?.name}</p>
                        <p className={styles._subtitle}>{item?.address}</p>
                      </div>
                      <div className={styles._textRadioContent}>
                        <p className={styles._subtitle}>{item?.code}</p>
                      </div>
                    </>
                  )}
                </label>
              </div>
            ))}
          </>
        ) : (
          <div className={styles._loaderContainer}>
            <CircularProgress color="primary" size={35} />
          </div>
        )}

        {mapData.length != 0 && (
          <div className={styles._paginationContainer}>
            <div>
              <p className={styles._paginate}>
                {currentPage}/{totalPages}
              </p>
            </div>

            <div className={styles._paginate}>
              <Icon
                icon="bx:left-arrow-alt"
                onClick={leftMethod}
                className={currentPage >= 2 ? styles._icon : styles._iconNone}
                width={24}
                color={"#909090"}
              ></Icon>

              <Icon
                icon="bx:right-arrow-alt"
                width={24}
                onClick={rightMethod}
                className={
                  currentPage <= totalPages - 1
                    ? styles._icon
                    : styles._iconNone
                }
                color="#909090"
              ></Icon>
            </div>
          </div>
        )}
      </div>
    </RadioGroup>
  );
};

export default SingleGeneralRadioGroup;
