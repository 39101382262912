import styles from "./styles.module.scss";
import { S3_URL, baseUrl } from "paths";
import {
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  CircularProgress,
} from "@mui/material";
import clsx from "clsx";
import { Icon } from "@iconify/react";
import { accordion } from "./materialStyles";
import { PROFILE_QUERY, GET_PROFILE_ACCOUNTS } from "graphql/queries";
import { useQuery } from "@apollo/client";
import i18next from "i18next";
import {
  CreateBankAccount,
  ManageBankAccounts,
  RequestChangesModal,
} from "web/components";
import { UseIntermitence } from "hooks";
import { useSelector } from "react-redux";
import { userSpaceSelector } from "store/selectors";
import { bankFees, parseAmount, translate } from "utils";
import { useState } from "react";
import { useMediaQuery, Theme } from "@mui/material";
import ProfileMobileDrawer from "web/components/Drawers/ProfileMobileDrawer";

const Profile = ({ t }: any) => {
  const { currentSpace } = useSelector(userSpaceSelector);
  const [selectedAccount, setSelectedAccount]: any = useState();
  const [openBussines, setOpenBussines]: any = useState(true);
  const [openLegal, setOpenLegal]: any = useState(true);
  const [openShareHolders, setOpenShareHolders]: any = useState(true);
  const { switchStatus, status } = UseIntermitence();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const { switchStatus: manageSwitchStatus, status: manageStatus } =
    UseIntermitence();
  const { switchStatus: switchChangeStatus, status: changeStatus } =
    UseIntermitence();
  const { data: profile, loading: profileLoading } = useQuery(PROFILE_QUERY, {
    variables: {
      spaceId: currentSpace?.id,
    },
    fetchPolicy: "cache-and-network",
  });

  const {
    data: banks,
    loading: banksLoading,
    refetch,
  } = useQuery(GET_PROFILE_ACCOUNTS, {
    fetchPolicy: "cache-and-network",
    variables: {
      spaceId: currentSpace?.id,
      perPage: 999,
    },
  });

  const validateIcon = (bankName: string) => {
    if (bankName == "Bank of America") {
      return (
        <div className={styles._bankIcon}>
          <img
            src={`${baseUrl}/resources/bofa_witouth_background.svg`}
            alt="bank-icon"
          />
        </div>
      );
    }

    return (
      <div className={styles._defaultIcon}>
        <Icon width={25} icon="bxs-bank"></Icon>
      </div>
    );
  };

  return (
    <>
      {!isMobile && (
        <div className={styles._mainContainer}>
          {profileLoading ? (
            <div className={styles._loaderContainer}>
              <CircularProgress color="primary" size={35} />
            </div>
          ) : (
            <div className={styles._bottomContainer}>
              <div className={styles._titleContainer}>
                <p className={styles._title}>{t("my_details")}</p>
                <p
                  onClick={() => {
                    switchChangeStatus();
                  }}
                  className={styles._pinkTitle}
                >
                  {t("request_changes")}
                </p>
              </div>
              <div className={styles._bottomContainer_child}>
                <Grid
                  container
                  sx={{
                    paddingTop: 0,
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Grid item xs={12}>
                    <p className={styles._subtitle}>{t("creation")}</p>
                    <p className={styles._content}>
                      {profile?.customer?.dateOfCreation}
                    </p>
                  </Grid>
                  <Grid item xs={4} lg={4} className={styles._item}>
                    <p className={styles._subtitle}>{t("name")}</p>
                    <p className={styles._content}>
                      {profile?.customer?.type == "juridic"
                        ? profile?.customer?.legalRepresentative?.businessName
                        : `${profile?.customer?.user?.profile?.firstName} ${profile?.customer?.user?.profile?.lastName}`}
                    </p>
                  </Grid>
                  <Grid item xs={4} lg={4} className={styles._item}>
                    <p className={styles._subtitle}>{t("Rif")}</p>
                    <p className={styles._content}>
                      {profile?.customer?.user?.profile?.dniType}-
                      {profile?.customer?.user?.profile?.dniNumber}
                    </p>
                  </Grid>

                  <Grid item xs={4} lg={4} className={styles._item}>
                    <p className={styles._subtitle}>{t("natural_address")}</p>
                    <p className={styles._content}>
                      {profile?.customer?.fiscalAddress}
                    </p>
                  </Grid>
                </Grid>
                <p className={clsx(styles._titleBold)}>
                  {t("legal_signature")}
                </p>
                <div className={styles._signatureContainer}>
                  <img
                    src={`${profile?.customer?.signatureUrl}`}
                    alt="signature"
                    className={styles._signature}
                  />
                </div>

                <Accordion
                  sx={accordion}
                  elevation={0}
                  expanded={openBussines}
                  onChange={() =>
                    setOpenBussines((prevValue: any) => !prevValue)
                  }
                >
                  <AccordionSummary
                    expandIcon={<Icon icon={"bx-chevron-down"} width={22} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={styles._backgroundAccordion}
                  >
                    <Typography className={styles._accordionTitle}>
                      {t("bussines")}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{ paddingLeft: 0, paddingBottom: 0, paddingRight: 0 }}
                  >
                    <Grid
                      container
                      sx={{
                        paddingTop: 0,
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid item xs={4} lg={4} className={styles._bussinesItem}>
                        <p className={styles._subtitle}>{t("business_type")}</p>
                        <div className={styles._commerceType}>
                          {profile?.customer?.typeCommerceFallback ? (
                            <p className={styles._content}>
                              {profile?.customer?.typeCommerceFallback}
                            </p>
                          ) : (
                            <>
                              <Icon
                                icon={profile?.customer?.typeCommerce?.icon}
                                color="#E6427A"
                              />
                              <p className={styles._content}>
                                {i18next.language == "es"
                                  ? profile?.customer?.typeCommerce?.esName
                                  : profile?.customer?.typeCommerce?.enName}
                              </p>
                            </>
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={4} lg={4} className={styles._bussinesItem}>
                        <p className={styles._subtitle}>
                          {t("local_telephone")}
                        </p>
                        <p className={styles._content}>
                          {profile?.customer.user.profile.phoneNumber}
                        </p>
                      </Grid>

                      <Grid item xs={4} lg={4} className={styles._bussinesItem}>
                        <p className={styles._subtitle}>
                          {t("monthly_income")}
                        </p>
                        <p className={styles._content}>
                          ${parseAmount(profile?.customer?.monthlyIncome)}
                        </p>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      sx={{
                        paddingTop: 0,
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid item xs={4} lg={4} className={styles._bussinesItem}>
                        <p className={styles._subtitle}>
                          {t("email_not_electronic")}
                        </p>
                        <p className={styles._content}>
                          {profile?.customer.user.email}
                        </p>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>

                {profile?.customer?.type == "juridic" && (
                  <Accordion
                    sx={accordion}
                    elevation={0}
                    expanded={openLegal}
                    onChange={() =>
                      setOpenLegal((prevValue: any) => !prevValue)
                    }
                  >
                    <AccordionSummary
                      expandIcon={<Icon icon={"bx-chevron-down"} width={22} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className={styles._backgroundAccordion}
                    >
                      <Typography className={styles._accordionTitle}>
                        {t("legal_representative")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{ paddingLeft: 0, paddingBottom: 0, paddingRight: 0 }}
                    >
                      <Grid
                        container
                        sx={{
                          paddingTop: 0,
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Grid
                          item
                          xs={4}
                          lg={4}
                          className={styles._bussinesItem}
                        >
                          <Grid item xs={4} lg={4}>
                            <p className={styles._subtitle}>
                              {t("legal_representative")}
                            </p>
                            <p className={styles._content}>
                              {`${profile?.customer?.legalRepresentative?.profile?.firstName} ${profile?.customer?.legalRepresentative?.profile?.lastName}`}
                            </p>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          lg={4}
                          className={styles._bussinesItem}
                        >
                          <p className={styles._subtitle}>
                            {t("natural_address")}
                          </p>
                          <p className={styles._content}>
                            {profile?.customer?.fiscalAddress}
                          </p>
                        </Grid>

                        <Grid
                          item
                          xs={4}
                          lg={4}
                          className={styles._bussinesItem}
                        >
                          <p className={styles._subtitle}>{t("phone")}</p>
                          <p className={styles._content}>
                            {
                              profile?.customer?.legalRepresentative?.profile
                                ?.cellPhoneNumber
                            }
                          </p>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        sx={{
                          paddingTop: 0,
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Grid
                          item
                          xs={4}
                          lg={4}
                          className={styles._bussinesItem}
                        >
                          <p className={styles._subtitle}>{t("Rif")}</p>
                          <p className={styles._content}>
                            {profile?.customer?.legalRepresentative?.dni}
                          </p>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                )}

                {profile?.customer?.type == "juridic" && (
                  <Accordion
                    sx={accordion}
                    elevation={0}
                    expanded={openShareHolders}
                    onChange={() =>
                      setOpenShareHolders((prevValue: any) => !prevValue)
                    }
                  >
                    <AccordionSummary
                      expandIcon={<Icon icon={"bx-chevron-down"} width={22} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className={styles._backgroundAccordion}
                    >
                      <Typography className={styles._accordionTitle}>
                        {t("shareholders")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        padding: "8px 0 0 0",
                        // borderBottom: "1px solid #6B818C",
                      }}
                    >
                      <div className={styles?._shareholdersBox}>
                        {profile?.customer.shareholders.map(
                          (item: any, index: string) => {
                            return (
                              <Grid
                                container
                                key={index}
                                className={styles._shareholdersContainer}
                              >
                                <Grid
                                  item
                                  xs={0.5}
                                  lg={0.5}
                                  className={styles._shareHolderItems}
                                >
                                  <p className={styles._emptySubtitle}>
                                    {t("")}
                                  </p>
                                  <p className={styles._index}>{index + 1}</p>
                                </Grid>
                                <Grid
                                  item
                                  xs={2}
                                  lg={2}
                                  className={styles._shareHolderItems}
                                >
                                  <p className={styles._subtitle}>
                                    {t("shareholder")}
                                  </p>
                                  <p className={styles._content}>
                                    {`${item?.profile?.firstName} ${item?.profile?.lastName}`}
                                  </p>
                                </Grid>
                                <Grid
                                  item
                                  xs={2}
                                  lg={2}
                                  className={styles._shareHolderItems}
                                >
                                  <p className={styles._subtitle}>{t("Rif")}</p>
                                  <p className={styles._content}>{item?.dni}</p>
                                </Grid>
                                <Grid
                                  item
                                  xs={2}
                                  lg={2}
                                  className={styles._shareHolderItems}
                                >
                                  <p className={styles._subtitle}>
                                    {t("born_date")}
                                  </p>
                                  <p className={styles._content}>
                                    {item?.profile?.bornDate}
                                  </p>
                                </Grid>
                                <Grid
                                  item
                                  xs={2}
                                  lg={2}
                                  className={styles._shareHolderItems}
                                >
                                  <p className={styles._subtitle}>
                                    {t("email")}
                                  </p>
                                  <p className={styles._content}>
                                    {item?.email}
                                  </p>
                                </Grid>
                                <Grid
                                  item
                                  xs={2}
                                  lg={2}
                                  className={styles._shareHolderItems}
                                >
                                  <p className={styles._subtitle}>
                                    {t("phone")}
                                  </p>
                                  <p className={styles._content}>
                                    {item?.profile?.cellPhoneNumber}
                                  </p>
                                </Grid>
                              </Grid>
                            );
                          }
                        )}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                )}
              </div>
            </div>
          )}

          {banksLoading ? (
            <div className={styles._loaderContainer}>
              <CircularProgress color="primary" size={35} />
            </div>
          ) : (
            <div className={styles._bottomContainer}>
              <div className={styles._titleContainer}>
                <p className={styles._title}>{t("my_affiliate_accounts")}</p>
                <p
                  className={styles._manageTitle}
                  onClick={() => manageSwitchStatus()}
                >
                  {t("manage")}
                </p>
              </div>
              {banks?.bankAccounts?.items?.map((item: any, index: string) => {
                return (
                  <div
                    key={index}
                    className={styles._accountContainer}
                    onClick={() => {
                      switchStatus();
                      setSelectedAccount(item);
                    }}
                  >
                    <div className={styles._row}>
                      {validateIcon(item?.bank?.title)}

                      <div>
                        <p className={styles._accountTitle}>
                          {t(item?.bank?.title)}
                        </p>
                        <p className={styles._accountSubtitle}>
                          {translate("account_affiliated_bank", {
                            bank: item?.bank?.title,
                          })}
                        </p>
                      </div>
                    </div>
                    <div>
                      <p className={styles._miniTitle}>{t("ach_transfer")}</p>
                      <p className={styles._miniSubTitle}>
                        {t("commission")} $
                        {parseAmount(bankFees[item?.routeType])}
                      </p>
                    </div>
                  </div>
                );
              })}
              <div
                className={styles._accountContainer}
                onClick={() => switchStatus()}
              >
                <div className={styles._row}>
                  <img
                    className={styles._addIcon}
                    src={`${baseUrl}/resources/plus-gray-back-icon.svg`}
                    alt="no-wallet-icon"
                  />

                  <div>
                    <p className={styles._accountTitle}>
                      {t("add_and_account")}
                    </p>
                    <p className={styles._accountSubtitle}>
                      {t("account_affiliated")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {isMobile && <ProfileMobileDrawer />}
      <CreateBankAccount
        open={status}
        onClose={() => {
          switchStatus();
          setSelectedAccount(null);
        }}
        accountData={selectedAccount}
        refetch={refetch}
        resetEdit={() => setSelectedAccount(null)}
      />
      {manageStatus && (
        <ManageBankAccounts
          open={manageStatus}
          onClose={manageSwitchStatus}
          onchangeModal={switchStatus}
        />
      )}
      {changeStatus && (
        <RequestChangesModal
          open={changeStatus}
          onClose={switchChangeStatus}
        ></RequestChangesModal>
      )}
    </>
  );
};

export default Profile;
