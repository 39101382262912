import { gql } from '@apollo/client';

const VERYFY_EMAIL = gql`
  mutation VerifyEmail($verifierToken: String!) {
      verifyEmail(data: {verifierToken: $verifierToken}) {
        result
    }
}
`;
export default VERYFY_EMAIL;
