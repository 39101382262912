import { Theme } from '@mui/material';

const gridContainer = (theme: Theme) => ({
  paddingBottom: '4.125rem',
});

const accordion = (theme: Theme) => ({
  marginBottom: '0 !important',

  '&.MuiAccordion-root, & .Mui-expanded': {},
  '&.MuiAccordion-root:before': {
    backgroundColor: '#F4F4F4',
  },
  '&.MuiAccordion-root, & .MuiAccordionSummary-content': {
    margin: '0 !important',
  },
  [theme.breakpoints.down('md')]: {
    left: 0,
    backgroundColor: '#E5E5E5',
    marginTop: '0 !important',
    '& .Mui-expanded': {
      marginTop: '20px !important',
    },
  },
});

export { gridContainer, accordion };
