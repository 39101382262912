import { tabsParent } from "./materialStyles";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Icon } from "@iconify/react";
import { GeneralTabs } from "components";
import Settings from "./Tabs/Settings";
import Detail from "./Tabs/Details";
import Accounts from "./Tabs/Accounts";
import { RequestChangesModal } from "web/components";
import { UseIntermitence } from "hooks";
import { userAuthSelector } from "store/selectors";
import { useSelector } from "react-redux";

const ProfileMobileDrawer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    userAuthData: { customer, email, profile },
  } = useSelector(userAuthSelector);
  const [tabValue, setTabValue] = useState(0);
  const { switchStatus: switchChangeStatus, status: changeStatus } =
    UseIntermitence();

  const handlerChange = async (_e: any, value: any) => {
    setTabValue(value);
  };
  return (
    <>
      <div className={styles._main}>
        <div className={styles._mainContainer}>
          <div className={styles._titleContainer}>
            <p className={styles._title}>{t("my_profile")}</p>
            <p
              onClick={() => {
                switchChangeStatus();
              }}
              className={styles._pinkTitle}
            >
              {t("request_changes")}
            </p>
          </div>
          <div className={styles._imageContainer}>
            <div className={styles._imageCard}>
              <Icon icon="bxs:user" width={28} color="#000000"></Icon>
            </div>
            <div className={styles._cardTextContainer}>
              <p className={styles._cardTitle}>
                {" "}
                {customer?.type == "juridic"
                  ? `${customer?.legalRepresentative?.profile?.firstName} ${customer?.legalRepresentative?.profile?.lastName} `
                  : `${profile?.firstName} ${profile?.lastName}`}
              </p>
              <p className={styles._cardSubtitle}>{email}</p>
            </div>
          </div>

          <GeneralTabs
            sx={tabsParent}
            data={[
              { name: t("settings") },
              { name: t("my_details") },
              { name: t("my_accounts") },
            ]}
            onChange={handlerChange}
            value={tabValue}
            labelKey="name"
            maxWidth={"100%"}
          />

          {tabValue == 0 && <Settings />}
          {tabValue == 1 && <Detail />}
          {tabValue == 2 && <Accounts />}
        </div>
      </div>
      {changeStatus && (
        <RequestChangesModal
          open={changeStatus}
          onClose={switchChangeStatus}
        ></RequestChangesModal>
      )}
    </>
  );
};

export default ProfileMobileDrawer;
