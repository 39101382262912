import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { userAuthSelector } from "store/selectors";
import { useLocation } from "react-router-dom";
import { routesTypeAndPermissions } from "utils";
const ProtectedRoute = () => {
  const location = useLocation();
  
  const navigation = useNavigate();
  // Aqui habria que agregar tambien un selector que apunte a los permisos del usuario
  // dichos permisos se actualizan dependiendo del branch office y puede cambiar entre
  // usuario natural y juridico, se supone que solo los usuarios que tienen ui role
  // empleado son los que tendran permisos. De momento, solo estoy considerando el tipo.
  // const userPermissions = useSelector()
  const { userAuthData, permissions } = useSelector(userAuthSelector);

  if (!userAuthData && !userAuthData?.token) {
    return <Navigate to="/onboarding" replace />;
  }

  if (userAuthData.customer) {
    if (
      !routesTypeAndPermissions[location.pathname].type.includes(
        userAuthData?.customer.type
      )
    ) {
      return <Navigate to="/dashboard" replace />;
    }
  } else if (
    !permissions.includes(routesTypeAndPermissions[location.pathname].permission)
  ) {
    navigation(-1);
    return null;
  }

  return <Outlet />;
};

export default ProtectedRoute;
