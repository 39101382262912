import { gql } from '@apollo/client'

const ACTIVATE_WALLET = gql`
  mutation ActivateWallet($walletId: ID!, $branchOfficeId: ID!) {
    activateWallet(data: { walletId: $walletId, branchOfficeId: $branchOfficeId }) {
      id
      name
      title
  }
}
`;
export default ACTIVATE_WALLET;
