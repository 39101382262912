import { gql } from '@apollo/client';

const EXPORT_WHITELIST = gql`
  mutation ExportWhitelist($data: ExportWhitelistInput!) {
    exportWhitelist(data: $data) {
      result
    }
  }
`;
export default EXPORT_WHITELIST;
