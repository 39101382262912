import { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import {
  GeneralButton,
  GeneralInput,
  GeneralTable,
  GeneralConfirmModal,
} from "../../../../../../components";
import { useLazyQuery, useQuery } from "@apollo/client";
import { transformToRows } from "./functions";
import { shallowCompare } from "../../../../../../utils";
import { Theme, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import { userSpaceSelector } from "store/selectors";
import { Icon } from "@iconify/react";
import ROLES_QUERY from "graphql/queries/roles";
import UI_ROLE from "graphql/queries/uiRole";
import RolesModal from "../RolesModal";
import { DELETE_UI_ROLE } from "graphql/mutations";
import { UseAlert, UseIntermitence } from "hooks";
import { useMutation } from "@apollo/client";

const RolesList = ({ t }: any) => {
  const { currentSpace, currentBranchOffice } = useSelector(userSpaceSelector);
  const { loading, data, refetch } = useQuery(ROLES_QUERY, {
    variables: { spaceId: currentSpace.id, page: 1, search: undefined },
  });

  const [getRole] = useLazyQuery(UI_ROLE);
  const [openModal, setOpenModal] = useState(false);
  const dataRef = useRef(null);
  const [deleteRol] = useMutation(DELETE_UI_ROLE);
  const [roleId, setRoleId] = useState("");
  const { showAlert } = UseAlert();
  const { switchStatus: deleteModalSwitch, status: deleteStatus } =
    UseIntermitence();

  const [ordering, setOrdering] = useState({
    orderBy: "",
    orderDirection: "desc",
    search: "",
  });
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("lg")
  );

  const items: any = !loading
    ? transformToRows(
        data?.uiRoles?.items,
        isMobile,
        currentBranchOffice.code,
        t
      )
    : [];

  const handleRefetchQuery = (newOrdering?: any, newPage?: number) => {
    const toUpdate = { ...ordering, ...newOrdering };
    if (!shallowCompare(ordering, toUpdate)) {
      setOrdering(toUpdate);
      return refetch({
        ...toUpdate,
      });
    }
    refetch({ page: newPage });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      refetch({ search: ordering.search });
    }, 300);

    return () => clearTimeout(timer);
  }, [ordering.search]);

  const showDeleteModal = (event: any) => {
    const index = isMobile ? 1 : 2;
    if (event?.values?.[index]?.value > 0) {
      return showAlert("cant_delete_role", "error");
    }
    setRoleId(event?.id);
    deleteModalSwitch();
  };

  const delRole = () => {
    deleteRol({
      variables: { id: roleId },
      onError: (error: any) => {
        showAlert(error?.message, "error");
      },
      onCompleted: (response: any) => {
        const {
          deleteUiRole: { result },
        } = response;

        if (result) {
          showAlert("sucess_delete_role", "success");
          deleteModalSwitch();
          setRoleId("");
          return refetch();
        }
        setRoleId("");
        deleteModalSwitch();
        showAlert("something_went_wrong", "error");
      },
    });
  };

  return (
    <>
      <div className={styles._topContainer}>
        <div className={styles._titleContainer}>
          <p>{t("Roles")}</p>
          <GeneralButton
            icon={
              <div>
                <Icon icon="bx:user-plus" />
              </div>
            }
            action={() => {
              setOpenModal(true);
            }}
          />
        </div>
        <div className={styles._headerParent}>
          <div className={styles._inputParent}>
            <GeneralInput
              onChange={(event: any) =>
                setOrdering((prevValue) => ({
                  ...prevValue,
                  search: event.target.value,
                }))
              }
              value={ordering.search}
              className={styles._noMargin}
              placeholder={t("search")}
              withIcon
              icon="bx:search"
              iconColor="#909090"
              iconClass={styles._inputIcon}
            />
          </div>

          <div className={styles._btnParent}>
            <GeneralButton
              text="create_new_rol"
              action={() => {
                setOpenModal(true);
              }}
            />
          </div>
        </div>
      </div>
      <div className={styles._bottomContainer}>
        <GeneralTable
          pagination={data?.uiRoles.pagination}
          customTableStyle={styles._headerNoMargin}
          sortIconClass={styles._sortIconClass}
          moveClick={(page) => handleRefetchQuery(undefined, page)}
          orderBy={ordering.orderBy}
          orderDirection={ordering.orderDirection}
          searching={!!ordering.search}
          actions={(row) => {
            return [
              {
                actionClick: (event) => {
                  getRole({
                    variables: { id: event.id },
                    fetchPolicy: "cache-and-network",
                    onCompleted(data) {
                      dataRef.current = data;
                      setOpenModal(true);
                    },
                  });
                },
                icon: "bx:edit-alt",
              },
              {
                actionClick: (e) => {
                  showDeleteModal(e);
                },
                icon: "bx:trash",
              },
            ];
          }}
          header={{
            values: [
              { value: t("Rol"), order: "TITLE" },
              {
                value: t("rol_description_permissions"),
                order: "PERMISSIONS",
              },
              { value: t("members"), order: "MEMBERS" },
            ],
            sortClick: (e) => handleRefetchQuery(e),
          }}
          rows={items}
          loading={loading}
          emptyRender={
            <p>
              {t("no_role")}
              <br /> {t("no_role_2")}
            </p>
          }
          emptySearchRender={<p>{t("no_coincidence")}</p>}
        />
      </div>
      {openModal && (
        <RolesModal
          open={openModal}
          data={dataRef.current?.uiRole}
          currentSpaceId={currentSpace?.id}
          currentBranchOfficeId={currentBranchOffice?.id}
          refetchRolesQuery={refetch}
          onCancel={() => {
            setOpenModal(false);
            dataRef.current = null;
          }}
        />
      )}
      <GeneralConfirmModal
        title="are_you_sure_delete_role"
        open={deleteStatus}
        onConfirm={() => delRole()}
        onCancel={deleteModalSwitch}
      />
    </>
  );
};

export default RolesList;
