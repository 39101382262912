import { Method } from 'axios';
import { call } from 'redux-saga/effects';
import API from './customAxios';

export function* fetchService({
  url,
  method = 'GET',
  params = null,
  token = '',
}: {
  url: string;
  method: Method;
  params: null;
  token: string;
}): any {
  const objectRequest: any = {
    url,
    method,
    data: params,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (token) objectRequest.headers['Authorization'] = `Bearer ${token}`;

  const response = yield call(API, objectRequest);
  return response;
}
