import { Theme } from '@mui/material';

const rightDrawerWidth = '22.688rem';

const drawerStyles = (theme: Theme) => ({
  width: rightDrawerWidth,
  flexShrink: 0,
  zIndex: 1300,
  '& .MuiDrawer-paper': {
    width: rightDrawerWidth,
    padding: '0 1.1875rem 2rem 0.8125rem',
    boxSizing: 'border-box',
    background: theme.palette.background.paper,
  },

  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
});

const divider = (theme: Theme) => ({
  width: '100%',
  border: 'none',
  height: '2px',
  margin: '0.781rem auto 1rem auto',
  backgroundColor: theme.palette.primary.main,
});

const textItemButton: any = {
  width: '19.125rem',
  height: '3.563rem',
  padding: '0.4rem',
  margin: '0.3rem 0',
  borderRadius: '0.375rem',
  backgroundColor: '#F1F1F1',
  '&: hover': {
    background: '#F1F1F1',
  },
};

const listItemIcon = { minWidth: '2.375rem' };
const textStyle = (theme: Theme, active: boolean) => ({
  margin: 0,
  '& .MuiTypography-root': {
    fontSize: '14px',
    // lineHeight: '1.27625rem',

    color: active
      ? theme.palette.primary.main
      : theme.palette.blackColorCustom?.main,
  },
});

const tabsParent = (maxWidth: any) => ({
  width: '100%',
  maxWidth: '100%',
});

const gridContainer = (theme: Theme) => ({
  paddingBottom: '4.125rem',
});

const accordion = (theme: Theme) => ({
  marginBottom: '0 !important',
  [theme.breakpoints.down('md')]: {
    marginTop: '0 !important',
  },
  '&.MuiAccordion-root, & .Mui-expanded': {
    marginTop: '0 !important',
  },
  '&.MuiAccordion-root:before': {
    backgroundColor: '#F4F4F4',
  },
  '&.MuiAccordion-root, & .MuiAccordionSummary-content': {
    margin: '0 !important',
  },
  [theme.breakpoints.down('md')]: {
    left: 0,
    backgroundColor: '#F9F9F9',
    marginTop: '0 !important',
    '& .Mui-expanded': {
      marginTop: '20px !important',
    },
  },
});

export {
  drawerStyles,
  divider,
  textItemButton,
  listItemIcon,
  textStyle,
  tabsParent,
  accordion,
  gridContainer,
};
