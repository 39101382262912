import { gql } from '@apollo/client';

const  USERS_FOR_BRANCH_OFFICE = gql`
query (
  $page: Int
  $perPage: Int
  $search: String
  $orderDirection: OrderDirectionEnum
  $spaceId:ID!
  $branchOfficeId: ID
) { usersForSpace(
    page: $page
    perPage: $perPage
    search: $search
    orderDirection: $orderDirection
    spaceId: $spaceId
    branchOfficeId: $branchOfficeId
)
  {
    items {
      id
      name
      email
      belongsBranchOffice
    }
    pagination {
      page
      perPage
      totalItems
      totalPages
    }
  }
}

`;

export default USERS_FOR_BRANCH_OFFICE;
