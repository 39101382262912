import { gql } from '@apollo/client';

const REGISTER_ONBOARDING = gql`
  mutation CustomerMutation($data: CustomerOnboardingInput!) {
    customerOnboarding(data: $data) {
      id
      user {
        email
      }
    }
  }
`;

export default REGISTER_ONBOARDING;
