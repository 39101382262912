import React, { memo } from 'react'
import { Routes, Route } from "react-router-dom";
import {
  ChooseTypeUser,
  BasicUserData,
  AccountData,
  Agreements,
  Shareholders,
  Signature,
} from "../web/screens";

import { ProtectedOnboardingRoute, HelmetHeader } from "../components";
import { OnboardingLayout } from "../web/components";
import { useSelector } from 'react-redux';
import { onboardingSelector } from 'store/selectors';

const Onboarding = (props: any) => {

  const { type } = useSelector(onboardingSelector)

  const handleBackground = () => {
    if (!type) return 'onboarding-cover.png'
    if (type == 'natural') return 'natural-banner.svg'
    return 'juridic-banner.svg'
  }

  return (
    <OnboardingLayout backgroundImg={handleBackground()} position='center'>
    <HelmetHeader title='onboarding_path'/>
    <Routes>
      <Route path="/" element={<ChooseTypeUser {...props} />} />
      <Route element={<ProtectedOnboardingRoute />}>
        <Route path="/basic-user-data" element={<BasicUserData {...props} />} />
        <Route path="/shareholders" element={<Shareholders {...props} />} />
        <Route path="/account-data" element={<AccountData {...props} />} />
        <Route path="/signature" element={<Signature {...props} />} />
        <Route path="/agreements" element={<Agreements {...props} />} />
      </Route>
    </Routes>
  </OnboardingLayout>
  )
};

export default memo(Onboarding);
