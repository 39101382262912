import { gql } from '@apollo/client';
import { FRAGMENT_USER_RESPONSE } from '../fragments'

const UPDATE_USER_FOR_BRANCH_OFFICE = gql`
${FRAGMENT_USER_RESPONSE}
  mutation UpdateUserForBranchOffice($data: UpdateUserForBranchOfficeInput!) {
    updateUserForBranchOffice(data: $data) {
      id
      role{
        id
        name
      }
      ...UserResponse
    }
  }
`;
export default UPDATE_USER_FOR_BRANCH_OFFICE;
