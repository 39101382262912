import clsx from "clsx";
import styles from "./styles.module.scss";
import { GeneralCheckboxType } from "./types";
import { useTranslation } from 'react-i18next';

const GeneralCheckbox = ({
  checked,
  onChange,
  label,
  className,
}: GeneralCheckboxType) => {
  const { t } = useTranslation()
  return (
    <label className={clsx(styles._mainContainer, className)}>
      <div className={styles._container}>
        <input type="checkbox" checked={checked} onChange={onChange} />
        <div className={styles._checkmark}></div>
      </div>
      <span className={styles._label}>{t(label)}</span>
    </label>
  );
};

export default GeneralCheckbox;
