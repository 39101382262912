import { AppBar, Toolbar } from "@mui/material";
import { baseUrl } from "paths";
import { appBarStyles } from "./materialStyles";
import styles from "./styles.module.scss";
import OnboardingLangButton from '../../OnboardingLangButton'

const SimpleHeader = () => {
  return (
    <>
      <AppBar position="fixed" sx={appBarStyles} elevation={0}>
        <Toolbar disableGutters>
          <div className={styles._toolbarContainer}>
            <div>
              <img
                src={`${baseUrl}/resources/puntu-logo.png`}
                alt="onboarding-img"
                width="140px"
                className={styles._appbarImage}
              />
            </div>
            <div>
              <OnboardingLangButton />
            </div>

          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default SimpleHeader;
