import clsx from "clsx";
import { GeneralButton, Stepper } from "../../../components";
import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { styledComponentsBreakpoints } from "utils";
import styles from "./styles.module.scss";
import { FooterType } from "./types";
import { useTranslation } from 'react-i18next';

const OnboardingFooter = ({
  buttonText = "next",
  buttonAction,
  step,
  maxSteps,
  className,
  buttonDisabled,
  loading,
  showBtn = true,
  showStepper = true,
  message = "already_register",
  link = "enter",
  route = "/login",
}: FooterType) => {

  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className={clsx(styles._footerContainer, className)}>
      {showBtn && (
        <GeneralButton
          action={buttonAction}
          text={t(buttonText)}
          disabled={buttonDisabled}
          loading={loading}
        />
      )}

      {showStepper && (
        <div className={styles._stepperParent}>
          <Stepper step={step} maxSteps={maxSteps} />
        </div>
      )}

      <div className={styles._separator}>
        <div className={styles._line} />
        <div className={styles._circle} />
        <div className={styles._line} />
      </div>
      <div className={styles._registerText}>
        <p>{t(message)}</p>
        <p onClick={() => navigate(route)}>{t(link)}</p>
      </div>
    </div>
  );
};

export default styled(memo(OnboardingFooter))`
  padding-right: ${(props: any) => props.paddingRight};
  @media ${styledComponentsBreakpoints.lg} {
    padding-right: 0;
  }
  @media (max-height: 783px) {
    padding-bottom: 1rem;
  }
`;
