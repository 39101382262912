import { Main } from "routers";
import { Alert } from "./components";
import { useSelector } from "react-redux";
import { userAuthSelector } from "store/selectors";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { API_URL } from "paths";
import { GoogleOAuthProvider } from "@react-oauth/google";

const App = () => {
  const { userAuthData } = useSelector(userAuthSelector);
  const client = new ApolloClient({
    headers: {
      authorization: userAuthData?.accesToken
        ? `Bearer ${userAuthData?.accesToken}`
        : "",
    },
    uri: API_URL,
    cache: new InMemoryCache(),
    connectToDevTools: true,
  });

  return (
    <GoogleOAuthProvider clientId="734884743483-oh6mqvo3semalrs2g4d1hm181cd6aat4.apps.googleusercontent.com">
      <ApolloProvider client={client}>
        <Main />
        <Alert />
      </ApolloProvider>
    </GoogleOAuthProvider>
  );
};
export default App;
