import { gql } from '@apollo/client';

const SUSCRIBE_NEWSLETTER= gql`
  mutation SubscribeNewsletter($data: SubscribeNewsletterInput!) {
    subscribeNewsletter(data: $data) {
      result
    }
  }
`;
export default SUSCRIBE_NEWSLETTER;
