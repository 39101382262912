import { SET_ACTIVITY_DATA, SET_ACTIVITY_PARAMS } from './action-types'

const initialState: any = {
  activities: null,
  perPage: 10,
  searchValue: '',
  walletId: null,
  transactionType: null,
  spaceId: null,
  branchOfficeId: null
};

const onboarding = (state = initialState, { type, payload }: any) => {
  switch (type) {
    case SET_ACTIVITY_DATA:
      return { ...state, ...payload };

    case SET_ACTIVITY_PARAMS:
      return { ...state, ...payload };

    default:
      return state;
  }
};

export default onboarding;
