import axios from 'axios';
import { baseUrl } from 'paths';

const API = axios.create({
  baseURL: baseUrl,
  timeout: 30000000,
});

API.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response ? error.response.status : -1;
    switch (status) {
      // case 500:
      //   break;
      // case -1:
      //   break;
      // case 400:
      //   break;
      // case 401:
      // 	return error.response

      // case 404:
      //   break;
      default:
        break;
    }
    return Promise.reject({ ...error });
  }
);

export default API;
