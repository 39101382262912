import { gql } from '@apollo/client';

const BRANCH_OFFICE_QUERY = gql`
  query BranchOffices($id: ID!) {
    branchOffice(id: $id) {
      id
      name
      address
      code
      email
      dateOfCreation
      space {
        EIN
        name
        isSpecial
        user {
          profile {
            id
            phoneNumber
            cellPhoneNumber
          }
          email
          customer {
            fiscalAddress
            monthlyIncome
            typeCommerce {
              esName
              enName
              icon
            }
            typeCommerceFallback
            legalRepresentative {
              profile {
                firstName
                lastName
                dniType
                dniNumber
                phoneNumber
                cellPhoneNumber
              }
            }
          }
        }
      }
    }
  }
`;

export default BRANCH_OFFICE_QUERY;
