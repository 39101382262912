import { memo, useState } from "react";
import styles from "./styles.module.scss";
import { PhoneInputType } from "./types";
import styled from "styled-components";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { GeneralSelect } from "..";
import { countries } from "utils/countries";

const statusManagment = (type: any = "inherit"): string => {
  if (type == "inherit") return styles._input;
  if (type == "success") return styles._input;
  if (type == "error") return styles._inputError;
};

const GeneralPhoneInput = ({
  placeholder,
  type,
  className,
  inputClasses,
  value,
  onChange,
  onFocus,
  onBlur,
  id,
  name,
  disabled,
  hasError = false,
  errorMessage,
  setFieldValue,
  status = "inherit",
}: PhoneInputType) => {
  const { t } = useTranslation();
  const [selectValue, setSelected] = useState(
    value.substring(0, value.indexOf(" ")) || "+58"
  );
  const [inputValue, setInputValue] = useState(
    value.substring(value.indexOf(" ") + 1) || ""
  );

  return (
    <div className={clsx(styles._inputContainer, className)}>
      <div
        style={{
          width: "100%",
          position: "relative",
          justifyContent: "space-between",
          display: "flex",
        }}
      >
        <GeneralSelect
          value={selectValue}
          options={countries.countries}
          onChange={(e: any) => {
            setSelected(e.target.value);
            setFieldValue(name, e.target.value + " " + inputValue);
          }}
          className={styles._selectWidth}
          innerPaddingRight="14px !important"
        />
        <input
          className={clsx(statusManagment(status), styles._inputWidth)}
          type={type}
          placeholder={t(placeholder)}
          value={inputValue}
          onChange={(e: any) => {
            setInputValue(e.target.value);
            setFieldValue(name, selectValue + " " + e.target.value);
          }}
          onFocus={onFocus}
          onBlur={onBlur}
          id={id}
          name={name}
          disabled={disabled}
        />
      </div>
      {hasError && errorMessage && (
        <p className={styles._textError}>{t(errorMessage)}</p>
      )}
    </div>
  );
};

export default styled(memo(GeneralPhoneInput))`
  width: ${({ inputWidth }) => (inputWidth ? inputWidth : "")};
`;
