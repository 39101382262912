import React from 'react'
import styles from './styles.module.scss'
import { StepperType } from 'types'
import styled from "styled-components";

const Div: any = styled.div`
  width: ${({ width }: any) => (width && width)};
`
const Stepper = ({ step = 1, maxSteps = 4, width }: StepperType) => {
  return (
    <div className={styles._parent}>
      {
        Array.from(Array(maxSteps).keys()).map((item: number) => {
          const currentStep = item + 1;
          return <Div width={width} className={step === currentStep ? styles._stepColor : styles._step} key={item} />
        })
      }
    </div>
  )
}

export default Stepper
