import { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import {
  GeneralTable,
  GeneralConfirmModal,
} from "../../../../../../components";
import { useQuery } from "@apollo/client";
import { USERS_FOR_BRANCH_OFFICE } from "../../../../../../graphql/queries";
import { transformToRows } from "./functions";

import { CreateUserForBranchOffice } from "web/components";
import { Theme, useMediaQuery } from "@mui/material";
import { UseAlert, UseIntermitence } from "hooks";
import { userSpaceSelector } from "store/selectors";
import { useTranslation } from "react-i18next";
import { shallowCompare } from "../../../../../../utils";
import { useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { DELETE_USERS_FOR_BRANCH_OFFICE } from "graphql/mutations";

const UsersBranchOfficeList = ({
  branchName,
  closeCreate,
  ordering,
  openCreate,
  branchId,
  type = "create",
}: any) => {
  const { t } = useTranslation();
  const [deleteUser] = useMutation(DELETE_USERS_FOR_BRANCH_OFFICE);
  const [userId, setUserId] = useState("");

  const [status, setStatus] = useState(false);
  const { switchStatus: deleteModalSwitch, status: deleteStatus } =
    UseIntermitence();
  const [typeModal, setTypeModal] = useState(type);
  const { showAlert } = UseAlert();

  const { currentSpace } = useSelector(userSpaceSelector);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("lg")
  );

  const { loading, data, refetch } = useQuery(USERS_FOR_BRANCH_OFFICE, {
    variables: {
      page: 1,
      perPage: 4,
      spaceId: currentSpace?.id,
      branchOfficeId: branchId,
      search: ordering?.search,
    },
  });

  const items: any = !loading
    ? transformToRows(data?.usersForBranchOffice?.items, isMobile)
    : [];

  const [order, setOrder] = useState({
    orderBy: "",
    orderDirection: "desc",
    search: "",
  });

  const handleRefetchHeader = (newOrdering?: any, newPage?: number) => {
    const toUpdate = { ...ordering, ...newOrdering };
    if (!shallowCompare(ordering, toUpdate)) {
      setOrder(toUpdate);
      return refetch({
        ...toUpdate,
      });
    }
    refetch({ page: newPage });
  };

  const showDeleteModal = (id: string) => {
    setUserId(id);
    deleteModalSwitch();
  };

  const editModal = (id: string) => {
    setUserId(id);
    setTypeModal("edit");
    setStatus(true);
  };

  const delBranch = () => {
    deleteUser({
      variables: { id: userId },
      onError: (error: any) =>
        showAlert(error?.message, "error"),
      onCompleted: (response: any) => {
        const {
          deleteUserForBranchOffice: { result },
        } = response;

        if (result) {
          showAlert("sucess_delete_user", "success");
          deleteModalSwitch();
          setUserId("");
          return refetch();
        }
        setUserId("");
        deleteModalSwitch();
        showAlert("something_went_wrong", "error");
      },
    });
  };

  useEffect(() => {
    if (openCreate) setStatus(true);
  }, [openCreate]);

  return (
    <>
      <div className={styles._bottomContainer}>
        <GeneralTable
          title={`${t("branch_office")} ${branchName ?? ""}`}
          pagination={data?.usersForBranchOffice.pagination}
          moveClick={(page) => handleRefetchHeader(undefined, page)}
          orderBy={order.orderBy}
          orderDirection={order.orderDirection}
          searching={!!ordering.search}
          actions={(row) => {
            return [
              {
                actionClick: (event) => {},
                icon: "bx:show",
                hide: true,
              },
              {
                actionClick: (event) => {
                  editModal(event?.id);
                },
                icon: "bx:edit-alt",
              },
              {
                actionClick: (e) => {
                  showDeleteModal(e?.id);
                },
                icon: "bx:trash",
              },
            ];
          }}
          header={{
            values: [
              { value: t("name"), order: "NAME" },
              { value: t("email"), order: "EMAIL" },
              { value: t("role"), order: "ROLE" },
              { value: t("creation"), order: "CREATEDAT" },
              { value: t("updated"), order: "UPDATEDAT" },
            ],
            sortClick: (e) => handleRefetchHeader(e),
          }}
          rows={items}
          loading={loading}
          emptyRender={<p>{t("no_members_branch_office")}</p>}
          emptySearchRender={<p>{t("no_coincidence")}</p>}
        />
      </div>
      {status && (
        <CreateUserForBranchOffice
          open={status}
          onClose={() => {
            setStatus(false);
            closeCreate();
            setUserId("");
          }}
          refetch={refetch}
          branchId={branchId}
          type={typeModal}
          userId={userId}
        />
      )}

      <GeneralConfirmModal
        title="are_you_sure_delete_user"
        open={deleteStatus}
        onConfirm={() => delBranch()}
        onCancel={deleteModalSwitch}
      />
    </>
  );
};

export default UsersBranchOfficeList;
