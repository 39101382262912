import { actionObject } from 'utils';
import {
  SET_USER_AUTH,
  LOGOUT,
  UPDATE_WALLETS,
  SET_PERMISSIONS,
  SET_2FA_DISABLE,
  SET_USER_EMAIL,
} from './action-types';

export const setUserAuth = (data: any) => actionObject(SET_USER_AUTH, data);
export const setPermissions = (data: any) =>
  actionObject(SET_PERMISSIONS, data);
export const logout = () => actionObject(LOGOUT);
export const updateWallets = (wallet: any) =>
  actionObject(UPDATE_WALLETS, wallet);
export const set2faDisable = () => actionObject(SET_2FA_DISABLE);

export const setUserEmail = (email: string) =>
  actionObject(SET_USER_EMAIL, email);
