import { gql } from '@apollo/client';

const VERYFY_DNI = gql`
  mutation VerifyDNI($dniNumber: String!) {
    verifyDNI(data: {dniNumber: $dniNumber}) {
        result
    }
}
`;
export default VERYFY_DNI;
