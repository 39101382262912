import { gql } from '@apollo/client';

const UI_ROLES = gql`
query UiRoles(
  $page: Int
  $perPage: Int
  $search: String
  $orderDirection: OrderDirectionEnum
  $orderBy: UiRolesOrderByEnum
  $spaceId:ID!
) {uiRoles(
    page: $page
    perPage: $perPage
    search: $search
    orderDirection: $orderDirection
    orderBy: $orderBy
    spaceId: $spaceId
)
  {
    items {
      id
      title
      members
      permissions
      members
    }
    pagination {
      page
      perPage
      totalItems
      totalPages
    }
  }
}
`;

export default UI_ROLES;
