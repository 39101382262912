import { useEffect, useState } from "react";
import {
  GeneralModal,
  GeneralInput,
  GeneralButton,
  GeneralRadioGroup,
} from "../../../components";
import styles from "./styles.module.scss";
import Grid from "@mui/material/Grid";
import { FormikConfig } from "./formik";
import { useSelector } from "react-redux";
import { userSpaceSelector } from "store/selectors";
import { useMediaQuery, Theme } from "@mui/material";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import {
  CREATE_USER_BRANCH_OFFICE,
  UPDATE_USER_FOR_BRANCH_OFFICE,
} from "graphql/mutations";
import { UseAlert } from "hooks";
import { useTranslation } from "react-i18next";
import { UseIntermitence } from "hooks";
import { UI_ROLES, USER_FOR_BRANCH_OFFICE } from "graphql/queries";
import { Icon } from "@iconify/react";

const CreateUserForBranchOffice = ({
  open,
  onClose,
  refetch,
  branchId,
  userId,
}: any) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState("");
  const [timer, setTimer] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const { showAlert } = UseAlert();
  const { status, switchStatus } = UseIntermitence();
  const { status: iconConfirmStatus, switchStatus: switchConfirmIconStatus } =
    UseIntermitence();
  const { status: iconStatus, switchStatus: switchIconStatus } =
    UseIntermitence();

  const { currentSpace } = useSelector(userSpaceSelector);

  const { data: rolesdata, loading: rolesLoader } = useQuery(UI_ROLES, {
    fetchPolicy: "no-cache",
    variables: {
      page: currentPage,
      perPage: 4,
      spaceId: currentSpace?.id,
      search: searchValue,
    },
  });

  const [getUserDetail, { data: userDetail }] = useLazyQuery(
    USER_FOR_BRANCH_OFFICE,
    {
      fetchPolicy: "no-cache",
    }
  );

  const userDetailed = userDetail?.userForBranchOffice;
  const totalPages = rolesdata?.uiRoles?.pagination?.totalPages || 0;

  useEffect(() => {
    return () => resetForm();
  }, [open]);

  const handleOnTouched = (key: string) =>
    setTouched({ ...touched, [key]: true });

  const handleCheckUsers = (id: number) => {
    setFieldValue("uiRoleId", id);
  };

  const nextPage = () => {
    if (currentPage <= totalPages - 1)
      setCurrentPage((currentPage: number) => currentPage + 1);
  };

  const previousPage = () => {
    if (currentPage >= 2)
      setCurrentPage((currentPage: number) => currentPage - 1);
  };

  const [createUserBranchOffice, { loading }] = useMutation(
    CREATE_USER_BRANCH_OFFICE
  );
  const [updateUserBranchOffice] = useMutation(UPDATE_USER_FOR_BRANCH_OFFICE);

  const submitData = (formData: any) => {
    createUserBranchOffice({
      variables: { data: { ...formData } },
      onError: (error) => {
        showAlert("user_branch_office_error", "error");
      },
      onCompleted: (response: any) => {
        if (response) {
          showAlert(t("user_branch_office_created"), "success");
          switchStatus();
          onClose();
          resetForm();
          refetch && refetch();
        }
      },
    });
  };

  const updateData = async (formData: any) => {
    await updateUserBranchOffice({
      variables: { data: { ...formData, id: userDetailed?.id } },
      onError: (error) => {
        showAlert("user_branch_office_error_edited", "error");
      },
      onCompleted: (response: any) => {
        if (response) {
          showAlert(t("user_branch_office_edited"), "success");
          switchStatus();
          refetch && refetch();
          onClose();
        }
      },
    });
  };

  const getUserData = () => {
    getUserDetail({
      variables: { id: userId },
      onCompleted: (response: any) => {
        const formUserData: any = {
          firstName: response?.userForBranchOffice?.user?.profile?.firstName,
          lastName: response?.userForBranchOffice?.user?.profile?.lastName,
          email: response?.userForBranchOffice?.user?.email,
          uiRoleId: response?.userForBranchOffice?.uiRoleId,
        };

        for (const item in formUserData) {
          setFieldValue(item, formUserData[item]);
        }
      },
    });
  };

  useEffect(() => {
    if (userId) getUserData();
  }, [userId]);

  const {
    submitForm,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setTouched,
    setFieldValue,
    isValid,
    values,
    resetForm,
    dirty,
  } = FormikConfig(!userId ? submitData : updateData, branchId, userDetailed);

  const changeInputStatus = (value: string, error: any) => {
    if (error) return "error";
    if (value?.length) return "success";
    return "inherit";
  };

  const inputHandler = (event: any) => {
    if (!loading) {
      const {
        target: { value },
      } = event;
      setSearchValue(value);

      if (timer) clearTimeout(timer);

      setTimer(setTimeout(() => {}, 500));
    }
  };

  return (
    <>
      <GeneralModal
        open={open}
        title={!userId ? t("create_new_user") : t("edit_user")}
        onCancel={onClose}
      >
        <div className={styles._main}>
          <p className={styles._subtitle}>
            {!userId ? t("enter_data_create_user") : t("enter_data_edit_user")}
          </p>

          <form onSubmit={handleSubmit}>
            <Grid container spacing={isMobile ? 2.8 : 3.5}>
              <Grid item xs={12} sm={12} md={6}>
                <GeneralInput
                  placeholder="name"
                  id="firstName"
                  name="firstName"
                  onChange={handleChange}
                  value={values?.firstName}
                  hasError={
                    errors.firstName && touched.firstName ? true : false
                  }
                  onFocus={() => handleOnTouched("firstName")}
                  errorMessage={errors.firstName}
                  status={changeInputStatus(
                    values?.firstName,
                    errors.firstName && touched.firstName
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <GeneralInput
                  placeholder="last_name"
                  id="lastName"
                  name="lastName"
                  onChange={handleChange}
                  value={values?.lastName}
                  hasError={errors.lastName && touched.lastName ? true : false}
                  onFocus={() => handleOnTouched("lastName")}
                  errorMessage={errors.lastName}
                  status={changeInputStatus(
                    values?.lastName,
                    errors.lastName && touched.lastName
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <GeneralInput
                  placeholder="email"
                  id="email"
                  name="email"
                  onChange={handleChange}
                  disabled={!userId ? false : true}
                  value={values?.email}
                  hasError={errors.email && touched.email ? true : false}
                  onFocus={() => handleOnTouched("email")}
                  errorMessage={errors.email}
                  status={changeInputStatus(
                    values.email,
                    errors.email && touched.email
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <GeneralInput
                  placeholder="password"
                  onFocus={() => {
                    switchStatus();
                    handleOnTouched("password");
                  }}
                  onBlur={switchStatus}
                  withIcon
                  iconColor={"#919191"}
                  icon={`${!iconStatus ? "bx:show" : "bx:low-vision"}`}
                  id="password"
                  name="password"
                  onChange={handleChange}
                  value={values.password}
                  hasError={errors.password && touched.password ? true : false}
                  iconMethod={switchIconStatus}
                  type={`${!iconStatus ? "password" : "text"}`}
                  errorMessage={errors.password}
                  status={changeInputStatus(
                    values.password,
                    errors.password && touched.password
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <GeneralInput
                  placeholder="confirm_password"
                  withIcon
                  id="confirmPassword"
                  name="confirmPassword"
                  iconColor={"#919191"}
                  icon={`${!iconConfirmStatus ? "bx:show" : "bx:low-vision"}`}
                  type={`${!iconConfirmStatus ? "password" : "text"}`}
                  iconMethod={switchConfirmIconStatus}
                  onChange={handleChange}
                  hasError={
                    errors.confirmPassword && touched.confirmPassword
                      ? true
                      : false
                  }
                  value={values.confirmPassword}
                  onFocus={() => handleOnTouched("confirmPassword")}
                  errorMessage={errors.confirmPassword}
                  status={changeInputStatus(
                    values.confirmPassword,
                    errors.confirmPassword && touched.confirmPassword
                  )}
                />
              </Grid>
            </Grid>

            <>
              <Grid item xs={12} sm={12} md={12}>
                <div style={{ paddingTop: "28px" }}>
                  <div className={styles._inputParent}>
                    <GeneralInput
                      onChange={(event: any) => inputHandler(event)}
                      value={searchValue}
                      className={styles._noMargin}
                      placeholder="assign_role"
                    />
                    <Icon
                      icon="bx:search"
                      width={18}
                      className={styles._searchIcon}
                      onClick={null}
                    />
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <div style={{ paddingTop: "28px" }}>
                  <GeneralRadioGroup
                    checkValue={values.uiRoleId}
                    handleCheckValue={(event: any) =>
                      handleCheckUsers(event?.target?.value)
                    }
                    multiple={false}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    mapData={rolesdata?.uiRoles?.items}
                    leftMethod={previousPage}
                    rightMethod={nextPage}
                    loading={!rolesLoader}
                  />
                </div>
              </Grid>
            </>

            <div className={styles._buttonParent}>
              <GeneralButton
                text={!userId ? "add" : "save"}
                type="submit"
                action={submitForm}
                disabled={!isValid || !dirty}
              />
            </div>
          </form>
        </div>
      </GeneralModal>
    </>
  );
};

export default CreateUserForBranchOffice;
