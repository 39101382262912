import React from "react";
import { Modal, Box } from "@mui/material";
import styles from "./styles.module.scss";
import { Icon } from "@iconify/react";
import { GeneralButton } from "../../components";
import { ConfirmModalType } from "./types";
import { modalBox, modal } from "./materialStyles";
import { useTranslation } from "react-i18next";

const GeneralConfirmModal = ({
  title = "",
  onConfirm = null,
  onCancel = null,
  open = false,
  btnText = "delete",
  body,
  withDrawer = true,
}: ConfirmModalType) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} onClose={onCancel} sx={(theme)=> modal(theme, withDrawer)}>
      <Box sx={(theme)=> modalBox(theme, withDrawer)}>
        <div className={styles._iconParent} onClick={onCancel}>
          <Icon icon="zondicons:close" width={18} color="#000" />
        </div>
        <h3 className={styles._title}>{t(title)}</h3>
        {body}
        <div className={styles._btnsParent}>
          <div className={styles._btnParent}>
            <GeneralButton text={t("cancel")} action={onCancel} />
          </div>
          <div className={styles._btnParent}>
            <GeneralButton text={t(btnText)} action={onConfirm} />
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default GeneralConfirmModal;
