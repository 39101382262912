import { useRef, useState } from "react";
import { GeneralModal, GeneralDropdown, GeneralButton} from "../../../components";
import styles from "./styles.module.scss";
import { useTranslation } from 'react-i18next'
import { Icon } from '@iconify/react'
import { EXPORT_ACTIVITIES } from "graphql/mutations";
import { useMutation, useQuery } from '@apollo/client';
import { UseAlert } from "hooks";
import CircularProgress from "@mui/material/CircularProgress";
import ACTIVITY_AVAILABLE from "graphql/queries/activityAvailable";
import { userSpaceSelector } from 'store/selectors';
import { useSelector } from 'react-redux'

const ExportModal = ({onCancel}: any) => {

  const { t } = useTranslation()
  const ref = useRef()
  const { showAlert } = UseAlert();
  const { currentSpace } = useSelector(userSpaceSelector)
  const [exportData, { loading }] = useMutation(EXPORT_ACTIVITIES);
  const { data } = useQuery(ACTIVITY_AVAILABLE, {
    variables:{
      spaceId: currentSpace?.id
    }
  });

  const [currentIndex, setCurrentIndex] = useState(-1)
  const [showAdvice, setShowAdvice] = useState(0)


  const files = [
    // { label: '.pdf', value: 'pdf' },
    { label: '.txt', value: 'txt' },
    { label: '.csv', value: 'csv' },
  ]

  const openDrop = (index: number) => {
    if (currentIndex == index) {
      return setCurrentIndex(-1);
    }
    setCurrentIndex(index)
  }

  const exportCsv = (folder: string, month: number, value: any) => {
    exportData({
      variables: { folder, monthNumber: month, format: value, spaceId:currentSpace?.id },
      onError: (error: any) => {
        showAlert(error?.message, 'error')
        setCurrentIndex(-1)
      },
      onCompleted: (response: any) => {
        const { exportActivities: { result } } = response
        if (result) {
          window.location.href = result
          setCurrentIndex(-1)
        }
      }
    })
  }

  return (
    <>
      <GeneralModal open={showAdvice == 0} onCancel={onCancel}  title={t('account_statements')}>
        <div className={styles._mainContainer}>
          <div className={styles._content}>
            {
              data?.activityAvailable.map((item: any, index: number) => (
                <div style={{ position: "relative" }} key={index}>
                  <div key={index} ref={ref} className={styles._contentMonth}
                    onClick={() => openDrop(index)}>
                    <p className={styles._text}>{t('accountt_statement')} {t(item.month)} {item.year}</p>
                    {loading && currentIndex == index ?
                      <CircularProgress color="inherit" size={22} />
                      :
                      <Icon icon='bxs-download' width={18} color='#919191' />
                    }
                  </div>
                  <GeneralDropdown method={
                    (value: any) => exportCsv('ActivityReports', item.monthNumber, value)}
                    show={currentIndex == index && !loading}
                    options={files} />

                </div>
              ))
            }
            <p className={styles._advice}>{t('request_more_six_activities')}</p>

            <div className={styles._button}>
              <GeneralButton
                action={() =>   setShowAdvice(1)}
                text={'request'}
                loading={loading}
                type="submit"
              />
            </div>
          </div>
        </div>
      </GeneralModal>

      <GeneralModal open={showAdvice == 1}
      title={t('need_more_activities')}
      onCancel={onCancel}>
      {}
      </GeneralModal>
    </>
  );
};

export default ExportModal;
