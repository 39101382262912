import styles from "./styles.module.scss";
import { Icon } from "@iconify/react";
import { GeneralIconButton } from "components";
import clsx from "clsx";
import { useMediaQuery, Theme } from "@mui/material";
import { useTranslation } from "react-i18next";

const WhiteListDetail = ({
  address,
  cellPhoneNumber,
  email,
  phoneNumber,
  identification,
  type,
  firstName,
  lastName,
  comeBack,
  id,
  onDelete,
  onEdit,
}: any) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const { t } = useTranslation();

  return (
    <div className={styles._mainContainer}>
      <p className={styles._title}>{t("Directory")}</p>

      <div className={styles._comeBackParent} onClick={comeBack}>
        <Icon icon="bx:left-arrow-alt" width={24} color="#E6427A"></Icon>
        <p>{t("return_directoy")}</p>
      </div>

      <div className={styles._card}>
        <div className={styles._cardHeader}>
          <p className={styles._name}>
            {firstName ?? ""} {lastName ?? ""}
          </p>

          <div className={styles._btnsParent}>
            <div
              className={styles._deleteBtnParent}
              onClick={() => onDelete(id)}
            >
              <GeneralIconButton
                className={styles._customBtn}
                leftIconType="bx:trash"
                text={!isMobile ? "delete" : ""}
                iconColor="#000"
                leftIcon
                background="#F1F1F1"
                secondaryColor="#F1F1F1"
              />
            </div>

            <div className={styles._editBtnParent} onClick={() => onEdit(id)}>
              <GeneralIconButton
                className={styles._customBtnEdit}
                leftIconType="bx:edit-alt"
                text={t(!isMobile ? "edit_user" : "")}
                iconColor="#E6427A"
                leftIcon
                background="#FAEAF2"
                secondaryColor="#F1F1F1"
                textColor="#E6427A"
              />
            </div>
          </div>
        </div>

        <p className={styles._mobileName}>
          {firstName ?? ""} {lastName ?? ""}
        </p>

        <div className={styles._infoParent}>
          <div className={styles._column}>
            <div className={styles._columTop}>
              <p>{t("type")}</p>
              <p>{t(type)}</p>
            </div>

            <div className={styles._columBottom}>
              <p>{t("email")}</p>
              <p>{email}</p>
            </div>
          </div>

          <div className={styles._column}>
            <div className={styles._columTop}>
              <p>{t("id")}</p>
              <p>{identification}</p>
            </div>

            <div className={styles._columBottom}>
              <p>{t("address")}</p>
              <p>{address}</p>
            </div>
          </div>
          <div className={clsx(styles._column, styles._toBottom)}>
            <div className={styles._columTop}>
              <p>{t("cell_phone")}</p>
              <p>{cellPhoneNumber}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhiteListDetail;
