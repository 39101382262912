import { SET_WALLETS, SET_CURRENCIES } from './action-types';

const initialState: any = {
  wallets: [],
  currencies: []
};

const resource = (state = initialState, { type, payload }: any) => {
  switch (type) {
    case SET_WALLETS:
      return { ...state, ...payload };

    case SET_CURRENCIES:
      return { ...state, ...payload };

    default:
      return state;
  }
};

export default resource;
