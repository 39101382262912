import { useEffect, useState } from "react";
import { OnboardingFooter, OnboardingLayout } from "../../../components";
import { FormikConfig } from "./formik";
// import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { gapi } from "gapi-script";
import { useDispatch, useSelector } from "react-redux";
import {
  setPermissions,
  setUserAuth,
  setUserEmail,
  setUserSpace,
} from "store/actions";
import { useLazyQuery, useMutation } from "@apollo/client";
import { LOGIN_MUTATION, SIGN_IN_GOOGLE } from "graphql/mutations";
import { withTrans } from "i18n/withTrans";
import { set2FAEmail } from "store/twoFA/action";
import { Icon } from "@iconify/react";
import { UseIntermitence, UseAlert } from "hooks";
import { CLAIM_PAYMENT, permissionsToRoutes } from "utils/permissions";
import {
  GeneralInput,
  GeneralButton,
  HelmetHeader,
  GeneralOTP,
  GeneralCheckbox,
} from "../../../../components";
import USER_PERMISSIONS_QUERY from "graphql/queries/userPermissions";
import styles from "./styles.module.scss";
import { userAuthSelector } from "store/selectors";

const Login = ({ t }: any) => {
  const clientId = process.env.GOOGLE_CLIENT_ID;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [statusIcon, setStatusIcon] = useState("");
  const [rememberMe, setRememnberMe] = useState(false);
  const { showAlert } = UseAlert();
  const { userEmail } = useSelector(userAuthSelector);
  const [login, { loading }] = useMutation(LOGIN_MUTATION);
  const [getPermissions] = useLazyQuery(USER_PERMISSIONS_QUERY, {
    onCompleted: (data) => {
      const permissions: Array<string> = data?.userPermissions.map(
        (permission: { name: string }) => permission.name
      );

      if (permissions.length) {
        dispatch(
          setPermissions({
            permissions,
          })
        );

        let routePermission = permissions[0];

        if (routePermission == CLAIM_PAYMENT) {
          routePermission = permissions && permissions[1];
        }

        navigateTo(permissionsToRoutes[routePermission]);
        showAlert("login_success", "success");

        return;
      }
      showAlert("permissions_required", "error");
    },
  });
  const [signInGoogle] = useMutation(SIGN_IN_GOOGLE);
  const { switchStatus } = UseIntermitence();
  const [googleAuthToken, setGoogleAuthToken] = useState("");

  const { status: iconStatus, switchStatus: switchIconStatus } =
    UseIntermitence();

  const handleSubmit = (formValues: any, form: any) => {
    login({
      variables: { ...formValues },
      onError: (error: any) => {
        if (error.message == "dont_have_access_inactive_account") {
          return showAlert("verify_your_account", "error");
        }
        if (error.message == "need_active_two_fact_code") {
          dispatch(set2FAEmail(formValues.email));
          return navigateTo("/set-2fa");
        }
        if (error.message == "invalid_otp_code" && !formValues.otpView) {
          form.setFieldTouched("otp", false);
          return form.setFieldValue("otpView", true);
        }

        if (formValues.otpView) {
          setStatusIcon("error");
        }
        showAlert(error?.message, "error");
      },
      onCompleted: (response: any) => {
        // setStatusIcon("success");
        const { signIn } = response;

        const data: any = {
          accesToken: signIn?.accessToken,
          ...signIn?.user,
        };

        const spaceData = {
          currentSpace: data?.spaces?.[0]?.space,
          currentBranchOffice: data?.spaces?.[0]?.space?.branchOffices?.[0],
        };
        dispatch(setUserEmail(rememberMe ? formValues.email : ""));
        dispatch(setUserAuth({ userAuthData: data }));
        dispatch(setUserSpace(spaceData));

        if (data.customer) {
          showAlert("login_success", "success");
          navigateTo("/dashboard");
          return;
        }

        getPermissions({
          variables: {
            branchOfficeId: spaceData.currentBranchOffice?.id,
          },
        });
      },
    });
  };
  const {
    values,
    handleSubmit: formikSubmit,
    handleChange,
    submitForm,
    errors,
    touched,
    resetForm,
    setTouched,
    setFieldValue,
    dirty,
    isValid,
  } = FormikConfig(handleSubmit);

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: "email",
      });
    }

    gapi.load("client", start);

    if (userEmail !== "") {
      setFieldValue("email", userEmail);
      setFieldValue("password", "");
      setRememnberMe(true);
    } else {
      resetForm();
    }
  }, []);

  const navigateTo = (route: string) => navigate(route);
  const handleOnTouched = (key: string) =>
    setTouched({ ...touched, [key]: true });

  const loginGoogle = (res: any) => {
    const googleToken = {
      verifierToken: res,
    };

    const googleTokenWithOtp = {
      verifierToken: googleAuthToken,
      otp: values?.otp,
    };

    signInGoogle({
      variables:
        googleAuthToken !== "" ? { ...googleTokenWithOtp } : { ...googleToken },
      onError: (error: any) => {
        if (error.message == "email_dont_exist") {
          showAlert("email_dont_exist", "error");
          return navigateTo("/onboarding");
        }
        if (error.message == "Please activate authenticator 2FA") {
          dispatch(set2FAEmail(res.profileObj?.email));
          return navigateTo("/set-2fa");
        }
        if (error.message == "invalid_otp_code" && !values?.otpView) {
          setGoogleAuthToken(googleToken?.verifierToken);
          return setFieldValue("otpView", true);
        }

        if (values?.otpView) {
          setStatusIcon("error");
        }
        showAlert(error?.message, "error");
      },

      onCompleted: (response: any) => {
        setStatusIcon("success");
        const {
          signInWithGoogle: { user, accessToken },
        } = response;

        if (user) {
          const data: any = {
            accesToken: accessToken,
            ...user,
          };

          const spaceData = {
            currentSpace: data?.spaces?.[0]?.space,
            currentBranchOffice: data?.spaces?.[0]?.space?.branchOffices?.[0],
          };

          dispatch(setUserSpace(spaceData));
          dispatch(setUserAuth({ userAuthData: data }));
          if (data.customer) {
            showAlert("login_success", "success");
            return navigateTo("/dashboard");
          }
          getPermissions({
            variables: {
              branchOfficeId: spaceData.currentBranchOffice?.id,
            },
          });
        }
      },
    });
  };

  const changeInputStatus = (value: string, error: any) => {
    if (value !== "") {
      if (error) return "error";
      return "success";
    }
    return "inherit";
  };

  // const initGoogleLogin = useGoogleLogin({
  //   scope: "email",
  //   onSuccess: (tokenResponse: any) => {
  //     loginGoogle(tokenResponse?.access_token);
  //   },

  //   onError: (error: any) => {
  //     console.log("Login Failed", error);
  //   },
  // });

  return (
    <OnboardingLayout className={styles._fullHeight}>
      <HelmetHeader title="login_path" />
      <div className={styles._mainContainer}>
        <form className={styles._form} onSubmit={formikSubmit} id="my-form">
          {!values.otpView ? (
            <div className={styles._input}>
              <p className={styles._title}>{t("login")}</p>
              <p className={styles._subtitleL}>{t("thats_puntu")}</p>
              <div className={styles._inputDualContainer}>
                <div className={styles._inputContainer}>
                  <GeneralInput
                    withIcon={false}
                    placeholder={"email"}
                    id="email"
                    name="email"
                    onChange={handleChange}
                    value={values.email}
                    hasError={errors.email && touched.email ? true : false}
                    onFocus={() => handleOnTouched("email")}
                    errorMessage={errors.email}
                    status={changeInputStatus(
                      values.email,
                      errors.email && touched.email
                    )}
                  />
                </div>

                <div className={styles._inputContainer}>
                  <GeneralInput
                    placeholder={"password"}
                    id="password"
                    name="password"
                    type={`${!iconStatus ? "password" : "text"}`}
                    onBlur={switchStatus}
                    withIcon
                    iconMethod={switchIconStatus}
                    iconColor={"#919191"}
                    icon={`${!iconStatus ? "bx:show" : "bx:low-vision"}`}
                    onChange={handleChange}
                    value={values.password}
                    hasError={
                      errors.password && touched.password ? true : false
                    }
                    errorMessage={errors.password}
                    onFocus={() => handleOnTouched("password")}
                    status={changeInputStatus(
                      values.password,
                      errors.password && touched.password
                    )}
                  />
                </div>

                <GeneralCheckbox
                  checked={rememberMe}
                  onChange={() => setRememnberMe((rememberMe) => !rememberMe)}
                  label="remember_me"
                />

                <p className={styles._tinyTitle}>
                  {t("forgot_password")}{" "}
                  <span onClick={() => navigateTo("/forgot-password")}>
                    {t("retrieve")}
                  </span>
                </p>
              </div>
            </div>
          ) : (
            <div className={styles._otpContainer}>
              <p className={styles._title}>{t("2fa_code")}</p>
              <p className={styles._subtitle}>{t("enter_auth_code")}</p>
              <GeneralOTP
                value={values.otp}
                error={touched.otp && errors.otp}
                placeholder="000000"
                onChange={(value) => {
                  handleOnTouched("otp");
                  setFieldValue("otp", value);
                }}
                className={styles._otp}
              />
            </div>
          )}
          {statusIcon ? (
            <div className={styles._statusIcon}>
              <>
                <Icon
                  icon={
                    statusIcon == "error"
                      ? "bxs:message-x"
                      : "bxs:message-alt-check"
                  }
                  className={styles._icon}
                  color="#C2CED5"
                />
                <p>
                  {statusIcon == "error"
                    ? t("code_error")
                    : t("enter_valid_code")}
                </p>
              </>
            </div>
          ) : null}
          <div className={styles._bottomContainer}>
            <div className={styles._buttonsContainer}>
              {!values.otpView ? (
                <>
                  {/* <div className={styles._button}>
                    <button
                      className={clsx(styles._googleButton)}
                      type={"button"}
                      onClick={() => initGoogleLogin()}
                    >
                      <img
                        src={`${baseUrl}/resources/google.svg`}
                        alt="back-arrow"
                      />
                      <p className={styles._buttonText}>{t("sign_google")}</p>
                    </button>
                  </div> */}
                  <div className={styles._button}>
                    <GeneralButton
                      action={submitForm}
                      text={"login"}
                      type="submit"
                      loading={loading}
                      disabled={!isValid || !dirty}
                    />
                  </div>
                </>
              ) : (
                <div className={styles._buttonsContainerTwoFact}>
                  <div className={styles._buttonLeft}>
                    <GeneralButton
                      action={() => {
                        resetForm();
                        setStatusIcon("");
                      }}
                      text={"return"}
                      type="submit"
                      isGoBack={true}
                    />
                  </div>
                  <div className={styles._buttonRight}>
                    <GeneralButton
                      action={googleAuthToken !== "" ? loginGoogle : submitForm}
                      text={"continue"}
                      type="submit"
                      loading={loading}
                      disabled={values?.otp.length < 6}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className={styles._footer}>
              <OnboardingFooter
                buttonText="log_in"
                buttonAction={submitForm}
                step={1}
                showStepper={false}
                showBtn={false}
                message={t("not_registered")}
                link={t("register")}
                route={"/onboarding"}
              />
              {!values.otpView ? (
                <p
                  onClick={() => navigateTo("/verify-account")}
                  className={styles._validateText}
                >
                  {t("validate_account")}
                </p>
              ) : null}
            </div>
          </div>
        </form>
      </div>
    </OnboardingLayout>
  );
};

export default withTrans(Login);
