import { gql } from '@apollo/client';

const DELETE_USERS_FOR_BRANCH_OFFICE = gql`
    mutation DeleteUserForBranchOffice($id: ID!) {
      deleteUserForBranchOffice(data: { id: $id }) {
        result
    }
  }
`;
export default DELETE_USERS_FOR_BRANCH_OFFICE;

