import { gql } from '@apollo/client';
import { FRAGMENT_USER_RESPONSE } from '../fragments'

const USER_FOR_BRANCH_OFFICE = gql`
${FRAGMENT_USER_RESPONSE}
query userForBranchOffice($id: ID!) {
    userForBranchOffice(id: $id) {
    id
    branchOfficeId
    uiRoleId
    user {
        ...UserResponse
      }
    }
  }
`;

export default USER_FOR_BRANCH_OFFICE;
