import { gql } from '@apollo/client'

const VERIFY_ACCOUNT = gql`
 mutation ResendVerificationEmail($data: EmailInput!) {
     resendVerificationEmail(data: $data) {
       result
     }
   }
`;
export default VERIFY_ACCOUNT;
