import { useFormik } from 'formik';
import * as Yup from 'yup';
import { emailRegex, translate, onlyNumbersRegex, onlyLettersRegex } from 'utils';

const createSchema: any = Yup.object({
  firstName: Yup.string()
    .required('field_required')
    .min(3, () => translate('min_invalid', { number: 3 }))
    .max(200, () => translate('max_invalid', { number: 200 }))
    .matches(onlyLettersRegex, 'only_alphanumeric'),

  lastName: Yup.string()
    .required('field_required')
    .min(3, () => translate('min_invalid', { number: 3 }))
    .max(200, () => translate('max_invalid', { number: 200 }))
    .matches(onlyLettersRegex, 'only_alphanumeric'),

  type: Yup.string()
    .required()
    .oneOf(['EMPLOYEE', 'CUSTOMER', 'SUPPLIER']),

  identification: Yup.string()
    .min(6, () => translate('min_invalid', { number: 6 }))
    .max(11, () => translate('max_invalid', { number: 11 }))
    .matches(onlyNumbersRegex, 'only_numbers'),

  email: Yup.string()
    .required('field_required')
    .matches(emailRegex, 'email_valid')
    .max(200, () => translate('max_invalid', { number: 200 })),

  address: Yup.string()
    .min(4, () => translate('min_invalid', { number: 4 }))
    .max(200, () => translate('max_invalid', { number: 200 })),

  mobileCode: Yup.string().default('+58')
    .required('field_required'),

  cellPhoneNumber: Yup.string()
    .required('field_required')
    .matches(onlyNumbersRegex, 'phone_invalid')
    .min(7, () => translate('min_invalid', { number: 7 }))
    .max(11, () => translate('max_invalid', { number: 11 })),
})

export const FormikConfig = (handleSubmit: any, spaceId: string, currentWhiteList: any, handleUpdate: Function) =>
  useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: currentWhiteList?.firstName ?? '',
      lastName: currentWhiteList?.lastName ?? '',
      type: currentWhiteList?.originalType.toUpperCase() ?? '',
      identification: currentWhiteList?.identification ?? '',
      email: currentWhiteList?.email ?? '',
      address: currentWhiteList?.address ?? '',
      mobileCode: currentWhiteList?.cellPhoneNumber?.split(' ')?.[0] ?? '',
      cellPhoneNumber: currentWhiteList?.cellPhoneNumber?.split(' ')?.[1] ?? '',
    },

    validationSchema: createSchema,

    onSubmit: (values) => {

      const { mobileCode, cellPhoneNumber, ...form } = values;
      const formValues = {
        ...form,
        cellPhoneNumber: `${mobileCode} ${cellPhoneNumber}`,
        spaceId
      }

      const updateValues = {
        ...formValues,
        id: currentWhiteList?.id
      }

      if (!currentWhiteList) return handleSubmit(formValues)
      handleUpdate(updateValues)
    },
  });
