import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import DashboardDrawer from "../../components/Drawers/DashboardDrawer";
import DashboardHeader from "../../components/Headers/DashboardHeader";
import { useLocation } from "react-router-dom";
import MobileDrawer from "../../components/Drawers/MobileDrawer";
import { useMediaQuery, Theme } from "@mui/material";
import { Outlet } from "react-router-dom";
import { HelmetHeader } from "../../../components";
import { appBarStyles } from "../Headers/DashboardHeader/materialStyles";
import ProfileDrawer from "../Drawers/ProfileDrawer";

export default function DashboardLayout({ children }: any) {
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  return (
    <Box sx={{ display: "flex" }} id="component-main">
      <HelmetHeader title="dashboard_path" />
      <CssBaseline />
      <DashboardHeader mobileMenuOpen={open} setOpen={setOpen} />
      <DashboardDrawer location={location} />
      <MobileDrawer location={location} open={open} setOpen={setOpen} />
      {location?.pathname == "/profile" && <ProfileDrawer />}
      <Box
        component="main"
        sx={(theme) => ({
          width: "100%",
          height: !isMobile ? "100vh" : "auto",
          minHeight: "100vh",
          overflowX: "hidden",
          backgroundColor: theme.palette.background.default,
        })}
      >
        <Toolbar sx={appBarStyles} style={{ zIndex: 0 }} />
        <Outlet />
      </Box>
    </Box>
  );
}
