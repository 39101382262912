import { API_URL_BASE } from 'paths';
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { fetchService } from 'services/fetchService';
import { setAlert } from 'store/actions';
import { ResponseGenerator } from '../../utils';

import { DELETE_FILE, LOAD_FILE } from './action-types';

function* uploadFileCall({ payload, callback }: any) {
  try {
    if (!payload.file) {
      throw Error();
    }
    const formData = new FormData();
    formData.append('file', payload.file);
    formData.append('folder', payload.folder);

    const new_payload: any = {
      url: `${API_URL_BASE}upload-files`,
      method: 'POST',
      params: formData,
    };

    const response: ResponseGenerator = yield call(fetchService, new_payload);

    const values = { link: response.data.result, name: payload.file.name };
    callback &&
      callback({
        ok: true,
        values,
      });
  } catch (e) {
    yield put(
      setAlert(2, 'error', 'An error occurred when submitting the form')
    );
  }
}

function* deleteFileCall({ payload, callback }: any) {
  try {
    /* const { accessToken } = yield select(getAuth);
    const new_payload: any = {
      url: getExpertsPath,
      token: accessToken,
    }; */
    //	const response: ResponseGenerator = yield call(fetchService, new_payload)
    console.log(payload.name);
    yield delay(2000);
    callback &&
      callback({
        ok: true,
      });
  } catch (e) {
    console.log(e);
    /*  yield put(
      actionObject(
        SHOW_SNACKBAR,
        errorMessage('An error has occurred. Please try again later')
      )
    ); */
  }
}

export function* fileHandler(): Generator {
  yield takeLatest(LOAD_FILE, uploadFileCall);
  yield takeLatest(DELETE_FILE, deleteFileCall);
}
