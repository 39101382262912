import { gql } from '@apollo/client';
import { FRAGMENT_USER_RESPONSE } from '../fragments'

const CREATE_USER_BRANCH_OFFICE = gql`
  ${FRAGMENT_USER_RESPONSE}
    mutation createUserForBranchOffice($data: CreateUserForBranchOfficeInput!) {
      createUserForBranchOffice(data: $data) {
        id
        ...UserResponse
    }
  }
`;
export default CREATE_USER_BRANCH_OFFICE;

