import { useFormik } from 'formik';
import * as Yup from 'yup';
import { amountCharactersRegex, parseToSimpleNumber } from 'utils';

export const FormikConfig = (handleSubmit: any, amount: any, memo: string) =>
  useFormik({
    initialValues: {
      amount: amount || '',
      memo: memo || '',
    },
    validate: (values) => {
      const actualValue = parseFloat(parseToSimpleNumber(values.amount));
      if (actualValue <= 0) {
        return { amount: 'non_positive_value' };
      }
    },
    validationSchema: Yup.object({
      amount: Yup.string()
        .required('field_required')
        .matches(amountCharactersRegex, 'number_valid'),

      memo: Yup.string().notRequired().max(50),
    }),

    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
