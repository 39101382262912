import { useFormik } from 'formik';
import * as Yup from 'yup';
import { emailRegex, translate, onlyLettersRegex, passwordRegex } from 'utils';

const validationSchema: any = Yup.object({
  firstName: Yup.string()
    .required('field_required')
    .max(255, () => translate('max_invalid', { number: 255 }))
    .min(3, () => translate('min_invalid', { number: 3 }))
    .matches(onlyLettersRegex, 'only_letters'),

  lastName: Yup.string()
    .required('field_required')
    .max(255, () => translate('max_invalid', { number: 255 }))
    .min(3, () => translate('min_invalid', { number: 3 }))
    .matches(onlyLettersRegex, 'only_letters'),

  uiRoleId: Yup.string().required('field_required'),

  email: Yup.string()
    .required('field_required')
    .max(255, () => translate('max_invalid', { number: 255 }))
    .matches(emailRegex, 'email_valid'),

  password: Yup.string()
    .required('field_required')
    .matches(passwordRegex, 'field_invalid'),

  confirmPassword: Yup.string()
    .required('field_required')
    .matches(passwordRegex, 'field_invalid')
    .oneOf([Yup.ref('password'), null], 'password_must_match'),
});

const validationSchemaUpdate: any = Yup.object({
  firstName: Yup.string()
    .required('field_required')
    .max(255, () => translate('max_invalid', { number: 255 }))
    .min(3, () => translate('min_invalid', { number: 3 }))
    .matches(onlyLettersRegex, 'only_letters'),

  lastName: Yup.string()
    .required('field_required')
    .max(255, () => translate('max_invalid', { number: 255 }))
    .min(3, () => translate('min_invalid', { number: 3 }))
    .matches(onlyLettersRegex, 'only_letters'),

  uiRoleId: Yup.string().required('field_required'),

  email: Yup.string()
    .required('field_required')
    .max(255, () => translate('max_invalid', { number: 255 }))
    .matches(emailRegex, 'email_valid'),

  password: Yup.string()
  .matches(passwordRegex, 'field_invalid'),

  confirmPassword: Yup.string()

    .matches(passwordRegex, 'field_invalid')
    .oneOf([Yup.ref('password'), null], 'password_must_match'),
});

export const FormikConfig = (
  handleSubmit: any,
  branchOfficeId: string,
  userDetailed: any
) =>
  useFormik({
    initialValues: {
      firstName: userDetailed?.user?.profile?.firstName || '',
      lastName: userDetailed?.user?.profile?.lastName || '',
      uiRoleId: userDetailed?.uiRoleId || '',
      email: userDetailed?.user?.email || '',
      password: '',
      confirmPassword: '',
    },

    validationSchema: !userDetailed ? validationSchema : validationSchemaUpdate,

    onSubmit: (values) => {
      const { confirmPassword, ...formValues } = values;

      const data = {
        ...formValues,
        branchOfficeId,
      };

      handleSubmit(data);
    },
  });
