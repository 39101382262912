import React, { memo } from "react";
import Grid from "@mui/material/Grid";
import styles from "./styles.module.scss";
import { baseUrl } from "paths";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";
import CreateBrancheOffice from "../CreateBranchOffice";
import { UseIntermitence } from "hooks";
import { setUserSpace } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { userSpaceSelector } from "store/selectors";

const BranchOfficeCards = ({
  item,
  withLine,
  className,
  dataLength,
  refetch,
  isAllowed
}: any) => {
  const { currentBranchOffice } = useSelector(userSpaceSelector);
  const dispatch = useDispatch();
  const { status, switchStatus } = UseIntermitence();
  const { name, code, address, isPrincipal } = item;
  const { t } = useTranslation();

  const handleBranchOffice = (branchOffice: any) =>
    dispatch(setUserSpace({ currentBranchOffice: branchOffice }));

  return (
    <>
      {isPrincipal && (
        <div
          className={clsx(
            styles._sucursalCardParent,
            item?.id == currentBranchOffice?.id && styles._selected
          )}
          onClick={() => handleBranchOffice(item)}
        >
          <div className={styles._leftSide}>
            <img
              src={`${baseUrl}/resources/sucursal-icon.svg`}
              alt="sucursal-icon"
            ></img>
            <div className={styles._textParent}>
              <p>{name}</p>
              <p>{address}</p>
            </div>
          </div>

          <div className={styles._rightSide}>
            <p>
              {t("code")} {code}
            </p>
          </div>
        </div>
      )}

      {!isPrincipal ? (
        <div className={styles._row} onClick={() => handleBranchOffice(item)}>
          <Grid
            container
            className={clsx(
              withLine ? styles._simpleRowChild : styles._simpleRowNoLine,
              className,
              item?.id == currentBranchOffice?.id && styles._selected
            )}
          >
            <Grid item xs={12}>
              <div>
                <ul className={styles._list}>
                  <li>{address}</li>
                  <li>
                    {t("code")} {code.toUpperCase()}
                  </li>
                </ul>
              </div>
            </Grid>
          </Grid>
        </div>
      ) : (
        dataLength <= 1 && (
          <div className={styles._row} onClick={switchStatus}>
            {isAllowed && (
              <Grid
                container
                className={clsx(
                  withLine ? styles._simpleRowChild : styles._simpleRowNoLine,
                  className
                )}
              >
                <Grid item xs={6}>
                  <p className={styles._addBranchOffice}>
                    {t("add_branch_offices")}
                  </p>
                </Grid>
                <Grid item xs={6}>
                  <div className={styles._iconParent}>
                    <Icon icon="bx:right-arrow-alt" width={24} />
                  </div>
                </Grid>
              </Grid>
            )}
          </div>
        )
      )}
      <CreateBrancheOffice
        open={status}
        onClose={switchStatus}
        type="create"
        refetch={refetch}
      />
    </>
  );
};

export default memo(BranchOfficeCards);
