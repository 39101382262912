import { Drawer } from "@mui/material";
import { drawerStyles } from "./materialStyles";
import styles from "./styles.module.scss";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLayoutEffect, useState } from "react";
import clsx from "clsx";
import { Icon } from "@iconify/react";
import { DashboardDrawerCard, BranchOfficeList } from "../../../components/";
import { GeneralButton } from "../../../../components";
import { returnUserName, EMPLOYEE } from "utils";
import { useSelector } from "react-redux";
import { userAuthSelector } from "store/selectors";
import { SpacesCircles } from "../../../components";
import { baseUrl } from "paths";
import DirectoryList from "web/components/DirectoryList";

const DashboardRightDrawer = () => {
  const [value, setValue] = useState("");
  const [timer, setTimer] = useState(null);
  const [order, setOrder] = useState(null);
  const [orderDesc, setOrderDesc] = useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [ready, setDomReady] = useState(null);
  const { userAuthData } = useSelector(userAuthSelector);
  const roleName = userAuthData?.role?.name;

  useLayoutEffect(() => {
    setDomReady(document.getElementById("component-main"));
  }, []);

  const {
    userAuthData: {
      profile,
      customer,
      spaces,
      role: { name },
    },
  } = useSelector(userAuthSelector);

  const inputHandler = (event: any) => {
    const {
      target: { value },
    } = event;

    setValue(value);
    if (timer) clearTimeout(timer);

    setTimer(setTimeout(() => {}, 500));
  };

  const handleOrder = () => {
    setOrder("ADDRESS");
    setOrderDesc((prevValue) => !prevValue);
  };

  return (
    ready &&
    ReactDOM.createPortal(
      <Drawer
        sx={drawerStyles}
        variant="permanent"
        anchor="right"
        className={styles._scrollBar}
      >
        <div className={styles._toolbarContainer}>
          {/* <Icon
            icon="bxs:bell"
            className={clsx(styles._icon, styles._iconMargin)}
            color="#000000"
          /> */}
          <div
            className={styles._profileContainer}
            onClick={() => navigate("/profile")}
          >
            {/* <p>{returnUserName(profile, customer, spaces)}</p> */}

            <div className={styles._iconPosition}>
              {roleName !== "employee" && (
                <div className={styles._profileImage}>
                  <Icon icon="bxs-user" width={28} color="#000000"></Icon>
                </div>
              )}
              <p className={styles._profileTitle}>Perfil</p>
              {/* {roleName !== "employee" && <SpacesCircles onlyOne={true} />} */}
              {/* )} */}
            </div>
            {/* {roleName !== "employee" && (
              <p className={styles._perfilText}>{t("profile")}</p>
            )} */}
          </div>
        </div>

        <p className={styles._titleUp}>{t("balance_available")}</p>
        <DashboardDrawerCard />
        <div className={styles._buttonsContainer}>
          {name != EMPLOYEE && (
            <>
              <GeneralButton
                className={styles._button}
                action={() => navigate("/payments?type=send")}
                text="send"
                icon={
                  <Icon
                    icon="bx:left-arrow-alt"
                    className={styles._arrowIcon}
                  />
                }
              />
              <GeneralButton
                className={styles._button}
                text="receive"
                action={() => navigate("/payments?type=receive")}
                icon={
                  <Icon
                    icon="bx:left-arrow-alt"
                    className={styles._arrowIcon}
                    style={{ transform: "rotate(270deg)" }}
                  />
                }
              />
            </>
          )}
        </div>
        <div className={styles._listHeaderContainer}>
          <span className={styles._title}>
            {customer?.type == "juridic" ? t("branch_offices") : t("Directory")}
          </span>
          <div className={styles._inputParent}>
            <input
              onChange={inputHandler}
              value={value}
              className={styles._input}
              placeholder={t("search")}
            />
            <Icon
              icon="bx:search"
              width={20}
              color="#919191"
              className={styles._iconInput}
            />
          </div>
          {/* <img src={`${baseUrl}/resources/sucursal-orderby-icon.svg`} alt='sucursales-sort-icon' onClick={handleOrder} className={styles._orderIcon}/> */}
          <Icon
            icon="bx:sort-alt-2"
            width={26}
            height={26}
            color={order ? "#E6427A" : "#909090"}
            className={styles._orderIcon}
            onClick={handleOrder}
          />
        </div>

        {customer?.type == "juridic" ? (
          <BranchOfficeList
            searchValue={value}
            order={order}
            orderDesc={orderDesc}
          />
        ) : (
          <div
            className={
              true ? styles._directoryParentEmpty : styles._directoryParent
            }
          >
            <DirectoryList
              withHeader={false}
              search={value}
              orderValue={order}
              isAllowed={name != EMPLOYEE}
            />
          </div>
        )}
      </Drawer>,
      ready
    )
  );
};

export default DashboardRightDrawer;
