import { gql } from '@apollo/client';

const CLAIM_PAYMENT= gql`
  mutation ClaimPayment($data: ClaimPaymentInput!) {
    claimPayment(data: $data) {
      result
    }
  }
`;
export default CLAIM_PAYMENT;
