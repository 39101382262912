import styles from "./styles.module.scss";
export const transformToRows = (items: Array<any>, isMobile: boolean) =>
  items
    ? items.map((item) => ({
        id: item.id,
        extraProps: {
          isPrincipal: item.isPrincipal,
        },
        actionCellWidth: isMobile ? "35%" : "20%",
        values: isMobile
          ? [
              {
                cellWidth: '65%',
                value: (
                  <div className={styles._mobileRow}>
                    <p className={styles._ellipsis}>
                      <b>{item.name}</b>
                    </p>
                    <p>{item.code.toUpperCase()}</p>
                  </div>
                ),
              },
            ]
          : [
              {
                value: <p className={styles._ellipsis}>{item.name}</p>,
              },
              {
                value: item.code.toUpperCase(),
              },
              {
                value: <p className={styles._ellipsis}>{item.address}</p>,
              },
              {
                value: item.dateOfCreation ?? "",
              },
            ],
      }))
    : [];
