import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { AppBar, Toolbar } from "@mui/material";
import clsx from "clsx";
import { baseUrl } from "paths";
import { appBarStyles } from "./materialStyles";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { userAuthSelector, userSpaceSelector } from "store/selectors";
import { returnUserName } from "utils";
import { useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery, Theme } from "@mui/material";
import { SpacesCircles } from "../../../components";
import { BackArrow } from "components";

const DashboardHeader = ({
  mobileMenuOpen,
  setOpen,
}: {
  mobileMenuOpen: boolean;
  setOpen: any;
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;

  const {
    userAuthData: { profile, customer, spaces },
  } = useSelector(userAuthSelector);
  const isInMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("lg")
  );
  const { currentBranchOffice } = useSelector(userSpaceSelector);
  const [showArrow, setShowArrow] = useState(true);
  const { userAuthData } = useSelector(userAuthSelector);
  const roleName = userAuthData?.role?.name;

  useEffect(() => {
    if (
      pathname == "/branch-office" ||
      pathname == "/directory" ||
      pathname == "/faq" ||
      pathname == "/deal-flow"
    )
      return setShowArrow(false);
    setShowArrow(true);
  }, [pathname]);

  return (
    <>
      <AppBar position="fixed" sx={appBarStyles} elevation={0}>
        <Toolbar disableGutters sx={{ width: "100%" }}>
          <div className={styles._titleContainer}>
            {pathname !== "/dashboard" ? (
              <>
                {showArrow && (
                  <div className={styles._arrowBackContainer}>
                    <BackArrow icon="bx:left-arrow-alt" route="/dashboard" />
                    <p>
                      {t("back_to")} {t("dashboard_literal")}
                    </p>
                  </div>
                )}
              </>
            ) : (
              <div>
                <p className={styles._title}>
                  {t("welcome")}, {returnUserName(profile, customer, spaces)}{" "}
                </p>
                <p className={styles._subtitle}>
                  {currentBranchOffice?.code?.toUpperCase()}
                </p>
              </div>
            )}
          </div>

          <div className={styles._toolbarContainer}>
            {/* {pathname == "/dashboard" && !isInMobile && <SpacesCircles />} */}

            <img
              src={`${baseUrl}/resources/puntu-logo.png`}
              alt="onboarding-img"
              width="125px"
              className={styles._appbarImage}
              onClick={() => navigate("/dashboard")}
            />
            {!mobileMenuOpen ? (
              <>
                {/* <Icon
                  icon="bxs:bell"
                  className={clsx(styles._icon, styles._iconMargin)}
                  color="#000000"
                /> */}

                {/* <Icon
                  icon="bxs:user"
                  color="#000"
                  className={clsx(
                    styles._icon,
                    styles._mobileMenuIcon,
                    styles._iconRightMargin,
                    styles._iconMargin
                  )}
                /> */}
                <div className={styles._iconsContainer}>
                  <div
                    className={styles._iconPosition}
                    onClick={() => navigate("/profile")}
                  >
                    {roleName !== "employee" && (
                      // <SpacesCircles onlyOne={true} />
                      <div className={styles._profileImage}>
                        <Icon icon="bxs:user" width={28} color="#000000"></Icon>
                      </div>
                    )}
                  </div>
                  {/* <div
                    className={styles._profile}
                    onClick={() => navigate("/profile")}
                  >
                    <div className={styles._profileImage}>
                      <Icon
                        icon="bx-image-add"
                        width={28}
                        color="#000000"
                      ></Icon>
                    </div>
                    <p className={styles._perfilText}>{t("profile")}</p>
                  </div> */}
                  <Icon
                    icon="bx:menu"
                    className={clsx(styles._icon, styles._mobileMenuIcon)}
                    color="#000000"
                    onClick={() => setOpen(true)}
                  />
                </div>
              </>
            ) : (
              <Icon
                icon="bx:x"
                className={clsx(
                  styles._icon,
                  styles._mobileMenuIcon,
                  styles._x
                )}
                color="#000000"
                onClick={() => setOpen(false)}
              />
            )}

            <div className={styles._profileContainer}>
              {/* <p>{returnUserName(profile, customer, spaces)}</p> */}
              <div
                onClick={() => navigate("/profile")}
                className={styles._iconPosition}
              >
                <div className={styles._profileImage}>
                  <Icon icon="bxs:user" width={28} color="#000000"></Icon>
                </div>
                <p className={styles._profileTitle}>Perfil</p>
                {/* {roleName !== "employee" && <SpacesCircles onlyOne={true} />} */}
              </div>

              {/* {roleName !== "employee" && (
                <div
                  className={styles._profile}
                  onClick={() => navigate("/profile")}
                >
                  <div className={styles._profileImage}>
                    <Icon icon="bx-image-add" width={28} color="#000000"></Icon>
                  </div>
                  <p className={styles._perfilText}>{t("profile")}</p>
                </div>
              )} */}
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default DashboardHeader;
