export const normalizeBasicUserData = ({
  firstName,
  lastName,
  fiscalAddress,
  typeCommerceFallback,
  dniNumber,
  dniType,
  city,
  state,
  zipCode,
  document,
  typeCommerceId,
  businessName,
}: any) => {
  return {
    firstName,
    lastName,
    businessName,
    dni: dniNumber,
    dniType,
    dniPhotoUrl: document ? document : '',
    city,
    state,
    zipCode,
    fiscalAddress,
    typeCommerceId,
    typeCommerceFallback,
  };
};

export const normalizeAccountData = ({
  email,
  password,
  phoneNumber,
  cellPhoneNumber,
  monthlyIncome,
  legalRepresentativeName,
  legalRepresentativeLastName,
  address,
  representativePhone,
  representativeCellPhone,
  representativeDniNumber,
  representativeDniType,
  requestTwoFactor,
  document,
}: any) => {
  return {
    email,
    password,
    phoneNumber,
    cellPhoneNumber,
    monthlyIncome,
    requestTwoFactor,
    legalRepresentative: {
      firstName: legalRepresentativeName || '',
      lastName: legalRepresentativeLastName || '',
      address: address || '',
      phoneNumber: representativePhone || '',
      cellPhoneNumber: representativeCellPhone || '',
      dni: representativeDniNumber || '',
      dniType: representativeDniType || '',
      dniPhotoUrl: document ? document : '',
    },
    completed: true,
  };
};
