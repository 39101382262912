import styles from "./styles.module.scss";
import { Grid, Theme, useMediaQuery } from "@mui/material";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { UseAlert, UseIntermitence } from "hooks";
import {
  GeneralButton,
  GeneralModal,
  GeneralInput,
  GeneralOTP,
} from "components";
import { FormikConfig } from "./formik";
import { DISABLED_2FA, RESET_AUTH } from "graphql/mutations";
import { set2faDisable } from "store/actions";
import { useDispatch } from "react-redux";

const Disable2FAModal = ({ open, onCancel }: any) => {
  const { showAlert } = UseAlert();
  const { t } = useTranslation();
  const { switchStatus } = UseIntermitence();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const { status: iconStatus, switchStatus: switchIconStatus } =
    UseIntermitence();

  const handleOnTouched = (key: string) =>
    setTouched({ ...touched, [key]: true });
  UseIntermitence();

  const [disableAuthenticator, { loading }] = useMutation(DISABLED_2FA);
  const [requestDisableAuthenticator, { loading: requestLoading }] =
    useMutation(RESET_AUTH);

  const handleSubmit = (formValues: any) => {
    disableAuthenticator({
      variables: { data: { ...formValues } },
      onError: (error: any) => {
        showAlert(error?.message, "error");
        resetForm();
      },
      onCompleted: (response: any) => {
        const {
          disableAuthenticator: { result },
        } = response;
        resetForm();
        if (result) {
          showAlert("auth_disable", "success");
          dispatch(set2faDisable());
          return onCancel();
        }

        showAlert("something_went_wrong", "error");
      },
    });
  };

  const requestResetAuth = () => {
    requestDisableAuthenticator({
      variables: { data: { type: "AUTHENTICATOR" } },
      onError: (error: any) => {
        showAlert(error?.message, "error");
      },
      onCompleted: (response: any) => {
        const {
          requestResetAuthenticator: { result },
        } = response;

        if (result) {
          showAlert("notification_success", "success");

          return onCancel();
        }

        showAlert("something_went_wrong", "error");
      },
    });
  };

  const {
    values,
    handleSubmit: formikSubmit,
    handleChange,
    submitForm,
    errors,
    touched,
    setTouched,
    resetForm,
    setFieldValue,
    isValid,
    dirty,
  } = FormikConfig(handleSubmit);

  const changeInputStatus = (value: string, error: any) => {
    if (value !== "") {
      if (error) return "error";
      return "success";
    }
    return "inherit";
  };

  return (
    <GeneralModal open={open} width={"41.063rem"} onCancel={onCancel}>
      <h3 className={styles._title}>{t("do_you_want_disable_2fa")}</h3>
      <p className={styles._subtitle}>{t("disable_2fa_request_password")}</p>

      <form onSubmit={formikSubmit} id="my-form">
        <Grid container spacing={isMobile ? 2.8 : 3.5}>
          <Grid
            item
            xs={isMobile ? 12 : 11}
            sm={isMobile ? 12 : 11}
            md={isMobile ? 12 : 11}
            margin={isMobile ? "0" : "1rem auto 0 1rem"}
          >
            <GeneralInput
              placeholder="password"
              onFocus={() => {
                switchStatus();
                handleOnTouched("password");
              }}
              onBlur={switchStatus}
              withIcon
              iconColor={"#919191"}
              icon={`${!iconStatus ? "bx:show" : "bx:low-vision"}`}
              id="password"
              name="password"
              onChange={handleChange}
              value={values.password}
              hasError={errors.password && touched.password ? true : false}
              iconMethod={switchIconStatus}
              type={`${!iconStatus ? "password" : "text"}`}
              errorMessage={errors.password}
              status={changeInputStatus(
                values.password,
                errors.password && touched.password
              )}
            />
          </Grid>
          <Grid
            item
            xs={isMobile ? 12 : 11}
            sm={isMobile ? 12 : 11}
            md={isMobile ? 12 : 11}
            margin={"0 auto"}
          >
            <h3 className={styles._2faTitle}>{t("2fa")}</h3>

            <GeneralOTP
              value={values.otp}
              error={touched.otp && errors.otp}
              placeholder="000000"
              onChange={(value) => {
                handleOnTouched("otp");
                setFieldValue("otp", value);
              }}
              className={styles._otp}
            />
          </Grid>
        </Grid>
        <div className={styles._button}>
          <GeneralButton
            action={submitForm}
            text={"disabled"}
            loading={loading}
            type="submit"
            disabled={!isValid || !dirty}
          />
        </div>
        <div className={styles._separator}>
          <div className={styles._line} />
          <div className={styles._circle} />
          <div className={styles._line} />
        </div>
        <div className={styles._registerText}>
          <p className={styles._subtitle}>
            {t("if_you_dont_have_the_device")}
            <span onClick={() => (requestLoading ? null : requestResetAuth())}>
              {t("send_notification")}
            </span>
          </p>
        </div>
      </form>
    </GeneralModal>
  );
};

export default Disable2FAModal;
