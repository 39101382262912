import React, { memo } from "react";
import Grid from "@mui/material/Grid";
import { Icon } from "@iconify/react";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

const emptyState = { name: "add_contact" };

const Card = ({ item, onRow, emptyState, isAllowed = true }: any) => {
  const { t } = useTranslation();  

  return (
    <div className={styles._row} onClick={onRow}>
      {isAllowed && (
        <Grid
          container
          className={!emptyState ? styles._simpleRowChild : styles._emptyRow}
        >
          <Grid item xs={10}>
            <div>
              <ul className={styles._list}>
                <li className={emptyState ? styles._listItem : ""}>
                  {t(item?.name)}
                </li>
                {!emptyState && (
                  <>
                    <li className={clsx(styles._title, styles._ellipsis)}>
                      {t(item?.firstName)} {t(item?.lastName)}
                    </li>
                    <li className={styles._ellipsis}>{item?.email}</li>
                  </>
                )}
              </ul>
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={styles._arrowIconParent}>
              <Icon icon="bx:right-arrow-alt" width={22} height={22} />
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

const Directory = ({ onRow, item, searchValue = null, isAllowed = true }: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateTo = (route: string, param: any) => navigate(route, param);

  return (
    <div className={styles._parent}>
      {!item && (
        <>
          {!searchValue && (
            <Card
              item={emptyState}
              onRow={onRow}
              emptyState={!searchValue ? true : false}
              isAllowed={isAllowed}
            />
          )}
          <div
            className={styles._emptyStateParent}
            style={{ height: "calc(100% - 3.875rem)" }}
          >
            <div className={styles._iconParent}>
              <Icon icon="bx:x-circle" width={32}></Icon>
            </div>
            <div className={styles._textParent}>
              <p>
                {!searchValue ? t("empty_directory") : t("directory_notfound")}
              </p>
            </div>
          </div>
        </>
      )}
      {item && (
        <Card
          item={item}
          onRow={() =>
            navigateTo("/directory", { state: { selectedCustomer: item } })
          }
          emptyState={false}
        />
      )}
    </div>
  );
};

export default memo(Directory);
