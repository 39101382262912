import React, { useState, useRef } from "react";
import { Main, Detail } from "./screens";
import styles from "./styles.module.scss";
import { BackArrow } from "components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Directory = () => {
  const childRef: any = useRef(null);
  const [detail, setDetail] = useState({});
  const handleSelected = (user: any) => setDetail(user);
  const comeBack = () => setDetail({});

  const { t } = useTranslation();

  const onDelete = (id: string) => {
    const { current } = childRef;
    current.handleDelete(id);
  };

  const onEdit = (id: string) => {
    const { current } = childRef;
    current.handleEdit(id);
  };

  const updateDetail = (data: any) => setDetail(data);
  const showDetail = (detail: any) => !Object.keys(detail).length;
  const navigate = useNavigate();
  return (
    <>
      <div className={showDetail(detail) ? styles._showItem : styles._hideItem}>
        <div
          className={styles._arrowBackContainer}
          onClick={() => navigate("/dashboard")}
        >
          <BackArrow icon="bx:left-arrow-alt" route="/dashboard" />
          <p>
            {t("back_to")} {t("dashboard_literal")}
          </p>
        </div>
        <Main
          handleSelected={handleSelected}
          ref={childRef}
          comeBack={comeBack}
          updateDetail={!showDetail(detail) ? updateDetail : null}
        />
      </div>

      <div
        className={!showDetail(detail) ? styles._showItem : styles._hideItem}
      >
        <Detail
          {...detail}
          comeBack={comeBack}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      </div>
    </>
  );
};

export default Directory;
