import { gql } from '@apollo/client';

const WALLETS_QUERY = gql`
  query Wallets {
    wallets {
      id
      name
      title
    }
  }
`;
export default WALLETS_QUERY;
