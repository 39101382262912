import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import { baseUrl } from "paths";
import { GeneralButton } from "../../../../components";
import { useNavigate } from "react-router-dom";
import { clearOnboardingData } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { twoFAEmailSelector } from "store/twoFA/selectors";
import { OnboardingLangButton } from "web/components";

const AllSet = ({ t }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const email2fa = useSelector(twoFAEmailSelector);

  useEffect(() => {
    dispatch(clearOnboardingData());
  }, []);

  return (
    <div className={styles._mainContainer}>
      <div className={styles._arrowContainer}>
        <img
          src={`${baseUrl}/resources/puntu-logo-white.svg`}
          alt="onboarding-img"
          width="150px"
          className={styles._mt1}
        />

        <div className={styles._mt1}>
          <OnboardingLangButton />
        </div>
      </div>

      <div className={styles._content}>
        <div className={styles._card}>
          <div className={styles._cardContent}>
            <img
              src={`${baseUrl}/resources/confirm-icon.svg`}
              alt="confirm-img"
              className={styles._icon}
            />

            <h1 className={styles._title}>{t("succesfull_register")}</h1>
            <p className={styles._subtitle}>{t("verify_your_account")}</p>

            <div className={styles._button}>
              <GeneralButton
                action={() => {
                  if (email2fa) return navigate("/set-2fa");
                  navigate("/login");
                }}
                text="log_in"
                type="submit"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllSet;
