import React, { useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { BALANCE_QUERY } from 'graphql/queries'
import { useSelector } from 'react-redux'
import { userSpaceSelector } from 'store/selectors'

const GetBalance = () => {
  const { currentSpace, currentBranchOffice } = useSelector(userSpaceSelector)
  const [getBalance, { data, loading }] = useLazyQuery(BALANCE_QUERY,
    {
      variables: {
        spaceId: currentSpace?.id,
        branchOfficeId: currentBranchOffice?.id,
        page: 1,
        perPage: 1
      },
      fetchPolicy: 'cache-and-network'
    }
  )

  const balance = data?.activities?.detail?.total || '0'

  useEffect(() => {
    getBalance()
  }, []);

  return { balance, loading }
}

export default GetBalance
