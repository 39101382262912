import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setAlert } from 'store/actions'
import { intermitenceSelector } from 'store/selectors'

const AlertHook = () => {
  let timeout: any = useRef();
  const dispatch = useDispatch()
  const { type, status, text } = useSelector(intermitenceSelector)
  const showAlert = (text: string, type: string) => dispatch(setAlert(2, type, text))

  const customTimeOut = () => {
   timeout.current = setTimeout(() => {
      dispatch(setAlert(3, type, text))
    }, 3000);
  }

  useEffect(() => {
    if (status == 2) customTimeOut()

    return () => clearTimeout(timeout.current)
  }, [status]);

  return { showAlert }
}

export default AlertHook
