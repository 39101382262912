import { useEffect, useState } from "react";
import {
  GeneralModal,
  GeneralInput,
  GeneralSelect,
  GeneralButton,
  GeneralRadioGroup,
} from "../../../components";
import styles from "./styles.module.scss";
import Grid from "@mui/material/Grid";
import { FormikConfig } from "./formik";
import { useSelector } from "react-redux";
import { userAuthSelector, userSpaceSelector } from "store/selectors";
import { countries } from "utils/countries";
import { useMediaQuery, Theme } from "@mui/material";
import { useMutation, useQuery, NetworkStatus } from "@apollo/client";
import { CREATE_BRANCH_OFFICE, UPDATE_BRANCH_OFFICE } from "graphql/mutations";
import { UseAlert } from "hooks";
import { useTranslation } from "react-i18next";
import { BranchOfficeConfirmation, GeneralInputLabel } from "web/components";
import { UseIntermitence } from "hooks";
import { Icon } from "@iconify/react";
import { USERS_FOR_SPACE } from "../../../graphql/queries";
import { paginate } from "utils";
import clsx from "clsx";

const CreateBrancheOffice = ({
  open,
  onClose,
  type,
  refetch,
  branchOffice = null,
}: any) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const { t } = useTranslation();
  const { userAuthData } = useSelector(userAuthSelector);
  const { currentSpace } = useSelector(userSpaceSelector);
  const [currentPage, setCurrentPage] = useState(1);
  const code = userAuthData?.profile?.dniNumber;
  const [searchValue, setSearchValue] = useState("");
  const [timer, setTimer] = useState(null);
  const [confirmCode, setConfirmCode] = useState("");

  const handleOnTouched = (key: string) =>
    setTouched({ ...touched, [key]: true });

  const { showAlert } = UseAlert();
  const { switchStatus, status } = UseIntermitence();
  const [createBranchOffice, { loading }] = useMutation(CREATE_BRANCH_OFFICE);
  const [updateBranchOffice, { loading: editLoading }] =
    useMutation(UPDATE_BRANCH_OFFICE);

  const {
    data: users,
    loading: usersLoading,
    refetch: r,
    networkStatus,
  } = useQuery(USERS_FOR_SPACE, {
    variables: {
      page: 1,
      perPage: 999,
      spaceId: currentSpace?.id,
      branchOfficeId: branchOffice?.id,
      search: searchValue,
    },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  const totalPages = Math.floor(users?.usersForSpace?.items?.length / 3);

  const nextPage = () => {
    if (currentPage <= totalPages - 1) {
      setCurrentPage((currentPage: number) => currentPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage >= 2)
      setCurrentPage((currentPage: number) => currentPage - 1);
  };

  const submitData = (formData: any) => {
    createBranchOffice({
      variables: { data: { ...formData } },
      onError: (error) => {
        showAlert("branch_office_error", "error");
      },
      onCompleted: (response: any) => {
        if (response) {
          const {
            createBranchOffice: { code },
          } = response;
          showAlert(t("branch_office_created"), "success");
          switchStatus();
          onClose();
          resetForm();
          refetch && refetch();
          setConfirmCode(code);
        }
      },
    });
  };

  const hanldleUpdate = (formData: any) => {
    updateBranchOffice({
      variables: { data: { ...formData } },
      onError: (error) => showAlert("branch_office_updated_error", "error"),
      onCompleted: (response: any) => {
        if (response) {
          showAlert(t("branch_office_updated"), "success");
          onClose();
          refetch && refetch();
        }
      },
    });
  };
  const {
    submitForm,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setTouched,
    setFieldValue,
    isValid,
    values,
    resetForm,
  } = FormikConfig(submitData, currentSpace?.id, branchOffice, hanldleUpdate);

  const changeInputStatus = (value: string, error: any) => {
    if (error) return "error";
    if (value?.length) return "success";
    return "inherit";
  };

  useEffect(() => {
    if (open) {
      r({ branchOfficeId: branchOffice?.id });
    }
    setFieldValue("ein", code);
    setFieldValue("localCode", "+58");
    setFieldValue("mobileCode", "+58");
    return () => resetForm();
  }, [branchOffice, open]);

  useEffect(() => {
    if (networkStatus == NetworkStatus.ready) {
      const members = (users?.usersForSpace?.items || [])
        .filter((user: any) => user.belongsBranchOffice)
        .map((user: any) => user.id);
      if (members.length) {
        setFieldValue("users", members);
      }
    }
  }, [networkStatus]);

  const handleCheckMembers = (id: string) => {
    if (id == undefined) return;

    const membersCopy = [...values.users];
    const index = membersCopy.findIndex((member: string) => member == id);
    if (index > -1) membersCopy.splice(index, 1);
    if (index == -1) membersCopy.push(id);
    setFieldValue("users", membersCopy);
  };

  const selectAllMembers = () => {
    if (users?.usersForSpace?.items?.length === values.users.length) {
      return setFieldValue("users", []);
    }

    const user = users?.usersForSpace?.items.map((member: any) => member?.id);
    setFieldValue("users", user);
  };

  const handleLocalCode = (event: any, key: string) => {
    const {
      target: { value },
    } = event;
    setFieldValue(key, value);
  };

  const inputHandler = (event: any) => {
    if (!loading) {
      const {
        target: { value },
      } = event;

      setSearchValue(value);

      if (timer) clearTimeout(timer);

      setTimer(setTimeout(() => {}, 500));
    }
  };

  return (
    <>
      <GeneralModal
        open={open}
        title={t(type == "create" ? "add_new_branch" : "edit_branch")}
        onCancel={onClose && onClose}
      >
        <div className={styles._main}>
          <p className={styles._subtitleTwo}>
            {t(
              type == "create"
                ? "enter_data_create_branch"
                : "enter_data_edit_branch"
            )}{" "}
            {t("remember_same_ein")}
          </p>
          {/* <p className={styles._subtitleTwo}></p> */}

          <form onSubmit={handleSubmit}>
            <Grid container spacing={isMobile ? 2.8 : 3.5}>
              <Grid item xs={12} sm={12} md={6}>
                <GeneralInput
                  placeholder="name"
                  id="name"
                  name="name"
                  onChange={handleChange}
                  value={values?.name}
                  hasError={errors.name && touched.name ? true : false}
                  onFocus={() => handleOnTouched("name")}
                  errorMessage={errors.name}
                  status={changeInputStatus(
                    values?.name,
                    errors.name && touched.name
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <GeneralInputLabel
                  className={styles._customInput}
                  label={values?.ein}
                  onChange={() => {}}
                  disabled={true}
                  value={"EIN"}
                  dir="ltr"
                  type="text"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <GeneralInput
                  placeholder="b_address"
                  id="address"
                  name="address"
                  onChange={handleChange}
                  value={values?.address}
                  hasError={errors.address && touched.address ? true : false}
                  onFocus={() => handleOnTouched("address")}
                  errorMessage={errors.address}
                  status={changeInputStatus(
                    values?.address,
                    errors.address && touched.address
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <GeneralInput
                  placeholder="email"
                  id="email"
                  name="email"
                  onChange={handleChange}
                  value={values?.email}
                  hasError={errors.email && touched.email ? true : false}
                  onFocus={() => handleOnTouched("email")}
                  errorMessage={errors.email}
                  status={changeInputStatus(
                    values.email,
                    errors.email && touched.email
                  )}
                />
              </Grid>
              {type == "create" && (
                <>
                  <Grid item xs={12} sm={12} md={6}>
                    <p className={styles._label}>{t("local_telephone")}</p>
                    <div className={styles._row}>
                      <div className={styles._leftSide}>
                        <GeneralSelect
                          options={countries.countries}
                          id="localCode"
                          name="localCode"
                          onChange={(event: any) =>
                            handleLocalCode(event, "localCode")
                          }
                          value={values?.localCode}
                        />
                      </div>

                      <div className={styles._rightSide}>
                        <GeneralInput
                          placeholder="2123443334"
                          id="phoneNumber"
                          name="phoneNumber"
                          onChange={handleChange}
                          value={values?.phoneNumber}
                          hasError={
                            errors.phoneNumber && touched.phoneNumber
                              ? true
                              : false
                          }
                          onFocus={() => handleOnTouched("phoneNumber")}
                          errorMessage={errors.phoneNumber}
                          status={changeInputStatus(
                            values.phoneNumber,
                            errors.phoneNumber && touched.phoneNumber
                          )}
                        ></GeneralInput>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6}>
                    <p className={styles._label}>{t("cell_phone")}</p>
                    <div className={styles._row}>
                      <div className={styles._leftSide}>
                        <GeneralSelect
                          options={countries.countries}
                          id="mobileCode"
                          name="mobileCode"
                          onChange={(event: any) =>
                            handleLocalCode(event, "mobileCode")
                          }
                          value={values?.mobileCode}
                        />
                      </div>

                      <div className={styles._rightSide}>
                        <GeneralInput
                          placeholder="4143443334"
                          id="cellPhoneNumber"
                          name="cellPhoneNumber"
                          onChange={handleChange}
                          value={values?.cellPhoneNumber}
                          hasError={
                            errors.cellPhoneNumber && touched.cellPhoneNumber
                              ? true
                              : false
                          }
                          onFocus={() => handleOnTouched("cellPhoneNumber")}
                          errorMessage={errors.cellPhoneNumber}
                          status={changeInputStatus(
                            values.cellPhoneNumber,
                            errors.cellPhoneNumber && touched.cellPhoneNumber
                          )}
                        ></GeneralInput>
                      </div>
                    </div>
                  </Grid>
                </>
              )}
              {type == "create" && (
                <>
                  <Grid item xs={12} sm={12} md={12}>
                    <div className={styles._inputParent}>
                      <GeneralInput
                        onChange={(event: any) => inputHandler(event)}
                        value={searchValue}
                        className={styles._noMargin}
                        placeholder="manage_members"
                      />
                      <Icon
                        icon="bx:search"
                        width={18}
                        className={styles._searchIcon}
                        onClick={null}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <GeneralRadioGroup
                      checkValue={(values.users.length && values.users) || []}
                      handleCheckValue={(event: any) =>
                        handleCheckMembers(event.currentTarget?.value)
                      }
                      handleSelectAll={() => selectAllMembers()}
                      currentPage={currentPage}
                      totalPages={totalPages}
                      mapData={paginate(
                        users?.usersForSpace?.items || [],
                        3,
                        currentPage
                      )}
                      leftMethod={previousPage}
                      rightMethod={nextPage}
                      loading={!usersLoading}
                      checkedAll={users?.usersForSpace?.items?.length}
                      multiple
                    />
                  </Grid>
                </>
              )}
            </Grid>

            <div
              className={
                type == "create"
                  ? styles._buttonParent
                  : styles._buttonParentEdit
              }
            >
              <GeneralButton
                text={type == "create" ? "add" : "save"}
                type="submit"
                action={submitForm}
                loading={loading || editLoading}
                disabled={!isValid}
              />
            </div>
          </form>
        </div>
      </GeneralModal>

      <BranchOfficeConfirmation
        open={status}
        code={confirmCode}
        onClose={switchStatus}
      />
    </>
  );
};

export default CreateBrancheOffice;
