import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { Icon } from '@iconify/react'
import { baseUrl } from 'paths'
import Directory from '../Directory'
import { useLazyQuery } from '@apollo/client'
import { WHITELISTS_QUERY } from 'graphql/queries'
import { useSelector } from 'react-redux'
import { userSpaceSelector } from 'store/selectors'
import { CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import SmallPagination from '../SmallPagination'
import CreateWhiteList from '../CreateWhitelist'
import { UseIntermitence } from 'hooks'
import { GeneralInput } from 'components'


const DirectoryList = ({ withHeader = true, search, orderValue, isAllowed = true }: any) => {
  const { t } = useTranslation()
  const { currentSpace } = useSelector(userSpaceSelector)
  const [searchValue, setSearchValue] = useState('')
  const [timer, setTimer] = useState(null)
  const [order, setOrder] = useState('asc');
  const [currentPage, setCurrentPage] = useState(1);

  const { status, switchStatus } = UseIntermitence()

  const [getWhiteList, { data, loading, refetch }] = useLazyQuery(WHITELISTS_QUERY, {
    variables: {
      spaceId: currentSpace?.id,
      search: search || searchValue,
      orderDirection: order || orderValue,
      page: currentPage,
      perPage: 10
    },
    fetchPolicy: "cache-and-network"
  })

  const whitelist = data?.whitelists?.items && data?.whitelists?.items?.length ? data?.whitelists?.items : [null]
  const totalPages = data?.whitelists?.pagination?.totalPages

  const handleInput = (event: any) => {

    const { target: { value } } = event;
    setSearchValue(value)
    if (timer) clearTimeout(timer);

    setTimer(
      setTimeout(() => {
      }, 500),
    );
  };

  const handleChangeOrder = () => setOrder(order == 'asc' ? 'desc' : 'asc')

  useEffect(() => {
    getWhiteList()
  }, [])

  const nextPage = () => {
    if (currentPage <= totalPages - 1) setCurrentPage((currentPage: number) => currentPage + 1)
  }

  const previousPage = () => {
    if (currentPage >= 2) setCurrentPage((currentPage: number) => currentPage - 1)
  }

  const openAddDirectory = () => switchStatus()

  return (
    <div className={styles._parent}>
      {
        withHeader && (
          <div className={styles._listHeaderContainer}>
            <div className={styles._formContainer}>
              <GeneralInput
                onChange={handleInput}
                value={searchValue}
                className={styles._noMargin}
                placeholder={t("search")}
                withIcon
                icon="bx:search"
                iconColor="#909090"
                iconClass={styles._inputIcon}
              />
              <Icon
                icon="bx:sort-alt-2"
                width={26}
                height={26}
                color="#909090"
                className={styles._orderIcon}
                onClick={handleChangeOrder}
              />
            </div>
          </div>
        )
      }

      {
        !loading ?
          whitelist.length &&
          <>
            <div className={whitelist?.length <= 1 ? styles._directoryEmpty : styles._directoryParent}>
              {
                whitelist.map((item: any, index: number) => (
                  <Directory
                    item={item} 
                    key={index} 
                    backgroundColor='#FFF' 
                    onRow={openAddDirectory} 
                    searchValue={(searchValue || search)}
                    isAllowed={isAllowed}
                  />
                ))}
            </div>

            <CreateWhiteList open={status} onClose={switchStatus} refresh={refetch} />
          </>
          : (<div className={styles._loaderParent}>
            <CircularProgress color="primary" size={24} />
          </div>
          )
      }
      {
        (whitelist.length >= 1 && !loading && whitelist.every((item: any) => !!item)) && (
          <div className={styles._paginationParent} >
            <SmallPagination next={nextPage} previous={previousPage} page={currentPage} totalPages={totalPages} />
          </div>
        )
      }

    </div>
  )
}

export default DirectoryList
