import styles from "./styles.module.scss";
import { Icon } from "@iconify/react";
import { Divider } from "@mui/material";
import { baseUrl } from "paths";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userSpaceSelector } from "store/selectors";
import { divider } from "./materialStyles";
import { BRANCH_OFFICE_WALLETS_QUERY } from "graphql/queries";
import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";

const DealFlow = ({ t }: any) => {
  const navigate = useNavigate();
  const { currentBranchOffice } = useSelector(userSpaceSelector);

  const [getBranchOfficeWallets, { data }] = useLazyQuery(
    BRANCH_OFFICE_WALLETS_QUERY,
    {
      variables: {
        branchOfficeId: currentBranchOffice?.id,
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const wallets = data?.branchOfficeWallets || [];

  const actualWallets = (wallets || []).filter(
    (item: any) => item?.wallet?.name !== "puntu"
  );

  useEffect(() => {
    getBranchOfficeWallets();
  }, []);

  return (
    <div className={styles._mainContainer}>
      <>
        <h3 className={styles._barTitle}>{t("my_profile")}</h3>
        <div className={styles._headerContainer}>
          <div className={styles._barTitleContainer}>
            <Icon
              onClick={() => navigate("/profile")}
              icon={"bx-left-arrow-alt"}
              className={styles._iconPointer}
              width={22}
            />
            <h3 className={styles._barTitle}>{t("settings")}</h3>
          </div>
        </div>

        <div className={styles._topContainer}>
          <Divider sx={(theme) => divider(theme, false)} />
        </div>
        <div className={styles._optionContainerTiny}>
          <div className={styles._optionsContent}>
            <div className={styles._iconContainer}>
              <Icon
                icon={"bxs-offer"}
                className={styles._iconPointer}
                width={22}
              />
            </div>
            <div className={styles._textContent}>
              <p className={styles._title}>{t("deal_flow")}</p>
            </div>
          </div>
        </div>
        <div className={styles._topContainer}>
          <Divider sx={(theme) => divider(theme, true)} />
        </div>
        <div className={styles._paymentContainer}>
          <div className={styles._leftContent}>
            <div className={styles._bottomLeftContainer}>
              {actualWallets?.map((item: any, index: number) => (
                <div className={styles._optionContainerSelected} key={index}>
                  <div className={styles._optionsContent}>
                    <div className={styles._iconContainer}>
                      <img
                        src={`${baseUrl}/resources/${item?.wallet?.name}-card-icon.svg`}
                        width={35}
                        alt="card-icon"
                      />
                    </div>
                    <div className={styles._textContent}>
                      <p className={styles._title}>{item?.wallet?.title}</p>
                    </div>
                  </div>
                  <div className={styles._optionsContent}>
                    <p className={styles._subtitle}>
                      {item?.feeValue ? item?.feeValue * 100 : 0}%
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    </div>
  );
};
export default DealFlow;
