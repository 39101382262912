import { gql } from '@apollo/client';

const BANK_TRANSFER_REQUEST = gql`
  mutation CreateTransferRequest($data: CreateTransferRequestInput!) {
    createTransferRequest(data: $data) {
      id
      bankAccountId
      branchOfficeId
      status
    }
  }
`;
export default BANK_TRANSFER_REQUEST;
